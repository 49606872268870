import { Map } from 'immutable';

// eslint-disable-next-line import/no-cycle
import { UpperHandStore } from 'dataStores';
import { LocationActions } from 'sources';

import ScheduleDrawerActions from 'event_mgmt/display/actions/ScheduleDrawerActions.js';

class LocationDataStore extends UpperHandStore {
  constructor() {
    super();

    this.records = Map();

    this.bindListeners({
      listSuccess: [
        LocationActions.listSuccess,
        ScheduleDrawerActions.listLocationSuccess,
      ],

      listError: [
        LocationActions.listError,
        ScheduleDrawerActions.listLocationError,
      ],

      fetchSuccess: [LocationActions.fetchSuccess],
      fetchError: [LocationActions.fetchError],
    });
  }

  listSuccess({ locations }) {
    const fetched = locations.groupBy(x => x.id).map(x => x.first());

    this.records = this.records.merge(fetched);
  }

  listError() {
    // eslint-disable-next-line prefer-rest-params
    this.notifyError('error fetching locations', arguments);
  }

  fetchSuccess(location) {
    this.records = this.records.set(location.id, location);
  }

  fetchError() {
    // eslint-disable-next-line prefer-rest-params
    this.notifyError('error fetching location', arguments);
  }
}

export default alt.createStore(LocationDataStore, 'LocationDataStore');
