import debounce from 'lodash.debounce';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import { List, Set, Map } from 'immutable';

import AvailableTime from 'shared/records/AvailableTime.jsx';
import EventTranslator from 'event_mgmt/shared/translators/EventTranslator.jsx';
import Order from 'event_mgmt/shared/records/Order.jsx';
import OrderItem from 'shared/records/OrderItem';
import OWNER_TYPE from 'calendar/types/OwnerType.jsx';
import QuickScheduleActions from 'calendar/actions/QuickScheduleActions.jsx';
import RegistrationPackage from 'shared/records/RegistrationPackage';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import MembershipDiscountStore from 'event_mgmt/display/stores/MembershipDiscountStore.js';
import MembershipListingStore from 'memberships/stores/MembershipListingStore.jsx';
import QSClientStore from 'calendar/stores/QSClientStore';
import ResourceListingStore from 'resources/stores/ResourceListingStore.js';
import StaffStore from 'shared/stores/StaffStore.jsx';

import CalendarActions from 'calendar/actions/CalendarActions.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import QSClientActions from 'calendar/actions/QSClientActions';

import initATD from 'shared/utils/ATDInitializationUtils.js';
import TentativeRegistration from 'shared/records/TentativeRegistration';
import ClientCreditStore from 'shared/stores/ClientCreditStore';
import ClientCreditActions from 'shared/actions/ClientCreditActions';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';

const moment = extendMoment(Moment);

class QuickScheduleStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.debouncedUpdateTaxIdAPICall = debounce(this.updateTaxIdAPICall, 500);
    this.debouncedAvailableEventsList = debounce(
      this.handleListAvailableEvents,
      800
    );

    this.bindListeners({
      toggleFeeExemption: QuickScheduleActions.TOGGLE_FEE_EXEMPTION,
      toggleFeeExemptionSuccess:
        QuickScheduleActions.TOGGLE_FEE_EXEMPTION_SUCCESS,
      toggleFeeExemptionError: QuickScheduleActions.TOGGLE_FEE_EXEMPTION_ERROR,
      toggleTaxExemption: QuickScheduleActions.TOGGLE_TAX_EXEMPTION,
      updateTaxExemptSuccess: QuickScheduleActions.UPDATE_TAX_EXEMPT_SUCCESS,
      updateTaxExemptError: QuickScheduleActions.UPDATE_TAX_EXEMPT_ERROR,
      updateTaxId: QuickScheduleActions.TAX_ID_UPDATED,
      updateTaxIdSuccess: QuickScheduleActions.UPDATE_TAX_ID_SUCCESS,
      updateTaxIdError: QuickScheduleActions.UPDATE_TAX_ID_ERROR,
      handleApplyCoupon: QuickScheduleActions.APPLY_COUPON,
      handleApplyCouponSuccess: QuickScheduleActions.APPLY_COUPON_SUCCESS,
      handleApplyCouponError: QuickScheduleActions.APPLY_COUPON_ERROR,
      fetchCartSuccess: QuickScheduleActions.FETCH_CART_SUCCESS,
      fetchCartError: QuickScheduleActions.FETCH_CART_ERROR,
      handleOpen: QuickScheduleActions.OPEN,
      handleClose: QuickScheduleActions.CLOSE,
      handleUpdateDateRange: QuickScheduleActions.UPDATE_DATE_RANGE,
      handleCalendarDateChange: CalendarActions.SET_CALENDAR_DATE,
      handleListAvailableEvents: QuickScheduleActions.LIST_AVAILABLE_EVENTS,
      handleListAvailableEventsSuccess:
        QuickScheduleActions.LIST_AVAILABLE_EVENTS_SUCCESS,
      handleListAvailableEventsError:
        QuickScheduleActions.LIST_AVAILABLE_EVENTS_ERROR,
      handleRegistrationUpdate: QuickScheduleActions.UPDATE_REGISTRATION,
      handleRegistrationUpdateSuccess:
        QuickScheduleActions.UPDATE_REGISTRATION_SUCCESS,
      handleRegistrationUpdateError:
        QuickScheduleActions.UPDATE_REGISTRATION_ERROR,
      handleListAutomationsSuccess:
        QuickScheduleActions.LIST_AUTOMATIONS_SUCCESS,
      handleListAutomationsError: QuickScheduleActions.LIST_AUTOMATIONS_ERROR,
      handleSelectEvent: QuickScheduleActions.SELECT_EVENT,
      handleRemoveClient: QuickScheduleActions.REMOVE_CLIENT,
      handleSelectStaff: QuickScheduleActions.SELECT_STAFF,
      handleRemoveStaff: QuickScheduleActions.REMOVE_STAFF,
      handleSelectResource: QuickScheduleActions.SELECT_RESOURCE,
      handleRemoveResource: QuickScheduleActions.REMOVE_RESOURCE,
      handleSelectAutomationPackage:
        QuickScheduleActions.SELECT_AUTOMATION_PACKAGE,
      handleSelectDefaultPackage: QuickScheduleActions.SELECT_DEFAULT_PACKAGE,
      handleSaveAndCharge: QuickScheduleActions.SAVE_AND_CHARGE,
      handleCreateOrderItemSuccess:
        QuickScheduleActions.CREATE_ORDER_ITEM_SUCCESS,
      handleCreateOrderItemError: QuickScheduleActions.CREATE_ORDER_ITEM_ERROR,
      handleDeleteOrderItem: QuickScheduleActions.DELETE_ORDER_ITEM,
      handleDeleteOrderItemsSuccess:
        QuickScheduleActions.DELETE_ORDER_ITEMS_SUCCESS,
      handleDeleteOrderItemsError:
        QuickScheduleActions.DELETE_ORDER_ITEMS_ERROR,
      handleSaveCellWidth: QuickScheduleActions.SAVE_CELL_WIDTH,
      handleSelectClient: [
        QuickScheduleActions.SELECT_CLIENT,
        QSClientActions.FETCH_MANAGING_USER_SUCCESS,
      ],
      handleDiscardOrder: [
        QuickScheduleActions.DELETE_ORDER_SUCCESS,
        QuickScheduleActions.DELETE_ORDER_ERROR,
        QuickScheduleActions.CANCEL_ORDER_SUCCESS,
        QuickScheduleActions.CANCEL_ORDER_ERROR,
      ],
      setAvailableCredits: ClientCreditActions.LIST_SUCCESS,
      handleCheckoutSuccess: QuickScheduleActions.CHECKOUT_SUCCESS,
      updateManualDiscount: QuickScheduleActions.UPDATE_MANUAL_DISCOUNT,
      updateSearchTerm: QuickScheduleActions.updateSearchTerm,
      manageDiscount: QuickScheduleActions.MANAGE_DISCOUNT,
      manageDiscountSuccess: QuickScheduleActions.MANAGE_DISCOUNT_SUCCESS,
      manageDiscountError: QuickScheduleActions.MANAGE_DISCOUNT_ERROR,

      accountCreditsChanged: QuickScheduleActions.ACCOUNT_CREDITS_CHANGED,
      applyAccountCredits: QuickScheduleActions.APPLY_ACCOUNT_CREDITS,
      applyAccountCreditsSuccess:
        QuickScheduleActions.APPLY_ACCOUNT_CREDITS_SUCCESS,
      applyAccountCreditsError:
        QuickScheduleActions.APPLY_ACCOUNT_CREDITS_ERROR,
    });
  }

  reset() {
    this.searchTerm = '';
    this.anchorEl = null;
    this.automations = List();
    this.calendar = null;
    this.cellWidth = 0;
    this.checkoutTotal = 0;
    this.clientMembership = null;
    this.couponIsLoading = false;
    this.creatingOrderItem = false;
    this.creditsRemaining = 0;
    this.dateRange = null;
    this.events = List();
    this.eventDiscount = null;
    this.fetchingAvailableEvents = false;
    this.isOrderSuccess = false;
    this.filteredStaff = List();
    this.filteredResources = List();
    this.managingCustomerUserId = null;
    this.managingCustomerUserVault = false;
    this.open = false;
    this.orderId = null;
    this.orderItem = null;
    this.orderItemId = null;
    this.paymentPlanDescription = null;
    this.packagePricingDescription = null;
    this.order = null;
    this.quickSchedulingStep = 'scheduling';
    this.registrationPackage = new RegistrationPackage({
      quantity: 1,
      tentative_details: List([new TentativeRegistration()]),
    });
    this.registrations = List();
    this.scheduleAvailabilities = Map();
    this.selectedAvailability = null;
    this.selectedClient = null;
    this.selectedEvent = null;
    this.selectedResources = List();
    this.selectedStaff = List();
    this.isQuickDiscountApplied = false;
    this.lockResourcePicker = false;
  }

  accountCreditsChanged(amount) {
    this.orderItem = this.orderItem.set('account_credit_amount', amount);

    if (amount === null) {
      this.applyAccountCredits({ item: this.orderItem });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  applyAccountCredits({ item }) {
    uhApiClient.put({
      url: `order_items/${item.id}`,
      data: JSON.stringify({
        attributes: new OrderItem(item).toServer(),
        fields: ['account_credit_amount'],
      }),
      success: QuickScheduleActions.applyAccountCreditsSuccess,
      error: QuickScheduleActions.applyAccountCreditsError,
    });
  }

  applyAccountCreditsSuccess() {
    this.fetchCart();
  }

  applyAccountCreditsError(...args) {
    this.notifyError('Error applying account credits', args);
  }

  handleApplyCoupon({ code }) {
    this.couponIsLoading = true;
    return uhApiClient.patch({
      url: `orders/${this.orderId}`,
      data: JSON.stringify({
        attributes: { coupon_code: code },
        fields: ['coupon', 'order_items'],
      }),
      success: QuickScheduleActions.applyCouponSuccess,
      error: QuickScheduleActions.applyCouponError,
    });
  }

  handleApplyCouponSuccess(data) {
    this.order = new Order(data);
    this.couponIsLoading = false;
  }

  handleApplyCouponError(...args) {
    this.couponIsLoading = false;
    this.notifyError('Error processing coupon code.', args);
  }

  toggleTaxExemption(taxExempt) {
    this.taxExempt = taxExempt;

    return uhApiClient.patch({
      url: `orders/${this.orderId}`,
      data: JSON.stringify({
        attributes: { tax_exempt: taxExempt },
        fields: ['coupon', 'order_items'],
      }),
      success: QuickScheduleActions.updateTaxExemptSuccess,
      error: QuickScheduleActions.updateTaxExemptError,
    });
  }

  updateTaxExemptSuccess(data) {
    this.order = new Order(data);
  }

  updateTaxExemptError(...args) {
    this.notifyError('Error tax exempt', args);
  }

  updateTaxId(value) {
    this.taxId = value;
    this.debouncedUpdateTaxIdAPICall();
  }

  toggleFeeExemption(feeExempt) {
    return uhApiClient.patch({
      url: `orders/${this.orderId}`,
      data: JSON.stringify({
        attributes: { fee_exempt: feeExempt },
        fields: ['coupon', 'order_items'],
      }),
      success: QuickScheduleActions.updateTaxExemptSuccess,
      error: QuickScheduleActions.updateTaxExemptError,
    });
  }

  toggleFeeExemptionSuccess(data) {
    this.order = new Order(data);
  }

  toggleFeeExemptionError(...args) {
    this.notifyError('Error fee exempt', args);
  }

  updateSearchTerm(value) {
    this.searchTerm = value;
    this.fetchingAvailableEvents = true;
    this.debouncedAvailableEventsList();
  }

  updateTaxIdAPICall() {
    return uhApiClient.patch({
      url: `orders/${this.orderId}`,
      data: JSON.stringify({
        attributes: { tax_id: this.taxId },
        fields: ['coupon', 'order_items'],
      }),
      success: QuickScheduleActions.updateTaxIdSuccess,
      error: QuickScheduleActions.updateTaxIdError,
    });
  }

  updateTaxIdSuccess(data) {
    this.order = new Order(data);
  }

  updateTaxIdError(...args) {
    this.notifyError('error updating order tax id', args);
  }

  handleOpen({ cell, dateRange, calendar }) {
    this.waitFor([StaffStore, ResourceListingStore]);

    this.anchorEl = cell;
    this.open = true;
    this.dateRange = dateRange;
    this.calendar = calendar;

    if (calendar && calendar.ownerType === OWNER_TYPE.STAFF) {
      const { allStaff } = StaffStore.getState();

      this.handleSelectStaff(allStaff.find(s => s.id === calendar.id));
    }

    this.handleListAvailableEvents();
  }

  handleClose() {
    this.reset();
  }

  handleUpdateDateRange(dateRange) {
    this.dateRange = dateRange;

    this.registrationPackage = this.registrationPackage.update(
      'tentative_details',
      (ls = List()) =>
        ls.map(r =>
          r.merge({
            starts_at: dateRange.start,
            ends_at: dateRange.end,
          })
        )
    );
    this.setAvailableResourceAndStaff();
    this.handleListAvailableEvents();
  }

  setAvailableResourceAndStaff() {
    if (!this.selectedEvent) {
      return;
    }

    const formattedStart = moment(this.dateRange.start).format('HH:mm:ss');
    const { allStaff } = StaffStore.getState();
    const { resources } = ResourceListingStore.getState();
    const selectedSlotData =
      this.selectedAvailability &&
      this.selectedAvailability
        .filter(ar => ar.start_time === formattedStart)
        .first();

    if (this.selectedEvent.getSchedule().resource_usage_mode === 'use_all') {
      this.selectedResources = resources.filter(resource =>
        selectedSlotData.resources.some(
          item => item.resource_id === resource.id
        )
      );
    } else {
      this.filteredResources = resources.filter(resource =>
        selectedSlotData.resources.some(
          item => item.resource_id === resource.id
        )
      );

      if (this.calendar) {
        // Check if resource from clicked column is added on Staff&Resources tab on edit event page
        const isEventResource = selectedSlotData.resources.some(
          item => item.resource_id === this.calendar.id
        );

        if (isEventResource) {
          // If resource is added to event lock picker and select resource from clicked column
          this.lockResourcePicker = true;
          this.selectedResources = this.filteredResources.filter(
            resource => resource.id === this.calendar.id
          );
          this.setRegistrationPackageResources();
        } else {
          // If not unlock picker and allow resource selection
          this.lockResourcePicker = false;
        }
      }
    }

    const schedule = this.selectedEvent.getSchedule();
    const useAllStaff = schedule.staff_usage_mode === 'use_all';

    if (!this.selectedEvent.allow_staff_selection && useAllStaff) {
      this.selectedStaff = allStaff.filter(staff =>
        selectedSlotData.staff.some(item => item.staff_id === staff.id)
      );
    } else {
      this.filteredStaff = allStaff.filter(staff =>
        selectedSlotData.staff.some(item => item.staff_id === staff.id)
      );
    }
  }

  selectAvailabilityFromDateRange() {
    const availability = this.scheduleAvailabilities.get(
      this.selectedEvent?.id
    );

    this.selectedAvailability = availability;

    if (!this.selectedAvailability) return null;

    const bestAvailableTime =
      availability.find(a => {
        const { start } = a.toSessionRange(this.selectedEvent, customerTZ());
        const currentStart = this.dateRange.start;

        // Use this availability if the hour and minute
        // match the start of the date range
        return (
          start.hour() === currentStart.hour() &&
          start.minute() === currentStart.minute()
        );
      }) || availability.first();

    return bestAvailableTime;
  }

  handleCalendarDateChange(newDate) {
    if (!this.dateRange) return;

    const updateDate = date =>
      date.year(newDate.year()).month(newDate.month()).date(newDate.date());

    this.dateRange = moment.range(
      updateDate(this.dateRange.start),
      updateDate(this.dateRange.end)
    );
  }

  handleListAvailableEvents() {
    const data = {
      start_date: this.dateRange.start.format(),
      end_date: this.dateRange.end.format(),
      staff_ids: this.selectedStaff.map(s => s.id).toArray(),
      search: this.searchTerm,
    };
    this.fetchingAvailableEvents = true;

    uhApiClient.get({
      url: '/available_events',
      data,
      success: QuickScheduleActions.listAvailableEventsSuccess,
      error: QuickScheduleActions.listAvailableEventsError,
    });
  }

  handleListAvailableEventsSuccess(data) {
    const { schedule_availabilities: scheduleAvailabilities, events } = data;

    this.scheduleAvailabilities = Map().withMutations(m => {
      Object.entries(scheduleAvailabilities).map(([eventId, availableTimes]) =>
        m.set(
          Number.parseInt(eventId, 10),
          List(availableTimes.map(a => new AvailableTime(a)))
        )
      );
    });

    this.events = events.map(e => new EventTranslator(e).toClient());
    this.fetchingAvailableEvents = false;
    this.selectAvailabilityFromDateRange();
  }

  handleListAvailableEventsError(...args) {
    this.reset();
    this.notifyError('listAvailableEventsError', args);
  }

  handleListEventAutomations() {
    if (!this.selectedEvent) return;

    uhApiClient.get({
      url: 'automation_template_descriptions',
      data: { event_ids: [this.selectedEvent.id] },
      success: QuickScheduleActions.listAutomationsSuccess,
      error: QuickScheduleActions.listAutomationsError,
    });
  }

  handleListAutomationsSuccess(data) {
    const atds = data.automation_template_descriptions
      .map(atd => initATD(atd))
      .filter(atd => atd && atd.event_id === this.selectedEvent.id);

    this.automations = List(atds);

    this.paymentPlanDescription =
      this.automations.find(atd => atd.isRecurringPaymentPlan()) || null;

    this.packagePricingDescription =
      this.automations.find(atd => atd.isPackagePricing()) || null;

    this.updateCheckoutTotal();
  }

  handleListAutomationsError() {
    this.reset();
  }

  handleSelectEvent(event) {
    this.waitFor([StaffStore, ResourceListingStore]);

    this.selectedEvent = this.events.find(e => e.id === event.id);

    const bestAvailableTime = this.selectAvailabilityFromDateRange();

    if (bestAvailableTime) {
      this.handleUpdateDateRange(
        bestAvailableTime.toSessionRange(this.selectedEvent, customerTZ())
      );
    }

    const schedule = this.selectedEvent.getSchedule();
    const useAllStaff = schedule.staff_usage_mode === 'use_all';

    if (!this.selectedEvent.allow_staff_selection && useAllStaff) {
      const staffIds = List(schedule.customer_user_ids);

      staffIds.forEach(id => {
        const staff = StaffStore.getState().allStaff.find(s => s.id === id);

        if (staff) {
          this.handleSelectStaff(staff);
        }
      });
    } else {
      this.handleSelectStaff(this.selectedStaff.first());
    }

    this.registrationPackage = this.registrationPackage
      .set('event_id', this.selectedEvent.id)
      .set('quantity', 1)
      .setIn(['tentative_details', 0, 'schedule_id'], schedule.id)
      .set('automation_template_description', null)
      .set('automation_template_description_id', null)
      .set('automation_option_uuid', null);

    this.setAvailableCredits();
    this.updateCheckoutTotal();
    this.handleListEventAutomations();
  }

  handleRegistrationUpdate() {
    this.waitFor(ClientCreditStore);

    if (this.creditsRemaining > 0) {
      const tentativeRegistration = new TentativeRegistration({
        client_id: this.selectedClient.id,
        schedule_id: this.selectedEvent.getSchedule().id,
        starts_at: this.dateRange.start,
        ends_at: this.dateRange.end,
        staff_ids: this.selectedStaff.map(s => s.id),
        resource_ids: this.selectedResources.map(r => r.id).toSet(),
      });
      uhApiClient.post({
        url: '/registrations/schedule',
        data: JSON.stringify({
          attributes: [tentativeRegistration.toServer()],
        }),
        success: QuickScheduleActions.updateRegistrationSuccess,
        error: QuickScheduleActions.updateRegistrationError,
      });
    }
  }

  handleRegistrationUpdateSuccess() {
    this.reset();
    const message = new TranslatableMessage({
      id: 'calendar.quick_schedule.successfully_scheduled',
    });

    return MessageWindowActions.addMessage.defer(message);
  }

  handleRegistrationUpdateError(...args) {
    this.notifyError('Error updating registration: ', args);
  }

  handleSelectClient() {
    this.waitFor(
      QSClientStore,
      MembershipDiscountStore,
      MembershipListingStore
    );

    const { client, managingCustomerUser, clientIsValid, loadingManager } =
      QSClientStore.getState();

    // The selected client is a managed customer user. Wait for the manger to load.
    if (loadingManager) {
      return false;
    }

    this.selectedClient = client;
    this.managingCustomerUserId = managingCustomerUser.id;
    this.managingCustomerUserVault = managingCustomerUser.access_to_vault;

    // If the profile is not complete there is no need to fetch any data associated
    // to the client. User will have to continue in POS.
    if (!clientIsValid) {
      return true;
    }

    // Should we use client.hasAccessTo(this.selectedEvent) to check
    // access and display error if not --joshuamanns

    const memberships = MembershipListingStore.getState().records;

    this.clientMembership = memberships.find(
      m => this.selectedClient.active_membership_id === m.id
    );

    this.setRegistrationPackageClient();

    const membershipEventDiscounts =
      MembershipDiscountStore.getState().findByEventTypeId(
        this.selectedEvent && this.selectedEvent.event_type.id
      );

    this.eventDiscount = membershipEventDiscounts.find(
      med =>
        med.membership_id ===
        (this.selectedClient && this.selectedClient.active_membership_id)
    );

    return this.setAvailableCredits();
  }

  handleRemoveClient() {
    this.selectedClient = null;
    this.setRegistrationPackageClient();
  }

  setRegistrationPackageClient() {
    this.registrationPackage = this.registrationPackage
      .set(
        'client_ids',
        this.selectedClient ? Set([this.selectedClient.id]) : null
      )
      .setIn(
        ['tentative_details', 0, 'client_id'],
        this.selectedClient ? this.selectedClient.id : null
      );
  }

  handleSelectStaff(staff) {
    if (!staff) return;
    if (!this.selectedStaff.find(s => s.id === staff.id))
      this.selectedStaff = this.selectedStaff.push(staff);

    this.setRegistrationPackageStaff();
  }

  handleRemoveStaff(staff) {
    this.selectedStaff = this.selectedStaff.filter(s => s.id !== staff.id);

    this.setRegistrationPackageStaff();
  }

  setRegistrationPackageStaff() {
    const schedule = this.selectedEvent?.getSchedule();
    const allowStaffSelection = Boolean(
      this.selectedEvent?.allow_staff_selection
    );
    const useAllStaff = schedule?.staff_usage_mode === 'use_all';
    const staffIds =
      this.selectedEvent && (allowStaffSelection || !useAllStaff)
        ? this.selectedStaff.map(s => s.id)
        : List();

    this.registrationPackage = this.registrationPackage.setIn(
      ['tentative_details', 0, 'staff_ids'],
      staffIds
    );
  }

  handleSelectResource(resource) {
    if (resource) {
      this.selectedResources = this.selectedResources.push(resource);
      this.setRegistrationPackageResources();
    }
  }

  handleRemoveResource(resource) {
    this.selectedResources = this.selectedResources.filter(
      r => r.id !== resource.id
    );
    this.setRegistrationPackageResources();
  }

  setRegistrationPackageResources() {
    this.registrationPackage = this.registrationPackage.setIn(
      ['tentative_details', 0, 'resource_ids'],
      this.selectedResources.map(r => r.id).toSet()
    );
  }

  handleSelectAutomationPackage(uuid) {
    if (
      this.packagePricingDescription &&
      this.registrationPackage.automation_option_uuid !== uuid
    ) {
      const selectedPackage = this.packagePricingDescription.getOption(uuid);

      this.registrationPackage = this.registrationPackage
        .setSize(selectedPackage.quantity, this.selectedClient.id)
        .set('automation_template_description', this.packagePricingDescription)
        .set(
          'automation_template_description_id',
          this.packagePricingDescription.id
        )
        .set('automation_option_uuid', uuid);
    }

    this.updateCheckoutTotal();
  }

  handleSelectDefaultPackage(size) {
    this.registrationPackage = this.registrationPackage.setSize(size);

    this.updateCheckoutTotal();
  }

  setAvailableCredits() {
    this.waitFor(ClientCreditStore);

    if (this.selectedEvent && this.selectedClient) {
      this.creditsRemaining = ClientCreditStore.getState().clientCredits.getIn([
        this.selectedEvent.id,
        this.selectedClient.id,
        'unlimited',
      ])
        ? Infinity
        : ClientCreditStore.getState().clientCredits.getIn(
            [
              this.selectedEvent.id,
              this.selectedClient.id,
              'credits_remaining',
            ],
            0
          );
    } else {
      this.creditsRemaining = 0;
    }
  }

  handleSaveAndCharge() {
    this.createOrderItem();
  }

  buildOrderItem() {
    this.orderItem = new OrderItem({
      orderable: this.registrationPackage,
      orderable_type: 'registration_package',
      quantity: 1,
    });
  }

  createOrderItem() {
    this.buildOrderItem();

    this.creatingOrderItem = true;

    return uhApiClient.post({
      url: 'order_items',
      data: JSON.stringify({
        attributes: this.orderItem.toServer(),
        customer_user_id: this.managingCustomerUserId,
      }),
      success: QuickScheduleActions.createOrderItemSuccess,
      error: QuickScheduleActions.createOrderItemError,
    });
  }

  handleCreateOrderItemSuccess(data) {
    this.orderItem = new OrderItem(data);
    this.orderItemId = data.id;
    this.orderId = data.order_id;

    if (this.selectedClient === null) {
      this.deleteOrderItem();
      return true;
    }

    this.updateDiscountStatuses();

    return this.fetchCart();
  }

  fetchCart() {
    return uhApiClient.get({
      url: `/orders/${this.orderId}`,
      data: { fields: ['order_items'] },
      success: QuickScheduleActions.fetchCartSuccess,
      error: QuickScheduleActions.fetchCartError,
    });
  }

  fetchCartSuccess(data) {
    this.orderItemId = data.order_items[0].id;
    this.orderId = data.id;

    if (this.selectedClient === null) {
      this.deleteOrderItem();
      return true;
    }
    this.orderItem = new OrderItem(data.order_items[0]);
    this.order = new Order(data);

    this.quickSchedulingStep = 'paymentSelect';
    this.creatingOrderItem = false;
    this.updateDiscountStatuses();
    return true;
  }

  fetchCartError(...args) {
    this.notifyError('error while fetching cart', args);
  }

  handleCreateOrderItemError(...args) {
    this.deleteOrderItem();

    this.notifyError('Error creating order item.', args);
  }

  handleCheckoutSuccess() {
    this.quickSchedulingStep = 'summary';
    const message = new TranslatableMessage({
      id: 'calendar.quick_schedule.successfully_scheduled',
    });
    MessageWindowActions.addMessage.defer(message);
  }

  handleDeleteOrderItem() {
    this.deleteOrderItem();
  }

  deleteOrderItem() {
    if (!this.orderItemId) {
      this.discardOrder();
      return;
    }

    uhApiClient.delete({
      url: `/order_items/${this.orderItemId}`,
      success: QuickScheduleActions.deleteOrderItemsSuccess,
      error: QuickScheduleActions.deleteOrderItemsError,
    });
  }

  handleDeleteOrderItemsSuccess() {
    this.discardOrder();
  }

  handleDeleteOrderItemsError(...args) {
    this.discardOrder();
    this.notifyError('Error processing order.', args);
  }

  discardOrder() {
    return currentCustomer().use_stripe
      ? uhApiClient.delete({
          url: `/orders/${this.orderId}`,
          success: QuickScheduleActions.deleteOrderSuccess,
          error: QuickScheduleActions.deleteOrderError,
        })
      : uhApiClient.post({
          url: `/orders/${this.orderId}/cancel`,
          success: QuickScheduleActions.cancelOrderSuccess,
          error: QuickScheduleActions.cancelOrderError,
        });
  }

  handleDiscardOrder() {
    this.reset();
  }

  updateManualDiscount(discount = null) {
    this.orderItem = this.orderItem
      .merge({ manual_discount: discount })
      .validate();
    if (this.orderItem.isMembershipItem()) {
      this.removeDuplicateSubscriptionPackages(
        this.orderItem.orderable.customer_user_ids
      );
    }
    this.updateDiscountStatuses();
  }

  removeDuplicateSubscriptionPackages(customerUserIds) {
    this.cart = this.cart.update('order_items', (orderItems = List()) =>
      orderItems
        .map(oi => {
          if (oi.isMembershipItem()) {
            return oi.updateIn(['orderable', 'customer_user_ids'], ids =>
              ids.subtract(customerUserIds)
            );
          }
          return oi;
        })
        .filterNot(
          oi =>
            oi.isMembershipItem() &&
            oi.getIn(['orderable', 'customer_user_ids']).size === 0
        )
    );
    this.orderItem = this.cart.get('order_items').first();
  }

  updateDiscountStatuses() {
    this.isQuickDiscountApplied =
      !!this.orderItem.get('applied_adjustments').length &&
      this.orderItem.get('applied_adjustments').some(a => a.isManual());
  }

  manageDiscount(applying = false) {
    this.isLoadingDiscount = true;
    const payload = {
      value: null,
      discount_type: null,
    };
    if (this.orderItem.get('manual_discount') && applying) {
      payload.value = parseFloat(
        this.orderItem.get('manual_discount').value,
        10
      );
      payload.discount_type = this.orderItem.get('manual_discount').type;
    }

    return uhApiClient.post({
      url: `order_items/${this.orderItem.id}/discount`,
      data: JSON.stringify({ attributes: payload }),
      success: QuickScheduleActions.manageDiscountSuccess,
      error: QuickScheduleActions.manageDiscountError,
    });
  }

  manageDiscountSuccess() {
    this.isLoadingDiscount = false;
    this.fetchCart();
  }

  manageDiscountError({ ...args }) {
    this.isLoadingDiscount = false;
    this.notifyError('error while managing discount', args);
  }

  updateCheckoutTotal() {
    let price = 0;
    if (this.creditsRemaining > 0) {
      this.checkoutTotal = 0;
      return;
    }

    if (this.packagePricingDescription) {
      const uuid = this.registrationPackage.get('automation_option_uuid');
      const option = this.packagePricingDescription.getOption(uuid);

      price = option ? option.price : 0;
    } else {
      price =
        this.registrationPackage.quantity * this.selectedEvent.price * 100;
    }

    this.checkoutTotal = price;
  }

  handleSaveCellWidth(cellWidth) {
    if (cellWidth) this.cellWidth = cellWidth;
  }
}

export default alt.createStore(QuickScheduleStore, 'QuickScheduleStore');
