import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@upperhand/playmaker';
import PropTypes from 'prop-types';

import StaffList from 'containers/events/admin/sessionSummaryDrawer/components/details/components/StaffList.jsx';
import Resources from 'containers/events/admin/sessionSummaryDrawer/components/details/components/Resources.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import { SessionDataStore, EventDataStore } from 'dataStores';
import DetailsStore from 'containers/events/admin/sessionSummaryDrawer/components/details/store';

function EntityDetails({
  type = 'staff',
  sessionId = null,
  detailsStore = {},
  sessionDataStore = {},
}) {
  const intl = useIntl();
  const {
    selectableStaff,
    staffIds,
    searchedStaffName,
    resourceIds,
    selectableResources,
    searchedResourceName,
  } = detailsStore;
  const { sessions } = sessionDataStore;
  const session = sessions.get(sessionId);
  const sessionStaffIds = session.get('staff_ids');
  const sessionResourceIds = session.get('resource_ids');

  switch (type) {
    case 'staff':
      return (
        <>
          <Typography variant="caption">
            {t('.staff', intl, __filenamespace)}
          </Typography>
          <StaffList
            simpleList
            isAddingStaff
            intl={intl}
            selectableStaff={selectableStaff}
            staffIds={staffIds}
            searchedStaffName={searchedStaffName}
            sessionStaffIds={sessionStaffIds}
          />
        </>
      );
    case 'resource':
      return (
        <>
          <Typography variant="caption">
            {t('.resource', intl, __filenamespace)}
          </Typography>
          <Resources
            simpleList
            isAddingResource
            intl={intl}
            resourceIds={resourceIds}
            selectableResources={selectableResources}
            searchedResourceName={searchedResourceName}
            sessionResourceIds={sessionResourceIds}
          />
        </>
      );
    default:
      return null;
  }
}

EntityDetails.propTypes = {
  type: PropTypes.string,
  sessionId: PropTypes.string,
  detailsStore: PropTypes.object,
  sessionDataStore: PropTypes.object,
};

export default compose(
  memo,
  altContainer({
    stores: {
      detailsStore: DetailsStore,
      sessionDataStore: SessionDataStore,
      eventDataStore: EventDataStore,
    },
  })
)(EntityDetails);
