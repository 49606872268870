import { List } from 'immutable';
import AutomationTemplateDescriptionActions from 'shared/actions/AutomationTemplateDescriptionActions.jsx';
import initATD from 'shared/utils/ATDInitializationUtils.js';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

const url = (options = {}) => {
  const opts = { ...options };
  const idPart = opts.id ? `/${opts.id}` : '';

  delete opts.id;

  return `automation_template_descriptions${idPart}`;
};

class AutomationTemplateDescriptionStore extends UpperHandStore {
  constructor() {
    super();
    this.description = null;
    this.descriptions = List();
    this.systemLevelDescriptions = List();
    this.findById = this.findById.bind(this);
    this.byTemplateType = type =>
      this.descriptions.filter(
        description => description.template_type === type
      );
    this.forEvent = id =>
      this.descriptions.filter(d => id && d.event_id === id);
    this.currentListOptions = {};

    this.bindListeners({
      handleList: AutomationTemplateDescriptionActions.list,
      handleListSuccess: AutomationTemplateDescriptionActions.listSuccess,
      handleListError: AutomationTemplateDescriptionActions.listError,

      handleFetch: AutomationTemplateDescriptionActions.fetch,
      handleFetchSuccess: AutomationTemplateDescriptionActions.fetchSuccess,
      handleFetchError: AutomationTemplateDescriptionActions.fetchError,

      handleCreate: AutomationTemplateDescriptionActions.create,
      handleCreateSuccess: AutomationTemplateDescriptionActions.createSuccess,
      handleCreateError: AutomationTemplateDescriptionActions.createError,

      handleUpdate: AutomationTemplateDescriptionActions.update,
      handleUpdateSuccess: AutomationTemplateDescriptionActions.updateSuccess,
      handleUpdateError: AutomationTemplateDescriptionActions.updateError,

      handleDelete: AutomationTemplateDescriptionActions.delete,
      handleDeleteSuccess: AutomationTemplateDescriptionActions.deleteSuccess,
      handleDeleteError: AutomationTemplateDescriptionActions.deleteError,

      handleUpdateStore: AutomationTemplateDescriptionActions.updateStore,
      handleDeleteParameter:
        AutomationTemplateDescriptionActions.deleteParameter,
      handleDeselectCurrent:
        AutomationTemplateDescriptionActions.deselectCurrent,

      handleUpdateStoreKeypath:
        AutomationTemplateDescriptionActions.updateStoreKeypath,
    });
  }

  handleUpdateStore(data) {
    const newAttributes = data.toJSON ? data.toJSON() : data;
    if (data.id) {
      this.updateFromPreexistingDescription(newAttributes);
    } else {
      let currentAttributes = {};

      if (this.description) {
        currentAttributes = this.description.toJSON
          ? this.description.toJSON()
          : this.description;
      }

      this.description = this.convertToAutomationTemplateDescription(
        Object.assign(currentAttributes, newAttributes)
      );
    }
  }

  // TODO: Replace handleUpdateStore with this code.
  handleUpdateStoreKeypath([keypath, value]) {
    if (keypath && keypath.length) {
      this.description = this.description.setIn(keypath, value);
    } else {
      this.description = value;
    }
  }

  handleDeleteParameter(keypath) {
    this.description = this.description.deleteIn(keypath);
  }

  handleList(options = {}) {
    const theUrl = url(options);

    this.currentListOptions = { per_page: 50, ...options };

    return uhApiClient.get({
      url: theUrl,
      data: this.currentListOptions,
      success: AutomationTemplateDescriptionActions.listSuccess,
      error: AutomationTemplateDescriptionActions.listError,
    });
  }

  handleListSuccess(data) {
    this.descriptions = this.sorted(
      this.descriptions.withMutations(collection => {
        data.automation_template_descriptions.forEach(atd => {
          const index = this.descriptions.findIndex(d => d.id === atd.id);
          if (index >= 0) {
            collection.setIn([index], initATD(atd));
          } else {
            collection.push(initATD(atd));
          }
        });
      })
    );
    if (data.total_count > this.descriptions.size) {
      this.handleList(
        Object.assign(this.currentListOptions, {
          page: data.page + 1,
        })
      );
    } else {
      this.currentListOptions = {};
    }
  }

  handleListError(...args) {
    this.notifyError(
      'error while listing automation template descriptions',
      args
    );
  }

  // eslint-disable-next-line class-methods-use-this
  handleFetch(id) {
    uhApiClient.get({
      url: url({ id }),
      success: AutomationTemplateDescriptionActions.fetchSuccess,
      error: AutomationTemplateDescriptionActions.fetchError,
    });
  }

  handleFetchSuccess(attributes) {
    this.description = initATD(attributes);
  }

  handleFetchError(...args) {
    this.notifyError(
      'error while fetching automation template description',
      args
    );
  }

  handleCreate() {
    if (this.description) {
      uhApiClient.post({
        url: url(),
        data: this.payload(),
        success: AutomationTemplateDescriptionActions.createSuccess,
        error: AutomationTemplateDescriptionActions.createError,
      });
    }
  }

  handleCreateSuccess(attributes) {
    this.updateFromNewDescription(attributes);
  }

  handleCreateError(...args) {
    this.notifyError(
      'error while creating automation template description',
      args
    );
  }

  handleUpdate(id) {
    if (!!this.description && this.description.id === id) {
      uhApiClient.put({
        url: url({ id }),
        data: this.payload(),
        success: AutomationTemplateDescriptionActions.updateSuccess,
        error: AutomationTemplateDescriptionActions.updateError,
      });
    }
  }

  handleUpdateSuccess(attributes) {
    this.updateFromPreexistingDescription(attributes);
  }

  handleUpdateError(...args) {
    this.notifyError(
      'error while updating automation template description',
      args
    );
  }

  // eslint-disable-next-line class-methods-use-this
  handleDelete(id) {
    return uhApiClient.delete({
      url: url({ id }),
      success: AutomationTemplateDescriptionActions.deleteSuccess,
      error: AutomationTemplateDescriptionActions.deleteError,
    });
  }

  handleDeleteSuccess(attributes) {
    this.removeDescription(attributes);
  }

  handleDeleteError(...args) {
    this.notifyError(
      'error while deleting automation template description',
      args
    );
  }

  handleDeselectCurrent() {
    this.description = null;
  }

  /*
   * PRIVATE HELPERS
   */
  findById(id) {
    return this.descriptions.find(description => description.id === id);
  }

  payload() {
    const payload = this.description.toJSON();
    delete payload.id;
    if (payload.event_id === null || payload.event_id === undefined) {
      delete payload.event_id;
    }
    return JSON.stringify({ attributes: payload });
  }

  // eslint-disable-next-line class-methods-use-this
  sorted(descriptions) {
    return descriptions.sort((a, b) => {
      if (!a.name) {
        if (!b.name) {
          return 0;
        }
        return -1;
      }
      if (!b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  removeDescription(attributes) {
    const index = this.descriptions.findIndex(d => d.id === attributes.id);
    this.description = null;
    this.descriptions = this.sorted(this.descriptions.delete(index));
  }

  updateFromPreexistingDescription(attributes) {
    const index = this.descriptions.findIndex(d => d.id === attributes.id);
    this.description = initATD(attributes);
    this.descriptions = this.sorted(
      this.descriptions.setIn([index], this.description)
    );
  }

  updateFromNewDescription(attributes) {
    this.description = initATD(attributes);
    this.descriptions = this.sorted(this.descriptions.push(this.description));
  }
}

export default alt.createStore(
  AutomationTemplateDescriptionStore,
  'AutomationTemplateDescriptionStore'
);
