import React, { useRef, useEffect } from 'react';

import Content from 'event_mgmt/index/components/_Content.jsx';
import Header from 'event_mgmt/index/components/_Header.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';

import AutomationTemplateDescriptionActions from 'shared/actions/AutomationTemplateDescriptionActions.jsx';
import EventListActions from 'event_mgmt/index/actions/EventListActions.jsx';
import StoreActions from 'shared/actions/StoreActions.jsx';

import AutomationTemplateDescriptionStore from 'shared/stores/AutomationTemplateDescriptionStore.jsx';
import EventListStore from 'event_mgmt/index/stores/EventListStore.jsx';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import FilterStore from 'event_mgmt/index/stores/FilterStore.jsx';
import SelectedEventsStore from 'event_mgmt/index/stores/SelectedEventsStore.jsx';

import '../styles.scss';

function EventMgmtIndex(props) {
  const prevEventsRef = useRef();
  const { readOnly, eventList } = props;
  const { events } = eventList;

  useEffect(() => {
    StoreActions.prepareForReuse();
    EventListActions.eventsDeselected();
  }, []);

  useEffect(() => {
    const shouldListAtd =
      prevEventsRef.current &&
      !events.equals(prevEventsRef.current) &&
      events?.size > 0;

    if (shouldListAtd) {
      const eventIds = events.map(event => event.id);

      AutomationTemplateDescriptionActions.list({
        event_ids: eventIds.toJS(),
      });
    }

    prevEventsRef.current = events;
  }, [events]);

  return (
    <div className="events">
      <Header />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Content readOnly={readOnly} {...props} />
    </div>
  );
}

export default altContainer({
  stores: {
    automations: AutomationTemplateDescriptionStore,
    eventList: EventListStore,
    eventTypes: EventTypeListingStore,
    eventStore: EventStore,
    filter: FilterStore,
    selectedEvents: SelectedEventsStore,
  },
})(EventMgmtIndex);
