import debounce from 'lodash.debounce';
import { OrderedSet, Map } from 'immutable';

import { WaitlistSource } from 'sources';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import WaitlistActions from './actions';

class WaitlistSessionDrawerTabStore extends UpperHandStore {
  constructor() {
    super();

    this.debouncedSearchWaitlist = debounce(
      () => this.listWaitlist(false),
      500
    );
    this.reset();
    this.bindListeners({
      mounted: WaitlistActions.mounted,
      searchWaitlist: WaitlistActions.searchWaitlist,
      selectEntry: WaitlistActions.selectEntry,

      listWaitlist: WaitlistActions.listWaitlist,
      listWaitlistSuccess: WaitlistActions.listWaitlistSuccess,
      listWaitlistError: WaitlistActions.listWaitlistError,

      waitlistAddToEvent: WaitlistActions.waitlistAddToEvent,
      waitlistAddToEventSuccess: WaitlistActions.waitlistAddToEventSuccess,
      waitlistAddToEventError: WaitlistActions.waitlistAddToEventError,

      removeEntry: WaitlistActions.removeEntry,
      removeEntrySuccess: WaitlistActions.removeEntrySuccess,
      removeEntryError: WaitlistActions.removeEntryError,
    });
  }

  reset() {
    this.sessionId = null;
    this.entryIds = OrderedSet();
    this.selectedEntryIds = OrderedSet();
    this.entriesLoading = false;
    this.totalEntries = 0;
    this.joinedEntries = 0;
    this.pagination = Map({
      page: 1,
      per_page: 50,
      total_count: 0,
    });
    this.filters = Map({
      search: '',
    });
  }

  mounted({ sessionId, initial = true }) {
    this.reset();
    this.sessionId = sessionId;
    this.listWaitlist(initial);
  }

  // Need to get the total number of joined entries to calculate the progress bar and counter
  getJoinedEntries() {
    this.entriesLoading = true;

    WaitlistSource.listEntries({
      params: {
        page: 1,
        per_page: 1,
        session_id: this.sessionId,
        statuses: ['joined'],
      },
      success: ({ totalCount }) => {
        this.entriesLoading = false;
        this.joinedEntries = totalCount;
      },
      error: (...args) => {
        this.entriesLoading = false;
        this.notifyError('Failed to get joined entries', args);
      },
    });
  }

  selectEntry(entryId) {
    this.selectedEntryIds = this.selectedEntryIds.has(entryId)
      ? this.selectedEntryIds.delete(entryId)
      : this.selectedEntryIds.add(entryId);
  }

  listWaitlist(initial = true) {
    this.entriesLoading = true;

    if (initial) {
      this.getJoinedEntries();
    }

    WaitlistSource.listEntries({
      params: {
        page: this.pagination.get('page'),
        per_page: this.pagination.get('per_page'),
        search: this.filters.get('search'),
        session_id: this.sessionId,
        statuses: ['joined', 'pending', 'invited'],
      },
      success: {
        action: WaitlistActions.listWaitlistSuccess,
        args: [initial],
      },
      error: WaitlistActions.listWaitlistError,
    });
  }

  listWaitlistSuccess([
    { event_waitlist_entries: entries, page, perPage, totalCount },
    initial,
  ]) {
    this.entryIds = OrderedSet(entries.map(entry => entry.id));
    this.pagination = this.pagination.merge({
      page,
      per_page: perPage,
      total_count: totalCount,
    });

    if (initial) {
      this.totalEntries = totalCount;
    }

    this.entriesLoading = false;
  }

  listWaitlistError(...args) {
    this.notifyError('Failed to load waitlist entries', args);
    this.entriesLoading = false;
  }

  searchWaitlist(search) {
    this.filters = this.filters.set('search', search);
    this.pagination = this.pagination.set('page', 1);
    this.entriesLoading = true;
    this.debouncedSearchWaitlist();
  }

  waitlistAddToEvent() {
    if (this.selectedEntryIds.size === 0) {
      return;
    }

    WaitlistSource.addToEvent({
      params: {
        ids: this.selectedEntryIds.toArray(),
      },
      success: WaitlistActions.waitlistAddToEventSuccess,
      error: WaitlistActions.waitlistAddToEventError,
    });
  }

  waitlistAddToEventSuccess() {
    this.listWaitlist();
    this.selectedEntryIds = OrderedSet();
    MessageWindowActions.addMessage.defer('Client(s) added to class.');
  }

  waitlistAddToEventError(...args) {
    this.notifyError('Failed to add waitlist entries to event', args);
  }

  removeEntry(entry) {
    if (!entry) return;

    if (this.selectedEntryIds.has(entry.id)) {
      this.selectedEntryIds = this.selectedEntryIds.delete(entry.id);
    }

    WaitlistSource.removeEntry({
      id: entry.id,
      success: WaitlistActions.removeEntrySuccess,
      error: WaitlistActions.removeEntryError,
    });
  }

  removeEntrySuccess() {
    this.listWaitlist(false);
    MessageWindowActions.addMessage.defer('Client removed from waitlist.');
  }

  removeEntryError(...args) {
    this.notifyError('Failed to remove waitlist entry', args);
  }
}

export default alt.createStore(
  WaitlistSessionDrawerTabStore,
  'WaitlistSessionDrawerTabStore'
);
