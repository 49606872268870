import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Grid, Typography, Button } from '@upperhand/playmaker';
import ErrorIcon from '@mui/icons-material/Error';

import { t } from 'shared/utils/LocaleUtils';

function NoPasses({
  noAvailable = false,
  totalCredits = 0,
  hasMemberships = false,
  onViewMemberships = () => {},
}) {
  const intl = useIntl();

  return (
    <Grid container spacing={1} className="no-passes">
      <Grid item xs={1}>
        <ErrorIcon color="error" className="no-passes__error-icon" />
      </Grid>
      <Grid item xs={11}>
        <Typography color="error" className="no-passes__error-text">
          {noAvailable
            ? t('.no_passes_available', intl, __filenamespace, {
                count: totalCredits,
              })
            : t('.no_passes', intl, __filenamespace)}
        </Typography>
      </Grid>
      {!noAvailable && hasMemberships && (
        <>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Button fullWidth onClick={onViewMemberships}>
              {t('.view_memberships', intl, __filenamespace)}
            </Button>
          </Grid>
          <Grid item xs={1} />
        </>
      )}
    </Grid>
  );
}

NoPasses.propTypes = {
  noAvailable: PropTypes.bool,
  totalCredits: PropTypes.number,
  hasMemberships: PropTypes.bool,
  onViewMemberships: PropTypes.func,
};

export default memo(NoPasses);
