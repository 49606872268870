import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Spinner, Confirmation, Typography } from '@upperhand/playmaker';
import LinearProgress from '@mui/material/LinearProgress';

import SearchBar from 'shared/components/SearchBar.jsx';
import WaitlistEntry from 'containers/events/admin/sessionSummaryDrawer/components/waitlist/components/WaitlistEntry.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import { WaitlistDataStore } from 'dataStores';

import WaitlistActions from './actions';
import WaitlistSessionDrawerTabStore from './store';

import './styles.scss';

function WaitList({
  sessionId = null,
  waitlistDataStore = {},
  waitlistSessionDrawerTabStore = {},
}) {
  useEffect(() => {
    if (sessionId) WaitlistActions.mounted({ sessionId });
  }, [sessionId]);

  const intl = useIntl();
  const [entryToRemove, setEntryToRemove] = useState(null);

  if (!sessionId) return null;

  const {
    entryIds,
    selectedEntryIds,
    filters,
    totalEntries,
    joinedEntries,
    entriesLoading,
  } = waitlistSessionDrawerTabStore;
  const { waitlistEntries } = waitlistDataStore;
  const entries = entryIds.map(id => waitlistEntries.get(id));
  const filterString = filters.get('search', '');
  const totalJoinedEntries = joinedEntries + selectedEntryIds.size;

  const getProgressValue = () => {
    if (totalJoinedEntries === 0) return 0;

    return Math.round((totalJoinedEntries / totalEntries) * 100);
  };

  return (
    <div className="ssd-waitlist">
      <div className="ssd-waitlist__search">
        <div className="ssd-waitlist__search-bar">
          <SearchBar
            autoFocus
            hintText={t('.search', intl, __filenamespace)}
            onTypingEnd={WaitlistActions.searchWaitlist}
            searchText={filterString}
          />
          <span>
            {t('.fraction', intl, __filenamespace, {
              den: totalEntries,
              num: totalJoinedEntries,
            })}
          </span>
        </div>
        <LinearProgress
          className="ssd-waitlist__progress"
          variant="determinate"
          color="secondary"
          value={getProgressValue()}
        />
      </div>
      <div className="ssd-waitlist__entries">
        {entriesLoading && (
          <div className="ssd-waitlist__spinner">
            <Spinner />
          </div>
        )}
        {!entriesLoading && entries.size === 0 && (
          <div className="ssd-waitlist__no-entries">
            {t('.no_waitlist', intl, __filenamespace)}
          </div>
        )}
        {!entriesLoading &&
          entries.map(entry => (
            <WaitlistEntry
              key={entry.id}
              entry={entry}
              isSelected={selectedEntryIds.includes(entry.id)}
              onSelect={WaitlistActions.selectEntry}
              onRemove={setEntryToRemove}
            />
          ))}
      </div>
      <Confirmation
        open={!!entryToRemove}
        title={t('.remove_confirmation', intl, __filenamespace)}
        hasCheckbox={false}
        confirmButtonLabel={t('.yes', intl, __filenamespace)}
        cancelButtonLabel={t('.no', intl, __filenamespace)}
        onCancel={() => setEntryToRemove(null)}
        onConfirm={() => {
          WaitlistActions.removeEntry(entryToRemove);
          setEntryToRemove(null);
        }}
      >
        <Typography variant="body1">
          {t('.remove_confirmation_text', intl, __filenamespace, {
            name: entryToRemove?.client?.name(),
          })}
        </Typography>
      </Confirmation>
    </div>
  );
}

WaitList.propTypes = {
  sessionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  waitlistDataStore: PropTypes.object,
  waitlistSessionDrawerTabStore: PropTypes.object,
};

export default compose(
  memo,
  altContainer({
    stores: {
      waitlistSessionDrawerTabStore: WaitlistSessionDrawerTabStore,
      waitlistDataStore: WaitlistDataStore,
    },
  })
)(WaitList);
