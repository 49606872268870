import * as React from 'react';
import { Grid } from '@upperhand/playmaker';

import SessionCard from './SessionCard.jsx';

function SessionListing({
  clientId,
  creditsCount,
  waitlistEntriesIds,
  sessionIds,
  selectedSessions,
  onSelect,
  onJoinWaitlist,
}) {
  const sessionCards = sessionIds
    .map(id => (
      <Grid key={id} item xs={6}>
        <SessionCard
          sessionId={id}
          creditsCount={creditsCount}
          waitlistEntriesIds={waitlistEntriesIds}
          selectedSessions={selectedSessions}
          clientId={clientId}
          onSelect={onSelect}
          onJoinWaitlist={onJoinWaitlist}
        />
      </Grid>
    ))
    .toList();

  if (sessionCards.size < 1) {
    return null;
  }

  return (
    <Grid container alignItems="center" xs={12} spacing={1}>
      {sessionCards}
    </Grid>
  );
}

export default SessionListing;
