import React, { memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Grid, Typography } from '@upperhand/playmaker';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import EntityDetails from 'containers/events/admin/sessionSummaryDrawer/components/editSession/components/EntityDetails.jsx';
import TimeField from 'containers/events/admin/sessionSummaryDrawer/components/editSession/components/TimeField.jsx';

import DetailsActions from 'containers/events/admin/sessionSummaryDrawer/components/details/actions';

import { t } from 'shared/utils/LocaleUtils';

function DateTimeContent({ data, sessionId, onChange }) {
  useEffect(() => {
    DetailsActions.initStaffAdd(false);
    DetailsActions.initResourceAdd(false);

    return () => {
      DetailsActions.cancelStaffAdd();
      DetailsActions.cancelResourceAdd();
    };
  }, []);

  const intl = useIntl();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="caption">
          {t('.labels.date', intl, __filenamespace)}
        </Typography>
        <DatePicker
          disableMaskedInput
          inputFormat="ddd, MMM DD, YYYY"
          value={data.get('date')}
          renderInput={params => (
            <TextField
              fullWidth
              style={{ marginTop: '5px' }}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
            />
          )}
          DialogProps={{ disableEnforceFocus: true }}
          onChange={value => onChange('date', value)}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="caption">
          {t('.labels.start_time', intl, __filenamespace)}
        </Typography>
        <TimeField
          disableKeyboardEditing
          value={data.get('startTime')}
          onAccept={value => onChange('startTime', value)}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="caption">
          {t('.labels.end_time', intl, __filenamespace)}
        </Typography>
        <TimeField
          disableKeyboardEditing
          value={data.get('endTime')}
          onAccept={value => onChange('endTime', value)}
        />
      </Grid>
      <Grid item xs={12}>
        <EntityDetails type="staff" sessionId={sessionId} />
      </Grid>
      <Grid item xs={12}>
        <EntityDetails type="resource" sessionId={sessionId} />
      </Grid>
    </Grid>
  );
}

export default memo(DateTimeContent);
