/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';

function AddStaff({
  staffItems,
  selectedStaffId = null,
  placeholder = '',
  label = '',
  onChange = () => {},
}) {
  const selectedStaff =
    staffItems.find(s => s.value === selectedStaffId) || null;

  return (
    <Autocomplete
      value={selectedStaff}
      noOptionsText=""
      sx={{ margin: '5px 0' }}
      options={staffItems}
      getOptionLabel={option => option.label ?? ''}
      renderInput={params => (
        <TextField {...params} label={label} placeholder={placeholder} />
      )}
      renderOption={(props, option) => (
        <MenuItem {...props} key={option.value}>
          {option.label}
        </MenuItem>
      )}
      onChange={(_, newValue) => onChange(newValue?.value || null)}
    />
  );
}

AddStaff.propTypes = {
  staffItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  selectedStaffId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

export default memo(AddStaff);
