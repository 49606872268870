import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import ArrowDropDown from '@mui/icons-material/KeyboardArrowUp';
import ArrowDropUp from '@mui/icons-material/KeyboardArrowDown';
import { TextField } from '@upperhand/playmaker';

import Form from 'shared/components/Form.jsx';
import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors, uhFormElement } from 'shared/styles/uhStyles.jsx';

const couponStyles = {
  applyButton: {
    height: 40,
    lineHeight: '40px',
    margin: '1rem 0',
  },

  buttonLabel: {
    fontSize: 16,
  },

  fonts: {
    color: uhColors.leftNavGrey,
    fontSize: 15,
    lineHeight: '18px',
  },

  header: {
    alignItems: 'center',
    cursor: 'pointer',
  },

  paper: {
    backgroundColor: '#EDFAFF',
    padding: '16px 20px',
    marginTop: 10,
  },

  textFieldFloatingLabel: {
    fontSize: 17,
    fontWeight: uhFormElement.floatingLabel.fontWeight,
    color: 'var(--color-old-gray)',
  },
};

class CouponForm extends React.Component {
  state = {
    open: false,
    couponCode: '',
  };

  setCouponCode = e => {
    /* we stop propagation of this event so that it doesn't bubble up
     * to the containing tab when the cart is in its small screen state
     */
    e.stopPropagation();
    this.setState({ couponCode: e.target.value });
  };

  applyCoupon = () => {
    const { couponCode } = this.state;
    const { onCouponApply } = this.props;

    if (couponCode) {
      onCouponApply({ code: couponCode });
    }
  };

  toggleOpenClosed = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    const { couponCode, open } = this.state;
    const { intl, isLoading } = this.props;

    return (
      <div style={couponStyles.paper}>
        <FlexBoxJustify
          style={couponStyles.header}
          onClick={this.toggleOpenClosed}
        >
          <div style={couponStyles.fonts}>
            <FormattedMessage id={messageId('.enter_code', __filenamespace)} />
          </div>
          {open ? (
            <ArrowDropDown sx={{ color: uhColors.blue }} />
          ) : (
            <ArrowDropUp sx={{ color: uhColors.blue }} />
          )}
        </FlexBoxJustify>

        {open && (
          <>
            <Form onSubmit={this.applyCoupon}>
              <TextField
                label={t('.coupon_code', intl, __filenamespace)}
                fullWidth
                onChange={this.setCouponCode}
                onPaste={e => {
                  e.preventDefault();
                  this.setState({
                    couponCode: e.clipboardData.getData('Text').trim(),
                  });
                }}
                value={couponCode}
              />
            </Form>
            <StateChangingButton
              primary
              label={t('.apply', intl, __filenamespace)}
              labelInProgress={t('.applying', intl, __filenamespace)}
              inProgress={isLoading}
              labelStyle={couponStyles.buttonLabel}
              style={merge(couponStyles.applyButton)}
              onClick={this.applyCoupon}
            />
          </>
        )}
      </div>
    );
  }
}

export default injectIntl(CouponForm);
