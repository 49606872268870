import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Grid, Typography, Button } from '@upperhand/playmaker';

import { compose } from 'shared/utils/SharedUtils.js';
import { t } from 'shared/utils/LocaleUtils.js';
import altContainer from 'shared/hocs/altContainer.jsx';

import UserAvatar from 'shared/components/_UserAvatar.jsx';

import { ClientDataStore, CreditCountDataStore } from 'dataStores';

import './styles.scss';

function AccountProfile({
  clientDataStore = {},
  creditCountStore = {},
  clientId,
  eventId,
  intl,
  scheduleSession,
}) {
  const user = clientDataStore.clients.get(clientId);
  const fullName = `${user.first_name} ${user.last_name}`;

  const creditsRemaining = creditCountStore.eventClients.getIn(
    [eventId, clientId, 'credits_remaining'],
    0
  );
  const unlimited = creditCountStore.eventClients.getIn(
    [eventId, clientId, 'unlimited'],
    false
  );
  const canBook = creditsRemaining > 0 || unlimited;
  const availableText = unlimited
    ? t('.unlimited_credits', intl, __filenamespace)
    : t('.available_credits', intl, __filenamespace, {
        amount: creditsRemaining,
      });

  return (
    <div className="account-profile">
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={2}>
          <UserAvatar user={user} />
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1">{fullName}</Typography>
        </Grid>
        <Grid item xs={12}>
          {canBook && (
            <Button fullWidth onClick={() => scheduleSession(clientId)}>
              {t('.book', intl, __filenamespace, {
                creditsAmount: availableText,
              })}
            </Button>
          )}
          {!canBook && (
            <Button fullWidth onClick={() => scheduleSession(clientId)}>
              {t('.book_no_credits', intl, __filenamespace, {
                creditsAmount: availableText,
              })}
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

AccountProfile.propTypes = {
  clientDataStore: PropTypes.shape({
    clients: PropTypes.object,
    clientId: PropTypes.number,
  }),
  clientId: PropTypes.number.isRequired,
  creditCountStore: PropTypes.shape({
    records: PropTypes.object,
  }),
  eventId: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
  scheduleSession: PropTypes.func.isRequired,
};

export default compose(
  altContainer({
    stores: {
      creditCountStore: CreditCountDataStore,
      clientDataStore: ClientDataStore,
    },
  }),
  injectIntl
)(AccountProfile);
