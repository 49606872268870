import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { enabledCustomerFeatures } from 'shared/utils/CustomerUtils';

import Roster from 'containers/classes/classesList/components/Tabs/Roster.jsx';
import Schedule from 'containers/classes/classesList/components/Tabs/Schedule.jsx';
import Staff from 'containers/classes/classesList/components/Tabs/Staff.jsx';
import Waitlist from 'containers/classes/classesList/components/Tabs/Waitlist.jsx';

import ClassesListActions from 'containers/classes/classesList/Actions';
import ClassesListStore, {
  CLASS_TABS,
} from 'containers/classes/classesList/Store';
import { EventDataStore } from 'dataStores';

import './styles.scss';

function ClassesTabs({ classesListStore = {}, eventDataStore = {} }) {
  const { selectedTab, selectedClass } = classesListStore;
  const { events } = eventDataStore;
  const classId = selectedClass?.get('id');
  const classEvent = events.get(classId);
  const schedules = classEvent?.get('schedules');
  const waitlistEnabled = schedules?.some(
    schedule => schedule.get('waitlist_mode', 'none') !== 'none'
  );

  const tabItems = [
    { label: 'Roster', content: <Roster />, value: CLASS_TABS.ROSTER },
    {
      label: 'Schedule',
      content: <Schedule listUpcoming />,
      value: CLASS_TABS.SCHEDULE,
      disabled: !selectedClass,
    },
    {
      label: 'Completed',
      content: <Schedule listUpcoming={false} />,
      value: CLASS_TABS.COMPLETED,
      disabled: !selectedClass,
    },
    {
      label: 'Staff',
      content: <Staff />,
      value: CLASS_TABS.STAFF,
      disabled: !selectedClass,
    },
    enabledCustomerFeatures(['classes_waitlist']) &&
      waitlistEnabled && {
        label: 'Waitlist',
        content: <Waitlist />,
        value: CLASS_TABS.WAITLIST,
        disabled: !selectedClass,
      },
  ];

  const handleTabChange = (_, value) =>
    ClassesListActions.handleTabChange(value);

  return (
    <Tabs
      classes={{ root: 'tabs' }}
      variant="fullWidth"
      tabItems={tabItems}
      value={selectedTab}
      onChange={handleTabChange}
    />
  );
}

ClassesTabs.propTypes = {
  classesListStore: PropTypes.object,
  eventDataStore: PropTypes.object,
};

export default compose(
  altContainer({
    stores: {
      classesListStore: ClassesListStore,
      eventDataStore: EventDataStore,
    },
  })
)(ClassesTabs);
