import * as React from 'react';
import { injectIntl } from 'react-intl';
import CollapsibleTable, {
  EmptyRow,
} from 'reporting/sales_detail/components/tables/_CollapsibleTable.jsx';
import { RowExtended } from 'reporting/sales_detail/components/tables/_Rows.jsx';
import { TotalRowExtended } from 'reporting/sales_detail/components/tables/_TotalRows.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

const COLUMNS = [
  messageId(
    'reporting.sales_detail.SalesDetail.RetailCategoryTable.retail_category',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.RetailCategoryTable.sales',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.RetailCategoryTable.tax',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.RetailCategoryTable.service_fee',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.RetailCategoryTable.qty',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.RetailCategoryTable.returns',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.RetailCategoryTable.returns_tax',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.RetailCategoryTable.returns_qty',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.RetailCategoryTable.total',
    __filenamespace
  ),
];

const COLUMNS_SIZE = COLUMNS.length;

function RetailCategoryTable({
  intl,
  retailCategorySales,
  retailCategoryTotals,
}) {
  return (
    <CollapsibleTable
      title={t('.title', intl, __filenamespace)}
      columns={COLUMNS}
      totals={
        retailCategorySales.size ? (
          <TotalRowExtended
            data={retailCategoryTotals}
            label={t('.subtotal', intl, __filenamespace)}
          />
        ) : null
      }
    >
      {retailCategorySales.size > 0 ? (
        retailCategorySales.map(r => <RowExtended key={r.name} data={r} />)
      ) : (
        <EmptyRow colSpan={COLUMNS_SIZE} />
      )}
    </CollapsibleTable>
  );
}

export default injectIntl(RetailCategoryTable);
