class ClientWaitlistActions {
  constructor() {
    this.generateActions(
      'mounted',
      'list',
      'listSuccess',
      'listError',
      'remove',
      'removeSuccess',
      'removeError'
    );
  }
}

export default alt.createActions(ClientWaitlistActions);
