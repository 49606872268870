export default alt.generateActions(
  'addError',
  'addSuccess',
  'applyCouponError',
  'applyCouponSuccess',
  'applyAccountCredits',
  'applyAccountCreditsSuccess',
  'applyAccountCreditsError',
  'updateAccountCredits',
  'couponApplied',
  'creditPassPackageAdded',
  'fetch',
  'fetchError',
  'fetchSuccess',
  'registrationPackageAdded',
  'remove',
  'removeError',
  'removeSuccess',
  'resetCheckout',
  'subscriptionPackageAdded',
  'update',
  'updateCheckoutStep',
  'updateError',
  'updateSuccess',
  'resetAfterSuccess',
  'fetchEventsSuccess',
  'fetchEventsError',
  'fetchSessionsSuccess',
  'fetchSessionsError',
  'fetchStaffSuccess',
  'fetchStaffError',
  'toggleCheckoutStep',
  'resetOnUnmount',
  'updateInsurance',
  'applyInsurance',
  'applyInsuranceSuccess',
  'applyInsuranceError',
  'clearInsurance',
  'emptyOfferError',
  'scheduleSuccess',
  'scheduleError'
);
