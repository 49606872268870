/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import AltContainer from 'alt-container';
import { injectIntl } from 'react-intl';
import Drawer from '@mui/material/Drawer';

import AddClient from 'point_of_sale/components/AddClient.jsx';
import CartSummary from 'point_of_sale/components/CartSummary.jsx';
import ProRateMembership from 'point_of_sale/components/ProRateMembership.jsx';
import Checkout from 'point_of_sale/components/Checkout.jsx';
import ClientList from 'point_of_sale/components/ClientList.jsx';
import CreditPassRegistering from 'point_of_sale/components/CreditPassRegistering.jsx';
import EventScheduling from 'point_of_sale/components/EventScheduling.jsx';
import MembershipRegistering from 'point_of_sale/components/MembershipRegistering.jsx';
import ProductList from 'point_of_sale/components/products/list/ProductList.jsx';
import ProductView from 'point_of_sale/components/ProductView.jsx';

import MembershipDiscountStore from 'event_mgmt/display/stores/MembershipDiscountStore.js';
import POSActions from 'point_of_sale/actions/POSActions.jsx';
import POSAutomationsStore from 'point_of_sale/stores/POSAutomationsStore.jsx';
import POSCartStore from 'point_of_sale/stores/POSCartStore.jsx';
import POSCheckoutStore from 'point_of_sale/stores/POSCheckoutStore.jsx';
import POSClientListStore from 'point_of_sale/stores/POSClientListStore.jsx';
import POSClientStore from 'point_of_sale/stores/POSClientStore.jsx';
import POSCreditPassStore from 'point_of_sale/stores/POSCreditPassStore.js';
import POSCreditPassPackageStore from 'point_of_sale/stores/POSCreditPassPackageStore';
import POSEventStore from 'point_of_sale/stores/POSEventStore.jsx';
import POSEventStaffStore from 'point_of_sale/stores/POSEventStaffStore.js';
import POSEventTypeListStore from 'point_of_sale/stores/POSEventTypeListStore.jsx';
import POSMembershipListingStore from 'point_of_sale/stores/POSMembershipListingStore.js';
import POSMembershipStore from 'point_of_sale/stores/POSMembershipStore.jsx';
import POSMembershipRegistrationStore from 'point_of_sale/stores/POSMembershipRegistrationStore.jsx';
import POSProductListStore from 'point_of_sale/stores/POSProductListStore.jsx';
import POSProductStore from 'point_of_sale/stores/POSProductStore.js';
import POSRegistrationAvailableTimesStore from 'point_of_sale/stores/POSRegistrationAvailableTimesStore.js';
import POSRetailCategoryListStore from 'point_of_sale/stores/POSRetailCategoryListStore.jsx';
import POSRegistrationFormStore from 'point_of_sale/stores/POSRegistrationFormStore.jsx';
import POSSchedulingStore from 'point_of_sale/stores/POSSchedulingStore.jsx';
import POSSchedulingAvailableTimesStore from 'point_of_sale/stores/POSSchedulingAvailableTimesStore.js';
import POSSingleSessionContentStore from 'point_of_sale/stores/POSSingleSessionContentStore.jsx';
import POSSpecificEventsListStore from 'point_of_sale/stores/POSSpecificEventsListStore.js';
import { PaymentStore } from 'containers/payments';
import { STANDARD_DRAWER_WIDTH, smallScreen } from 'shared/utils/DOMUtils';

import POSStore, {
  ADDING_CLIENT,
  CLIENT_LIST,
  PRODUCT_LIST,
  EVENT_SCHEDULING,
  MEMBERSHIP_REGISTERING,
  CREDIT_PASS_REGISTERING,
  PRODUCT_VIEW,
  CART_SUMMARY,
  CHECKOUT,
  PRORATE,
} from 'point_of_sale/stores/POSStore.jsx';
import { SessionDataStore, StaffDataStore } from 'dataStores';
import ClientCreditStore from 'shared/stores/ClientCreditStore';
import CreditPassListingStore from 'credit_passes/stores/CreditPassListingStore.jsx';

function Content({ step, ...props }) {
  switch (step) {
    case ADDING_CLIENT:
      return <AddClient {...props} />;
    case CLIENT_LIST:
      return <ClientList {...props} />;
    case PRODUCT_LIST:
      return <ProductList {...props} />;
    case EVENT_SCHEDULING:
      return <EventScheduling {...props} />;
    case MEMBERSHIP_REGISTERING:
      return <MembershipRegistering {...props} />;
    case CREDIT_PASS_REGISTERING:
      return <CreditPassRegistering {...props} />;
    case PRODUCT_VIEW:
      return <ProductView {...props} />;
    case CART_SUMMARY:
      return <CartSummary {...props} />;
    case CHECKOUT:
      return <Checkout {...props} />;
    case PRORATE:
      return <ProRateMembership {...props} />;
    default:
      return <ClientList {...props} />;
  }
}

function POSDrawer(props) {
  const {
    posStore: { drawerOpen, step },
  } = props;
  return (
    <Drawer
      disableEnforceFocus
      anchor="right"
      open={drawerOpen}
      PaperProps={{
        sx: {
          width: smallScreen()
            ? window.innerWidth * 0.9
            : STANDARD_DRAWER_WIDTH,
          overflowX: 'hidden',
        },
      }}
      onClose={POSActions.drawerDismissed}
    >
      <Content step={step} {...props} />
    </Drawer>
  );
}

function WrappedPOSDrawer(props) {
  return (
    <AltContainer
      stores={{
        clientCreditStore: ClientCreditStore,
        membershipDiscountStore: MembershipDiscountStore,
        posAutomationsStore: POSAutomationsStore,
        posCartStore: POSCartStore,
        posCheckoutStore: POSCheckoutStore,
        posClientListStore: POSClientListStore,
        posClientStore: POSClientStore,
        posCreditPassStore: POSCreditPassStore,
        posCreditPassPackageStore: POSCreditPassPackageStore,
        posEventStaffStore: POSEventStaffStore,
        posEventStore: POSEventStore,
        posEventTypeListStore: POSEventTypeListStore,
        posMembershipListingStore: POSMembershipListingStore,
        posMembershipRegistrationStore: POSMembershipRegistrationStore,
        posMembershipStore: POSMembershipStore,
        posProductListStore: POSProductListStore,
        posProductStore: POSProductStore,
        posRegistrationAvailableTimesStore: POSRegistrationAvailableTimesStore,
        posRegistrationFormStore: POSRegistrationFormStore,
        posRetailCategoryListStore: POSRetailCategoryListStore,
        posSchedulingAvailableTimesStore: POSSchedulingAvailableTimesStore,
        posSchedulingStore: POSSchedulingStore,
        posSingleSessionContentStore: POSSingleSessionContentStore,
        posSpecificEventsListStore: POSSpecificEventsListStore,
        posStore: POSStore,
        sessionDataStore: SessionDataStore,
        staffDataStore: StaffDataStore,
        paymentStore: PaymentStore,
        creditPassListingStore: CreditPassListingStore,
      }}
    >
      <POSDrawer {...props} />
    </AltContainer>
  );
}

export default injectIntl(WrappedPOSDrawer);
