import * as React from 'react';
import { injectIntl } from 'react-intl';
import CollapsibleTable, {
  EmptyRow,
} from 'reporting/sales_detail/components/tables/_CollapsibleTable.jsx';
import { Row } from 'reporting/sales_detail/components/tables/_Rows.jsx';
import { TotalRow } from 'reporting/sales_detail/components/tables/_TotalRows.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

const COLUMNS = [
  messageId(
    'reporting.sales_detail.SalesDetail.OnlineInStoreTable.payment_type',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.OnlineInStoreTable.sales',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.OnlineInStoreTable.tax',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.OnlineInStoreTable.service_fee',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.OnlineInStoreTable.returns',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.OnlineInStoreTable.returns_tax',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.OnlineInStoreTable.total',
    __filenamespace
  ),
];

const COLUMNS_SIZE = COLUMNS.length;

function OnlineInStoreTable({ intl, saleTypeSales, saleTypeTotals }) {
  return (
    <CollapsibleTable
      title={t('.title', intl, __filenamespace)}
      columns={COLUMNS}
      totals={
        saleTypeSales.size > 0 && (
          <TotalRow
            data={saleTypeTotals}
            label={t('.subtotal', intl, __filenamespace)}
          />
        )
      }
    >
      {saleTypeSales.size > 0 ? (
        saleTypeSales.map(r => <Row key={r.name} data={r} />)
      ) : (
        <EmptyRow colSpan={COLUMNS_SIZE} />
      )}
    </CollapsibleTable>
  );
}

export default injectIntl(OnlineInStoreTable);
