import { List, Map, OrderedSet, OrderedMap } from 'immutable';
import moment from 'moment-timezone';

import Schedule from 'event_mgmt/shared/records/Schedule.jsx';
import Location from 'shared/records/Location.jsx';
import Address from 'shared/records/Address.jsx';
import AvailabilitySchedule from 'shared/records/AvailabilitySchedule.jsx';
import FieldErrors from 'shared/records/FieldErrors.jsx';

import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import { byFields } from 'shared/utils/ImmutableUtils';
import { calculateEndDate } from 'event_mgmt/shared/utils/ScheduleUtils.jsx';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import ClassesListStore, {
  VIEW_MODES,
} from 'containers/classes/classesList/Store';
import { StaffDataStore, ResourceDataStore } from 'dataStores';

import {
  LocationSource,
  StaffSource,
  ScheduleSource,
  ResourceSource,
} from 'sources';

import ClassesListActions from 'containers/classes/classesList/Actions';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import EventSessionCreationActions from './Actions';

export const REPEAT_MODES = {
  NEVER: 'never',
  UNTIL: 'until',
  NUMBER_OF_TIMES: 'occurrences',
};

export const STEPS = {
  SCHEDULE: 'SCHEDULE',
  PARTICIPANTS: 'PARTICIPANTS',
  STAFF_AND_RESOURCES: 'STAFF_AND_RESOURCES',
};

export const TEAMS_STEPS = {
  SCHEDULE: 'SCHEDULE',
  STAFF_AND_RESOURCES: 'STAFF_AND_RESOURCES',
};

export const SCHEDULING_UNITS = {
  MIN: 'min',
  HOUR: 'hour',
  DAY: 'day',
};

class EventSessionCreationStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      toggleEditConfirm: EventSessionCreationActions.toggleEditConfirm,
      toggleDrawer: EventSessionCreationActions.toggleDrawer,
      setCurrentStep: EventSessionCreationActions.setCurrentStep,
      handleFieldChange: EventSessionCreationActions.handleFieldChange,
      setRepeating: EventSessionCreationActions.setRepeating,
      setWeekdays: EventSessionCreationActions.setWeekdays,
      setDayTimes: EventSessionCreationActions.setDayTimes,
      addDayTime: EventSessionCreationActions.addDayTime,
      removeDayTime: EventSessionCreationActions.removeDayTime,
      setRepeatMode: EventSessionCreationActions.setRepeatMode,
      setRepeatDuration: EventSessionCreationActions.setRepeatDuration,
      setDailyTime: EventSessionCreationActions.setDailyTime,
      updateDayTime: EventSessionCreationActions.updateDayTime,
      addBlackoutDate: EventSessionCreationActions.addBlackoutDate,
      removeBlackoutDate: EventSessionCreationActions.removeBlackoutDate,
      updateBlackoutDate: EventSessionCreationActions.updateBlackoutDate,
      setScheduleDeadlineUnit:
        EventSessionCreationActions.setScheduleDeadlineUnit,
      setScheduleDeadline: EventSessionCreationActions.setScheduleDeadline,
      setCancellationDeadline:
        EventSessionCreationActions.setCancellationDeadline,
      setCancellationDeadlineUnit:
        EventSessionCreationActions.setCancellationDeadlineUnit,
      setWaitListExpirationUnit:
        EventSessionCreationActions.setWaitListExpirationUnit,
      setWaitListExpiration: EventSessionCreationActions.setWaitListExpiration,
      calculateDeadline: EventSessionCreationActions.calculateDeadline,
      listLocations: EventSessionCreationActions.listLocations,
      listLocationsSuccess: EventSessionCreationActions.listLocationsSuccess,
      listLocationsError: EventSessionCreationActions.listLocationsError,
      listStaffAndResources: EventSessionCreationActions.listStaffAndResources,
      listStaffAndResourcesSuccess:
        EventSessionCreationActions.listStaffAndResourcesSuccess,
      listStaffAndResourcesError:
        EventSessionCreationActions.listStaffAndResourcesError,
      toggleStaffDrawer: EventSessionCreationActions.toggleStaffDrawer,
      toggleResourceDrawer: EventSessionCreationActions.toggleResourceDrawer,
      listStaff: EventSessionCreationActions.listStaff,
      listStaffSuccess: EventSessionCreationActions.listStaffSuccess,
      listStaffError: EventSessionCreationActions.listStaffError,
      listResources: EventSessionCreationActions.listResources,
      listResourcesSuccess: EventSessionCreationActions.listResourcesSuccess,
      listResourcesError: EventSessionCreationActions.listResourcesError,
      searchStaff: EventSessionCreationActions.searchStaff,
      searchResources: EventSessionCreationActions.searchResources,
      addToList: EventSessionCreationActions.addToList,
      removeFromList: EventSessionCreationActions.removeFromList,
      saveList: EventSessionCreationActions.saveList,
      createSchedule: EventSessionCreationActions.createSchedule,
      createScheduleSuccess: EventSessionCreationActions.createScheduleSuccess,
      createScheduleError: EventSessionCreationActions.createScheduleError,
      editSchedule: EventSessionCreationActions.editSchedule,
      updateSchedule: EventSessionCreationActions.updateSchedule,
      updateScheduleSuccess: EventSessionCreationActions.updateScheduleSuccess,
      updateScheduleError: EventSessionCreationActions.updateScheduleError,
    });
  }

  reset() {
    this.defaultTimes = List([
      Map({
        start_time: moment().format('HH:mm'),
        end_time: moment().add(1, 'hour').format('HH:mm'),
      }),
    ]);

    const dayTimeMap = Map().set(moment().format('d'), this.defaultTimes);

    this.eventId = null;
    this.isRepeating = false;
    this.locationsLoading = false;
    this.staffAndResourcesLoading = false;
    this.staffLoading = false;
    this.resourcesLoading = false;
    this.openStaffDrawer = false;
    this.openResourcesDrawer = false;
    this.creatingSchedule = false;
    this.creatingMode = true;
    this.isValid = false;
    this.showEditConfirm = false;
    this.searchText = '';
    this.resourceSearchText = '';
    this.locationsIds = OrderedSet();
    this.staffIds = OrderedSet();
    this.availableStaffIds = OrderedSet();
    this.selectedStaffIds = OrderedSet();
    this.resourceIds = OrderedSet();
    this.availableResourcesIds = OrderedSet();
    this.selectedResourcesIds = OrderedSet();
    this.currentStep = STEPS.SCHEDULE;
    this.scheduleDeadlineUnit = SCHEDULING_UNITS.DAY;
    this.scheduleDeadlineValue = 1;
    this.cancellationDeadlineUnit = SCHEDULING_UNITS.HOUR;
    this.cancellationDeadlineValue = 24;
    this.waitlistExpirationUnit = SCHEDULING_UNITS.HOUR;
    this.waitlistExpirationValue = 0;
    this.schedule = new Schedule({
      availability_schedule: new AvailabilitySchedule({
        daytimes: dayTimeMap,
        date_specific_daytimes: OrderedMap(),
        frequency: null,
        repeat_mode: REPEAT_MODES.NEVER,
        start_date: moment(),
        end_date: moment(),
      }),
    });
    this.initialSchedule = this.schedule;
    this.errors = new FieldErrors();
  }

  getEventData() {
    this.waitFor(EventStore);
    const { customerEvent } = EventStore.getState();
    return customerEvent;
  }

  validate() {
    const customerEvent = this.getEventData();
    const isTeamEvent =
      this.eventId === customerEvent.id && customerEvent.isTeamEvent();
    const availabilitySchedule = this.schedule.get('availability_schedule');
    const location = this.schedule.get('location');
    const scheduleType = this.schedule.get('team_schedule_type');
    const scheduleName = this.schedule.get('label');
    const frequency = availabilitySchedule.get('frequency');
    const repeatMode = availabilitySchedule.get('repeat_mode');
    const startDate = moment(availabilitySchedule.get('start_date'));
    const stopsBy = moment(availabilitySchedule.get('stops_by_date'));
    const isFirstStep = this.currentStep === STEPS.SCHEDULE;
    this.errors = this.errors.clear();

    if (!location.get('id', false) && isFirstStep) {
      this.errors = this.errors.add(
        'location',
        'containers.eventSessionCreation.Schedule.validation.required_field'
      );
    }

    if (isTeamEvent && !scheduleName && isFirstStep) {
      this.errors = this.errors.add(
        'scheduleName',
        'containers.eventSessionCreation.Schedule.validation.required_field'
      );
    }

    if (isTeamEvent && !scheduleType && isFirstStep) {
      this.errors = this.errors.add(
        'scheduleType',
        'containers.eventSessionCreation.Schedule.validation.required_field'
      );
    }

    if (frequency === null && isFirstStep) {
      const dayTimes = availabilitySchedule.get('daytimes');
      const isToday = startDate.isSame(moment.tz(customerTZ()), 'day');
      const dayTime = dayTimes.first();
      const startTime = dayTime.first().get('start_time');
      const endTime = dayTime.first().get('end_time');

      if (
        isToday &&
        startTime < moment.tz(customerTZ()).format('HH:mm') &&
        this.creatingMode
      ) {
        this.errors = this.errors.add(
          'start_time',
          'containers.eventSessionCreation.Schedule.validation.start_time'
        );
      }

      if (
        isToday &&
        endTime < moment.tz(customerTZ()).format('HH:mm') &&
        this.creatingMode
      ) {
        this.errors = this.errors.add(
          'end_time',
          'containers.eventSessionCreation.Schedule.validation.end_time'
        );
      }
    }

    if (repeatMode === 'until' && frequency === 'weekly') {
      if (stopsBy.isBefore(startDate)) {
        this.errors = this.errors.add(
          'stops_by_date',
          'containers.eventSessionCreation.Schedule.validation.stops_by_date'
        );
      }
    }

    this.isValid = !this.errors.hasErrors();
  }

  calculateNewEndDate() {
    const availabilitySchedule = this.schedule.get('availability_schedule');
    const stopsBy =
      availabilitySchedule.repeat_mode === 'until' &&
      availabilitySchedule.stops_by_date;
    const isCalculateNeeded = !(
      this.isRepeating &&
      availabilitySchedule.repeat_mode === REPEAT_MODES.NEVER
    );

    let newEndDate = '';
    if (isCalculateNeeded) {
      newEndDate = calculateEndDate(
        availabilitySchedule.start_date,
        availabilitySchedule.interval,
        availabilitySchedule.repeat_duration,
        availabilitySchedule.repeatingWeekdays(),
        stopsBy,
        availabilitySchedule.frequency
      );
    }
    this.schedule = this.schedule.setIn(
      ['availability_schedule', 'end_date'],
      newEndDate
    );
  }

  calculateDeadline({
    unitField = 'scheduleDeadlineUnit',
    valueField = 'scheduleDeadlineValue',
    storeField = 'scheduling_deadline',
  }) {
    let schedulingDeadline = 60;

    if (this[unitField] === SCHEDULING_UNITS.MIN) {
      schedulingDeadline = this[valueField] * 60;
    }

    if (this[unitField] === SCHEDULING_UNITS.HOUR) {
      schedulingDeadline = this[valueField] * 3600;
    }

    if (this[unitField] === SCHEDULING_UNITS.DAY) {
      schedulingDeadline = this[valueField] * 86400;
    }

    this.handleFieldChange({
      field: storeField,
      value: schedulingDeadline,
    });
  }

  resetAvailability() {
    let availabilitySchedule = this.schedule.get('availability_schedule');
    const dayTimeMap = Map().set(moment().format('d'), this.defaultTimes);

    availabilitySchedule = availabilitySchedule
      .set('repeat_mode', REPEAT_MODES.NEVER)
      .set('indefinite', true)
      .set('repeat_duration', 1)
      .set('stops_by_date', '')
      .set('frequency', null)
      .set('daytimes', dayTimeMap)
      .set('daytimesAreUnique', false)
      .set('exclusions', List())
      .set('interval', 1)
      .set('end_date', availabilitySchedule.get('start_date', moment()));

    this.schedule = this.schedule.set(
      'availability_schedule',
      availabilitySchedule
    );
  }

  toggleDrawer({ eventId = null }) {
    if (eventId) {
      this.listLocations({});
    } else {
      this.reset();
    }
    this.eventId = eventId;
  }

  setCurrentStep(step) {
    this.validate();

    if (this.isValid) {
      this.currentStep = step;
    }
  }

  listLocations({ page = 1 }) {
    this.locationsLoading = true;
    LocationSource.list({
      params: { page, per_page: 50 },
      success: EventSessionCreationActions.listLocationsSuccess,
      error: EventSessionCreationActions.listLocationsError,
    });
  }

  listLocationsSuccess({ locations, page, perPage, totalCount }) {
    this.locationsIds = this.locationsIds.concat(
      locations.map(location => location.id).toOrderedSet()
    );

    if (page * perPage < totalCount) {
      this.listLocations({ page: page + 1 });
    } else {
      this.locationsLoading = false;
    }
  }

  listLocationsError() {
    this.locationsLoading = false;
  }

  handleFieldChange({ field, value }) {
    const fieldToUpdate = typeof field === 'string' ? [field] : field;

    this.schedule = this.schedule.setIn(fieldToUpdate, value);
    this.validate();
  }

  setRepeating(isRepeating) {
    this.isRepeating = isRepeating;
    this.handleFieldChange({
      field: ['availability_schedule', 'frequency'],
      value: isRepeating ? 'weekly' : null,
    });

    if (isRepeating) {
      this.calculateNewEndDate();
    } else {
      this.resetAvailability();
    }
  }

  setWeekdays(weekdays) {
    if (weekdays.size > 0) {
      const availabilitySchedule = this.schedule.get('availability_schedule');
      const { daytimes } = availabilitySchedule;
      const newDayTimes = weekdays
        .toMap()
        .map(weekday => daytimes.get(weekday, this.defaultTimes));

      this.schedule = this.schedule.setIn(
        ['availability_schedule', 'daytimes'],
        newDayTimes
      );
    }
  }

  getStartEndTime({ key, value, dateToCompare }) {
    if (key === 'start_time') {
      const endTime = moment(
        dateToCompare || this.defaultTimes.first().get('end_time'),
        'HH:mm'
      );
      const startTime = moment(value.format('HH:mm'), 'HH:mm');
      const isAfter = startTime.isAfter(endTime);

      return {
        startTime: startTime.format('HH:mm'),
        endTime: isAfter
          ? value.add(1, 'hours').format('HH:mm')
          : endTime.format('HH:mm'),
      };
    }

    if (key === 'end_time') {
      const startTime = moment(
        dateToCompare || this.defaultTimes.first().get('start_time'),
        'HH:mm'
      );
      const endTime = moment(value.format('HH:mm'), 'HH:mm');
      const isBefore = endTime.isBefore(startTime);

      return {
        startTime: isBefore
          ? value.add(-1, 'hours').format('HH:mm')
          : startTime.format('HH:mm'),
        endTime: endTime.format('HH:mm'),
      };
    }

    return {
      startTime:
        key === 'start_time'
          ? value.format('HH:mm')
          : this.defaultTimes.first().get('start_time'),
      endTime:
        key === 'end_time'
          ? value.format('HH:mm')
          : this.defaultTimes.first().get('end_time'),
    };
  }

  setDayTimes({ key, value }) {
    const dates = this.getStartEndTime({ key, value });
    const newDefaultTimes = this.defaultTimes
      .first()
      .set('start_time', dates.startTime)
      .set('end_time', dates.endTime);

    const dayTimes = this.schedule
      .getIn(['availability_schedule', 'daytimes'])
      .map(dayTimeList =>
        dayTimeList.map(timeMap =>
          timeMap
            .set('start_time', dates.startTime)
            .set('end_time', dates.endTime)
        )
      );

    this.defaultTimes = List([newDefaultTimes]);
    this.schedule = this.schedule.setIn(
      ['availability_schedule', 'daytimes'],
      dayTimes
    );
    this.validate();
  }

  addDayTime({ dayNumber, index }) {
    const timesList = this.schedule
      .getIn(['availability_schedule', 'daytimes', dayNumber])
      .set(index, this.defaultTimes.first());

    this.schedule = this.schedule.setIn(
      ['availability_schedule', 'daytimes', dayNumber],
      timesList
    );
  }

  removeDayTime({ dayNumber, index }) {
    const timesList = this.schedule
      .getIn(['availability_schedule', 'daytimes', dayNumber])
      .delete(index);

    this.schedule = this.schedule.setIn(
      ['availability_schedule', 'daytimes', dayNumber],
      timesList
    );
  }

  updateDayTime({ keyPath, time, dayTimesAreUnique = false }) {
    const key = keyPath[keyPath.length - 1];

    if (dayTimesAreUnique) {
      const compareTimeKeyPath = [...keyPath];

      compareTimeKeyPath[compareTimeKeyPath.length - 1] =
        key === 'start_time' ? 'end_time' : 'start_time';

      const dateToCompare = this.schedule.getIn(compareTimeKeyPath);
      const dates = this.getStartEndTime({
        key,
        dateToCompare,
        value: time,
      });

      this.schedule = this.schedule
        .setIn(keyPath, key === 'start_time' ? dates.startTime : dates.endTime)
        .setIn(
          compareTimeKeyPath,
          key === 'start_time' ? dates.endTime : dates.startTime
        );
    } else {
      const keyToCompare = key === 'start_time' ? 'end_time' : 'start_time';
      const dateToCompare = this.schedule
        .getIn(['availability_schedule', 'daytimes'])
        .first()
        .first()
        .get(keyToCompare);
      const dates = this.getStartEndTime({
        key,
        dateToCompare,
        value: time,
      });
      const dayTimes = this.schedule
        .getIn(['availability_schedule', 'daytimes'])
        .map(() =>
          List([
            Map({
              start_time: dates.startTime,
              end_time: dates.endTime,
            }),
          ])
        );

      this.schedule = this.schedule.setIn(
        ['availability_schedule', 'daytimes'],
        dayTimes
      );
    }
  }

  setRepeatMode(mode) {
    let availabilitySchedule = this.schedule.get('availability_schedule');
    const { start_date: startDate } = availabilitySchedule;
    const endDate = startDate ? moment(startDate).endOf('month').toDate() : '';

    if (mode === REPEAT_MODES.NEVER) {
      availabilitySchedule = availabilitySchedule
        .set('repeat_mode', mode)
        .set('end_date', endDate)
        .set('indefinite', true)
        .set('repeat_duration', 1)
        .set('stops_by_date', '');
    }

    if (mode === REPEAT_MODES.UNTIL) {
      availabilitySchedule = availabilitySchedule
        .set('indefinite', false)
        .set('repeat_duration', 1)
        .set('stops_by_date', new Date())
        .set('repeat_mode', mode);
    }

    if (mode === REPEAT_MODES.NUMBER_OF_TIMES) {
      availabilitySchedule = availabilitySchedule
        .set('indefinite', false)
        .set('repeat_duration', 1)
        .set('stops_by_date', '')
        .set('repeat_mode', mode);
    }

    this.schedule = this.schedule.set(
      'availability_schedule',
      availabilitySchedule
    );
    this.calculateNewEndDate();
  }

  setRepeatDuration(duration) {
    this.handleFieldChange({
      field: ['availability_schedule', 'repeat_duration'],
      value: duration,
    });
    this.calculateNewEndDate();
  }

  setDailyTime(value) {
    this.schedule = this.schedule.setIn(
      ['availability_schedule', 'daytimesAreUnique'],
      value
    );

    const dayTimes = this.schedule
      .getIn(['availability_schedule', 'daytimes'])
      .map(() => this.defaultTimes);

    this.schedule = this.schedule.setIn(
      ['availability_schedule', 'daytimes'],
      dayTimes
    );
  }

  addBlackoutDate(date) {
    const exclusions = this.schedule
      .getIn(['availability_schedule', 'exclusions'])
      .push(date.format('YYYY-MM-DD'));

    this.handleFieldChange({
      field: ['availability_schedule', 'exclusions'],
      value: exclusions,
    });
  }

  removeBlackoutDate(index) {
    const exclusions = this.schedule
      .getIn(['availability_schedule', 'exclusions'])
      .delete(index);

    this.handleFieldChange({
      field: ['availability_schedule', 'exclusions'],
      value: exclusions,
    });
  }

  updateBlackoutDate({ date, index }) {
    this.handleFieldChange({
      field: ['availability_schedule', 'exclusions', index],
      value: date.format('YYYY-MM-DD'),
    });
  }

  setWaitListExpirationUnit(unit) {
    this.waitlistExpirationUnit = unit;
    this.calculateDeadline({
      unitField: 'waitlistExpirationUnit',
      valueField: 'waitlistExpirationValue',
      storeField: 'waitlist_invite_expiration',
    });
  }

  setWaitListExpiration(value) {
    this.waitlistExpirationValue = value;
    this.calculateDeadline({
      unitField: 'waitlistExpirationUnit',
      valueField: 'waitlistExpirationValue',
      storeField: 'waitlist_invite_expiration',
    });
  }

  setScheduleDeadlineUnit(unit) {
    this.scheduleDeadlineUnit = unit;
    this.calculateDeadline({});
  }

  setScheduleDeadline(value) {
    this.scheduleDeadlineValue = value;
    this.calculateDeadline({});
  }

  setCancellationDeadlineUnit(unit) {
    this.cancellationDeadlineUnit = unit;
    this.calculateDeadline({
      unitField: 'cancellationDeadlineUnit',
      valueField: 'cancellationDeadlineValue',
      storeField: 'cancellation_deadline',
    });
  }

  setCancellationDeadline(value) {
    this.cancellationDeadlineValue = value;
    this.calculateDeadline({
      unitField: 'cancellationDeadlineUnit',
      valueField: 'cancellationDeadlineValue',
      storeField: 'cancellation_deadline',
    });
  }

  setAvailableStaff() {
    const assignedStaff = this.schedule.get('schedule_staff');

    this.availableStaffIds = OrderedSet(this.staffIds)
      .subtract(assignedStaff.toOrderedSet())
      .sort();
  }

  setAvailableResources() {
    const assignedResources = this.schedule.get('schedule_resources');

    this.availableResourcesIds = OrderedSet(this.resourceIds)
      .subtract(assignedResources.toOrderedSet())
      .sort();
  }

  listStaffAndResources() {
    const availabilitySchedule = this.schedule.get('availability_schedule');

    this.staffAndResourcesLoading = true;
    uhApiClient.get({
      url: 'available_resources',
      data: {
        availability_schedule: JSON.stringify(availabilitySchedule.toServer()),
        customer_id: currentCustomer().id,
        types: ['staff', 'resources'],
        ignoring_availability_schedule_id: availabilitySchedule.id,
      },
      success: EventSessionCreationActions.listStaffAndResourcesSuccess,
      error: EventSessionCreationActions.listStaffAndResourcesError,
    });
  }

  listStaffAndResourcesSuccess({
    staff_ids: staffIds,
    resource_ids: resourceIds,
  }) {
    this.staffAndResourcesLoading = false;
    this.staffIds = OrderedSet(staffIds);
    this.resourceIds = OrderedSet(resourceIds);
    this.setAvailableStaff();
    this.setAvailableResources();
    this.listStaff({});
    this.listResources({});
  }

  listStaffAndResourcesError() {
    this.staffAndResourcesLoading = false;
  }

  listStaff({ page = 1 }) {
    this.staffLoading = true;
    StaffSource.list({
      params: {
        ids: this.staffIds.toArray(),
        page,
        per_page: 50,
        access_revoked: false,
      },
      success: EventSessionCreationActions.listStaffSuccess,
      error: EventSessionCreationActions.listStaffError,
    });
  }

  listStaffSuccess({ page, perPage, totalCount }) {
    if (page * perPage < totalCount) {
      this.listStaff({ page: page + 1 });
    } else {
      this.staffLoading = false;
    }
  }

  listStaffError() {
    this.staffLoading = false;
  }

  listResources({ page = 1 }) {
    this.resourcesLoading = true;
    ResourceSource.list({
      params: {
        page,
        per_page: 50,
        ids: this.resourceIds.toArray(),
      },
      success: EventSessionCreationActions.listResourcesSuccess,
      error: EventSessionCreationActions.listResourcesError,
    });
  }

  listResourcesSuccess({ page, perPage, totalCount }) {
    if (page * perPage < totalCount) {
      this.listResources({ page: page + 1 });
    } else {
      this.resourcesLoading = false;
    }
  }

  listResourcesError() {
    this.resourcesLoading = false;
  }

  toggleStaffDrawer() {
    this.selectedStaffIds = OrderedSet();
    this.openStaffDrawer = !this.openStaffDrawer;
  }

  toggleResourceDrawer() {
    this.selectedResourcesIds = OrderedSet();
    this.openResourcesDrawer = !this.openResourcesDrawer;
  }

  searchStaff(searchText) {
    this.searchText = searchText;

    const { staff } = StaffDataStore.getState();
    const customerUserIds = this.schedule.get('schedule_staff');
    const availableStaff = staff.filter(
      st =>
        !customerUserIds.includes(st.id) &&
        this.staffIds.includes(st.id) &&
        byFields(searchText, ['first_name', 'last_name'])(st)
    );

    this.availableStaffIds = availableStaff
      .map(st => st.id)
      .toOrderedSet()
      .sort();
  }

  searchResources(searchText) {
    this.resourceSearchText = searchText;

    const { records: resources } = ResourceDataStore.getState();
    const scheduleResources = this.schedule.get('schedule_resources');
    const availableResources = resources.filter(
      r =>
        !scheduleResources.includes(r.id) &&
        this.resourceIds.includes(r.id) &&
        byFields(searchText, ['name'])(r)
    );

    this.availableResourcesIds = availableResources
      .map(st => st.id)
      .toOrderedSet()
      .sort();
  }

  addToList({ listName = 'schedule_resources', idToInsert }) {
    if (listName === 'schedule_resources') {
      this.selectedResourcesIds = this.selectedResourcesIds.includes(idToInsert)
        ? this.selectedResourcesIds.delete(idToInsert)
        : this.selectedResourcesIds.add(idToInsert);
    } else {
      this.selectedStaffIds = this.selectedStaffIds.includes(idToInsert)
        ? this.selectedStaffIds.delete(idToInsert)
        : this.selectedStaffIds.add(idToInsert);
    }
  }

  removeFromList({ listName = 'schedule_resources', idToRemove }) {
    const list = this.schedule.get(listName);

    this.handleFieldChange({
      field: listName,
      value: list.filter(id => id !== idToRemove),
    });

    if (listName === 'schedule_resources') {
      this.setAvailableResources();
    } else {
      this.setAvailableStaff();
    }
  }

  saveList({ listName = 'schedule_resources' }) {
    const list = this.schedule.get(listName).toOrderedSet();

    if (listName === 'schedule_resources') {
      const resources = list.merge(this.selectedResourcesIds);

      this.handleFieldChange({
        field: listName,
        value: resources,
      });
      this.selectedResourcesIds = OrderedSet();
      this.openResourcesDrawer = false;
      this.setAvailableResources();
    } else {
      const staff = list.merge(this.selectedStaffIds);

      this.handleFieldChange({
        field: listName,
        value: staff,
      });
      this.selectedStaffIds = OrderedSet();
      this.openStaffDrawer = false;
      this.setAvailableStaff();
    }
  }

  getRequestData() {
    const customerEvent = this.getEventData();
    if (!this.isRepeating) {
      const startDate = this.schedule.getIn([
        'availability_schedule',
        'start_date',
      ]);
      const dayTimeMap = Map().set(
        moment(startDate).format('d'),
        this.defaultTimes
      );

      this.schedule = this.schedule.setIn(
        ['availability_schedule', 'daytimes'],
        dayTimeMap
      );
      this.schedule = this.schedule.setIn(
        ['availability_schedule', 'end_date'],
        startDate
      );
    }

    const scheduleResources = this.schedule.get('schedule_resources').toList();
    const scheduleStaff = this.schedule.get('schedule_staff').toList();
    const serverSchedule = this.schedule.toServer();
    const isTeamEvent =
      this.eventId === customerEvent.id && customerEvent.isTeamEvent();

    if (isTeamEvent) {
      serverSchedule.min_age = customerEvent.getIn(['team_detail', 'min_age']);
      serverSchedule.max_age = customerEvent.getIn(['team_detail', 'max_age']);
      serverSchedule.max_size = customerEvent.getIn([
        'team_detail',
        'roster_size',
      ]);
      serverSchedule.gender_restriction = customerEvent.getIn([
        'team_detail',
        'gender_restriction',
      ]);
    } else {
      delete serverSchedule.label;
      delete serverSchedule.team_schedule_type;
    }

    const { availability_schedule: availabilitySchedule } = serverSchedule;

    if (scheduleResources.size > 0) {
      serverSchedule.schedule_resources = scheduleResources
        .map((id, index) => ({
          position: index,
          resource_id: id,
          schedule_id: this.schedule.id,
        }))
        .toJS();
    }

    if (scheduleStaff.size > 0) {
      serverSchedule.schedule_staff = scheduleStaff
        .map((id, index) => ({
          position: index,
          customer_user_id: id,
          schedule_id: this.schedule.id,
        }))
        .toJS();
    }

    const scheduleDates = {
      start_date: moment(availabilitySchedule.start_date).format('YYYY-MM-DD'),
      end_date: availabilitySchedule.end_date
        ? moment(availabilitySchedule.end_date).format('YYYY-MM-DD')
        : '',
      stops_by_date: availabilitySchedule.stops_by_date
        ? moment(availabilitySchedule.stops_by_date).format('YYYY-MM-DD')
        : '',
    };

    serverSchedule.availability_schedule = {
      ...serverSchedule.availability_schedule,
      ...scheduleDates,
    };

    return serverSchedule;
  }

  createSchedule() {
    const requestData = this.getRequestData();

    this.creatingSchedule = true;
    ScheduleSource.create({
      availabilitySchedule: {
        ...requestData,
        ...{ event_id: this.eventId },
      },
      success: EventSessionCreationActions.createScheduleSuccess,
      error: EventSessionCreationActions.createScheduleError,
    });
  }

  createScheduleSuccess() {
    const { viewMode } = ClassesListStore.getState();

    this.creatingSchedule = false;

    if (viewMode === VIEW_MODES.SESSIONS) {
      ClassesListActions.listSessions.defer({});
    } else {
      ClassesListActions.listSchedules.defer({ eventId: this.eventId });
    }

    this.toggleDrawer({});
  }

  createScheduleError() {
    this.creatingSchedule = false;
  }

  editSchedule({ schedule }) {
    const {
      availability_schedule: availabilitySchedule,
      location,
      id,
      schedule_resources: scheduleResources,
      schedule_staff: scheduleStaff,
      label,
      team_schedule_type: scheduleType,
    } = schedule;

    const clientAvailabilitySchedule = new AvailabilitySchedule(
      availabilitySchedule,
      {
        fromServer: true,
      }
    );
    const { daytimes } = clientAvailabilitySchedule;

    if (availabilitySchedule.frequency === 'weekly') {
      this.isRepeating = true;
    }

    if (!availabilitySchedule.frequency && daytimes.size === 1) {
      const startTime = daytimes.first().first().get('start_time');
      const endTime = daytimes.first().first().get('end_time');

      this.defaultTimes = List([
        Map({
          start_time: startTime,
          end_time: endTime,
        }),
      ]);
    }

    this.schedule = new Schedule({
      id,
      availability_schedule: clientAvailabilitySchedule,
      location: new Location({
        id: location?.id || null,
        name: location?.name || null,
        address: new Address(location?.address || {}),
      }),
      max_size: schedule.max_size,
      max_age: schedule.max_age,
      min_age: schedule.min_age,
      gender_restriction: schedule.gender_restriction,
      scheduling_deadline: schedule.scheduling_deadline,
      cancellation_deadline: schedule.cancellation_deadline,
      registrationDeadline: schedule.registrationDeadline,
      scheduling_timeframe: schedule.scheduling_timeframe,
      waitlist_mode: schedule.waitlist_mode,
      waitlist_invite_expiration: schedule.waitlist_invite_expiration,
      schedule_staff:
        scheduleStaff.length > 0
          ? List(scheduleStaff.map(s => s.customer_user_id))
          : List(),
      schedule_resources:
        scheduleResources.length > 0
          ? List(scheduleResources.map(r => r.resource_id))
          : List(),
      label,
      team_schedule_type: scheduleType,
    });

    if (this.schedule.get('scheduling_deadline', false)) {
      this.scheduleDeadlineUnit = this.schedule.getDeadlineUnit({});
      this.scheduleDeadlineValue = this.schedule.getDeadlineValue({
        unit: this.scheduleDeadlineUnit,
        fromSeconds: true,
      });
    }

    if (this.schedule.get('cancellation_deadline', false)) {
      this.cancellationDeadlineUnit = this.schedule.getDeadlineUnit({
        deadlineField: 'cancellation_deadline',
        excludeDays: true,
      });
      this.cancellationDeadlineValue = this.schedule.getDeadlineValue({
        unit: this.cancellationDeadlineUnit,
        deadlineField: 'cancellation_deadline',
        fromSeconds: true,
      });
    }

    if (this.schedule.get('waitlist_mode', 'none') === 'invite') {
      this.waitlistExpirationUnit = this.schedule.getDeadlineUnit({
        deadlineField: 'waitlist_invite_expiration',
      });
      this.waitlistExpirationValue = this.schedule.getDeadlineValue({
        unit: this.waitlistExpirationUnit,
        deadlineField: 'waitlist_invite_expiration',
        fromSeconds: true,
      });
    }

    this.creatingMode = false;
    this.toggleDrawer({ eventId: schedule.event_id });
    this.initialSchedule = this.schedule;
  }

  sendUpdatedData() {
    const requestData = this.getRequestData();

    this.creatingSchedule = true;
    ScheduleSource.put({
      id: this.schedule.id,
      availabilitySchedule: requestData,
      fields: [
        'schedule_resources',
        'schedule_staff',
        'future_registration_count',
        'total_registration_count',
      ],
      success: EventSessionCreationActions.updateScheduleSuccess,
      error: EventSessionCreationActions.updateScheduleError,
    });
  }

  confirmNeeded() {
    const initialAvailability = this.initialSchedule.get(
      'availability_schedule'
    );
    const availability = this.schedule.get('availability_schedule');
    const daysChanged = !initialAvailability.daytimes.every((_times, day) =>
      availability.daytimes.get(day)
    );

    const showWarning =
      !moment(availability.start_date).isSame(initialAvailability.start_date) ||
      !moment(availability.end_date).isSame(initialAvailability.end_date) ||
      daysChanged;

    return showWarning;
  }

  updateSchedule(skipConfirm = false) {
    const needConfirm = this.confirmNeeded();

    if (needConfirm && !skipConfirm) {
      this.toggleEditConfirm();
    } else {
      this.sendUpdatedData();
    }
  }

  updateScheduleSuccess() {
    this.creatingSchedule = false;
    this.editedFields = OrderedSet();
    this.toggleDrawer({});
  }

  updateScheduleError() {
    this.creatingSchedule = false;
  }

  toggleEditConfirm() {
    this.showEditConfirm = !this.showEditConfirm;
  }
}

export default alt.createStore(
  EventSessionCreationStore,
  'EventSessionCreationStore'
);
