import React, { memo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Typography, Grid, Checkbox, Button } from '@upperhand/playmaker';

import UserAvatar from 'shared/components/_UserAvatar.jsx';

import WaitlistEntryRecord from 'shared/records/WaitlistEntry';

function WaitlistEntry({
  entry,
  isSelected = false,
  onSelect = () => {},
  onRemove = () => {},
}) {
  if (!entry) return null;

  const canRemove = !entry.isJoined && !entry.isRemoved;
  const handleSelect = () => {
    if (!entry.canAddToEvent) return;

    onSelect(entry.id);
  };
  return (
    <Grid
      container
      alignItems="center"
      className="ssd-waitlist__entry"
      wrap="nowrap"
      spacing={1}
      justify="space-between"
    >
      <Grid
        item
        container
        xs={entry.isJoined ? 8 : 10}
        alignItems="center"
        className="ssd-waitlist__entry--container"
        wrap="nowrap"
      >
        {entry.canAddToEvent && (
          <Grid item>
            <Checkbox
              checked={isSelected}
              onChange={() => onSelect(entry.id)}
            />
          </Grid>
        )}
        <Grid item>
          <div
            className={clsx(
              entry.canAddToEvent && 'ssd-waitlist__entry-info--clickable'
            )}
            role="presentation"
            onClick={handleSelect}
          >
            <UserAvatar user={entry.get('client')} size={30} />
          </div>
        </Grid>
        <Grid item className="ssd-waitlist__entry-info">
          <div
            className={clsx(
              entry.canAddToEvent && 'ssd-waitlist__entry-info--clickable'
            )}
            role="presentation"
            onClick={handleSelect}
          >
            <Typography
              variant="body1"
              className="ssd-waitlist__entry-info--name"
            >
              {entry.get('client').name()}
            </Typography>
            <Typography
              variant="body2"
              className="ssd-waitlist__entry-info--email"
            >
              {entry.get('created_at').format('MMM D, YYYY • h:mma')}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={!entry.isJoined && !entry.isRemoved ? 2 : 4}>
        {canRemove ? (
          <Button
            rounded
            icon="close"
            type="tertiary"
            onClick={() => onRemove(entry)}
          />
        ) : (
          <Typography
            variant="body1"
            className={clsx(
              'ssd-waitlist__entry-status',
              `ssd-waitlist__entry-status-${entry.state}`
            )}
          >
            {entry.state}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

WaitlistEntry.propTypes = {
  entry: PropTypes.instanceOf(WaitlistEntryRecord).isRequired,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
};

export default memo(WaitlistEntry);
