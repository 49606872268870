import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Set, List } from 'immutable';
import { Typography, Button, Expander, Grid } from '@upperhand/playmaker';

import AvatarWithName from 'shared/components/AvatarWithName.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import {
  SessionDataStore,
  ClientDataStore,
  StaffDataStore,
  EventDataStore,
} from 'dataStores';

function BookingSuccess({
  clientId = null,
  sessionIds = Set(),
  actionTitle = '',
  action = undefined,
  sessionDataStore = {},
  clientDataStore = {},
  staffDataStore = {},
  eventDataStore = {},
}) {
  const intl = useIntl();
  const { staff: staffList } = staffDataStore;
  const { sessions } = sessionDataStore;
  const { clients } = clientDataStore;
  const { events } = eventDataStore;
  const client = clients.get(clientId);

  return (
    <div className="session-booking-success">
      {client && <AvatarWithName user={client} />}
      <Expander
        arrowRight
        contentOffset={false}
        header={
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography>
                {t('.sessions_booked', intl, __filenamespace, {
                  count: sessionIds.size,
                })}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>{t('.details', intl, __filenamespace)}</Typography>
            </Grid>
          </Grid>
        }
      >
        <div>
          {sessionIds.map(sessionId => {
            const session = sessions.get(sessionId);

            if (!session) {
              return null;
            }

            const event = events.get(session.event_id);
            const schedules = event?.get('schedules', List());
            const schedule = schedules?.find(s => s.id === session.schedule_id);
            const location = schedule ? schedule.location : null;
            const locationName = location ? location.name : '';
            const startsAt = session.get('starts_at');
            const endsAt = session.get('ends_at');
            const staffIds = session.get('staff_ids');
            const staffNames =
              staffIds
                .map(staffId => staffList.get(staffId).name())
                ?.toArray() || [];

            return (
              <Grid container key={sessionId} spacing={1}>
                <Grid item container xs={12}>
                  <Grid item xs={6}>
                    <Typography variant="caption">
                      {startsAt.format('MM/DD/YY')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{staffNames.join(', ')}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {startsAt.format('h:mm a')} - {endsAt.format('h:mm a')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {locationName}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </div>
      </Expander>

      {typeof action === 'function' && (
        <Button
          fullWidth
          classes={{ root: 'session-booking-success__action' }}
          onClick={action}
        >
          {actionTitle || t('.book_more', intl, __filenamespace)}
        </Button>
      )}
    </div>
  );
}

BookingSuccess.propTypes = {
  clientId: PropTypes.number,
  sessionIds: PropTypes.instanceOf(Set),
  actionTitle: PropTypes.string,
  action: PropTypes.func,
  sessionDataStore: PropTypes.object,
  clientDataStore: PropTypes.object,
  staffDataStore: PropTypes.object,
  eventDataStore: PropTypes.object,
};

export default compose(
  memo,
  altContainer({
    stores: {
      sessionDataStore: SessionDataStore,
      clientDataStore: ClientDataStore,
      staffDataStore: StaffDataStore,
      eventDataStore: EventDataStore,
    },
  })
)(BookingSuccess);
