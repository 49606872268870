import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { OrderedSet, Map } from 'immutable';

import WaitlistEntry from 'containers/classes/classesList/components/Waitlist/WaitlistEntry.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import { WaitlistDataStore } from 'dataStores';

import './styles.scss';

function WaitlistEntries({
  waitlistEntriesIds = OrderedSet(),
  waitlistDataStore = {
    waitlistEntries: Map(),
  },
  onRemoveEntry = () => null,
  onAddToEvent = () => null,
  onShowClient = () => null,
}) {
  const intl = useIntl();
  const { waitlistEntries } = waitlistDataStore;

  const getMenuOptions = entry => {
    const options = [
      {
        label: t('.add_to_event', intl, __filenamespace),
        onClick: () => onAddToEvent(entry),
        visible: entry.canAddToEvent,
      },
      {
        label: t('.remove', intl, __filenamespace),
        onClick: () => onRemoveEntry(entry),
        visible: entry.canRemove,
      },
    ].filter(option => option.visible);

    return options;
  };

  return (
    <>
      {waitlistEntriesIds.map(entryId => {
        const entry = waitlistEntries.get(entryId);

        if (!entry) return null;

        return (
          <WaitlistEntry
            key={entryId}
            entry={entry}
            menuOptions={getMenuOptions(entry)}
            onShowClient={onShowClient}
          />
        );
      })}
    </>
  );
}

WaitlistEntries.propTypes = {
  waitlistEntriesIds: PropTypes.instanceOf(OrderedSet),
  waitlistDataStore: PropTypes.shape({
    waitlistEntries: PropTypes.instanceOf(Map),
  }),
  onRemoveEntry: PropTypes.func,
  onAddToEvent: PropTypes.func,
  onShowClient: PropTypes.func,
};

export default compose(
  memo,
  altContainer({
    stores: {
      waitlistDataStore: WaitlistDataStore,
    },
  })
)(WaitlistEntries);
