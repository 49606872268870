import * as React from 'react';
import PropTypes from 'prop-types';
import { Contacts as CapacitorContacts } from '@capacitor-community/contacts';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid } from '@upperhand/playmaker';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import ProminentDisclosure from 'containers/contacts/contactsPage/components/ProminentDisclosure.jsx';

import ContactFilter from 'containers/contacts/contactsFilters/records/ContactFilter';

import ContactImportActions from 'contacts/index/actions/ContactImportActions.js';
import ContactsClientsActions from 'containers/contacts/contactsClients/Actions';

import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { isNative } from 'shared/utils/UserAgentUtils';

const onImportClick = () => ContactImportActions.drawerOpened();
const onExportClick = () => ContactsClientsActions.downloadList();

function ButtonActions({
  disableExport = false,
  handleShowDisclosure = () => {},
}) {
  const handleImportClick = async () => {
    if (isNative()) {
      const permission = await CapacitorContacts.checkPermissions();

      if (permission.contacts !== 'granted') {
        handleShowDisclosure();
        return;
      }
    }

    onImportClick();
  };

  return (
    <Grid
      container
      spacing={1}
      justify="flex-end"
      className="contacts__abs-button-container"
    >
      <div className="btn-wrapper">
        <Button
          color="dark"
          onClick={handleImportClick}
          startIcon={
            <CloudUploadIcon
              style={{ height: 20, width: 20, color: uhColors.activeBlue }}
            />
          }
        >
          <FormattedMessage id={messageId('.import', __filenamespace)} />
        </Button>
        <Button
          color="dark"
          disabled={disableExport}
          onClick={onExportClick}
          startIcon={
            <CloudDownloadIcon
              style={{ height: 20, width: 20, color: uhColors.activeBlue }}
            />
          }
        >
          <FormattedMessage id={messageId('.export', __filenamespace)} />
        </Button>
      </div>
    </Grid>
  );
}

function MenuActions({ handleShowDisclosure }) {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleImportClick = async () => {
    handleMenuClose();

    if (isNative()) {
      const permission = await CapacitorContacts.checkPermissions();

      if (permission.contacts !== 'granted') {
        handleShowDisclosure();
        return;
      }
    }

    onImportClick();
  };

  return (
    <>
      <IconButton onClick={handleMenuClick}>
        <MoreVertIcon sx={{ color: uhColors.iconGrey }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleImportClick}>
          {t('.import', intl, __filenamespace)}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onExportClick();
            handleMenuClose();
          }}
        >
          {t('.export', intl, __filenamespace)}
        </MenuItem>
      </Menu>
    </>
  );
}

function ActionButtons({
  appliedFilters = new ContactFilter(),
  selectedTab = 0,
  disableExport = false,
}) {
  const [showDisclosure, setShowDisclosure] = React.useState(false);

  const handleShowDisclosure = () => setShowDisclosure(true);

  return (
    <>
      <ResponsiveElement
        largeScreen={
          <ButtonActions
            appliedFilters={appliedFilters}
            selectedTab={selectedTab}
            disableExport={disableExport}
            handleShowDisclosure={handleShowDisclosure}
          />
        }
        smallScreen={
          <MenuActions
            appliedFilters={appliedFilters}
            selectedTab={selectedTab}
            handleShowDisclosure={handleShowDisclosure}
          />
        }
      />
      <ProminentDisclosure
        open={showDisclosure}
        onClose={() => setShowDisclosure(false)}
        onPermissionGranted={onImportClick}
      />
    </>
  );
}

ActionButtons.propTypes = {
  appliedFilters: PropTypes.instanceOf(ContactFilter),
  selectedTab: PropTypes.number,
  disableExport: PropTypes.bool,
};

export default ActionButtons;
