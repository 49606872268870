/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import moment from 'moment-timezone';
import { FormattedMessage, injectIntl } from 'react-intl';

import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import Attribute from 'contacts/index/components/_UserDetailAttribute.jsx';

import StaffAvailabilityActions from 'shared/actions/StaffAvailabilityActions.jsx';
import StaffDetailsDrawerActions from 'contacts/shared/actions/StaffDetailsDrawerActions.jsx';
import UserDetails from 'contacts/index/components/_UserDetails.jsx';
import { FlexBox } from 'shared/components/FlexBox.jsx';
import {
  formatDate,
  formatClientTime,
} from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  divider: {
    color: '#e1e1e1',
    marginTop: 20,
  },
};

function DaytimeList({ daytimes, dateSpecificDaytimes, intl }) {
  const weekdays = [...Array(7).keys()].map(weekdayIndex =>
    moment().startOf('week').add(weekdayIndex, 'days').format('dddd')
  );

  return (
    <div style={{ marginBottom: '10px' }}>
      {daytimes
        .map((times, day, i) => (
          <FlexBox key={`${day}-${i}`} style={{ marginTop: 20 }}>
            <div style={{ fontWeight: 'bold', flex: '1 0 50%' }}>
              {weekdays[day]}
            </div>
            <div style={{ flex: '1 0 50%', marginTop: '10px' }}>
              {times
                .sortBy(timemap => timemap.get('start_time'))
                .map((timemap, j) => (
                  <div key={`${day}-${timemap.start_time}-${j}`}>
                    {formatClientTime(timemap.get('start_time'), 'h:mma')}
                    &ensp;-&ensp;
                    {formatClientTime(timemap.get('end_time'), 'h:mma')}
                  </div>
                ))}
            </div>
          </FlexBox>
        ))
        .toIndexedSeq()}
      <Divider style={styles.divider} />
      {dateSpecificDaytimes.size > 0 && (
        <div>
          <Attribute label={t('.date_specific', intl, __filenamespace)}>
            {dateSpecificDaytimes
              .sortBy((_timemap, index) => index)
              .map((times, date, i) => (
                <FlexBox key={`${date}-${i}`} style={{ marginTop: 20 }}>
                  <div style={{ fontWeight: 'bold', flex: '1 0 50%' }}>
                    {weekdays[moment(date).day()]}
                  </div>
                  <div style={{ flex: '1 0 50%', fontWeight: 'bold' }}>
                    {formatDate(date)}
                    {times.map((timemap, timeIndex) => (
                      <div
                        style={{ fontWeight: 'normal', padding: '7px 0' }}
                        key={`${timeIndex}-${i}`}
                      >
                        {formatClientTime(timemap.get('start_time'), 'h:mma')}
                        &ensp;-&ensp;
                        {formatClientTime(timemap.get('end_time'), 'h:mma')}
                      </div>
                    ))}
                  </div>
                </FlexBox>
              ))
              .toIndexedSeq()}
          </Attribute>
          <Divider style={styles.divider} />
        </div>
      )}
    </div>
  );
}

function DateList({ dates }) {
  return (
    <div>
      {dates
        .map(date => moment(date))
        .map(date => (
          <FlexBox style={{ marginTop: 20 }} key={date.format('dddd')}>
            <div style={{ fontWeight: 'bold', flex: '1 0 50%' }}>
              {date.format('dddd')}
            </div>
            <div style={{ flex: '1 0 50%' }}>{formatDate(date)}</div>
          </FlexBox>
        ))}
    </div>
  );
}

function StaffDetailsDrawerView({
  staffUnavailability,
  unavailabilityIds,
  availabilitySchedule,
  staffUser,
  canEditInfo,
  intl,
}) {
  const { unavailabilities } = staffUnavailability;
  const unavailabilityByWeekday = unavailabilityIds
    .map(id => unavailabilities.get(id))
    .toOrderedMap()
    .groupBy(u => u.starts_at.format('dddd'));

  return (
    <div>
      <UserDetails user={staffUser} />

      <Divider style={styles.divider} />

      {availabilitySchedule && (
        <Attribute
          label={t('.availability', intl, __filenamespace)}
          action={
            canEditInfo ? (
              <IconButton
                style={{ padding: '0', height: 36, width: 36 }}
                onClick={() => {
                  StaffAvailabilityActions.stageAvailabilityScheduleFor(
                    staffUser
                  );
                  StaffDetailsDrawerActions.toggleInfoEditMode();
                }}
              >
                <EditIcon sx={{ color: uhColors.navIconGrey }} />
              </IconButton>
            ) : null
          }
        >
          <p>
            {formatDate(availabilitySchedule.start_date)}
            &ensp;&ndash;&ensp;
            {availabilitySchedule.indefinite ? (
              <FormattedMessage
                id={messageId('.indefinite', __filenamespace)}
              />
            ) : (
              formatDate(availabilitySchedule.end_date)
            )}
          </p>
          <DaytimeList
            dateSpecificDaytimes={availabilitySchedule.get(
              'date_specific_daytimes'
            )}
            daytimes={availabilitySchedule.daytimes}
            intl={intl}
          />
        </Attribute>
      )}
      {availabilitySchedule && availabilitySchedule.exclusions.size > 0 && (
        <>
          <Attribute label={t('.blackout_days', intl, __filenamespace)}>
            <DateList dates={availabilitySchedule.exclusions} />
          </Attribute>
          <Divider style={styles.divider} />
        </>
      )}
      {unavailabilityIds.size > 0 && (
        <Attribute label={t('.unavailability', intl, __filenamespace)}>
          {unavailabilityByWeekday.entrySeq().map(([weekday, items]) => (
            <FlexBox key={weekday} style={{ marginTop: 20 }}>
              <div style={{ fontWeight: 'bold', flex: '1 0 50%' }}>
                {weekday}
              </div>
              <div
                style={{
                  flex: '1 0 50%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                }}
              >
                {items.toArray().map(item => (
                  <div key={item.id}>
                    <p style={{ fontWeight: 'bold' }}>
                      {formatClientTime(item.starts_at, 'MMMM D, YYYY')}
                    </p>
                    <p>
                      {formatClientTime(item.starts_at, 'h:mma')}
                      &nbsp;-&ensp;
                      {formatClientTime(item.ends_at, 'h:mma')}
                    </p>
                    <p style={{ textTransform: 'capitalize' }}>
                      {item.reason.replace('_', ' ')}
                    </p>
                  </div>
                ))}
              </div>
            </FlexBox>
          ))}
        </Attribute>
      )}
    </div>
  );
}
export default injectIntl(StaffDetailsDrawerView);
