import * as React from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { messageId } from 'shared/utils/LocaleUtils.js';

import {
  DateDisplay,
  DividedContent,
  TimeDisplay,
  Typography,
  Button,
} from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import Circle from 'shared/components/Circle.jsx';
import Session from 'shared/records/Session.js';
import { EventDataStore, SessionDataStore } from 'dataStores';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';

const styles = {
  mainContainer: {
    padding: '0 16px',
  },
  circleContainer: {
    paddingRight: 10,
  },
  dividedContentContainer: {
    padding: '15px 0 15px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cancelledBadge: {
    backgroundColor: uhColors.warning,
    borderRadius: 2,
    fontSize: '10px',
    padding: '1px 5px',
    color: uhColors.charcoalBlack,
    fontWeight: 600,
    marginBottom: '8px',
    width: 'fit-content',
  },
};

function Header({
  minimized,
  sessionDataStore: { sessions },
  sessionId,
  isEditMode,
  onNextSession,
  onPreviousSession,
  event,
}) {
  const isTeamEvent = event?.isTeamEvent();
  const session = sessions.get(sessionId, new Session());
  const clientCount = session.registration_ids.count();
  const maxSize = session.get('max_size');
  const showPrevBtn = !isEditMode && typeof onPreviousSession === 'function';
  const showNextBtn = !isEditMode && typeof onNextSession === 'function';
  const isClass = event.isClassSchedule();
  const eventTypeColor = isTeamEvent
    ? event.team_type.color
    : event.event_type.color;
  const eventTypeName = isTeamEvent
    ? event.team_type.name
    : event.event_type.name;
  return (
    <div className="session-summary-drawer-header">
      {(event.isCancelled() || session.isCancelled()) && (
        <div style={styles.cancelledBadge}>
          <FormattedMessage id={messageId('.cancelled', __filenamespace)} />
        </div>
      )}
      <Typography variant="h4">{event.title}</Typography>
      <FlexBoxCenter>
        <div style={styles.circleContainer}>
          <Circle color={eventTypeColor} diameter={6} />
        </div>
        <span>
          {session.duration() && (
            <Typography variant="button" component="span">
              <FormattedMessage
                id={messageId('.minutes', __filenamespace)}
                values={{ count: session.duration().as('minutes') }}
              />
            </Typography>
          )}
          <Typography variant="drawerTitle" component="span">
            {eventTypeName}
          </Typography>
        </span>
      </FlexBoxCenter>

      {isEditMode && !minimized && (
        <Typography
          variant="fieldLabel"
          className="session-summary-drawer-header__edit-label"
        >
          <FormattedMessage id={messageId('.edit_session', __filenamespace)} />
        </Typography>
      )}
      {!minimized && (
        <div style={styles.dividedContentContainer}>
          {showPrevBtn && (
            <Button
              rounded
              classes={{ root: 'prev-next-btn' }}
              icon="arrowLeft"
              type="tertiary"
              onClick={onPreviousSession}
            />
          )}
          <DividedContent
            classes={{
              container: 'divided-content',
              pane: 'divided-content__pane',
            }}
            panes={[
              <DateDisplay from={session.starts_at} to={session.ends_at} />,
              <TimeDisplay from={session.starts_at} to={session.ends_at} />,
              <div
                className={clsx(
                  'class-size',
                  (showPrevBtn || showNextBtn) && 'class-size-padding'
                )}
              >
                <Typography variant="caption" className="class-size-title">
                  <FormattedMessage
                    id={messageId(
                      isClass ? '.class_size' : '.session_size',
                      __filenamespace
                    )}
                  />
                </Typography>
                <Typography variant="dateDisplay">
                  {clientCount} / {maxSize}
                </Typography>
              </div>,
            ]}
          />
          {showNextBtn && (
            <Button
              rounded
              classes={{ root: 'prev-next-btn' }}
              icon="arrowRight"
              type="tertiary"
              onClick={onNextSession}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default altContainer({
  stores: {
    eventDataStore: EventDataStore,
    sessionDataStore: SessionDataStore,
    eventStore: EventStore,
  },
})(Header);
