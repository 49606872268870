class WaitlistActions {
  constructor() {
    this.generateActions(
      'mounted',
      'listWaitlist',
      'listWaitlistSuccess',
      'listWaitlistError',
      'searchWaitlist',
      'selectEntry',
      'waitlistAddToEvent',
      'waitlistAddToEventSuccess',
      'waitlistAddToEventError',
      'removeEntry',
      'removeEntrySuccess',
      'removeEntryError'
    );
  }
}

export default alt.createActions(WaitlistActions);
