import React, { useRef } from 'react';
import { Box } from '@mui/material';

import Content from 'team_mgmt/index/components/_Content.jsx';
import Header from 'team_mgmt/index/components/_Header.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';

import StoreActions from 'shared/actions/StoreActions.jsx';
import TeamListActions from 'team_mgmt/index/actions/TeamListActions.jsx';
import AutomationTemplateDescriptionActions from 'shared/actions/AutomationTemplateDescriptionActions.jsx';
import AutomationTemplateDescriptionStore from 'shared/stores/AutomationTemplateDescriptionStore.jsx';
import TeamTypeListingStore from 'shared/stores/TeamTypeListingStore.jsx';
import FilterStore from 'team_mgmt/index/stores/FilterStore.jsx';
import TeamStore from 'team_mgmt/shared/stores/TeamStore.jsx';
import TeamListStore from 'team_mgmt/index/stores/TeamListStore.jsx';

import '../styles.scss';

function TeamMgmtIndex(props) {
  const prevTeamsRef = useRef();
  const { readOnly, teamList } = props;
  const { teams } = teamList;

  React.useEffect(() => {
    StoreActions.prepareForReuse();
    TeamListActions.teamsDeselected();
  }, []);

  React.useEffect(() => {
    const shouldListAtd =
      prevTeamsRef.current &&
      !teams.equals(prevTeamsRef.current) &&
      teams?.size > 0;

    if (shouldListAtd) {
      const teamIds = teams.map(team => team.id);

      AutomationTemplateDescriptionActions.list({
        event_ids: teamIds.toJS(),
      });
    }

    prevTeamsRef.current = teams;
  }, [teams]);

  return (
    <Box>
      <Box className="teams">
        <Header />
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Content readOnly={readOnly} {...props} />
      </Box>
    </Box>
  );
}

export default altContainer({
  stores: {
    automations: AutomationTemplateDescriptionStore,
    teamList: TeamListStore,
    teamTypes: TeamTypeListingStore,
    teamStore: TeamStore,
    filter: FilterStore,
  },
})(TeamMgmtIndex);
