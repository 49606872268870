import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import TodayIcon from '@mui/icons-material/Today';
import BlockIcon from '@mui/icons-material/Block';
import ClockIcon from 'shared/components/icons/Clock4pm.jsx';

import Amount from 'calendar/components/quick_schedule/Amount.jsx';
import Attendees from 'calendar/components/quick_schedule/Attendees.jsx';
import Checkout from 'calendar/components/quick_schedule/Checkout.jsx';
import DateSelector from 'calendar/components/quick_schedule/DateSelector.jsx';
import EventSelector from 'calendar/components/quick_schedule/EventSelector.jsx';
import PackageSelector from 'shared/components/registration/PackageSelector.jsx';
import Resources from 'calendar/components/quick_schedule/Resources.jsx';
import Staff from 'calendar/components/quick_schedule/Staff.jsx';
import TimeSelector from 'calendar/components/quick_schedule/TimeSelector.jsx';
import StaffUnavailability from 'calendar/components/staff_unavailability/StaffUnavailability.jsx';

import { FlexBoxJustify, FlexBox } from 'shared/components/FlexBox.jsx';
import { formattedHourString } from 'calendar/utils/CalendarUtils.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { PaymentActions, PaymentStore } from 'containers/payments';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import QuickScheduleActions from 'calendar/actions/QuickScheduleActions.jsx';
import ScheduleActions from 'calendar/components/quick_schedule/ScheduleActions.jsx';
import StaffUnavailabilityActions from 'calendar/actions/StaffUnavailabilityActions';

const styles = {
  Popover: dateRange => {
    const minutesOffset = dateRange ? (dateRange.start.minute() / 60) * 96 : 0;
    return {
      backgroundColor: 'transparent',
      borderRadius: '0',
      boxShadow: 'none',
      transform: `translateY(${minutesOffset}px)`,
    };
  },
  TimeSlot: (dateRange, cellWidth) => {
    const height = dateRange ? (dateRange.diff('minutes') / 60) * 96 : 0;

    return {
      border: '4px solid var(--color-secondary)',
      borderRadius: '5px',
      height: `${height}px`,
      minWidth: '60px',
      width: cellWidth,
      zIndex: 1299,
    };
  },
  Dialog: {
    backgroundColor: 'white',
    borderRadius: '5px',
    fontSize: 15,
    left: '50%',
    position: 'fixed',
    transform: 'translate(-50%, -50%)',
    transformOrigin: 'left top 0px',
    top: '50%',
    width: '388px',
    zIndex: 1300,
  },
  Header: {
    fontSize: '13px',
    fontWeight: '600',
    paddingLeft: 15,
    marginBottom: -5,
  },
  membershipDiscountBanner: {
    margin: '0rem 0rem 1rem',
    padding: '2rem',
    height: 42,
    backgroundColor: '#F4F4F4',
    alignItems: 'center',
  },
  RemainingSessions: {
    alignItems: 'baseline',
    borderBottom: '1px solid var(--color-divide)',
    paddingBottom: 14,
    marginBottom: 5,
  },
  RemainingSessionsLabel: {
    width: '100%',
  },
  RemainingSessionsAmount: {
    width: '100%',
    textAlign: 'right',
  },
};

const timeRangeText = dateRange => {
  const formattedStart = formattedHourString(dateRange.start, {
    trailingZeros: true,
    truncateMeridiem: false,
  });
  const formattedEnd = formattedHourString(dateRange.end, {
    trailingZeros: true,
    truncateMeridiem: false,
  });

  return `${formattedStart} - ${formattedEnd}`;
};

function Header({ onCloseDialog, onBlockTime }) {
  return (
    <header style={styles.Header}>
      <FlexBoxJustify style={{ alignItems: 'center', fontSize: 15 }}>
        <FormattedMessage id={messageId('.quick_schedule', __filenamespace)} />
        <div>
          <IconButton onClick={onBlockTime}>
            <BlockIcon />
          </IconButton>
          <IconButton onClick={onCloseDialog}>
            <CloseIcon sx={{ color: uhColors.iconLightGrey }} />
          </IconButton>
        </div>
      </FlexBoxJustify>
    </header>
  );
}

function RemainingSessions({ amount }) {
  return (
    <FlexBox style={styles.RemainingSessions}>
      <div style={styles.RemainingSessionsLabel}>
        <FormattedMessage
          id={messageId('.remaining_sessions', __filenamespace)}
        />
      </div>
      <div style={styles.RemainingSessionsAmount}>
        {amount === Infinity ? 'Unlimited' : amount}
      </div>
    </FlexBox>
  );
}

function ProcessingOrder() {
  return (
    <div style={{ textAlign: 'center', margin: '115px 0' }}>
      <CircularProgress size={60} />
      <div style={{ marginTop: '1rem' }}>
        <FormattedMessage
          id={messageId('.processing_order', __filenamespace)}
        />
      </div>
    </div>
  );
}

function Scheduler({
  onCloseDialog,
  onBlockTime,
  qsClientListStore,
  qsClientStore,
  quickScheduleStore,
  resourceListingStore,
  staffStore,
}) {
  const { creditsRemaining } = quickScheduleStore;

  const [selectedValue, setSelectedValue] = React.useState(0);

  return (
    <div id="qs-dialog-content" style={styles.Dialog}>
      <Header onCloseDialog={onCloseDialog} onBlockTime={onBlockTime} />

      {!quickScheduleStore.creatingOrderItem && (
        <EventSelector
          onSearchTermChange={QuickScheduleActions.updateSearchTerm}
          events={quickScheduleStore.events}
          fetchingAvailableEvents={quickScheduleStore.fetchingAvailableEvents}
          selectedEvent={quickScheduleStore.selectedEvent}
        />
      )}
      {quickScheduleStore.selectedEvent ? (
        <div>
          {quickScheduleStore.creatingOrderItem ? (
            <ProcessingOrder />
          ) : (
            <div>
              <div>
                <FlexBox style={{ alignItems: 'center' }}>
                  <TimeSelector quickScheduleStore={quickScheduleStore} />
                  <DateSelector quickScheduleStore={quickScheduleStore} />
                </FlexBox>

                <Attendees
                  quickScheduleStore={quickScheduleStore}
                  qsClientListStore={qsClientListStore}
                  qsClientStore={qsClientStore}
                />

                <div
                  style={{
                    backgroundColor: '#f9f9f9',
                    paddingLeft: 15,
                    paddingRight: 10,
                  }}
                >
                  <Staff
                    quickScheduleStore={quickScheduleStore}
                    staffStore={staffStore}
                  />

                  <Resources
                    quickScheduleStore={quickScheduleStore}
                    resourceListingStore={resourceListingStore}
                  />

                  {quickScheduleStore.selectedClient &&
                    !(creditsRemaining > 0) && (
                      <PackageSelector
                        label={
                          <FormattedMessage
                            id={messageId('.select_a_package', __filenamespace)}
                          />
                        }
                        packagePricingDescription={
                          quickScheduleStore.packagePricingDescription
                        }
                        selectedValue={selectedValue}
                        price={quickScheduleStore.selectedEvent.price}
                        registrationPackage={
                          quickScheduleStore.registrationPackage
                        }
                        onAutomationOptionSelect={(_, __, uuid) => {
                          QuickScheduleActions.selectAutomationPackage(uuid);
                        }}
                        onDefaultOptionSelect={(_, __, size) => {
                          setSelectedValue(size);
                          QuickScheduleActions.selectDefaultPackage(size);
                        }}
                        style={{ height: '100%' }}
                      />
                    )}

                  {quickScheduleStore.selectedClient &&
                    creditsRemaining > 0 && (
                      <RemainingSessions amount={creditsRemaining} />
                    )}
                  {quickScheduleStore.selectedClient && (
                    <Amount quickScheduleStore={quickScheduleStore} />
                  )}
                </div>
              </div>
            </div>
          )}
          <ScheduleActions
            qsClientStore={qsClientStore}
            quickScheduleStore={quickScheduleStore}
          />
        </div>
      ) : (
        <FlexBox style={{ alignItems: 'center', marginTop: 5, padding: 10 }}>
          <FlexBox style={{ alignItems: 'center', flex: '1 0 auto' }}>
            <ClockIcon style={{ height: 16, width: 16 }} />
            <span style={{ marginLeft: 5 }}>
              {timeRangeText(quickScheduleStore.dateRange)}
            </span>
          </FlexBox>
          <FlexBox style={{ alignItems: 'center', flex: '1 0 auto' }}>
            <TodayIcon
              sx={{ height: 16, width: 16, color: 'rgb(154, 158, 173)' }}
            />
            <span style={{ marginLeft: 5 }}>
              {quickScheduleStore.dateRange.start.format('ddd MMM D')}
            </span>
          </FlexBox>
        </FlexBox>
      )}
    </div>
  );
}
class QuickSchedule extends React.PureComponent {
  popover = React.createRef();

  componentDidUpdate() {
    setTimeout(this.reduceZindexForPopoverLayout, 20);
  }

  // !!!!! HACK !!!!!
  // To fix an issue for Time and Package Drop-Downs Populate in front of dialog
  // reducing zIndex from 2000 to 1999 of quickSchedulePopover layout
  reduceZindexForPopoverLayout = () => {
    const node = this.popover.current;

    if (node) {
      node.parentNode.parentNode.parentNode.parentNode.style.zIndex = 1298;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  handleRequestClose = () => {
    const { processingFields, processingPayment } = PaymentStore.getState();

    if (!processingFields && !processingPayment) {
      QuickScheduleActions.close();
      PaymentActions.reset();
    }

    StaffUnavailabilityActions.cancelBlockTime();
  };

  render() {
    const {
      quickScheduleStore,
      qsClientListStore,
      qsClientStore,
      staffStore,
      resourceListingStore,
      staffUnavailabilityStore,
    } = this.props;
    const { open, anchorEl, dateRange, quickSchedulingStep, cellWidth } =
      quickScheduleStore;
    const isCheckingOut = quickSchedulingStep !== 'scheduling';
    const { isBlockingTime } = staffUnavailabilityStore;

    return (
      <div>
        <Popover
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
          onClose={this.handleRequestClose}
          style={styles.Popover(dateRange)}
          className="quickSchedulePopover"
        >
          <div ref={this.popover}>
            <div style={styles.TimeSlot(dateRange, cellWidth)} />
          </div>
        </Popover>
        <Dialog
          disableEnforceFocus
          open={open}
          onClose={this.handleRequestClose}
        >
          <>
            {open && !isBlockingTime && !isCheckingOut && (
              <Scheduler
                qsClientListStore={qsClientListStore}
                qsClientStore={qsClientStore}
                quickScheduleStore={quickScheduleStore}
                resourceListingStore={resourceListingStore}
                staffStore={staffStore}
                onCloseDialog={this.handleRequestClose}
                onBlockTime={StaffUnavailabilityActions.blockTime}
              />
            )}
            {open && isBlockingTime && !isCheckingOut && (
              <StaffUnavailability
                staffUnavailabilityStore={staffUnavailabilityStore}
                staffStore={staffStore}
              />
            )}
            {isCheckingOut && (
              <div
                id="qs-dialog-content"
                style={{ ...styles.Dialog, maxHeight: '90%', overflow: 'auto' }}
              >
                <Checkout quickScheduleStore={quickScheduleStore} />
              </div>
            )}
          </>
        </Dialog>
      </div>
    );
  }
}

export default QuickSchedule;
