import { Iterable, List, Set, Record } from 'immutable';
import TentativeRegistration from 'shared/records/TentativeRegistration';
import { SCHEDULE_TYPE } from 'event_mgmt/shared/records/CustomerEvent.jsx';

class RegistrationPackage extends Record({
  id: null,
  automation_option_uuid: null,
  automation_template_description: null,
  automation_template_description_id: null,
  event_id: null,
  event_type_id: null,
  registration_ids: Set(),
  quantity: 1,
  quantity_remaining: 0,
  quantity_revoked: 0,
  ordered_at: null,
  client_ids: Set(),
  tentative_details: List(),
  client_payment_methods: null,
  purchasing_credits: 0,
  schedule_type: null,

  all_sessions_price: null,
  single_session_price: null,
  full_price: null,

  // These are used for fake order items
  credit_pass_id: null,
}) {
  constructor(obj = {}) {
    if (Iterable.isIterable(obj)) {
      // eslint-disable-next-line no-param-reassign
      obj = obj.toJS();
    }

    const registrationIds = Set(obj.registration_ids || []);
    const clientIds = Set(obj.client_ids || []);

    const tentativeDetails = List(
      (obj.tentative_details || []).map(d => new TentativeRegistration(d))
    );

    super({
      ...obj,
      registration_ids: registrationIds,
      client_ids: clientIds,
      tentative_details: tentativeDetails,
    });
  }

  setSize(newSize) {
    return this.merge({
      quantity: newSize,
      tentative_details: this.tentative_details.take(newSize),
    });
  }

  getAthleteRegistrations(athleteId) {
    return this.getRegistrations().filter(
      r => r.get('customer_user_id') === athleteId
    );
  }

  isTeamSchedule() {
    return this.schedule_type === SCHEDULE_TYPE.teamSchedule;
  }

  get scheduledRegistrationCount() {
    return this.tentative_details.count(d => d.get('client_id'));
  }

  get singleSessionCount() {
    return this.registrationPackage.tentative_details
      .groupBy(d => d.client_id)
      .map(d => d.size)
      .filter(n => n < this.event.session_ids.size)
      .reduce((sum, n) => sum + n, 0);
  }

  toServer() {
    const serverValue = this.toJS();

    if (serverValue.automation_template_description) {
      serverValue.automation_template_description_id =
        serverValue.automation_template_description.id;
      delete serverValue.automation_template_description;
    }

    serverValue.tentative_details = this.tentative_details
      .map(d => d.toServer())
      .toJS();

    return serverValue;
  }
}

export default RegistrationPackage;
