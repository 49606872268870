import * as React from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  Checkbox,
  Typography,
  Dropdown,
  Grid,
  TextField,
  Card,
  Expander,
} from '@upperhand/playmaker';
import LinkIcon from '@mui/icons-material/Link';
import { Box, IconButton } from '@mui/material';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import UserActions from 'shared/actions/UserActions.jsx';
import CurrentContextActions from 'shared/actions/CurrentContextActions.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import Google from 'shared/components/icons/Google.jsx';
import Apple from 'shared/components/icons/Apple.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

const getSessionReminderTimeItems = intl => [
  {
    value: 60,
    label: t('.session_reminder_time_value', intl, __filenamespace, {
      hours: 1,
    }),
  },
  {
    value: 180,
    label: t('.session_reminder_time_value', intl, __filenamespace, {
      hours: 3,
    }),
  },
  {
    value: 1440,
    label: t('.session_reminder_time_value', intl, __filenamespace, {
      hours: 24,
    }),
  },
  {
    value: 2880,
    label: t('.session_reminder_time_value', intl, __filenamespace, {
      hours: 48,
    }),
  },
  {
    value: 4320,
    label: t('.session_reminder_time_value', intl, __filenamespace, {
      hours: 72,
    }),
  },
];

const styles = {
  customButton: active => ({
    width: 32,
    height: 32,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: '1px',
    outline: active ? '2px solid rgba(0, 159, 221, 1)' : 'none',
  }),
};

function NotificationsTab({ intl, CurrentContextStore = {}, userStore = {} }) {
  const {
    customerUser,
    isCustomerUserStoreLoading: customerUserIsLoading,
    isSaving: customerUserIsSaving,
  } = CurrentContextStore;
  const { user, isLoading: userIsLoading, isSaving: userIsSaving } = userStore;
  const customerUserPreferences = customerUser.get('preferences');
  const sessionReminderMethods = customerUserPreferences.get(
    'sessionReminderMethods'
  );

  const handleSavePreferences = () => {
    UserActions.update(false);
    CurrentContextActions.updatePreferences();
  };

  const [calendarMode, setCalendarMode] = React.useState(null);
  const [calendarURL, setCalendarURL] = React.useState(null);

  React.useEffect(() => {
    uhApiClient
      .asyncGet(`/customer_users/${currentUser().customer_user_id}/ics_link`)
      .then(({ data }) => {
        setCalendarURL(data.ics_link);
      })
      .catch(() => {
        setCalendarURL(null);
      });
  }, []);

  const displayReminders =
    !currentUser().isAdmin() &&
    currentCustomer().reminder_notifications_enabled;

  return (
    <SpinWhileLoading
      isLoading={userIsLoading && customerUserIsLoading && !calendarURL}
      spinWhile="isLoading"
      contentContainerStyle={{ margin: 24 }}
    >
      <Grid container spacing={2} alignItems="flex-start">
        <Grid
          item
          container
          spacing={2}
          md={4}
          xs={12}
          alignContent="flex-start"
        >
          <Grid item xs={12}>
            <Typography variant="h6">
              {t('.title_sync_calendar', intl, __filenamespace)}
            </Typography>
            <Typography variant="body1">
              {t('.desc_sync_calendar', intl, __filenamespace)}
            </Typography>
          </Grid>
          <Grid item container spacing={1} direction="row" xs={12}>
            <Grid item>
              <IconButton
                disableRipple
                sx={styles.customButton(calendarMode === 'google')}
                onClick={() => setCalendarMode('google')}
              >
                <Google />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                disableRipple
                sx={styles.customButton(calendarMode === 'apple')}
                onClick={() => setCalendarMode('apple')}
              >
                <Apple />
              </IconButton>
            </Grid>
          </Grid>
          {calendarMode && calendarURL && (
            <Instructions
              intl={intl}
              calendarURL={calendarURL}
              calendarMode={calendarMode}
            />
          )}
        </Grid>
        <Grid item container spacing={5} md={4} xs={12}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Typography variant="h6">
                {t('.title_global', intl, __filenamespace)}
              </Typography>
              <Typography variant="body1">
                {t('.desc_global', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Checkbox
                label={t('.label_email', intl, __filenamespace)}
                checked={user.get_email_notifications}
                onChange={(_, value) =>
                  UserActions.updateStore(['get_email_notifications'], value)
                }
              />
            </Grid>
          </Grid>
          {displayReminders && (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {t('.title_reminder', intl, __filenamespace)}
                </Typography>
                <Typography variant="body1">
                  {t('.desc_reminder', intl, __filenamespace)}
                </Typography>
                <Grid item xs={12}>
                  <Checkbox
                    label={t('.label_email', intl, __filenamespace)}
                    checked={sessionReminderMethods.has('email')}
                    onChange={(_, value) =>
                      CurrentContextActions.changePreferences(
                        'sessionReminderMethods',
                        value,
                        'email'
                      )
                    }
                  />
                </Grid>
                {currentCustomer().sms_enabled && (
                  <Grid item xs={12}>
                    <Checkbox
                      label={t('.label_text', intl, __filenamespace)}
                      checked={sessionReminderMethods.has('text')}
                      onChange={(_, value) =>
                        CurrentContextActions.changePreferences(
                          'sessionReminderMethods',
                          value,
                          'text'
                        )
                      }
                    />
                  </Grid>
                )}
              </Grid>
              {(sessionReminderMethods.has('email') ||
                sessionReminderMethods.has('text')) && (
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    {t('.title_send_reminder', intl, __filenamespace)}
                  </Typography>
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Dropdown
                          value={customerUserPreferences.get(
                            'sessionReminderTime'
                          )}
                          rounded={false}
                          onChange={event =>
                            CurrentContextActions.changePreferences(
                              'sessionReminderTime',
                              event.target.value
                            )
                          }
                          items={getSessionReminderTimeItems(intl)}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">
                          {t('.label_send_reminder', intl, __filenamespace)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>
        <Grid item container spacing={2} md={4} xs={12} />
        <Grid item xs={12}>
          <StateChangingButton
            inProgress={userIsSaving && customerUserIsSaving}
            onClick={handleSavePreferences}
          />
        </Grid>
      </Grid>
    </SpinWhileLoading>
  );
}

function Instructions({ intl, calendarMode, calendarURL }) {
  return (
    <Grid item xs={12}>
      <Card rootStyle={{ padding: '10px' }}>
        <Grid container spacing={1}>
          <Grid item container direction="row" xs={12}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {t(
                  `.${calendarMode}_calendar_instructions`,
                  intl,
                  __filenamespace
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CopyToClipboard
              onCopy={() =>
                MessageWindowActions.addMessage(
                  t('.url_copied', intl, __filenamespace)
                )
              }
              style={{ cursor: 'pointer' }}
              text={calendarURL}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <TextField
                  value={calendarURL}
                  readOnly
                  fullWidth
                  variant="outlined"
                />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                    width: '33%',
                  }}
                >
                  <LinkIcon />
                  <Typography variant="subtitle1" noWrap>
                    {t('.copy_url', intl, __filenamespace)}
                  </Typography>
                </Box>
              </Box>
            </CopyToClipboard>
          </Grid>
          <Grid item xs={12}>
            {calendarMode === 'google' ? (
              <GoogleCalendarInstructions />
            ) : (
              <AppleCalendarInstructions />
            )}
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}

function GoogleCalendarInstructions() {
  return (
    <ol className="instructions-list">
      <li>
        Copy your <strong>Sync URL</strong> using the link icon.
      </li>
      <li>Use a web browser to open Google Calendar.</li>
      <li>
        On the left side, find <strong>Other Calendars.</strong>
      </li>
      <li>
        Click the <strong>+</strong> next to <strong>Other calendars</strong>.
      </li>
      <li>
        Select <strong>From URL</strong>.
      </li>
      <li>
        Paste your Sync URL in the text box and click{' '}
        <strong>Add Calendar</strong>.
      </li>
    </ol>
  );
}

function AppleCalendarInstructions() {
  const [showMobileInstructions, setShowMobileInstructions] =
    React.useState(false);
  const [showDesktopInstructions, setShowDesktopInstructions] =
    React.useState(false);
  return (
    <>
      <Expander
        header={
          <Typography variant="subtitle1">On your iPhone or iPad</Typography>
        }
        expanded={showMobileInstructions}
        onChange={() => setShowMobileInstructions(!showMobileInstructions)}
      >
        <ol className="instructions-list">
          <li>
            Copy your <strong>Sync URL</strong> using the link icon.
          </li>
          <li>
            Open the <strong>Settings</strong> app.
          </li>
          <li>
            Search for and tap the <strong>Calendar</strong> app.
          </li>
          <li>
            Tap <strong>Accounts</strong>.
          </li>
          <li>
            Tap <strong>Add Account</strong>.
          </li>
          <li>
            Tap <strong>Other</strong>.
          </li>
          <li>
            Tap <strong>Add Subscribed Calendar</strong>.
          </li>
          <li>
            Paste your <strong>Sync URL</strong> in the text box.
          </li>
          <li>
            Tap <strong>Next</strong>.
          </li>
          <li>
            Tap <strong>Save</strong>.
          </li>
        </ol>
      </Expander>
      <Expander
        header={<Typography variant="subtitle1">On your Mac</Typography>}
        expanded={showDesktopInstructions}
        onChange={() => setShowDesktopInstructions(!showDesktopInstructions)}
      >
        <ol className="instructions-list">
          <li>
            Copy your <strong>Sync URL</strong> using the link icon.
          </li>
          <li>
            Open the <strong>Calendar</strong> app.
          </li>
          <li>
            Click <strong>File</strong> in the menu bar.
          </li>
          <li>
            Click <strong>New Calendar Subscription</strong>.
          </li>
          <li>
            Paste your <strong>Sync URL</strong> in the text box.
          </li>
          <li>
            Click <strong>Subscribe</strong>.
          </li>
          <li>
            Enter a name for the calendar. Pick desired color and auto-refresh
            interval.
          </li>
          <li>
            Click <strong>OK</strong>.
          </li>
        </ol>
      </Expander>
    </>
  );
}

NotificationsTab.propTypes = {
  intl: PropTypes.object.isRequired,
  CurrentContextStore: PropTypes.object,
  userStore: PropTypes.object,
};

export default NotificationsTab;
