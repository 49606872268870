class ClassesListActions {
  constructor() {
    this.generateActions(
      'mounted',
      'reset',
      'selectClass',
      'switchMode',
      'listClasses',
      'listClassesSuccess',
      'listClassesError',
      'listSessions',
      'listSessionsWithStatus',
      'listSessionsSuccess',
      'listSessionsError',
      'sessionsPageChange',
      'schedulePageChange',
      'listSchedules',
      'listSchedulesSuccess',
      'listSchedulesError',
      'listStaff',
      'listStaffSuccess',
      'listStaffError',
      'listResources',
      'listResourcesSuccess',
      'listResourcesError',
      'listLocations',
      'listLocationsSuccess',
      'listLocationsError',
      'toggleSessionSummaryDrawer',
      'searchSessions',
      'statusFilterChange',
      'staffFilterChange',
      'resourceFilterChange',
      'locationFilterChange',
      'fetchEvent',
      'fetchEventSuccess',
      'fetchEventError',
      'handleTabChange',
      'listClassRoster',
      'listClassRosterSuccess',
      'listClassRosterError',
      'rosterPageChange',
      'searchRoster',
      'staffPageChange',
      'searchStaff',
      'searchClasses',
      'listWaitlist',
      'listWaitlistSuccess',
      'listWaitlistError',
      'deleteWaitlistEntry',
      'deleteWaitlistEntrySuccess',
      'deleteWaitlistEntryError',
      'searchWaitlist',
      'waitlistPageChange',
      'waitlistFiltersDrawerToggle',
      'setWaitlistFilters',
      'applyWaitlistFilters',
      'clearWaitlistFilters',
      'removeWaitlistFilter',
      'waitlistAddToEvent',
      'waitlistAddToEventSuccess',
      'waitlistAddToEventError',
      'setParams'
    );
  }
}

export default alt.createActions(ClassesListActions);
