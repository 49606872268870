import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { List } from 'immutable';

import { ClientSource, CreditCountSource } from 'sources';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import ScheduleSelectorActions from './Actions';

class AccountProfilesStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      creditCountListError: ScheduleSelectorActions.creditCountListError,
      creditCountListSuccess: ScheduleSelectorActions.creditCountListSuccess,
      clientListSuccess: ScheduleSelectorActions.clientListSuccess,
      clientListError: ScheduleSelectorActions.clientListError,
      managedClientListSuccess:
        ScheduleSelectorActions.managedClientListSuccess,
      managedClientListError: ScheduleSelectorActions.managedClientListError,
      mount: ScheduleSelectorActions.mount,
    });
  }

  reset() {
    this.clientIds = List();
    this.clientLoading = false;
    this.creditCountsIds = List();
    this.eventId = null;
    this.loading = false;
    this.managedClientsLoading = false;
  }

  mount(eventId) {
    const customerUserId = currentUser().customer_user_id;

    this.reset();
    this.eventId = eventId;
    this.clientList(customerUserId);
    this.managedClientList(customerUserId);
  }

  clientList(customerUserId) {
    const params = {
      ids: [customerUserId],
    };

    this.loading = true;
    this.clientLoading = true;

    ClientSource.list({
      params,
      success: ScheduleSelectorActions.clientListSuccess,
      error: ScheduleSelectorActions.clientListError,
    });
  }

  clientListSuccess({ clients }) {
    if (clients.count() > 0) {
      const currentClientId = clients.first().id;
      this.clientIds = this.clientIds.unshift(currentClientId);
    }
    this.clientLoading = false;
    this.creditCountList();
  }

  clientListError() {
    this.clientLoading = false;
    this.creditCountList();
  }

  managedClientList(customerUserId) {
    const params = {
      per_page: 50,
      managing_client_ids: [customerUserId],
      fields: ['agreement_details'],
    };

    this.loading = true;
    this.managedClientsLoading = true;

    ClientSource.list({
      params,
      success: ScheduleSelectorActions.managedClientListSuccess,
      error: ScheduleSelectorActions.managedClientListError,
    });
  }

  managedClientListSuccess({ clients }) {
    this.clientIds = this.clientIds
      .concat(clients.map(c => c.id))
      .toSet()
      .toList();
    this.managedClientsLoading = false;
    this.creditCountList();
  }

  managedClientListError() {
    this.managedClientsLoading = false;
    this.creditCountList();
  }

  creditCountList() {
    if (!this.clientLoading && !this.managedClientsLoading) {
      if (this.clientIds.count() < 1) {
        this.loading = false;
        return;
      }

      CreditCountSource.list({
        params: {
          client_ids: this.clientIds.toJS(),
          event_ids: [this.eventId],
          per_page: 100,
        },
        success: ScheduleSelectorActions.creditCountListSuccess,
        error: ScheduleSelectorActions.creditCountListError,
      });
    }
  }

  creditCountListSuccess() {
    this.loading = false;
  }

  creditCountListError() {
    this.loading = false;
  }
}

export default alt.createStore(AccountProfilesStore, 'AccountProfilesStore');
