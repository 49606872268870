import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography } from '@upperhand/playmaker';

import StateChangingButton from 'shared/components/_StateChangingButton.jsx';

import { t } from 'shared/utils/LocaleUtils';
import { handleChange } from 'shared/helpers/ChangeHandler.jsx';

import CustomerActions from 'shared/actions/CustomerActions.jsx';

import './styles.scss';

function AnalyticsTab({ customerStore = {} }) {
  const intl = useIntl();
  const { customer, activeSaveButton, isSaving } = customerStore;

  const onChange = ({ value, key }) => {
    handleChange({ actions: CustomerActions })(null, value, key);
  };

  return (
    <div className="analytics-tab">
      <Typography className="analytics-tab__warning" variant="body1">
        <span>{t('.warning', intl, __filenamespace)}</span>
        &nbsp;
        {t('.warning_text', intl, __filenamespace)}
      </Typography>
      <textarea
        className="analytics-tab__textarea"
        value={customer.get('custom_tracking_scripts', '')}
        onChange={e =>
          onChange({
            value: e.target.value,
            key: 'custom_tracking_scripts',
          })
        }
      />
      <StateChangingButton
        disabled={!activeSaveButton}
        inProgress={isSaving}
        onClick={() => {
          CustomerActions.update({
            id: customer.get('id'),
            fields: ['legal_entity', 'logo'],
          });
        }}
      />
    </div>
  );
}

AnalyticsTab.propTypes = {
  customerStore: PropTypes.object,
};

export default memo(AnalyticsTab);
