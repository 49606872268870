import React, { memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useIntl } from 'react-intl';
import { Grid, Typography, Card } from '@upperhand/playmaker';

import UserAvatar from 'shared/components/_UserAvatar.jsx';

import { t } from 'shared/utils/LocaleUtils';

import WaitlistEntryRecord from 'shared/records/WaitlistEntry';

function WaitlistEntry({
  entry = new WaitlistEntryRecord(),
  menuOptions = [],
  onShowClient = () => null,
}) {
  const intl = useIntl();
  const client = entry.get('client');
  const event = entry.get('event');
  const formattedDate = entry.created_at.format('MMM D, YYYY • h:mma');

  return (
    <Grid item xs={12} className="waitlist-entry">
      <Card
        contentDirection="row"
        menuOptions={menuOptions}
        classes={{
          root: 'waitlist-entry__card',
          secondaryActions:
            menuOptions.length > 0
              ? 'waitlist-entry__card-actions'
              : 'waitlist-entry__card-actions-hidden',
        }}
      >
        <Grid container xs={12} alignItems="center" spacing={1}>
          <Grid container item alignItems="center" xs={12} md={4}>
            <Grid item>
              <UserAvatar user={client} />
            </Grid>
            <Grid item>
              <div
                className="waitlist-entry__client-name"
                onClick={() =>
                  onShowClient({ clientId: client.id, eventId: event.id })
                }
                role="presentation"
              >
                <Typography>{client.name()}</Typography>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Typography>
              {client.gender
                ? t(`.${client.gender}`, intl, __filenamespace)
                : '--'}
            </Typography>
          </Grid>
          <Grid item xs={11} md={3}>
            <Typography>
              {client.age()}
              &nbsp;&middot;&nbsp;
              {moment(client.date_of_birth).format(
                t('dates.full', intl, __filenamespace)
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography>{formattedDate}</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography className="waitlist-entry__state">
              {entry.state}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}

WaitlistEntry.propTypes = {
  entry: PropTypes.instanceOf(WaitlistEntryRecord),
  menuOptions: PropTypes.array,
  onShowClient: PropTypes.func,
};

export default memo(WaitlistEntry);
