import React from 'react';
import { Grid, Typography } from '@upperhand/playmaker';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import CancelIcon from '@mui/icons-material/Cancel';

import { t } from 'shared/utils/LocaleUtils';

import DetailsActions from '../actions';

// TODO: Add autocomplete from Playmaker once it is done.
const styles = {
  popUpIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

function AddStaff({ intl, selectableStaff, searchedStaffName, noTitle }) {
  return (
    <Grid item xs={12}>
      {!noTitle && (
        <Typography variant="caption">
          {t('.add_staff', intl, __filenamespace)}
        </Typography>
      )}
      <Autocomplete
        clearOnBlur
        noOptionsText=""
        inputValue={searchedStaffName || ''}
        sx={{ margin: '5px 0' }}
        options={selectableStaff}
        getOptionLabel={option => option.text}
        renderInput={params => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            placeholder={t('.placeholder', intl, __filenamespace)}
          />
        )}
        renderOption={(props, option) => (
          <MenuItem
            sx={{ justifyContent: 'space-between' }}
            key={option.value}
            onClick={() => DetailsActions.addStaff([{ value: option.value }])}
          >
            {option.text}
          </MenuItem>
        )}
        popupIcon={
          <div
            role="presentation"
            style={styles.popUpIcon}
            onClick={DetailsActions.cancelStaffAdd}
          >
            <CancelIcon />
          </div>
        }
        onInputChange={(_e, value) =>
          DetailsActions.updateStaffSearchText([value])
        }
      />
      <Typography color="secondary" variant="body2">
        {t('.hint', intl, __filenamespace)}
      </Typography>
    </Grid>
  );
}

export default AddStaff;
