import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Confirmation } from '@upperhand/playmaker';

import WarningIcon from 'shared/components/icons/WarningIcon.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { t } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils';

import { StaffUnavailabilityDataStore } from 'dataStores';

function RemoveUnavailabilityModal({
  isLoading = false,
  unavailabilityId = null,
  onCancel = () => {},
  onConfirm = () => {},
  staffUnavailabilityDataStore = {},
}) {
  const intl = useIntl();

  if (!unavailabilityId) return null;

  const { unavailabilities } = staffUnavailabilityDataStore;
  const unavailability = unavailabilities.get(unavailabilityId);
  const staff = unavailability.get('staff');

  return (
    <Confirmation
      classes={{
        root: 'remove-unavailability-modal',
      }}
      open={Boolean(unavailabilityId)}
      hasCheckbox={false}
      level="warning"
      confirmButtonLabel={t('.remove', intl, __filenamespace)}
      cancelButtonLabel={t('.cancel', intl, __filenamespace)}
      confirmationDisabled={isLoading}
      onCancel={onCancel}
      onConfirm={onConfirm}
      title={
        <Typography className="remove-unavailability-modal__title">
          <WarningIcon />
          <Typography variant="h6">
            {t('.remove_unavailability', intl, __filenamespace)}
          </Typography>
        </Typography>
      }
    >
      <Typography variant="body1">
        {t('.remove_unavailability_confirmation', intl, __filenamespace, {
          staffName: (
            <span className="remove-unavailability-modal__staff-name">
              {staff.name()}
            </span>
          ),
        })}
      </Typography>
    </Confirmation>
  );
}

RemoveUnavailabilityModal.propTypes = {
  isLoading: PropTypes.bool,
  unavailabilityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  staffUnavailabilityDataStore: PropTypes.object,
};

export default compose(
  memo,
  altContainer({
    stores: {
      staffUnavailabilityDataStore: StaffUnavailabilityDataStore,
    },
  })
)(RemoveUnavailabilityModal);
