const StaffUnavailabilityActions = alt.generateActions(
  'blockTime',
  'cancelBlockTime',
  'create',
  'createSuccess',
  'createError',
  'update',
  'updateSuccess',
  'updateError',
  'delete',
  'deleteSuccess',
  'deleteError',
  'list',
  'listSuccess',
  'listError',
  'handleChange',
  'toggleRemoveDialog',
  'updateDateRange'
);

export default StaffUnavailabilityActions;
