import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Grid, Card, Typography, Icon, Spinner } from '@upperhand/playmaker';
import { useNavigate } from 'react-router-dom';

import Header from 'containers/classes/classesList/components/Header.jsx';
import Classes from 'containers/classes/classesList/components/Classes.jsx';
import ClassCreateModal from 'containers/classes/classesCreation/ClassCreateModal.jsx';
import CancelScheduleModal from 'containers/eventScheduleCancellation/CancelScheduleModal.jsx';
import SessionCreationDrawer from 'containers/eventSessionCreation/SessionCreationDrawer.jsx';
import CancelSessionModal from 'containers/events/admin/schedule/cancelSessionModal/CancelSessionModal.jsx';
import ClassCancelModal from 'containers/classes/classesCancellation/ClassCancelModal.jsx';
import SessionSummaryDrawer from 'containers/events/admin/sessionSummaryDrawer/SessionSummaryDrawer.jsx';
import MessagingDrawer from 'shared/components/MessagingDrawer.jsx';
import EventMarketingWrapper from 'event_mgmt/display/components/_EventMarketingWrapper.jsx';
import ClassesTabs from 'containers/classes/classesList/components/Tabs/index.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { smallScreen } from 'shared/utils/DOMUtils';
import { t } from 'shared/utils/LocaleUtils';

import EventSessionCreationActions from 'containers/eventSessionCreation/Actions';
import ClassCreationActions from 'containers/classes/classesCreation/Actions';
import ClassesListActions from 'containers/classes/classesList/Actions';

import { EventDataStore } from 'dataStores';
import ClassesListStore from 'containers/classes/classesList/Store';
import ClassCreationStore from 'containers/classes/classesCreation/Store';
import MessagingDrawerStore from 'shared/stores/MessagingDrawerStore.jsx';
import EventMarketingStore from 'event_mgmt/display/stores/EventMarketingStore';

import './styles.scss';

function ClassesListPage({
  intl,
  classSlug,
  classesListStore: {
    classesIds,
    sessionsIds,
    selectedClass,
    loadingClasses,
    hasMoreClasses,
    selectedSessionId,
    selectedEventId,
    statusFilter,
    searchClassText,
    loadingEvent,
    defaultSelectedTab,
  },
  classCreationStore: { classIdToUpdate },
  eventDataStore: { events },
  messagingDrawerStore,
  eventMarketingStore,
}) {
  const isMobile = smallScreen();

  React.useEffect(() => {
    ClassesListActions.mounted.defer(classSlug);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();
  const sessionsArray = sessionsIds.toArray();
  const event = events.get(selectedClass?.id, null);

  const handleClassSelect = classItem => {
    ClassesListActions.selectClass({ classItem });
    navigate(classItem.admin_path);
  };

  const handleNextSession = () => {
    const index = sessionsArray.indexOf(selectedSessionId);
    const nextSessionId = sessionsArray[index + 1];

    if (nextSessionId)
      ClassesListActions.toggleSessionSummaryDrawer({
        sessionId: nextSessionId,
        eventId: selectedEventId,
      });
  };

  const handlePreviousSession = () => {
    const index = sessionsArray.indexOf(selectedSessionId);
    const previousSessionId = sessionsArray[index - 1];

    if (previousSessionId)
      ClassesListActions.toggleSessionSummaryDrawer({
        sessionId: previousSessionId,
        eventId: selectedEventId,
      });
  };

  return (
    <div className="classes-list">
      {isMobile ? (
        <Header
          title={t('.title', intl, __filenamespace)}
          onClassCreate={ClassCreationActions.toggleCreationModal}
        />
      ) : (
        <Header
          title={t('.title', intl, __filenamespace)}
          onClassCreate={ClassCreationActions.toggleCreationModal}
          classId={selectedClass?.get('id')}
          createTitle={t('.create_session_title', intl, __filenamespace)}
          onCreate={() =>
            EventSessionCreationActions.toggleDrawer({
              eventId: selectedClass.get('id'),
            })
          }
        />
      )}
      <Grid container spacing={1} className="classes-container">
        <Grid item md={3} xs={12} className="classes-column">
          <Classes
            searchClassText={searchClassText}
            selectedClass={selectedClass}
            loading={loadingClasses}
            classesIds={classesIds}
            hasMoreClasses={hasMoreClasses}
            statusFilter={statusFilter}
            onItemClick={handleClassSelect}
          />
        </Grid>
        {isMobile && (
          <Header
            hideCreateButton
            classId={selectedClass?.get('id')}
            createTitle={t('.create_session_title', intl, __filenamespace)}
            onCreate={() =>
              EventSessionCreationActions.toggleDrawer({
                eventId: selectedClass.get('id'),
              })
            }
          />
        )}
        <Grid item md={9} xs={12} className="classes-info-column">
          {loadingEvent && (
            <Grid item xs={12}>
              <div style={{ textAlign: 'center' }}>
                <Spinner type="indeterminate" />
              </div>
            </Grid>
          )}
          {!selectedClass && !loadingEvent && (
            <Card>
              <Grid container justify="center">
                <Grid item>
                  <Typography>
                    {t('.no_class_selected', intl, __filenamespace)}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          )}
          {event && !loadingEvent && (
            <div className="class-location">
              <Icon name="location" />
              <Typography className="class-location__name">
                {event?.locationName}
              </Typography>
            </div>
          )}
          {selectedClass && !loadingEvent && <ClassesTabs />}
        </Grid>
      </Grid>
      <CancelSessionModal />
      <CancelScheduleModal />
      <SessionSummaryDrawer
        allowCancel
        defaultSelectedTab={defaultSelectedTab}
        isOpen={!!selectedSessionId}
        sessionId={selectedSessionId}
        eventId={selectedEventId}
        defaultEventTitle={selectedClass?.title}
        onClose={() => ClassesListActions.toggleSessionSummaryDrawer({})}
        onNextSession={
          selectedEventId === selectedClass?.id ? handleNextSession : undefined
        }
        onPreviousSession={
          selectedEventId === selectedClass?.id
            ? handlePreviousSession
            : undefined
        }
      />
      <ClassCreateModal creatingMode={!classIdToUpdate} />
      <ClassCancelModal />
      <SessionCreationDrawer />
      <MessagingDrawer messagingDrawerStore={messagingDrawerStore} />
      {events && (
        <EventMarketingWrapper
          event={event}
          eventMarketingStore={eventMarketingStore}
        />
      )}
    </div>
  );
}

ClassesListPage.propTypes = {
  intl: PropTypes.object.isRequired,
  classesListStore: PropTypes.object.isRequired,
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      classesListStore: ClassesListStore,
      messagingDrawerStore: MessagingDrawerStore,
      classCreationStore: ClassCreationStore,
      eventDataStore: EventDataStore,
      eventMarketingStore: alt.createStore(
        EventMarketingStore,
        'EventMarketingStore'
      ),
    },
  })
)(ClassesListPage);
