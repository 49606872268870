import * as React from 'react';

import StaffIcon from 'shared/components/icons/Staff.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  AssignedStaff: {
    padding: '4px 0',
  },
  StaffIcon: {
    width: '20px',
    height: '20px',
    margin: '0 8px 0 0',
  },
  Count: {
    fontWeight: '600',
    paddingRight: 2,
  },
  StaffText: {
    paddingTop: '2px',
  },
};

function AssignedStaff({ staff, intl }) {
  let text = '';
  const noStaffAssignedText = t('.no_staff', intl, __filenamespace);
  const staffText = t('.staff', intl, __filenamespace);

  switch (staff.size) {
    case 0:
      text = noStaffAssignedText;
      break;
    case 1:
      text = staff.first() ? staff.first().name() : text;
      break;
    default:
      text = (
        <span>
          <span style={styles.Count}>{staff.size}</span>
          {staffText}
        </span>
      );
      break;
  }

  return (
    <FlexBoxCenter style={styles.AssignedStaff}>
      <StaffIcon style={styles.StaffIcon} color={uhColors.iconGrey} />
      <div style={styles.StaffText}>{text}</div>
    </FlexBoxCenter>
  );
}

export default AssignedStaff;
