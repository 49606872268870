import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Spinner, Confirmation } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import WaitlistItem from 'containers/clientProfile/components/Waitlist/WaitlistItem.jsx';

import { WaitlistDataStore } from 'dataStores';

import ClientWaitlistActions from './Actions';
import ClientWaitlistStore from './Store';

import './styles.scss';

function WaitlistItems({
  clientId = null,
  eventId = null,
  hideInfo = false,
  clientWaitlistStore = {},
  waitlistDataStore = {},
}) {
  useEffect(() => {
    ClientWaitlistActions.mounted({ clientId, eventId });
  }, [clientId, eventId]);

  const intl = useIntl();
  const [entryToRemove, setEntryToRemove] = useState(null);

  if (!clientId) return null;

  const { isLoading, entryIds } = clientWaitlistStore;
  const { waitlistEntries } = waitlistDataStore;
  const hasWaitlistItems = entryIds.size > 0;

  const handleRemoveConfirmation = entry => {
    setEntryToRemove(entry);
  };

  return (
    <div className="client-waitlist">
      {isLoading && (
        <div className="client-waitlist__spinner">
          <Spinner />
        </div>
      )}

      {!isLoading && !hideInfo && hasWaitlistItems && (
        <Typography variant="body1">
          {t('.joined', intl, __filenamespace)}
        </Typography>
      )}
      {!isLoading && !hasWaitlistItems && (
        <Typography variant="body1">
          {t('.no_items', intl, __filenamespace)}
        </Typography>
      )}
      {!isLoading && hasWaitlistItems && (
        <>
          {entryIds.map(entryId => (
            <WaitlistItem
              key={entryId}
              entry={waitlistEntries.get(entryId)}
              hideInfo={hideInfo}
              onRemove={handleRemoveConfirmation}
            />
          ))}
        </>
      )}
      <Confirmation
        open={!!entryToRemove}
        title={t('.confirmation_title', intl, __filenamespace)}
        hasCheckbox={false}
        confirmButtonLabel={t('.yes', intl, __filenamespace)}
        cancelButtonLabel={t('.no', intl, __filenamespace)}
        onCancel={() => setEntryToRemove(null)}
        onConfirm={() => {
          ClientWaitlistActions.remove(entryToRemove);
          setEntryToRemove(null);
        }}
      >
        <Typography variant="body1">
          {t('.confirmation_text', intl, __filenamespace, {
            clientName: entryToRemove?.client.name(),
          })}
        </Typography>
      </Confirmation>
    </div>
  );
}

WaitlistItems.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hideInfo: PropTypes.bool,
  clientWaitlistStore: PropTypes.object,
  waitlistDataStore: PropTypes.object,
};

export default compose(
  memo,
  altContainer({
    stores: {
      clientWaitlistStore: ClientWaitlistStore,
      waitlistDataStore: WaitlistDataStore,
    },
  })
)(WaitlistItems);
