import * as React from 'react';
import clsx from 'clsx';
import { Set, List } from 'immutable';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Grid,
  TextField,
  Typography,
  Icon,
} from '@upperhand/playmaker';

import QRDialog from 'shared/components/_QRDialog.jsx';
import AvatarPreview from 'containers/clientProfile/components/AvatarPreview.jsx';
import ImageUpload from 'components/ImageUpload';
import MailTo from 'shared/components/MailTo.jsx';
import Gift from 'shared/components/icons/Gift.jsx';
import ManagingUserIcon from 'shared/components/icons/ManagingUser.jsx';
import UnsignedWaiverIcon from 'shared/components/icons/UnsignedWaiver.jsx';
import MembershipAgreementIndicator from 'shared/components/icons/MembershipAgreemenIndicator.jsx';
import MembershipAgreementView from 'shared/components/icons/MembershipAgreementView.jsx';
import UnsignedWaiverAdmin from 'shared/components/icons/UnsignedWaiverAdmin.jsx';
import SignedWaiverIcon from 'shared/components/icons/SignedWaiverIcon.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { customerScopedRoute } from 'shared/utils/RouteUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import { isDBatTheme } from 'shared/utils/ThemeUtils';

import { ClientDataStore } from 'dataStores';

import CurrentContextActions from 'shared/actions/CurrentContextActions.jsx';
import MembershipAgreementFormActions from 'shared/actions/MembershipAgreementFormActions';
import NewMessageDrawerActions from 'containers/events/admin/clients/components/NewMessageDrawer/Actions';
import WaiverAgreementDrawerActions from 'containers/waiver/actions';

import useEditMode from '../useEditMode';

function Name({
  clientId,
  onImageSelect,
  onClientSave,
  clientDataStore: { clients },
  intl,
  isMobile,
  onPrimaryAccountClick,
  membershipSubscriptions,
  subscriptionId,
}) {
  const isStaff = currentUser().isStaff();
  const isClient = currentUser().isClient();
  const client = clients.get(clientId);
  const [edit, toggleEdit] = useEditMode(clientId);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');

  if (!client) {
    return null;
  }

  const showWaiver = !client.has_accepted_terms && client.login_enabled;
  const canViewWaiver =
    (isStaff || isClient) && client.has_accepted_terms && client.login_enabled;
  const membershipSubscription = membershipSubscriptions?.get(subscriptionId);
  const membershipAgreementContent = currentCustomer().membership_agreement;
  const hasUnSignedAgreement =
    membershipSubscription &&
    client.hasUnSignedAgreement(membershipSubscription);
  const hasDownloadEnabled =
    membershipAgreementContent && !hasUnSignedAgreement;
  const signedDate = client.getAgreementSignedDate();
  const showViewAgreementBtn =
    membershipAgreementContent &&
    membershipSubscription &&
    !hasUnSignedAgreement;
  const showNoAgreementBtn =
    membershipAgreementContent &&
    membershipSubscription &&
    hasUnSignedAgreement;
  const payLaterDisabled = client.get('disable_pay_later', false) && isStaff;

  return (
    <Grid container spacing={1} direction="row" className="name">
      <Grid item xl className="name__avatar-container">
        <ImageUpload
          circularCrop
          cropAspect={1}
          uploadContainerWidth="100%"
          onChange={image => onImageSelect(client, image)}
          image={client.profile_image}
          preview={<AvatarPreview user={client} />}
        />
      </Grid>

      {edit ? (
        <Grid container item spacing={1} xl className="name__container">
          <Grid item xs={12}>
            <TextField
              label={t('.first_name', intl, __filenamespace)}
              value={firstName}
              fullWidth
              onChange={(_e, value) => setFirstName(value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={t('.last_name', intl, __filenamespace)}
              value={lastName}
              fullWidth
              onChange={(_e, value) => setLastName(value)}
            />
          </Grid>
          {isStaff && (
            <Grid item xs={12}>
              <TextField
                label={t('.email', intl, __filenamespace)}
                value={email}
                fullWidth
                onChange={(_e, value) => setEmail(value)}
              />
            </Grid>
          )}

          <Grid item className="name__form-actions">
            <Button
              onClick={() => {
                onClientSave(client, {
                  first_name: firstName,
                  last_name: lastName,
                  email,
                });
                toggleEdit();
              }}
            >
              <FormattedMessage
                id={messageId('actions.save', __filenamespace)}
              />
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          item
          alignContent="flex-start"
          xl
          className="name__container"
        >
          <Grid item xs={12} className="name__email">
            <Typography variant="h3">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ margin: '0 2px' }}>{client.name()} </p>
                {client.isTodayBirthDay() && (
                  <Gift color="#4AD669" style={{ margin: '0 5px' }} />
                )}
              </div>
            </Typography>
            {client.email && (
              <Typography component="span" variant="body1">
                <MailTo email={client.email} />
              </Typography>
            )}
          </Grid>

          {client.managed_by_id && isStaff ? (
            <Grid item container spacing={1} xs={12} alignItems="center">
              <Grid item>
                <ManagingUserIcon style={{ height: 12, width: 12 }} />
              </Grid>

              <Grid item>
                <Link
                  to={customerScopedRoute(
                    `clients/${client.managing_customer_user_id}`
                  )}
                  onClick={e => {
                    if (!isStaff || (isStaff && isMobile)) {
                      e.preventDefault();
                      onPrimaryAccountClick({
                        clientId: client.managing_customer_user_id,
                        isInitialPoint: false,
                      });
                    }
                  }}
                >
                  <Typography variant="body1">
                    {client.managing_customer_user_name}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          ) : (
            showWaiver && (
              <Grid item container xs={12}>
                {currentUser().isStaff() ? (
                  <Grid item xs={12}>
                    <Box
                      className="waiver-box"
                      onClick={CurrentContextActions.openDialog}
                    >
                      <UnsignedWaiverAdmin className="waiver-indicator-name" />
                      <Typography variant="body1">
                        <FormattedMessage
                          id={messageId('.no_waiver_on_file', __filenamespace)}
                        />
                      </Typography>
                    </Box>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Grid item>
                      <UnsignedWaiverIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1">
                        <FormattedMessage
                          id={messageId('.no_waiver_on_file', __filenamespace)}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )
          )}
          {canViewWaiver && (
            <Grid item container xs={12}>
              <Box
                className={clsx('waiver-box', 'waiver-box__view')}
                onClick={() => WaiverAgreementDrawerActions.openDrawer(client)}
              >
                <SignedWaiverIcon />
                <Typography variant="body1">
                  <FormattedMessage
                    id={messageId('.view_waiver', __filenamespace)}
                  />
                </Typography>
              </Box>
            </Grid>
          )}
          {showViewAgreementBtn && (
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <Box
                  className="view-membership-agreement-box"
                  onClick={() =>
                    MembershipAgreementFormActions.openAgreementDrawer({
                      clientIds: Set([membershipSubscription.client_id]),
                      clientName: client.name(),
                      membershipId: membershipSubscription.membership_id,
                      hasSignedAgreement: hasDownloadEnabled,
                      signedAt: signedDate,
                    })
                  }
                >
                  <MembershipAgreementView className="agreement-indicator-name" />
                  <Typography variant="body1">
                    <FormattedMessage
                      id={messageId(
                        '.view_membership_agreement',
                        __filenamespace
                      )}
                    />
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
          {showNoAgreementBtn && (
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <Box
                  className="view-membership-agreement-box"
                  onClick={() =>
                    MembershipAgreementFormActions.openAgreementDrawer({
                      clientIds: Set([membershipSubscription.client_id]),
                      clientName: client.name(),
                      membershipId: membershipSubscription.membership_id,
                      hasSignedAgreement: hasDownloadEnabled,
                      signedAt: signedDate,
                    })
                  }
                >
                  <MembershipAgreementIndicator className="agreement-indicator-name" />
                  <Typography variant="body1">
                    <FormattedMessage
                      id={messageId(
                        '.no_membership_agreement',
                        __filenamespace
                      )}
                    />
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
          {payLaterDisabled && (
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <Typography variant="body1" className="pay-later-disabled">
                  <Icon name="cart" />
                  <FormattedMessage
                    id={messageId('.pay_later_disabled', __filenamespace)}
                  />
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      <div className="name__edit-button name__no-top">
        {currentUser().isStaff() && isDBatTheme() && (
          <QRDialog skipCheck icon="qr" usersList={List([client])} />
        )}
        {client?.hasEmailAndLoginEnabled() && (
          <Button
            size="1x"
            icon="chatBubble"
            rounded
            type="tertiary"
            onClick={() => NewMessageDrawerActions.toggleDrawer({ clientId })}
          />
        )}
        <Button
          size="1x"
          icon={edit ? 'close' : 'edit'}
          rounded
          type="tertiary"
          onClick={() => {
            setFirstName(client.first_name);
            setLastName(client.last_name);
            setEmail(client.email);
            toggleEdit();
          }}
        />
      </div>
    </Grid>
  );
}

export default altContainer({
  stores: {
    clientDataStore: ClientDataStore,
  },
})(Name);
