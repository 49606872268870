import { Map } from 'immutable';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import WaitlistActions from 'sources/WaitlistActions';

class WaitlistDataStore extends UpperHandStore {
  constructor() {
    super();

    this.waitlistEntries = Map();

    this.bindListeners({
      listSuccess: WaitlistActions.listSuccess,
      listError: WaitlistActions.listError,
      createWaitlistEntrySuccess: WaitlistActions.createWaitlistEntrySuccess,
      createWaitlistEntryError: WaitlistActions.createWaitlistEntry,
    });
  }

  listSuccess({ event_waitlist_entries: waitlistEntries }) {
    const newEntriesMap = Map(waitlistEntries.map(entry => [entry.id, entry]));

    this.waitlistEntries = this.waitlistEntries.merge(newEntriesMap);
  }

  listError(...args) {
    this.notifyError('error fetching waitlist entries', args);
  }

  createWaitlistEntrySuccess(entry) {
    this.waitlistEntries = this.waitlistEntries.set(entry.id, entry);
  }

  createWaitlistEntryError(...args) {
    this.notifyError('error creating waitlist entry', args);
  }
}

export default alt.createStore(WaitlistDataStore, 'WaitlistDataStore');
