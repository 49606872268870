/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Close from '@mui/icons-material/Close';
import Block from '@mui/icons-material/Block';

import ContextMenu from 'calendar/components/views/shared/event_card/_ContextMenu.jsx';
import EventTimeMeta from 'calendar/components/views/shared/event_card/_EventTimeMeta.jsx';
import EventTitle from 'calendar/components/views/shared/event_card/_EventTitle.jsx';
import StatusBar from 'calendar/components/views/shared/event_card/_StatusBar.jsx';
import AssignedStaff from 'calendar/components/views/shared/event_card/_AssignedStaff.jsx';

import { formattedHourString } from 'calendar/utils/CalendarUtils.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { getVar } from 'shared/utils/StyleUtils';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

import CalendarActions from 'calendar/actions/CalendarActions.jsx';
import StaffUnavailabilityActions from 'calendar/actions/StaffUnavailabilityActions';

import './styles.scss';

const styles = {
  EventCard: (interacting, isEventDraft) => ({
    zIndex: interacting ? 3 : 0,
    position: 'absolute',
    right: '0',
    backgroundColor: interacting ? 'white' : 'rgba(255, 255, 255, 0.9)',
    boxShadow: interacting
      ? 'rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px'
      : 'none',
    overflow: 'hidden',
    transition: 'width 200ms ease-out',
    transitionProperty:
      'background-color, width, min-height, max-height, box-shadow',
    cursor: 'default',
    pointerEvents: 'auto',
    color: isEventDraft ? uhColors.fadedHintActive : uhColors.primaryMain,
    border: `1px ${isEventDraft ? 'dashed' : 'solid'} ${uhColors.tableGrey}`,
  }),
  StatusWrapper: {
    position: 'relative',
    transition: 'backgroundColor 240ms ease-out',
  },
  ContextMenuButton: interacting => ({
    zIndex: '1',
    position: 'absolute',
    top: '0',
    right: '0',
    opacity: interacting ? '1' : '0',
    transform: `translate3d(${interacting ? '0' : '48px'}, 0, 0)`,
    transition: 'all 200ms ease-out 180ms',
    transitionProperty: 'transform, opacity',
  }),
  Content: {
    position: 'relative',
    fontSize: '12px',
  },
  EventInfo: interacting => ({
    position: 'relative',
    padding: `8px ${interacting ? '36px' : '16px'} 8px 16px`,
  }),
  ColorBar: event => {
    const color = event?.isTeamEvent()
      ? event?.team_type?.color
      : event?.event_type?.color;
    return {
      position: 'absolute',
      top: '8px',
      left: '4px',
      width: '3px',
      height: 'calc(100% - 11px)',
      borderRadius: '1.5px',
      backgroundColor: color || uhColors.activeBlue,
      transition: 'all 300ms ease-in-out',
      transitionProperty: 'height',
    };
  },
  DayTime: {
    fontWeight: '600',
  },
  StatusColorEvent: (statusColor, hovering) => ({
    color: uhColors.white,
    fontSize: 'bold',
    fontWeight: 600,
    padding: '0 8px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: statusColor,
    height: hovering ? '22px' : '3px',
    overflow: hovering ? 'visible' : 'hidden',
  }),
  unavailabilityText: {
    display: 'flex',
    alignItems: 'center',
    gap: '7px',
    textTransform: 'capitalize',
  },
};

const stylesFromEventTime = (eventTime, groupIndex, hovering) => {
  const dateRange = eventTime.dateRange();
  const cellHeight = 192;
  const shrinkBasis = 15;
  const minWidthPercent = 50;

  const hoursFromMidnight =
    dateRange.start.hours() + dateRange.start.minutes() / 60;
  const widthOffset = shrinkBasis * groupIndex;
  const widthPercent = 100 - widthOffset;
  const height = dateRange.diff('hours', true) * cellHeight;

  return {
    top: `${hoursFromMidnight * cellHeight}px`,
    left: `${Math.min(widthOffset, minWidthPercent)}%`,
    width: !hovering ? `${widthPercent}%` : '280px',
    minWidth: !hovering ? `${widthPercent}%` : '100%',
    minHeight: `${Math.max(92, height)}px`,
    maxHeight: !hovering
      ? `${Math.max(48, height)}px`
      : `${Math.max(height, cellHeight * 4)}px`,
  };
};

const hexToRGBA = (color, alpha = 1) => {
  const hex = color.match(/^#/) ? color : getVar(color);

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

function EventCard({
  intl,
  eventTime,
  event,
  clients,
  staff,
  resources,
  memberships,
  sessions,
  groupIndex,
}) {
  const isInstructor = currentUser().isInstructor();
  const isStaff = currentUser().isStaff();
  const isCoach = currentUser().isCoach();
  const hasNotesPermission = isStaff || isInstructor;
  const scheduleType = event.isOpenBooking();
  let delayingHovering = true;
  const eventCardElement = React.useRef(null);
  const [hovering, setHovering] = React.useState(false);
  const [contextMenuOpen, setContextMenuOpen] = React.useState(false);
  const [hoverLock, setHoverLock] = React.useState(false);
  const domCalendarContainer = document.getElementById('StandardCalendar');

  React.useEffect(() => {
    if (hovering) {
      const scrollWidthBefore = domCalendarContainer.scrollWidth;
      setTimeout(() => {
        const scrollWidthAfter = domCalendarContainer.scrollWidth;
        if (scrollWidthAfter !== scrollWidthBefore) {
          domCalendarContainer.scrollLeft = scrollWidthAfter;
        }
      }, 200); // transition delay
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hovering]);

  const handleMouseEnter = e => {
    e.preventDefault();

    delayingHovering = true;
    setTimeout(() => {
      if (delayingHovering) {
        setHovering(true);
        delayingHovering = false;
      }
    }, 250);
  };

  const handleMouseLeave = e => {
    e.preventDefault();

    delayingHovering = false;
    if (!hoverLock) {
      setHovering(false);
    }
  };

  const handleContextMenuClick = e => {
    e.preventDefault();

    setContextMenuOpen(true);
  };

  const handleRequestClose = () => {
    setContextMenuOpen(false);
  };

  const handleHoverLock = e => {
    setHoverLock(true);
    setHovering(true);
    e.stopPropagation();
  };

  const handleHoverUnlock = e => {
    setHoverLock(false);
    setHovering(false);

    // When the note popover is closed without using the 'X' there is no event
    // object returned, so stopPropogation will not be defined.
    if (typeof e.stopPropagation === 'function') {
      e.stopPropagation();
    }
  };

  const toggleHovering = e => {
    e.preventDefault();
    setHovering(hoverLock || !hovering);
  };

  const handleClickOutside = e => {
    const contains = !eventCardElement.current.contains(e.target);
    if (contains) {
      return eventCardElement.current ? setHovering(false) : toggleHovering(e);
    }
    return null;
  };

  const handleAttendeesClick = e => {
    e.preventDefault();
    if (isInstructor) return;
    const { event_id: eventId, session_id: sessionId } = eventTime;
    CalendarActions.viewSessionClicked({
      eventId,
      sessionId,
      selectedTab: 1,
    });
  };

  React.useEffect(() => {
    window.addEventListener('touchend', handleClickOutside);

    return () => {
      window.removeEventListener('touchend', handleClickOutside);
    };
  });

  const [isNoShow, setIsNoShow] = React.useState(false);

  React.useEffect(() => {
    setIsNoShow(eventTime.isAllNoShow(clients));
  }, [eventTime, clients]);

  const interacting = hovering || contextMenuOpen;
  const dateRange = eventTime.dateRange();
  const allCheckedIn = eventTime.isAllCheckedIn(clients);
  const rootStyles = stylesFromEventTime(eventTime, groupIndex, interacting);

  const isPaid = eventTime.isAllPaid(clients);
  const statusColor = isPaid ? uhColors.green : uhColors.darkRed;
  const paidText = (
    <FormattedMessage
      id={messageId(
        isPaid ? '.all_paid' : '.n_unpaid_attendees',
        __filenamespace
      )}
      values={{ n: eventTime.unpaidClients().size }}
    />
  );

  const isEventCancelled = eventTime.isEventCancelled();
  const isEventDraft = eventTime.isEventDraft();
  const isEventStandard = eventTime.isEventStandard();
  const backgroundColor =
    allCheckedIn && !hovering && !contextMenuOpen
      ? hexToRGBA(statusColor, 0.1)
      : uhColors.white;

  const statusStyles = {
    backgroundColor: isEventCancelled
      ? uhColors.moderateWarning
      : backgroundColor,
    minHeight: rootStyles.minHeight,
  };

  const borderTopStyle =
    isEventCancelled || (isEventStandard && allCheckedIn)
      ? { borderTop: 'none' }
      : {};
  statusStyles.backgroundColor =
    isNoShow && !allCheckedIn ? uhColors.moderateWarning : uhColors.white;

  return (
    <div
      ref={eventCardElement}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={toggleHovering}
      style={{
        ...styles.EventCard(interacting, isEventDraft),
        ...rootStyles,
        ...borderTopStyle,
      }}
      className="event-card"
    >
      {eventTime?.isStaffUnavailability() && (
        <div style={styles.Content}>
          {(isStaff || isCoach) && (
            <IconButton
              onClick={() =>
                StaffUnavailabilityActions.toggleRemoveDialog(
                  eventTime.unavailabilityId
                )
              }
              style={styles.ContextMenuButton(interacting)}
            >
              <Close />
            </IconButton>
          )}
          <div style={styles.EventInfo(interacting)}>
            <div style={styles.ColorBar(event)} />
            <div style={styles.DayTime}>
              {`${formattedHourString(dateRange.start, {
                truncateMeridiem: false,
              })} - ${formattedHourString(dateRange.end, {
                truncateMeridiem: false,
              })}`}
            </div>
            <FormattedMessage
              id={messageId('.staff_unavailable', __filenamespace)}
            />
            <AssignedStaff intl={intl} staff={staff} />
            <div style={styles.unavailabilityText}>
              <Block />
              <p>{eventTime.unavailabilityText}</p>
            </div>
          </div>
        </div>
      )}

      {eventTime?.isEvent() && (
        <div
          style={{
            ...styles.StatusWrapper,
            ...statusStyles,
          }}
        >
          {isNoShow && !allCheckedIn && scheduleType && clients.size === 1 && (
            <div style={styles.StatusColorEvent(statusColor, hovering)}>
              {interacting ? paidText : ''}
            </div>
          )}
          <StatusBar
            eventTime={eventTime}
            clients={clients}
            statusColor={statusColor}
            interacting={interacting}
            isEventCancelled={isEventCancelled}
          />
          <div style={styles.Content}>
            {(isStaff || isCoach) && (
              <IconButton
                onClick={handleContextMenuClick}
                style={styles.ContextMenuButton(interacting)}
              >
                <MoreVertIcon />
              </IconButton>
            )}
            <div style={styles.EventInfo(interacting)}>
              <div style={styles.ColorBar(event)} />
              <div style={styles.DayTime}>
                {`${formattedHourString(dateRange.start, {
                  truncateMeridiem: false,
                })} - ${formattedHourString(dateRange.end, {
                  truncateMeridiem: false,
                })}`}
              </div>
              <EventTitle
                eventTime={eventTime}
                isEventCancelled={isEventCancelled}
                isEventDraft={isEventDraft}
                clients={clients}
                memberships={memberships}
                hovering={interacting}
                resources={resources}
                event={event}
                onClickViewNotes={handleHoverLock}
                onCloseNotes={handleHoverUnlock}
                scheduleType={scheduleType}
                handleAttendeesClick={handleAttendeesClick}
                hasNotesPermission={hasNotesPermission}
                readOnly={isInstructor}
              />
            </div>
            {interacting && (
              <EventTimeMeta
                event={event}
                eventTime={eventTime}
                clients={clients}
                staff={staff}
                resources={resources}
                memberships={memberships}
                sessions={sessions}
                hovering={interacting}
                onClickViewNotes={handleHoverLock}
                onCloseNotes={handleHoverUnlock}
                scheduleType={scheduleType}
                handleAttendeesClick={handleAttendeesClick}
                hasNotesPermission={hasNotesPermission}
                readOnly={isInstructor}
              />
            )}
          </div>
          <ContextMenu
            eventTime={eventTime}
            event={event}
            isEventStandard={isEventStandard}
            open={contextMenuOpen}
            anchorEl={eventCardElement.current}
            handleRequestClose={handleRequestClose}
          />
        </div>
      )}
    </div>
  );
}

export default injectIntl(EventCard);
