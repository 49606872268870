import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Drawer } from '@upperhand/playmaker';

import Footer from 'containers/events/admin/sessionSummaryDrawer/components/Footer.jsx';
import Content from 'containers/events/admin/sessionSummaryDrawer/components/Content.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { t } from 'shared/utils/LocaleUtils.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';

import { RegistrationDataStore, EventDataStore } from 'dataStores';

import CustomerEvent from 'event_mgmt/shared/records/CustomerEvent.jsx';

import SessionSummaryDrawerStore from 'containers/events/admin/sessionSummaryDrawer/Store.js';
import NewMessageDrawerStore from 'containers/events/admin/clients/components/NewMessageDrawer/Store';
import CheckInStore from 'containers/events/admin/sessionSummaryDrawer/components/checkIn/store.js';
import DetailsStore from 'containers/events/admin/sessionSummaryDrawer/components/details/store';
import NotesStore from 'containers/events/admin/sessionSummaryDrawer/components/notes/store';
import EditSessionStore from 'containers/events/admin/sessionSummaryDrawer/components/editSession/store';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import WaitlistSessionDrawerTabStore from 'containers/events/admin/sessionSummaryDrawer/components/waitlist/store';

import NewMessageDrawerActions from 'containers/events/admin/clients/components/NewMessageDrawer/Actions';
import SessionSummaryDrawerActions from 'containers/events/admin/sessionSummaryDrawer/Actions';

import './styles.scss';

// This style rule enforces SSD to appear below the POS
// We can't make POS above directly because it uses PM components
// Default zIndex is set to 1300 for PM components
// Increasing zIndex for POS will cause popovers (like dropdown) to be under the drawer
// So instead of changing all PM components zIndexes within a POS we are lowering zIndex for SSD
// !important is needed because drawers have direct inline styles added by the MUI

const useSSDStyles = makeStyles({
  wrapper: {
    zIndex: '1299 !important',
  },
  root: {
    backgroundColor: 'var(--color-white) !important',
    height: 'calc(100% - var(--safe-area-inset-top)) !important',
  },
});

const noUseSSDStyles = makeStyles({
  root: {
    backgroundColor: 'var(--color-white) !important',
    height: 'calc(100% - var(--safe-area-inset-top)) !important',
  },
});

const minimizedStyles = makeStyles({
  root: {
    backgroundColor: 'var(--color-white) !important',
    height: 'auto',
    minHeight: '135px',
    top: '147px',
    right: '5px',
    width: '315px',
  },
  header: {
    padding: '10px 10px 10px 15px',
  },
  content: {
    padding: '0 15px 10px',
  },
});

function SessionSummaryDrawer({
  eventId = null,
  intl,
  isOpen,
  useSSD,
  allowMinimization,
  onClose,
  sessionId,
  defaultEventTitle,
  defaultSelectedTab,
  detailsStore,
  notesStore,
  editSessionStore,
  registrationDataStore,
  checkInStore,
  newMessageDrawerStore,
  onNextSession,
  onPreviousSession,
  sessionSummaryDrawerStore,
  eventStore,
  eventDataStore,
  waitlistSessionDrawerTabStore,
}) {
  const {
    selectedTab,
    isEditMode,
    isLoading,
    isMinimized,
    sessionId: selectedSessionId,
  } = sessionSummaryDrawerStore;
  const { customerEvent } = eventStore;
  const { events } = eventDataStore;

  const isClient = currentUser().isClient();
  const isCoach = currentUser().isCoach();

  let event = new CustomerEvent();

  if (customerEvent.id === eventId) {
    event = customerEvent;
  } else {
    event = events.get(
      eventId,
      new CustomerEvent({ title: defaultEventTitle })
    );
  }

  React.useEffect(() => {
    if (sessionId && selectedSessionId !== sessionId) {
      SessionSummaryDrawerActions.mounted.defer({ sessionId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    SessionSummaryDrawerActions.selectTab(defaultSelectedTab);
  }, [defaultSelectedTab]);

  const {
    changed: detailsChanged,
    totalRegistrations,
    registrationIds,
  } = detailsStore;
  const { dirty: noteEdited, editModes, notes } = notesStore;
  const { changed: editDateTimeChanged } = editSessionStore;
  const { registrations } = registrationDataStore;
  const {
    dirty: checkInDirty,
    filteredClientsToCheckIn,
    filteredClientsToCheckOut,
  } = checkInStore;
  const { open: newMessageDrawerOpened } = newMessageDrawerStore;
  const { selectedEntryIds } = waitlistSessionDrawerTabStore;

  const noteEditMode =
    editModes.get('admin_note', false) || editModes.get('client_note', false);
  const isFooter =
    (detailsChanged && selectedTab === 'details') ||
    isEditMode ||
    (selectedTab === 'check-in' && checkInDirty) ||
    (selectedTab === 'notes' && noteEditMode) ||
    (selectedTab === 'wait-list' && selectedEntryIds?.size > 0);
  const showFooter = !isLoading && isFooter && !isMinimized;
  const clientIds = registrationIds
    .toList()
    .map(id => registrations.get(id)?.client_id);
  const hasAdminNote =
    editModes.get('admin_note', false) && notes.get('admin_note', '') !== '';
  const hasClientNote =
    editModes.get('client_note', false) && notes.get('client_note', '') !== '';
  const isActiveSubmit = isEditMode
    ? editDateTimeChanged || detailsChanged
    : noteEdited && (hasAdminNote || hasClientNote);

  const classes = useSSDStyles();
  const classesNoSSD = noUseSSDStyles();
  const classesMinimized = minimizedStyles();
  const drawerVariant = isMinimized ? 'persistent' : 'temporary';
  const isTeamEvent = event.isTeamEvent();
  const isCoachTeam = isTeamEvent && isCoach;

  const getClasses = () => {
    if (isMinimized) {
      return classesMinimized;
    }

    return useSSD ? classes : classesNoSSD;
  };

  const handleClose = () => {
    onClose();

    if (allowMinimization && isMinimized) {
      SessionSummaryDrawerActions.toggleMinimized();
    }

    if (isEditMode) {
      SessionSummaryDrawerActions.setEditMode(false);
    }
  };

  if (newMessageDrawerOpened) {
    return null;
  }

  return (
    <Drawer
      variant={drawerVariant}
      open={isOpen}
      classes={getClasses()}
      onClose={handleClose}
      title={t('.title', intl, __filenamespace)}
      actions={
        isLoading || isClient || (isCoach && !isCoachTeam)
          ? []
          : [
              {
                icon: 'message',
                visible: !isMinimized && !isCoachTeam,
                onClick: () =>
                  NewMessageDrawerActions.toggleDrawer({ sessionId }),
              },
              {
                icon: 'edit',
                visible: !isEditMode,
                onClick: () => {
                  SessionSummaryDrawerActions.toggleEditMenu();
                  SessionSummaryDrawerActions.setEditMode(true);
                },
              },
              {
                icon: 'minimize',
                visible: isEditMode && Boolean(allowMinimization),
                onClick: SessionSummaryDrawerActions.toggleMinimized,
              },
            ].filter(v => v.visible)
      }
      content={
        <Content
          minimized={isMinimized}
          total={totalRegistrations}
          eventId={eventId}
          intl={intl}
          isEditMode={isEditMode}
          isLoading={isLoading}
          selectedTab={selectedTab}
          sessionId={sessionId}
          onNextSession={onNextSession}
          onPreviousSession={onPreviousSession}
          event={event}
        />
      }
      footer={
        showFooter && (
          <Footer
            isEditMode={isEditMode}
            editModes={editModes}
            checkInMode={selectedTab === 'check-in' && checkInDirty}
            clientsToCheckInOutCount={clientIds.count(
              v =>
                filteredClientsToCheckIn.includes(v) ||
                filteredClientsToCheckOut.includes(v)
            )}
            detailsChanged={detailsChanged}
            isActiveSubmit={isActiveSubmit}
            selectedTab={selectedTab}
            waitlistIds={selectedEntryIds}
          />
        )
      }
    />
  );
}

SessionSummaryDrawer.propTypes = {
  eventId: PropTypes.number,
  defaultEventTitle: PropTypes.string,
  intl: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  useSSD: PropTypes.bool,
  allowMinimization: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  sessionId: PropTypes.string,
  sessionSummaryDrawerStore: PropTypes.object,
  defaultSelectedTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  checkInStore: PropTypes.object,
  registrationDataStore: PropTypes.object,
  notesStore: PropTypes.object,
  rosterTransferDrawerStore: PropTypes.object,
  newMessageDrawerStore: PropTypes.object,
  eventStore: PropTypes.object,
  eventDataStore: PropTypes.object,
  waitlistSessionDrawerTabStore: PropTypes.object,
  detailsStore: PropTypes.object,
  editSessionStore: PropTypes.object,
};

export default compose(
  altContainer({
    stores: {
      detailsStore: DetailsStore,
      editSessionStore: EditSessionStore,
      sessionSummaryDrawerStore: SessionSummaryDrawerStore,
      checkInStore: CheckInStore,
      registrationDataStore: RegistrationDataStore,
      notesStore: NotesStore,
      newMessageDrawerStore: NewMessageDrawerStore,
      eventStore: EventStore,
      eventDataStore: EventDataStore,
      waitlistSessionDrawerTabStore: WaitlistSessionDrawerTabStore,
    },
  }),
  injectIntl
)(SessionSummaryDrawer);
