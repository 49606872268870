import moment from 'moment-timezone';
import { Record } from 'immutable';
import CustomerEvent from 'event_mgmt/shared/records/CustomerEvent.jsx';
import Session from 'shared/records/Session';
import Client from 'shared/records/Client.jsx';
import EventType from 'event_mgmt/shared/records/EventType.jsx';

class WaitlistEntry extends Record({
  // Old waitlist entry fields
  customer_user_id: null,
  invited: null,

  // Fields for new waitlist entries
  id: null,
  state: null,
  invite_sent_at: null,
  event: new CustomerEvent(),
  session: new Session(),
  client: new Client(),
  created_at: null,
  updated_at: null,
}) {
  constructor(object = {}) {
    super({
      ...object,
      event: new CustomerEvent({
        ...object.event,
        event_type: object.event?.event_type
          ? new EventType(object.event.event_type)
          : new EventType(),
      }),
      session: new Session(object.session),
      client: new Client(object.client),
      invite_sent_at: object?.invite_sent_at
        ? moment(object.invite_sent_at)
        : null,
      created_at: object?.created_at ? moment(object.created_at) : null,
      updated_at: object?.updated_at ? moment(object.updated_at) : null,
    });
  }

  get isJoined() {
    return this.state === 'joined';
  }

  get isRemoved() {
    return this.state === 'removed';
  }

  get isInvited() {
    return this.state === 'invited';
  }

  get isExpired() {
    return this.state === 'expired';
  }

  get isPending() {
    return this.state === 'pending';
  }

  get canAddToEvent() {
    return this.isInvited || this.isPending;
  }

  get canRemove() {
    return !this.isJoined && !this.isRemoved;
  }
}

export default WaitlistEntry;
