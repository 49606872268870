import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Grid } from '@upperhand/playmaker';
import { Box } from '@mui/material';

import SessionSummaryDrawer from 'containers/events/admin/sessionSummaryDrawer/SessionSummaryDrawer.jsx';

import AltContainer from 'alt-container';

import { EventDataStore, ResourceDataStore, StaffDataStore } from 'dataStores';
import CompletedEventSessionsActions from './Actions';
import CompletedEventSessionsStore from './Store';

import EventSessionHeader from '../shared/EventSessionHeader.jsx';
import TeamSessionHeader from '../shared/TeamSessionHeader.jsx';
import SessionsList from './components/SessionsList.jsx';

function EventSessions({
  eventId,
  eventDataStore: { events },
  eventSessionsStore: {
    filtersPresent,
    isLoaded,
    loading,
    page,
    perPage,
    resourceFilter,
    search,
    sessionIds,
    staffFilter,
    staffIds,
    totalCount,
    sessionSummaryDrawer,
    scheduleNames,
    scheduleNameFilter,
  },
  sessionsActions: {
    closeSessionSummaryDrawer,
    mounted,
    pageSelected,
    resourceFilterUpdated,
    searchUpdated,
    staffFilterUpdated,
    viewAttendeesClicked,
    openSessionSummaryDrawer,
    scheduleNameFilterUpdated,
    viewNextSession,
    viewPreviousSession,
  },
  onToggleViewMode,
  intl,
  resourceDataStore: { records: resourceMap },
  isTeamEvent,
  staffDataStore: { staff: staffMap },
}) {
  React.useEffect(() => {
    if (!isLoaded) {
      mounted({ eventId, isTeamEvent });
    }
  }, [eventId, isLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  const staffItems = staffIds.size
    ? staffIds
        .map(id => ({
          label: `Staff: ${staffMap.get(id, { name: () => '' }).name()}`,
          value: id,
        }))
        .toArray()
    : [];

  const resourceItems = resourceMap
    .map(r => ({
      label: `Resource: ${r.name || ''}`,
      value: r.id,
    }))
    .toArray();

  const event = events.get(eventId);
  const canShowToggleViewModeButton =
    event &&
    !event.isOpenBooking() &&
    !event.isFixedSchedule() &&
    !!onToggleViewMode;

  return (
    <Box
      className="Admin-Schedule-Tab"
      sx={{
        padding: '12px 0',
        overflow: 'hidden',
      }}
    >
      <Grid alignItems="center" container spacing={1}>
        {isTeamEvent ? (
          <TeamSessionHeader
            intl={intl}
            eventId={eventId}
            resourceFilter={resourceFilter}
            resourceItems={resourceItems}
            search={search}
            searchUpdated={searchUpdated}
            staffFilter={staffFilter}
            staffItems={staffItems}
            staffFilterUpdated={staffFilterUpdated}
            resourceFilterUpdated={resourceFilterUpdated}
            canShowToggleViewModeButton={canShowToggleViewModeButton}
            onToggleViewMode={onToggleViewMode}
            scheduleNames={scheduleNames}
            scheduleNameFilter={scheduleNameFilter}
            scheduleNameFilterUpdated={scheduleNameFilterUpdated}
          />
        ) : (
          <EventSessionHeader
            intl={intl}
            staffItems={staffItems}
            staffFilter={staffFilter}
            search={search}
            resourceFilterUpdated={resourceFilterUpdated}
            staffFilterUpdated={staffFilterUpdated}
            searchUpdated={searchUpdated}
            resourceFilter={resourceFilter}
            resourceItems={resourceItems}
            canShowToggleViewModeButton={canShowToggleViewModeButton}
            onToggleViewMode={onToggleViewMode}
          />
        )}

        <SessionsList
          eventId={eventId}
          filtersPresent={filtersPresent}
          loading={loading}
          onViewAttendeesClicked={viewAttendeesClicked}
          openSessionSummaryDrawer={openSessionSummaryDrawer}
          sessionIds={sessionIds}
          page={page}
          onPageSelect={pageSelected}
          perPage={perPage}
          totalCount={totalCount}
          isTeamEvent={isTeamEvent}
        />
      </Grid>
      <SessionSummaryDrawer
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...sessionSummaryDrawer}
        onNextSession={viewNextSession}
        onPreviousSession={viewPreviousSession}
        onClose={closeSessionSummaryDrawer}
      />
    </Box>
  );
}

const CompletedEventSessions = injectIntl(props => (
  <AltContainer
    stores={{
      eventDataStore: EventDataStore,
      staffDataStore: StaffDataStore,
      resourceDataStore: ResourceDataStore,
      eventSessionsStore: CompletedEventSessionsStore,
    }}
    actions={{
      sessionsActions: CompletedEventSessionsActions,
    }}
  >
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <EventSessions {...props} />
  </AltContainer>
));

export default CompletedEventSessions;
