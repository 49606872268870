import { Record, List } from 'immutable';
import WaitlistEntry from 'shared/records/WaitlistEntry.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';

class Waitlist extends Record({
  event_id: null,
  spots_remaining: null,
  waitlist_entries: List(),
}) {
  constructor(object = {}) {
    // eslint-disable-next-line camelcase
    const waitlistEntries = List(
      (object.waitlist_entries || []).map(entry => new WaitlistEntry(entry))
    );

    super(merge(object, { waitlist_entries: waitlistEntries }));
  }
}

export default Waitlist;
