import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import ClientProfileActions from 'containers/clientProfile/Actions';
import ClientProfileDrawerActions from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/Actions';

import { currentUser } from 'shared/utils/UserUtils.jsx';

import { RegistrationSource, ClientSource } from 'sources';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import RSVPActions from './actions';

class RSVPStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      mounted: RSVPActions.mounted,
      reset: RSVPActions.reset,
      selectTab: RSVPActions.selectTab,
      remind: RSVPActions.remind,
      remindSuccess: RSVPActions.remindSuccess,
      remindError: RSVPActions.remindError,
      updateRsvpStatus: RSVPActions.updateRsvpStatus,
      updateRsvpStatusSuccess: RSVPActions.updateRsvpStatusSuccess,
      updateRsvpStatusError: RSVPActions.updateRsvpStatusError,
      openSessionSummaryDrawer: [
        ClientProfileActions.openSessionSummaryDrawer,
        ClientProfileDrawerActions.openSessionSummaryDrawer,
      ],

      listClientsSuccess: RSVPActions.listClientsSuccess,
      listClientsError: RSVPActions.listClientsError,
      listRegistrationsSuccess: RSVPActions.listRegistrationsSuccess,
      listRegistrationsError: RSVPActions.listRegistrationsError,
    });
  }

  reset() {
    this.sessionId = null;
    this.registrationId = null;
    this.selectedTab = 'attending';
    this.reminding = false;
    this.responding = false;
    this.loadingClients = false;
    this.loadingRegistrations = false;
  }

  mounted({ sessionId }) {
    if (this.sessionId !== sessionId && !currentUser().isClient()) {
      this.loadingClients = true;
      RegistrationSource.list({
        params: {
          session_ids: [sessionId],
          fields: ['paid'],
        },
        success: RSVPActions.listClientsSuccess,
        error: RSVPActions.listClientsError,
      });

      this.loadingRegistrations = true;
      ClientSource.list({
        params: {
          session_ids: [sessionId],
          fields: ['agreement_details'],
        },
        success: RSVPActions.listRegistrationsSuccess,
        error: RSVPActions.listRegistrationsError,
      });
    }

    this.sessionId = sessionId;
  }

  listClientsSuccess() {
    this.loadingClients = false;
  }

  listClientsError(...args) {
    this.loadingClients = false;
    this.notifyError('error while listing clients', args);
  }

  listRegistrationsSuccess() {
    this.loadingRegistrations = false;
  }

  listRegistrationsError(...args) {
    this.loadingRegistrations = false;
    this.notifyError('error while listing registrations', args);
  }

  selectTab(tabValue) {
    this.selectedTab = tabValue;
  }

  openSessionSummaryDrawer({ registrationId }) {
    this.registrationId = registrationId;
  }

  remind(registrationId) {
    this.reminding = true;
    RegistrationSource.remind({
      id: registrationId,
      success: RSVPActions.remindSuccess,
      error: RSVPActions.remindError,
    });
  }

  remindSuccess() {
    this.reminding = false;
    MessageWindowActions.addMessage.defer('Reminder email sent');
  }

  remindError(...args) {
    this.reminding = false;
    this.notifyError('error while reminding session', args);
  }

  updateRsvpStatus({ registrationId, status }) {
    this.responding = true;
    RegistrationSource.updateRsvpStatus({
      id: registrationId,
      params: { status, fields: ['paid'] },
      success: RSVPActions.updateRsvpStatusSuccess,
      error: RSVPActions.updateRsvpStatusError,
    });
  }

  updateRsvpStatusSuccess() {
    this.responding = false;
    MessageWindowActions.addMessage.defer('Response saved');
  }

  updateRsvpStatusError(...args) {
    this.responding = false;
    this.notifyError('error while updating rsvp status', args);
  }
}

export default alt.createStore(RSVPStore, 'RSVPStore');
