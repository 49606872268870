import { List } from 'immutable';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import { EventSource } from 'sources';

import RosterTransferDrawerActions from './Actions';
import ClientsActions from '../../actions';

const FIXED_SCHEDULE = 'fixed_schedule';
const TEAM_SCHEDULE = 'team_schedule';

class RosterTransferDrawerStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();
    this.showTransferDrawer = false;

    this.bindListeners({
      handleListEvents: RosterTransferDrawerActions.fetchEventList,
      handleListEventsSuccess: RosterTransferDrawerActions.listSuccess,
      handleListEventsError: RosterTransferDrawerActions.listError,
      setCurrentSelectedEvent:
        RosterTransferDrawerActions.setCurrentSelectedEvent,
      handleRosterTransfer: RosterTransferDrawerActions.handleRosterTransfer,
      rosterTransferSuccess: RosterTransferDrawerActions.rosterTransferSuccess,
      rosterTransferError: RosterTransferDrawerActions.rosterTransferError,
      toggleTransferDrawer: RosterTransferDrawerActions.toggleTransferDrawer,
    });
  }

  reset() {
    this.page = 1;
    this.perPage = 20;
    this.totalCount = 0;
    this.events = List();
    this.title = null;
    this.selectedEvent = null;
    this.isScroll = false;
    this.transferObj = { clientId: null, eventId: null };
    this.team = false;
    this.loading = false;
  }

  toggleTransferDrawer({ clientId, eventId, team = false }) {
    this.reset();
    if (clientId) {
      this.transferObj = {
        clientId,
        eventId,
      };
      this.team = team;
    }

    this.showTransferDrawer = !this.showTransferDrawer;
    if (this.showTransferDrawer) {
      this.handleListEvents({ page: 1, perPage: 20 });
    }
  }

  handleListEvents({ page, perPage, title = null }) {
    this.title = title;
    this.selectedEvent = null;
    this.perPage = perPage || this.perPage;
    this.page = page || this.page;

    const params = {
      page: this.page,
      per_page: this.perPage,
      schedule_type: [this.team ? TEAM_SCHEDULE : FIXED_SCHEDULE],
      statuses: ['active'],
      title,
    };

    if (this.page === 1) {
      this.events = List();
    }

    EventSource.list({
      params,
      success: RosterTransferDrawerActions.listSuccess,
      error: RosterTransferDrawerActions.listError,
    });
  }

  handleListEventsSuccess(data) {
    this.totalCount = data.totalCount;
    this.events = this.events.concat(
      data.events.filter(event => event.id !== this.transferObj.eventId)
    );
  }

  handleListEventsError(...args) {
    this.notifyError('error fetching events', args);
  }

  setCurrentSelectedEvent(event) {
    this.selectedEvent = event;
  }

  handleRosterTransfer() {
    this.loading = true;
    const params = {
      source_id: this.transferObj.eventId,
      dest_id: this.selectedEvent.id,
      client_id: this.transferObj.clientId,
      team_transfer: this.team,
    };
    EventSource.postTransferClient({
      params,
      success: RosterTransferDrawerActions.rosterTransferSuccess,
      error: RosterTransferDrawerActions.rosterTransferError,
    });
  }

  rosterTransferSuccess() {
    this.loading = false;
    const { customerEvent: event } = EventStore.getState();
    const ids = event.participant_ids.filter(
      id => id !== this.transferObj.clientId
    );
    this.showTransferDrawer = false;
    const eventData = {
      eventId: this.transferObj.eventId,
      ids,
      team: this.team,
    };
    ClientsActions.mounted.defer(eventData);
  }

  rosterTransferError(...args) {
    this.loading = false;
    this.notifyError('Roster transfer error', args);
  }
}

export default alt.createStore(
  RosterTransferDrawerStore,
  'RosterTransferDrawerStore'
);
