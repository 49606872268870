import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Button } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';

import OrderNoteForm from './OrderNoteForm.jsx';

import './styles.scss';

function OrderNoteSummary({
  order,
  loading = false,
  onOrderNoteApply = () => {},
}) {
  const intl = useIntl();
  const hasOrderNote = order.note && order.note.length > 0;
  const allowDeleteNote =
    currentUser().isAdmin() || currentUser().isStaffAdmin();

  return (
    <div className="order-note-summary">
      {!hasOrderNote && (
        <OrderNoteForm
          order={order}
          isLoading={loading}
          onOrderNoteApply={onOrderNoteApply}
        />
      )}
      {hasOrderNote && (
        <div className="order-note-summary__note">
          <Typography
            variant="fieldLabel"
            className="order-note-summary__note-header"
          >
            <span>{t('.title', intl, __filenamespace)}</span>
            {allowDeleteNote && (
              <Button
                rounded
                icon="close"
                type="tertiary"
                disabled={loading}
                onClick={() => onOrderNoteApply({ orderNote: '' })}
              />
            )}
          </Typography>
          <Typography>{order.note}</Typography>
        </div>
      )}
    </div>
  );
}

OrderNoteSummary.propTypes = {
  order: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  onOrderNoteApply: PropTypes.func,
};

export default OrderNoteSummary;
