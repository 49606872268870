import { Map, Set } from 'immutable';
import debounce from 'lodash.debounce';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import Coupon from 'records/Coupon';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';

import DiscountableItem, {
  COUPON_DISCOUNTABLE,
  COUPON_DISCOUNTABLE_TYPES,
} from 'records/DiscountableItem';
import {
  CreditPassSource,
  CouponSource,
  EventSource,
  MembershipSource,
  VariantSource,
} from 'sources';
import { customerScopedRoute, redirectTo } from 'shared/utils/RouteUtils.js';

import CouponActions from './Actions';

const ALERT_MODE = {
  NO_ITEMS: 'no_items',
  UNSUPPORTED_ITEMS: 'unsupported_items',
};

class CouponStore extends UpperHandStore {
  constructor() {
    super();
    this.couponId = null;
    this.updatedCoupon = new Coupon();
    this.errors = new FieldErrors();
    this.alertMode = null;
    this.dirty = false;
    this.saveConfirmation = false;
    this.isLoading = true;
    this.isLoadingCoupon = false;
    this.isLoadingEvents = false;
    this.isLoadingTeams = false;
    this.isLoadingMemberships = false;
    this.isRemoving = false;
    this.isOpenCreateModal = false;
    this.createMode = false;
    this.editMode = false;
    this.searchMap = Map({
      CreditPass: '',
      Event: '',
      Membership: '',
      Variant: '',
    });
    this.searchableTeams = [];
    this.searchableEvents = [];
    this.searchableMemberships = [];
    this.searchableCreditPasses = [];
    this.searchableVariants = [];
    this.selectedTab = 0;

    this.debouncedSearch = debounce(this.searchItems, 500);
    this.fetchedVariantIds = Set();
    this.fetchedMembershipsIds = Set();
    this.fetchedPassesIds = Set();
    this.fetchedTeamIds = Set();
    this.fetchedEventIds = Set();

    this.reset();
    this.bindListeners({
      addDiscountableItem: CouponActions.addDiscountableItem,
      back: CouponActions.back,
      cancelCreate: CouponActions.cancelCreate,
      cancelRemove: CouponActions.cancelRemove,
      cancelUpdate: CouponActions.cancelUpdate,
      confirmRemove: CouponActions.confirmRemove,
      createSuccess: CouponActions.createSuccess,
      createError: CouponActions.createError,
      creditPassListSuccess: CouponActions.creditPassListSuccess,
      discountableTypeChanged: CouponActions.discountableTypeChanged,
      dismissAlert: CouponActions.dismissAlert,
      edit: CouponActions.edit,
      eventListSuccess: CouponActions.eventListSuccess,
      fetch: CouponActions.fetch,
      fetchSuccess: CouponActions.fetchSuccess,
      fetchError: CouponActions.fetchError,
      membershipListSuccess: CouponActions.membershipListSuccess,
      mounted: CouponActions.mounted,
      openCreateModal: CouponActions.openCreateModal,
      removeDiscountableItem: CouponActions.removeDiscountableItem,
      remove: CouponActions.remove,
      removeSuccess: CouponActions.removeSuccess,
      save: CouponActions.save,
      search: CouponActions.search,
      selectedCreditPassListSuccess:
        CouponActions.selectedCreditPassListSuccess,
      selectedEventListSuccess: CouponActions.selectedEventListSuccess,
      selectedMembershipListSuccess:
        CouponActions.selectedMembershipListSuccess,
      selectedVariantListSuccess: CouponActions.selectedVariantListSuccess,
      tabChanged: CouponActions.tabChanged,
      toggleConfirmation: CouponActions.toggleConfirmation,
      updateField: CouponActions.updateField,
      updateSuccess: CouponActions.updateSuccess,
      updateError: CouponActions.updateError,
      variantListSuccess: CouponActions.variantListSuccess,
      selectedTeamListSuccess: CouponActions.selectedTeamListSuccess,
      teamListSuccess: CouponActions.teamListSuccess,
    });
  }

  reset() {
    this.eventIdsToList = Set();
    this.teamIdsToList = Set();
    this.passesIdsToList = Set();
    this.memshipsIdsToList = Set();
    this.variantIdsToList = Set();
  }

  updateLoadingState() {
    this.isLoading =
      this.isLoadingCreditPasses ||
      this.isLoadingCoupon ||
      this.isLoadingEvents ||
      this.isLoadingTeams ||
      this.isLoadingMemberships ||
      this.isLoadingVariants ||
      this.isCreating ||
      this.isUpdating;
  }

  mounted({ couponID, createRoute, editRoute }) {
    this.reset();
    this.updatedCoupon = new Coupon({
      all_items: false,
      discount_type: 'amount',
    });
    this.editMode = !!editRoute;
    this.createMode = !!createRoute;

    if (this.couponId !== parseInt(couponID, 10)) {
      this.fetchedVariantIds = Set();
      this.fetchedMembershipsIds = Set();
      this.fetchedPassesIds = Set();
      this.fetchedTeamIds = Set();
      this.fetchedEventIds = Set();
    }

    if (couponID) {
      this.couponId = parseInt(couponID, 10);
      this.fetch();
      // if no couponId editRoute === true means we are in creation mode
    } else {
      this.listDependencies();
    }
  }

  discountableTypeChanged([type, value]) {
    const discountables = this.updatedCoupon.get('discountables');
    if (value === COUPON_DISCOUNTABLE_TYPES.all) {
      this.updatedCoupon = this.updatedCoupon.set(
        'discountables',
        discountables.add(type)
      );
    } else {
      this.updatedCoupon = this.updatedCoupon.set(
        'discountables',
        discountables.remove(type)
      );
    }
  }

  addDiscountableItem([type, id]) {
    const discountablesItems = this.updatedCoupon.get('discountable_items');
    const isAdded = discountablesItems.some(
      di => di.item_id === id && di.item_type === type
    );

    if (isAdded) {
      MessageWindowActions.addMessage.defer(
        new TranslatableMessage({
          id: 'coupons.show_view.item_already_added',
        })
      );
      return;
    }

    this.updatedCoupon = this.updatedCoupon.set(
      'discountable_items',
      discountablesItems
        .push(
          new DiscountableItem({
            item_id: id,
            item_type: type,
          })
        )
        .toSet()
        .toList()
    );
    const searchFunc = `list${type}`;
    this[searchFunc]({ fetchAll: false });
  }

  removeDiscountableItem([type, id]) {
    const updatedDiscountablesItems = this.updatedCoupon
      .get('discountable_items')
      .filterNot(di => di.item_id === id && di.item_type === type);
    this.updatedCoupon = this.updatedCoupon.set(
      'discountable_items',
      updatedDiscountablesItems
    );
    const searchFunc = `list${type}`;
    this[searchFunc]({ fetchAll: false });
  }

  edit() {
    this.editMode = true;
  }

  getDiscountableIds(type) {
    const items = this.updatedCoupon.get('discountable_items');
    return items
      .filter(di => di.item_type === type)
      .map(i => i.item_id)
      .toSet();
  }

  listDependencies(selected) {
    this.variantIdsToList = this.getDiscountableIds(
      COUPON_DISCOUNTABLE.Variant
    );
    this.passesIdsToList = this.getDiscountableIds(
      COUPON_DISCOUNTABLE.CreditPass
    );
    this.memshipsIdsToList = this.getDiscountableIds(
      COUPON_DISCOUNTABLE.Membership
    );
    this.eventIdsToList = this.getDiscountableIds(COUPON_DISCOUNTABLE.Event);
    this.teamIdsToList = this.getDiscountableIds(COUPON_DISCOUNTABLE.Team);

    const {
      features: { memberships, credit_passes: creditPasses, retail },
    } = currentCustomer();

    const shouldListEvents =
      this.eventIdsToList.size > 0 &&
      this.fetchedEventIds.size < this.eventIdsToList.size;

    if (shouldListEvents) {
      this.listEvent({ selected });
    }

    const shouldListTeams =
      this.teamIdsToList.size > 0 &&
      this.fetchedTeamIds.size < this.teamIdsToList.size;

    if (shouldListTeams) {
      this.listTeam({ selected });
    }

    if (this.editMode || this.createMode) {
      this.listTeam({ selected: false });
      this.listEvent({ selected: false });
    }

    if (memberships) {
      const shouldListMemberships =
        this.memshipsIdsToList.size > 0 &&
        this.fetchedMembershipsIds.size < this.memshipsIdsToList.size;

      if (shouldListMemberships) {
        this.listMembership({ selected });
      }

      if (this.editMode || this.createMode) {
        this.listMembership({ selected: false });
      }
    }

    if (creditPasses) {
      const shouldListPasses =
        this.passesIdsToList.size > 0 &&
        this.fetchedPassesIds.size < this.passesIdsToList.size;

      if (shouldListPasses) {
        this.listCreditPass({ selected });
      }

      if (this.editMode || this.createMode) {
        this.listCreditPass({ selected: false });
      }
    }

    if (retail) {
      const shouldListVariants =
        this.variantIdsToList.size > 0 &&
        this.fetchedVariantIds.size < this.variantIdsToList.size;

      if (shouldListVariants) {
        this.listVariant({ selected });
      }

      if (this.editMode || this.createMode) {
        this.listVariant({ selected: false });
      }
    }

    this.updateLoadingState();
  }

  listCreditPass({ selected = false, uuids = Set() }) {
    const idsRequestParam = selected ? 'ids' : 'except_ids';
    this.isLoadingCreditPasses = true;
    const params = this.getParams(
      idsRequestParam,
      COUPON_DISCOUNTABLE.CreditPass,
      selected,
      uuids
    );
    const successWithSelected = {
      action: CouponActions.selectedCreditPassListSuccess,
      args: [{ fetchAll: selected }],
    };

    CreditPassSource.list({
      params,
      success: selected
        ? successWithSelected
        : CouponActions.creditPassListSuccess,
    });
  }

  listEvent({ selected = false, uuids = Set() }) {
    const idsRequestParam = selected ? 'uuids' : 'except_uuids';
    this.isLoadingEvents = selected;
    const params = this.getParams(
      idsRequestParam,
      COUPON_DISCOUNTABLE.Event,
      selected,
      uuids
    );
    const successWithSelected = {
      action: CouponActions.selectedEventListSuccess,
      args: [{ fetchAll: selected }],
    };

    EventSource.list({
      params: {
        ...params,
        schedule_type: ['fixed_schedule', 'open_booking', 'class_schedule'],
      },
      success: selected ? successWithSelected : CouponActions.eventListSuccess,
    });
  }

  listTeam({ selected = false, uuids = Set() }) {
    const idsRequestParam = selected ? 'uuids' : 'except_uuids';
    this.isLoadingTeams = selected;
    const params = this.getParams(
      idsRequestParam,
      COUPON_DISCOUNTABLE.Team,
      selected,
      uuids
    );
    const successWithSelected = {
      action: CouponActions.selectedTeamListSuccess,
      args: [{ fetchAll: selected }],
    };

    EventSource.list({
      params: { ...params, schedule_type: ['team_schedule'] },
      success: selected ? successWithSelected : CouponActions.teamListSuccess,
    });
  }

  listMembership({ selected = false, uuids = Set() }) {
    const idsRequestParam = selected ? 'uuids' : 'except_uuids';
    this.isLoadingMemberships = selected;
    const params = this.getParams(
      idsRequestParam,
      COUPON_DISCOUNTABLE.Membership,
      selected,
      uuids
    );
    const successWithSelected = {
      action: CouponActions.selectedMembershipListSuccess,
      args: [{ fetchAll: selected }],
    };

    MembershipSource.list({
      params,
      success: selected
        ? successWithSelected
        : CouponActions.membershipListSuccess,
    });
  }

  listVariant({ selected = false, uuids = Set() }) {
    const idsRequestParam = selected ? 'uuids' : 'except_uuids';

    this.isLoadingVariants = true;

    const params = this.getParams(
      idsRequestParam,
      COUPON_DISCOUNTABLE.Variant,
      selected,
      uuids
    );

    if (!selected) {
      delete params.except_uuids;
    }

    const successWithSelected = {
      action: CouponActions.selectedVariantListSuccess,
      args: [{ fetchAll: selected }],
    };

    VariantSource.list({
      params,
      success: selected
        ? successWithSelected
        : CouponActions.variantListSuccess,
    });
  }

  getParams(idsParamName, type, selected = false, uuids = Set()) {
    const dataIds = this.updatedCoupon
      .get('discountable_items')
      .filter(di => di.item_type === type)
      .map(i => i.item_id)
      .toSet()
      .toJS();
    const isEventOrTeam =
      type === COUPON_DISCOUNTABLE.Event || type === COUPON_DISCOUNTABLE.Team;
    const searchRequestParam = isEventOrTeam ? 'title' : 'search';
    const params = {
      [idsParamName]: uuids.size > 0 ? uuids.toJS() : dataIds,
      [searchRequestParam]: this.searchMap.get(type),
      per_page: selected ? 50 : 10,
    };

    if (type !== COUPON_DISCOUNTABLE.CreditPass) {
      params.fields = ['uuid'];
    }

    if (isEventOrTeam) {
      params.recurring =
        this.updatedCoupon.get('coupon_type') === Coupon.RECURRING ||
        this.updatedCoupon.get('coupon_type') === Coupon.RECURRINGONCE;
    }

    if (
      type === COUPON_DISCOUNTABLE.Membership &&
      this.updatedCoupon.get('coupon_type') === Coupon.ONE_TIME
    ) {
      params.join_fee = true;
    }

    return params;
  }

  search([type, search]) {
    this.debouncedSearch({ type, search });
  }

  searchItems({ type, search }) {
    this.searchMap = this.searchMap.set(type, search);
    const searchFunc = `list${type}`;
    this[searchFunc]({ fetchAll: false });
  }

  fetch() {
    this.isLoadingCoupon = true;
    this.updateLoadingState();
    CouponSource.fetch({
      id: this.couponId,
      params: { fields: ['redemption_count'] },
      success: CouponActions.fetchSuccess,
      error: CouponActions.fetchError,
    });
  }

  fetchSuccess(coupon) {
    this.isLoadingCoupon = false;
    this.updatedCoupon = coupon;
    this.updatedCoupon = this.updatedCoupon.set('all_items', false);
    this.listDependencies(true);
  }

  fetchError() {
    this.isLoadingCoupon = false;
    this.updateLoadingState();
  }

  creditPassListSuccess({ credit_passes: creditPasses }) {
    this.searchableCreditPasses = creditPasses
      .map(cp => ({ text: cp.name, value: cp.id }))
      .toList()
      .toJS();
    this.isLoadingCreditPasses = false;
    this.updateLoadingState();
  }

  selectedCreditPassListSuccess([
    { credit_passes: creditPasses },
    { fetchAll },
  ]) {
    const fetchedIds = creditPasses.map(credit => credit.id).toSet();

    this.fetchedPassesIds = this.fetchedPassesIds.union(fetchedIds);
    if (
      fetchAll &&
      fetchedIds.size > 0 &&
      this.fetchedPassesIds.size < this.passesIdsToList.size
    ) {
      this.listCreditPass({
        selected: true,
        uuids: this.passesIdsToList.subtract(this.fetchedPassesIds),
      });
      return;
    }

    if (this.editMode || this.createMode) {
      this.listCreditPass({});
    }

    this.isLoadingCreditPasses = false;
    this.updateLoadingState();
  }

  eventListSuccess({ events }) {
    this.searchableEvents = events
      .map(e => ({ text: e.title, value: e.uuid }))
      .toList()
      .toJS();
    this.isLoadingEvents = false;
    this.updateLoadingState();
  }

  selectedEventListSuccess([{ events }, { fetchAll }]) {
    const fetchedIds = events.map(evt => evt.uuid).toSet();

    this.fetchedEventIds = this.fetchedEventIds.union(fetchedIds);
    if (
      fetchAll &&
      fetchedIds.size > 0 &&
      this.fetchedEventIds.size < this.eventIdsToList.size
    ) {
      this.listEvent({
        selected: true,
        uuids: this.eventIdsToList.subtract(this.fetchedEventIds),
      });
      return;
    }

    if (this.editMode || this.createMode) {
      this.listEvent({});
    }

    this.isLoadingEvents = false;
    this.updateLoadingState();
  }

  teamListSuccess({ events: teams }) {
    this.searchableTeams = teams
      .map(t => ({ text: t.title, value: t.uuid }))
      .toList()
      .toJS();
    this.isLoadingTeams = false;
    this.updateLoadingState();
  }

  selectedTeamListSuccess([{ events: teams }, { fetchAll }]) {
    const fetchedIds = teams.map(team => team.uuid).toSet();

    this.fetchedTeamIds = this.fetchedTeamIds.union(fetchedIds);
    if (
      fetchAll &&
      fetchedIds.size > 0 &&
      this.fetchedTeamIds.size < this.teamIdsToList.size
    ) {
      this.listTeam({
        selected: true,
        uuids: this.teamIdsToList.subtract(this.fetchedTeamIds),
      });
      return;
    }

    if (this.editMode || this.createMode) {
      this.listTeam({});
    }

    this.isLoadingTeams = false;
    this.updateLoadingState();
  }

  membershipListSuccess({ memberships }) {
    this.searchableMemberships = memberships
      .map(e => ({ text: e.name, value: e.uuid }))
      .toList()
      .toJS();
    this.isLoadingMemberships = false;
    this.updateLoadingState();
  }

  selectedMembershipListSuccess([{ memberships }, { fetchAll }]) {
    const fetchedIds = memberships.map(m => m.uuid).toSet();

    this.fetchedMembershipsIds = this.fetchedMembershipsIds.union(fetchedIds);
    if (
      fetchAll &&
      fetchedIds.size > 0 &&
      this.fetchedMembershipsIds.size < this.memshipsIdsToList.size
    ) {
      this.listMembership({
        selected: true,
        uuids: this.memshipsIdsToList.subtract(this.fetchedMembershipsIds),
      });

      return;
    }

    if (this.editMode || this.createMode) {
      this.listMembership({});
    }

    this.isLoadingMemberships = false;
    this.updateLoadingState();
  }

  variantListSuccess({ variants }) {
    this.searchableVariants = variants
      .map(v => ({
        text: `${v.name} ${v?.variant ? `(${v.variant})` : ''}`,
        value: v.uuid,
      }))
      .toList()
      .toJS();
    this.isLoadingVariants = false;
    this.updateLoadingState();
  }

  selectedVariantListSuccess([{ variants }, { fetchAll }]) {
    const fetchedIds = variants.map(v => v.uuid).toSet();

    this.fetchedVariantIds = this.fetchedVariantIds.union(fetchedIds);

    if (
      fetchAll &&
      fetchedIds.size > 0 &&
      this.fetchedVariantIds.size < this.variantIdsToList.size
    ) {
      this.listVariant({
        selected: true,
        uuids: this.variantIdsToList.subtract(this.fetchedVariantIds),
      });

      return;
    }

    if (this.editMode || this.createMode) {
      this.listVariant({});
    }

    this.isLoadingVariants = false;
    this.updateLoadingState();
  }

  // eslint-disable-next-line class-methods-use-this
  updateField([field, value]) {
    if (field === 'coupon_type') {
      if (this.updatedCoupon.canSwitchToType(value)) {
        this.listEvent({});
        this.listMembership({});
      } else {
        this.alertMode = ALERT_MODE.UNSUPPORTED_ITEMS;
        return;
      }
    }

    this.updatedCoupon = this.updatedCoupon.set(field, value);

    if (!this.errors.isEmpty()) {
      this.validateCoupon();
    }
    this.dirty = true;
  }

  save() {
    if (!this.updatedCoupon.isDiscountableItemsSelected()) {
      this.alertMode = ALERT_MODE.NO_ITEMS;
      return;
    }
    if (this.validateCoupon()) {
      if (this.updatedCoupon.id) {
        this.update();
      } else {
        this.create();
      }
    }
  }

  create() {
    this.isCreating = true;
    this.updateLoadingState();
    CouponSource.create({
      params: this.payload(),
      success: CouponActions.createSuccess,
      error: CouponActions.createError,
    });
  }

  createSuccess(coupon) {
    this.couponId = coupon.id;
    this.editMode = false;
    this.isCreating = false;
    this.isOpenCreateModal = false;
    this.updateLoadingState();
    setTimeout(() => {
      redirectTo({
        path: customerScopedRoute('/coupons'),
      });
    }, 0);
  }

  createError(...args) {
    this.isCreating = false;
    this.updateLoadingState();
    this.notifyError('Coupon create error', args);
  }

  update() {
    this.isUpdating = true;
    this.updateLoadingState();
    CouponSource.update({
      id: this.updatedCoupon.id,
      params: this.payload(),
      success: CouponActions.updateSuccess,
      error: CouponActions.updateError,
    });
  }

  updateSuccess() {
    this.editMode = false;
    this.isUpdating = false;
    redirectTo({
      path: customerScopedRoute(`/coupons/${this.couponId}`),
    });
  }

  updateError(...args) {
    this.isUpdating = false;
    this.updateLoadingState();
    this.notifyError('Coupon updating error', args);
  }

  remove() {
    this.isRemoving = true;
  }

  confirmRemove() {
    CouponSource.destroy({
      id: this.couponId,
      success: CouponActions.removeSuccess,
    });
  }

  openCreateModal() {
    this.isOpenCreateModal = true;
  }

  cancelCreate() {
    if (this.dirty && !this.saveConfirmation) {
      this.toggleConfirmation();
      return;
    }
    this.dirty = false;
    this.saveConfirmation = false;
    this.isOpenCreateModal = false;
  }

  cancelRemove() {
    this.isRemoving = false;
  }

  cancelUpdate() {
    if (this.dirty && !this.saveConfirmation) {
      this.toggleConfirmation();
      return;
    }
    this.dirty = false;
    this.saveConfirmation = false;
    this.editMode = false;
    this.isLoading = true;
    redirectTo({
      path: customerScopedRoute(`/coupons/${this.couponId}`),
    });
  }

  // eslint-disable-next-line class-methods-use-this
  removeSuccess() {
    this.isRemoving = false;
    MessageWindowActions.addMessage.defer(
      new TranslatableMessage({
        id: 'coupons.show_view.successfully_removed',
      })
    );
    setTimeout(() => {
      redirectTo({
        path: customerScopedRoute('/coupons'),
      });
    }, 0);
  }

  // eslint-disable-next-line class-methods-use-this
  back() {
    redirectTo({
      path: customerScopedRoute('/coupons'),
    });
  }

  tabChanged([_, value]) {
    this.selectedTab = value;
  }

  dismissAlert() {
    this.alertMode = null;
  }

  toggleConfirmation() {
    this.saveConfirmation = !this.saveConfirmation;
  }

  validateCoupon() {
    const requiredFields = [
      ['code'],
      ['description'],
      ['discount_type'],
      ['value'],
      ['name'],
      ['start_date'],
      ['end_date'],
    ];

    this.errors = this.errors.clear();

    requiredFields.forEach(field => {
      if (!this.updatedCoupon.getIn(field)) {
        this.errors = this.errors.add(field, 'Required');
      }
    });

    return this.errors.isEmpty();
  }

  payload() {
    return JSON.stringify({ attributes: this.updatedCoupon.toServer() });
  }
}

export default alt.createStore(CouponStore, 'CouponStore2');
