import { OrderedSet } from 'immutable';

import Staff from 'shared/records/Staff.jsx';

import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { currentUser, hasAdminPermission } from 'shared/utils/UserUtils.jsx';

import { StaffUnavailabilitySource } from 'sources';
import StaffDetailsDrawerActions from 'contacts/shared/actions/StaffDetailsDrawerActions.jsx';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

export const INFO_TAB = 'info';
export const PAYROLL_TAB = 'payroll';

class StaffDetailsDrawerStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      toggleInfoEdit: StaffDetailsDrawerActions.TOGGLE_INFO_EDIT_MODE,
      togglePayrollEdit: StaffDetailsDrawerActions.TOGGLE_PAYROLL_EDIT_MODE,
      selectStaff: StaffDetailsDrawerActions.STAFF_SELECTED,
      updateActiveTab: StaffDetailsDrawerActions.TAB_SELECTED,
      updateHourlyRate: StaffDetailsDrawerActions.HOURLY_RATE_UPDATED,

      saveHourlyRate: StaffDetailsDrawerActions.HOURLY_RATE_SAVED,
      saveHourlyRateSuccess: StaffDetailsDrawerActions.HOURLY_RATE_SAVE_SUCCESS,
      saveHourlyRateError: StaffDetailsDrawerActions.HOURLY_RATE_SAVE_ERROR,

      listUnavailabilitySuccess:
        StaffDetailsDrawerActions.LIST_UNAVAILABILITY_SUCCESS,
      listUnavailabilityError:
        StaffDetailsDrawerActions.LIST_UNAVAILABILITY_ERROR,
    });
  }

  reset() {
    this.staff = new Staff();
    this.activeTab = INFO_TAB;
    this.infoEditMode = false;
    this.payrollEditMode = false;

    this.hourlyRate = 0;
    this.isSavingHourlyRate = false;

    this.canEditInfo = false;
    this.canViewPayroll = false;
    this.canEditPayroll = false;

    this.unavailabilityIds = OrderedSet();
  }

  toggleInfoEdit() {
    this.infoEditMode = !this.infoEditMode;
  }

  togglePayrollEdit() {
    this.payrollEditMode = !this.payrollEditMode;
    this.hourlyRate = this.payrollEditMode ? this.staff.hourly_rate : 0;
  }

  selectStaff(newStaff) {
    this.reset();

    this.staff = newStaff;

    this.canEditInfo =
      hasAdminPermission() || currentUser().customer_user_id === this.staff.id;

    this.canViewPayroll =
      currentCustomer().payroll_enabled &&
      currentCustomer().time_tracking_enabled &&
      (currentUser().isAdmin() ||
        currentUser().isStaffAdmin() ||
        currentUser().customer_user_id === this.staff.id);
    this.canEditPayroll =
      currentCustomer().payroll_enabled &&
      currentCustomer().time_tracking_enabled &&
      (currentUser().isAdmin() || currentUser().isStaffAdmin());

    this.listUnavailability();
  }

  listUnavailability(page = 1) {
    this.isLoading = true;

    StaffUnavailabilitySource.list({
      params: {
        page,
        per_page: 50,

        staff_ids: [this.staff.id],
      },
      success: StaffDetailsDrawerActions.listUnavailabilitySuccess,
      error: StaffDetailsDrawerActions.listUnavailabilityError,
    });
  }

  listUnavailabilityError(...args) {
    this.notifyError('error loading staff unavailabilities', args);
  }

  listUnavailabilitySuccess({
    staff_unavailabilities: unavailabilities,
    page,
    perPage,
    totalCount,
  }) {
    this.unavailabilityIds = this.unavailabilityIds.concat(
      OrderedSet(unavailabilities.map(u => u.id))
    );

    if (page * perPage < totalCount) {
      this.listUnavailability(page + 1);
    } else {
      this.isLoading = false;
    }
  }

  updateActiveTab(newActiveTab) {
    this.activeTab = newActiveTab;
  }

  updateHourlyRate(newRate) {
    this.hourlyRate = newRate;
  }

  saveHourlyRate() {
    this.isSavingHourlyRate = true;

    uhApiClient.patch({
      url: `customer_users/${this.staff.id}`,
      data: JSON.stringify({ attributes: { hourly_rate: this.hourlyRate } }),
      success: StaffDetailsDrawerActions.hourlyRateSaveSuccess,
      error: StaffDetailsDrawerActions.hourlyRateSaveError,
    });
  }

  saveHourlyRateSuccess(data) {
    this.staff = this.staff.set('hourly_rate', data.hourly_rate);
    this.isSavingHourlyRate = false;
    this.payrollEditMode = false;
    this.hourlyRate = 0;
  }

  saveHourlyRateError(...args) {
    this.isSavingHourlyRate = false;
    this.notifyError('error saving hourly rate', args);
  }
}

export default alt.createStore(
  StaffDetailsDrawerStore,
  'StaffDetailsDrawerStore'
);
