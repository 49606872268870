class CompletedEventSessionsActions {
  constructor() {
    this.generateActions(
      'closeSessionSummaryDrawer',
      'mounted',
      'openSessionSummaryDrawer',
      'pageSelected',
      'resourceFilterUpdated',
      'resourceListError',
      'resourceListSuccess',
      'scheduleListError',
      'scheduleListSuccess',
      'searchUpdated',
      'sessionListError',
      'sessionListSuccess',
      'staffFilterUpdated',
      'staffListError',
      'staffListSuccess',
      'viewAttendeesClicked',
      'scheduleNamesSuccess',
      'scheduleNamesError',
      'scheduleNameFilterUpdated',
      'viewNextSession',
      'viewPreviousSession'
    );
  }
}

export default alt.createActions(CompletedEventSessionsActions);
