import { Map, List } from 'immutable';
import initATD from 'shared/utils/ATDInitializationUtils.js';
import POSActions from 'point_of_sale/actions/POSActions.jsx';
import POSCheckoutActions from 'point_of_sale/actions/POSCheckoutActions.jsx';
import POSAutomationsActions from 'point_of_sale/actions/POSAutomationsActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class POSAutomationsStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      listAutomations: POSAutomationsActions.list,
      listSuccess: POSAutomationsActions.LIST_SUCCESS,
      listError: POSAutomationsActions.LIST_ERROR,

      reset: [
        POSActions.CLIENT_BACK_CLICKED,
        POSCheckoutActions.CLOSE_BUTTON_CLICKED,
      ],
    });
  }

  reset() {
    this.descriptionMap = Map();
    this.descriptions = List();
    this.page = 1;
    this.perPage = 50;
    this.totalCount = 0;
    this.isLoading = false;
  }

  listAutomations({ eventIds = [] }) {
    if (eventIds.length === 0) return false;

    return uhApiClient.get({
      url: 'automation_template_descriptions',
      data: { ...this.queryParams(), event_ids: eventIds },
      success: {
        action: POSAutomationsActions.listSuccess,
        args: [eventIds],
      },
      error: POSAutomationsActions.listError,
    });
  }

  listSuccess([
    {
      automation_template_descriptions: atds,
      page,
      per_page: perPage,
      total_count: totalCount,
    },
    eventIds,
  ]) {
    this.page = page;
    this.perPage = perPage;
    this.totalCount = totalCount;

    this.descriptionMap = this.descriptionMap.withMutations(m =>
      atds.forEach(atd => m.set(atd.id, initATD(atd)))
    );

    this.descriptions = this.descriptionMap.toList();

    if (this.totalCount > this.descriptions.size) {
      this.page += 1;
      this.listAutomations({ eventIds });
    } else {
      this.isLoading = false;
    }
  }

  listError(...args) {
    this.isLoading = false;
    this.notifyError('error listing automations', args);
  }

  queryParams() {
    return {
      page: this.page,
      per_page: this.perPage,
    };
  }
}

export default alt.createStore(POSAutomationsStore, 'POSAutomationsStore');
