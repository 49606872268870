import * as React from 'react';
import { injectIntl } from 'react-intl';
import CollapsibleTable, {
  EmptyRow,
} from 'reporting/sales_detail/components/tables/_CollapsibleTable.jsx';
import { Row } from 'reporting/sales_detail/components/tables/_Rows.jsx';
import { TotalRow } from 'reporting/sales_detail/components/tables/_TotalRows.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

const COLUMNS = [
  messageId(
    'reporting.sales_detail.SalesDetail.PaymentTypeTable.payment_type',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.PaymentTypeTable.receipts',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.PaymentTypeTable.tax',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.PaymentTypeTable.service_fee',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.PaymentTypeTable.returns',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.PaymentTypeTable.returns_tax',
    __filenamespace
  ),
  messageId(
    'reporting.sales_detail.SalesDetail.PaymentTypeTable.total',
    __filenamespace
  ),
];

const COLUMNS_SIZE = COLUMNS.length;

function PaymentTypeTable({ intl, paymentTypeSales, paymentTypeTotals }) {
  return (
    <CollapsibleTable
      title={t('.title', intl, __filenamespace)}
      columns={COLUMNS}
      totals={
        paymentTypeSales.size > 0 && (
          <TotalRow
            data={paymentTypeTotals}
            label={t('.subtotal', intl, __filenamespace)}
          />
        )
      }
    >
      {paymentTypeSales.size > 0 ? (
        paymentTypeSales.map(r => <Row key={r.name} data={r} />)
      ) : (
        <EmptyRow colSpan={COLUMNS_SIZE} />
      )}
    </CollapsibleTable>
  );
}

export default injectIntl(PaymentTypeTable);
