import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography, Hidden } from '@upperhand/playmaker';

import AutomationTemplateDescriptionActions from 'shared/actions/AutomationTemplateDescriptionActions.jsx';
import ClientCreditActions from 'shared/actions/ClientCreditActions';
import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventDisplayActions from 'event_mgmt/display/actions/EventDisplayActions.jsx';
import PurchaseDrawerActions from 'containers/purchaseDrawer/Actions';
import RegistrationActions from 'event_mgmt/display/actions/RegistrationActions.jsx';
import WaitlistActions from 'event_mgmt/shared/actions/WaitlistActions.jsx';
import { BrowseEvent } from 'records';
import { isLoggedIn } from 'shared/utils/UserUtils.jsx';
import { smallScreen } from 'shared/utils/DOMUtils';
import { STATUS } from 'records/compoundObjects/BrowseEvent.js';
import { t } from 'shared/utils/LocaleUtils.js';
import { enabledCustomerFeatures } from 'shared/utils/CustomerUtils';
import BrowseEventsActions from '../Actions';

function FixedScheduleCard({ browseEvent, intl, hasDeadlineReached }) {
  const {
    allowWaitlist,
    dayCount,
    eventId,
    eventTitle,
    eventTypeName,
    startsAt,
    spotsRemaining,
    status,
    externalUrl,
  } = browseEvent;

  const isFull = spotsRemaining < 1 && spotsRemaining !== null;

  const isSmallScreen = smallScreen();
  const handleClick = _e => {
    _e.stopPropagation();

    if (enabledCustomerFeatures(['external_event_url']) && externalUrl) {
      window.open(externalUrl, '_blank');
      return;
    }

    if (isLoggedIn()) {
      CartActions.fetch({});
      EventDisplayActions.clientDisplayMounted({ eventId });
      AutomationTemplateDescriptionActions.list({
        event_ids: [eventId],
      });
      ClientCreditActions.list({ eventIds: eventId });
      EventActions.fetchAvailability({ id: eventId });
      RegistrationActions.list({ event_id: eventId });
    }
    PurchaseDrawerActions.openDrawer();
  };

  const handleJoinWaitList = _e => {
    _e.stopPropagation();
    EventActions.fetch(eventId);
    BrowseEventsActions.openWaitListDrawer();
    if (isLoggedIn()) {
      EventDisplayActions.clientDisplayMounted({ eventId });
      RegistrationActions.list({ event_id: eventId });
      ClientCreditActions.list({ eventIds: eventId });
      WaitlistActions.fetch(eventId);
    }
  };

  const startsAtFallback = startsAt
    ? startsAt.format('MMM D, YYYY')
    : t('.date_fallback', intl, __filenamespace);
  const dayCountBlock = (
    <Grid item xs={12} sm={4}>
      <Typography variant="drawerTitle">
        {dayCount
          ? t('.day_count', intl, __filenamespace, {
              count: dayCount,
            })
          : startsAtFallback}
      </Typography>
    </Grid>
  );
  const spotsBlock = (
    <Grid item xs>
      {spotsRemaining > 0 && spotsRemaining <= 5 && (
        <Typography variant="body3" color="error" align="center">
          {spotsRemaining === 1
            ? t('.spot', intl, __filenamespace)
            : t('.spots', intl, __filenamespace, {
                count: spotsRemaining,
              })}
        </Typography>
      )}
    </Grid>
  );

  return (
    <Grid
      container
      alignItems={isSmallScreen ? 'flex-start' : 'center'}
      xs={12}
      spacing={1}
    >
      <Grid container item xs={3} sm={2}>
        {startsAt && (
          <>
            {isSmallScreen && (
              <Grid item xs={12}>
                <Typography variant="drawerTitle">
                  {startsAt.format('ddd')}
                </Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography variant="h5">
                {startsAt.format(isSmallScreen ? 'MMM D' : 'ddd, MMM D')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={isSmallScreen ? 'drawerTitle' : 'body1'}>
                {startsAt.format('h:mm a')}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
      <Grid container item xs={6} sm={7} alignItems="center">
        <Hidden smUp>{dayCountBlock}</Hidden>
        <Grid container item xs={12} sm={8}>
          <Grid item xs={12}>
            <Typography variant="h5" className="browse-events__event-title">
              {eventTitle}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="drawerTitle">{eventTypeName}</Typography>
          </Grid>
        </Grid>
        <Hidden smDown>{dayCountBlock}</Hidden>
      </Grid>
      <Grid
        container
        item
        xs={3}
        sm={3}
        direction={isSmallScreen ? 'column' : 'row'}
        alignItems={isSmallScreen ? 'stretch' : 'center'}
      >
        {!isSmallScreen && spotsBlock}
        <Grid item xs={isSmallScreen ? 12 : 6}>
          {hasDeadlineReached ? (
            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
              {t('.registration_closed', intl, __filenamespace)}
            </p>
          ) : (
            <Button
              type="secondary"
              fullWidth
              disabled={(isFull && !allowWaitlist) || status !== STATUS.active}
              onClick={
                isFull && allowWaitlist ? handleJoinWaitList : handleClick
              }
              classes={{
                root: 'browse-events__button',
              }}
            >
              {isFull
                ? t(
                    `.${allowWaitlist ? 'waitlist_btn' : 'full_btn'}`,
                    intl,
                    __filenamespace
                  )
                : t('.btn', intl, __filenamespace)}
            </Button>
          )}
        </Grid>
        {isSmallScreen && spotsBlock}
      </Grid>
    </Grid>
  );
}

FixedScheduleCard.propTypes = {
  browseEvent: PropTypes.instanceOf(BrowseEvent).isRequired,
  intl: PropTypes.object.isRequired,
};

export default FixedScheduleCard;
