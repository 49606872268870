import * as React from 'react';
import { injectIntl } from 'react-intl';
import { v4 } from 'uuid';
import { Confirmation } from '@upperhand/playmaker';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import CheckoutStepper from 'shared/components/checkout/CheckoutStepper.jsx';
import Header from 'point_of_sale/components/Header.jsx';

import { t } from 'shared/utils/LocaleUtils.js';
import { sumInt } from 'shared/utils/ImmutableUtils';

import POSCartActions from 'point_of_sale/actions/POSCartActions.jsx';
import POSCheckoutActions from 'point_of_sale/actions/POSCheckoutActions.jsx';
import POSRegistrationFormActions from 'point_of_sale/actions/POSRegistrationFormActions.jsx';
import { PaymentActions } from 'containers/payments';

import './styles.scss';

const styles = {
  closeButton: {
    height: 50,
  },
  closeButtonText: {
    fontSize: 16,
  },
  contentContainer: {
    padding: 20,
  },
  formWarning: {
    padding: 20,
    margin: '-20px -20px 15px -20px',
  },
  summaryItemDivider: {
    marginLeft: -20,
    marginRight: -20,
  },
  checkoutWrapper: {
    position: 'relative',
    height: '100%',
  },
  backdrop: {
    zIndex: 1301,
    position: 'absolute',
    width: '100%',
    height: '100%',
    color: 'var(--color-white)',
  },
};

const onItemRemoved = orderItem => POSCartActions.itemRemoved(orderItem.id);

const reviewBackClicked = () => POSCheckoutActions.reviewBackClicked();
const returnToReviewClicked = () => POSCheckoutActions.returnToReviewClicked();
const continueToPaymentClicked = () => {
  PaymentActions.reset();
  POSCheckoutActions.continueToPaymentClicked();
};
const closeButtonClicked = e => {
  PaymentActions.reset();
  POSCheckoutActions.closeButtonClicked();
  e.preventDefault();
};

const Checkout = injectIntl(
  ({
    intl,
    membershipDiscountStore,
    posStore,
    posAutomationsStore,
    posCartStore,
    posCheckoutStore,
    posMembershipRegistrationStore,
    posRegistrationFormStore,
    paymentStore,
  }) => {
    const allAdjustments = posCartStore.cart.order_items.flatMap(
      i => i.applied_adjustments
    );
    const { isBooking } = posCartStore;

    const appliedAccountCredits = allAdjustments
      .filter(a => a.isAccountCredit())
      .reduce(sumInt('amount'), 0);

    return (
      <Box sx={styles.checkoutWrapper} className="checkout-wrapper">
        {isBooking && (
          <Backdrop open={isBooking} sx={styles.backdrop}>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
            >
              <CircularProgress sx={{ color: 'var(--color-white)' }} />
              <Typography variant="h6" align="center">
                Booking
              </Typography>
            </Box>
          </Backdrop>
        )}
        <Header
          selectedClient={posStore.selectedClient}
          onClickBack={closeButtonClicked}
          processingPayment={
            paymentStore.processingPayment || paymentStore.processingFields
          }
        />
        <div style={styles.contentContainer}>
          <CheckoutStepper
            appliedAccountCredits={appliedAccountCredits}
            automations={posAutomationsStore.descriptions}
            cart={posCartStore.cart}
            posMembershipRegistrationStore={posMembershipRegistrationStore}
            couponIsLoading={posCartStore.isLoadingCoupon}
            savingNote={posCartStore.savingNote}
            dividerStyle={styles.summaryItemDivider}
            formWarningStyle={styles.formWarning}
            membershipDiscountStore={membershipDiscountStore}
            onCouponApply={POSCartActions.couponApplied}
            onOrderNoteApply={POSCartActions.orderNoteApplied}
            onItemEdit={POSCartActions.itemEditSelected}
            onItemRemoved={onItemRemoved}
            onItemView={POSCartActions.itemViewSelected}
            onPurchaseSuccess={POSCheckoutActions.switchToSummary.defer}
            onReviewBack={reviewBackClicked}
            onReturnToReview={returnToReviewClicked}
            onReviewContinue={continueToPaymentClicked}
            onTaxExemptToggle={POSCartActions.taxExemptToggled}
            onFeeExemptToggle={POSCartActions.feeExemptToggled}
            onTaxIdChange={POSCartActions.taxIdUpdated}
            paymentMethod={paymentStore.paymentMethod}
            processingPayment={
              paymentStore.processingPayment || paymentStore.processingFields
            }
            profiles={posStore.allProfiles}
            purchaserEmail={posStore.selectedClient.email}
            purchaserId={posStore.selectedClient.id}
            purchasingUserId={posStore.selectedClient.user_id}
            hasAccess={posStore.selectedClient.access_to_vault}
            registrationFormActions={POSRegistrationFormActions}
            registrationFormDrawerDirection="rightToLeft"
            registrationFormStore={posRegistrationFormStore}
            showReviewBackButton
            showScheduleButtons={false}
            showTaxExemptToggle
            showServiceFeeExemptToggle
            step={posCheckoutStore.step}
            summaryActionButton={
              <Button
                fullWidth
                variant="contained"
                style={{ ...styles.closeButton, ...styles.closeButtonText }}
                onClick={closeButtonClicked}
              >
                {t('.close', intl, __filenamespace)}
              </Button>
            }
          />
        </div>
        <Confirmation
          title={t('.embed_warning_modal_title', intl, __filenamespace)}
          level="warning"
          open={posCheckoutStore.embedErrors.length > 0}
          hasCheckbox={false}
          confirmButtonLabel={t(
            '.embed_warning_modal_confirm_label',
            intl,
            __filenamespace
          )}
          classes={{
            root: 'embed-warning-modal',
            actions: 'embed-warning-modal__actions',
          }}
          onConfirm={POSCheckoutActions.closeEmbedWarning}
          onCancel={POSCheckoutActions.closeEmbedWarning}
        >
          <>
            {posCheckoutStore.embedErrors.map(error => (
              <p key={v4()}>{error}</p>
            ))}
          </>
        </Confirmation>
      </Box>
    );
  }
);

export default Checkout;
