import { List, Map, Record } from 'immutable';
import moment from 'moment-timezone';
import AvailabilitySchedule from 'shared/records/AvailabilitySchedule.jsx';
import Location from 'shared/records/Location.jsx';
import DateOfBirth from 'records/DateOfBirth.jsx';

const RESOURCE_USAGE_ALL = 'use_all';
const RESOURCE_USAGE_ONE = 'use_one';

const STAFF_USAGE_ALL = 'use_all';
const STAFF_USAGE_ONE = 'use_one';

const SCHEDULING_UNITS = {
  MIN: 'min',
  HOUR: 'hour',
  DAY: 'day',
};

// Please merge with src/event_mgmt/shared/records/Schedule.js
class Schedule extends Record({
  id: null,
  location: new Location(),
  availability_schedule: new AvailabilitySchedule({ indefinite: false }),
  duration: null,
  duration_units: 'minutes',
  scheduling_deadline: null,
  cancellation_deadline: null,
  scheduling_timeframe: null,
  gender_restriction: null,
  registration_deadline: null,
  min_age: '',
  max_age: '',
  max_size: 999,
  resource_usage_mode: RESOURCE_USAGE_ALL,
  staff_usage_mode: STAFF_USAGE_ALL,
  resource_preferring_staff: Map(),
  start_time_interval: 15 * 60,
  customer_user_ids: List(),
  schedule_resources: List(),
  schedule_staff: List(),
  future_registration_count: null,
  total_registration_count: null,
  label: '',
  team_schedule_type: '',
  date_of_birth: new DateOfBirth(),
  waitlist_mode: 'none',
  waitlist_invite_expiration: 0,
}) {
  static get RESOURCE_USAGE_ALL() {
    return RESOURCE_USAGE_ALL;
  }

  static get RESOURCE_USAGE_ONE() {
    return RESOURCE_USAGE_ONE;
  }

  static get STAFF_USAGE_ALL() {
    return STAFF_USAGE_ALL;
  }

  static get STAFF_USAGE_ONE() {
    return STAFF_USAGE_ONE;
  }

  durationInMinutes() {
    if (!this.duration) return null;

    if (this.duration_units === 'hours') {
      return this.duration * 60;
    }
    return this.duration / 60;
  }

  toServer() {
    const data = this.toJS();
    data.location_id = data.location.id;
    data.availability_schedule = this.availability_schedule.toServer();
    data.schedule_resources = data.schedule_resources.map(
      (resource, index) => ({
        ...resource,
        position: resource.position ? resource.position : index,
      })
    );
    data.schedule_staff = data.schedule_staff.map((staff, index) => ({
      ...staff,
      position: staff.position ? staff.position : index,
    }));
    delete data.location;

    return data;
  }

  getDeadlineUnit({
    deadlineField = 'scheduling_deadline',
    excludeDays = false,
  }) {
    if (this[deadlineField] % 86400 === 0 && !excludeDays) {
      return SCHEDULING_UNITS.DAY;
    }
    if (this[deadlineField] % 3600 === 0) {
      return SCHEDULING_UNITS.HOUR;
    }
    return SCHEDULING_UNITS.MIN;
  }

  hasDeadlineReached() {
    return (
      !!this.registration_deadline &&
      moment().isAfter(this.registration_deadline, 'day')
    );
  }

  getDeadlineValue({
    unit,
    deadlineField = 'scheduling_deadline',
    fromSeconds = false,
  }) {
    if (unit === SCHEDULING_UNITS.MIN && this[deadlineField]) {
      return fromSeconds ? this[deadlineField] / 60 : this[deadlineField] * 60;
    }

    if (unit === SCHEDULING_UNITS.HOUR && this[deadlineField]) {
      return fromSeconds
        ? this[deadlineField] / 3600
        : this[deadlineField] * 3600;
    }

    if (unit === SCHEDULING_UNITS.DAY && this[deadlineField]) {
      return fromSeconds
        ? this[deadlineField] / 86400
        : this[deadlineField] * 86400;
    }
    return this[deadlineField];
  }

  hasAgeRestriction() {
    return this.min_age || this.max_age;
  }
}

export default Schedule;
