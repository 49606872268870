import React from 'react';
import { injectIntl } from 'react-intl';
import { Drawer } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { t } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils.js';

import { ClientDataStore } from 'dataStores';

import PaymentsMethods from '../Payments/PaymentMethods.jsx';
import ClientInfo from './ClientInfo.jsx';

import ClientProfileDrawerActions from './ClientProfileDrawer/Actions';
import ClientProfileDrawerStore from './ClientProfileDrawer/Store';

function PaymentsDrawer({
  intl,
  clientDataStore: { clients },
  clientProfileDrawerStore: {
    clientId,
    userId,
    accessToVault,
    showPaymentsDrawer,
    totalAccountCredit,
  },
}) {
  const client = clients.get(clientId);

  return (
    <Drawer
      classes={{ root: 'payments-drawer' }}
      open={showPaymentsDrawer}
      title={t('.title', intl, __filenamespace)}
      content={
        <>
          <ClientInfo clientId={clientId} />
          {userId && clientId && (
            <PaymentsMethods
              drawerMode
              clientId={clientId}
              userId={userId}
              accessToVault={accessToVault}
              accountCreditAutoPay={client?.get('account_credit_auto_pay')}
              disablePayLater={client?.get('disable_pay_later')}
              accountCredits={totalAccountCredit}
              intl={intl}
            />
          )}
        </>
      }
      onClose={() => ClientProfileDrawerActions.togglePaymentsDrawer({})}
    />
  );
}

export default compose(
  React.memo,
  injectIntl,
  altContainer({
    stores: {
      clientProfileDrawerStore: ClientProfileDrawerStore,
      clientDataStore: ClientDataStore,
    },
  })
)(PaymentsDrawer);
