import { List, Set } from 'immutable';
import moment from 'moment-timezone';

import {
  LocationSource,
  StaffSource,
  ScheduleSource,
  ResourceSource,
} from 'sources';
import { UpperHandStore } from 'dataStores';
import AdminPageActions from 'containers/events/admin/admin_page/actions.js';
import CancelCompleteScheduleActions from 'containers/events/admin/schedule/completeScheduleModal/Actions.js';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import ScheduleDrawerActions from 'event_mgmt/display/actions/ScheduleDrawerActions.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import EventSchedulesActions from './Actions';

export const FILTER_LOCATION_ALL = 'all';
export const FILTER_STAFF_ALL = 'all';
export const FILTER_RESOURCE_ALL = 'all';

class FutureEventSchedulesStore extends UpperHandStore {
  constructor() {
    super();

    this.scheduleIds = List();
    this.locationIds = Set();
    this.recourceIds = Set();
    this.staffIds = Set();
    this.page = 1;
    this.perPage = 25;
    this.totalCount = 0;
    this.locationFilter = '';
    this.staffFilter = '';
    this.resourceFilter = '';
    this.filtersPresent = false;
    this.isLoading = false;
    this.isLoaded = false;
    this.locationLoading = false;
    this.scheduleLoading = false;
    this.staffLoading = false;
    this.resourceLoading = false;
    this.actions = EventSchedulesActions;

    this.bindListeners({
      eventChanged: [AdminPageActions.mounted, AdminPageActions.fetchTeam],
      locationFilterUpdated: this.actions.locationFilterUpdated,
      locationListSuccess: this.actions.locationListSuccess,
      mounted: this.actions.mounted,
      pageSelected: this.actions.pageSelected,
      resourceFilterUpdated: this.actions.resourceFilterUpdated,
      resourceListSuccess: this.actions.resourceListSuccess,
      scheduleListSuccess: this.actions.scheduleListSuccess,
      scheduleListError: this.actions.scheduleListError,
      staffFilterUpdated: this.actions.staffFilterUpdated,
      staffListSuccess: this.actions.staffListSuccess,
      listSchedules: [
        CancelCompleteScheduleActions.scheduleCancelSuccess,
        CancelCompleteScheduleActions.scheduleCompleteSuccess,
        ScheduleDrawerActions.postUpdatedScheduleSuccess,
      ],
    });
  }

  checkIsLoadingData() {
    return (
      this.staffLoading ||
      this.scheduleLoading ||
      this.resourceLoading ||
      this.locationLoading
    );
  }

  eventChanged(eventId) {
    if (this.eventId !== eventId) {
      this.isLoaded = false;
    }
    this.eventId = eventId;
  }

  mounted({ eventId }) {
    this.eventId = eventId;
    this.locationFilter = FILTER_LOCATION_ALL;
    this.staffFilter = FILTER_STAFF_ALL;
    this.resourceFilter = FILTER_RESOURCE_ALL;
    this.listLocations();
    this.listSchedules();
    this.listStaff();
    if (!currentUser().isClient() && currentCustomer().resources_enabled) {
      this.listResources();
    }
  }

  listSchedules() {
    const params = {
      page: this.page,
      per_page: this.perPage,
      event_ids: [this.eventId],
      fields: [
        'schedule_resources',
        'schedule_staff',
        'future_registration_count',
        'total_registration_count',
      ],
      end_date_min: moment().toISOString(),
    };

    if (this.locationFilter !== FILTER_LOCATION_ALL) {
      params.location_ids = [this.locationFilter];
    }

    if (this.staffFilter !== FILTER_STAFF_ALL) {
      params.staff_ids = [this.staffFilter];
    }

    if (this.resourceFilter !== FILTER_RESOURCE_ALL) {
      params.resource_ids = [this.resourceFilter];
    }

    this.scheduleLoading = true;
    this.isLoading = true;
    ScheduleSource.list({
      params,
      success: this.actions.scheduleListSuccess,
      error: this.actions.scheduleListError,
    });
  }

  scheduleListSuccess({ schedules, _page, _perPage, totalCount }) {
    this.scheduleIds = schedules.map(s => s.id);
    this.totalCount = totalCount;
    this.filtersPresent = this.staffFilter !== FILTER_STAFF_ALL;
    this.scheduleLoading = false;
    this.staffLoading = true;
    this.isLoaded = true;
    const staffIds = schedules
      .map(s => s.staff_ids)
      .flatten()
      .toSet()
      .toJS();

    StaffSource.list({
      params: {
        ids: staffIds,
        per_page: 100,
      },
      success: this.actions.staffListSuccess,
      error: this.actions.staffListError,
    });
  }

  listLocations(page = 1) {
    this.locationLoading = true;
    this.isLoading = true;

    LocationSource.list({
      params: {
        page,
        per_page: 100,
      },
      success: this.actions.locationListSuccess,
    });
  }

  listResources(page = 1) {
    this.resourceLoading = true;
    this.isLoading = true;
    // We need to list all Resource for the event to support the filter dropdowns.
    ResourceSource.list({
      params: {
        page,
        per_page: 100,
      },
      success: this.actions.resourceListSuccess,
    });
  }

  listStaff(page = 1) {
    this.staffLoading = true;
    this.isLoading = true;
    // We need to list all staff for the event to support the filter dropdowns.
    StaffSource.list({
      params: {
        event_ids: [this.eventId],
        page,
        per_page: 100,
      },
      success: this.actions.staffListSuccess,
    });
  }

  staffListSuccess({ staff, page, totalCount }) {
    this.staffIds = this.staffIds.union(
      staff
        .map(s => s.id)
        .flatten()
        .toSet()
    );
    if (totalCount > this.staffIds.count()) {
      this.listStaff(page + 1);
      return;
    }
    this.staffLoading = false;
    this.isLoading = this.checkIsLoadingData();
  }

  locationListSuccess({ locations, page, totalCount }) {
    this.locationIds = this.locationIds.union(locations.map(l => l.id).toSet());
    if (totalCount > this.locationIds.count()) {
      this.listLocations(page + 1);
      return;
    }
    this.locationLoading = false;
    this.isLoading = this.checkIsLoadingData();
  }

  resourceListSuccess({ resources, page, totalCount }) {
    this.recourceIds = this.recourceIds.union(resources.map(r => r.id).toSet());
    if (totalCount > this.recourceIds.count()) {
      this.listResources(page + 1);
      return;
    }
    this.resourceLoading = false;
    this.isLoading = this.checkIsLoadingData();
  }

  scheduleListError() {
    this.scheduleLoading = false;
    this.isLoading = this.checkIsLoadingData();
  }

  pageSelected([page, perPage]) {
    this.page = page;
    this.perPage = perPage;

    this.listSchedules();
  }

  filterUpdated(filterName, newFilter) {
    if (this[filterName] !== newFilter) {
      this[filterName] = newFilter;
      this.page = 1;
      this.listSchedules();
    }
  }

  locationFilterUpdated(newLocationFilter) {
    this.filterUpdated('locationFilter', newLocationFilter);
  }

  resourceFilterUpdated(newResourceFilter) {
    this.filterUpdated('resourceFilter', newResourceFilter);
  }

  staffFilterUpdated(newStaffFilter) {
    this.filterUpdated('staffFilter', newStaffFilter);
  }
}

export default alt.createStore(
  FutureEventSchedulesStore,
  'FutureEventSchedulesStore'
);
