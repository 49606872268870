import { List } from 'immutable';
import Waitlist from 'shared/records/Waitlist.js';
import WaitlistEntry from 'shared/records/WaitlistEntry';
import Registration from 'shared/records/Registration';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onSuccess, onError } from 'sources/handlers';
import DataStoreActions from './WaitlistActions';

// Old waitlist source BEGIN
const parserTo = { type: Waitlist };
const parser = new Parser(parserTo);

export const fetch = ({ id, success, error }) => {
  uhApiClient.get({
    url: `events/${id}/waitlist`,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error,
  });
};

export const invite = ({
  id,
  customer_user_ids: customerUserIds,
  success,
  error,
}) => {
  uhApiClient.patch({
    url: `events/${id}/waitlist/invite`,
    data: JSON.stringify({
      attributes: { customer_user_ids: customerUserIds },
    }),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.fetchSuccess, success])
    ),
    error,
    requireToken: false,
  });
};
// Old waitlist source END

// New waitlist source BEGIN
const newParserTo = {
  type: WaitlistEntry,
  paginationKey: 'event_waitlist_entries',
};
const newParser = new Parser(newParserTo);
const parserToRegistration = { type: Registration };
const registrationParser = new Parser(parserToRegistration);

export const createEntry = ({ params, success, error }) => {
  uhApiClient.post({
    url: 'event_waitlist_entries',
    data: JSON.stringify(params),
    success: onSuccess(
      data => newParser.parseSingle(data),
      List([DataStoreActions.createWaitlistEntrySuccess, success])
    ),
    error: onError(List([DataStoreActions.createWaitlistEntryError, error])),
  });
};

export const listEntries = ({ params, success, error }) => {
  uhApiClient.get({
    url: 'event_waitlist_entries',
    data: params,
    success: onSuccess(
      data => newParser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error: onError(List([DataStoreActions.listError, error])),
  });
};

export const removeEntry = ({ id, success, error }) => {
  uhApiClient.delete({
    url: `event_waitlist_entries/${id}`,
    success: onSuccess(
      data => newParser.parseNone(data),
      List([DataStoreActions.deleteSuccess, success])
    ),
    error: onError(List([DataStoreActions.deleteError, error])),
  });
};

export const addToEvent = ({ params, success, error }) => {
  uhApiClient.post({
    url: 'event_waitlist_entries/register',
    data: JSON.stringify(params),
    success: onSuccess(
      data => registrationParser.parseSingle(data),
      List([DataStoreActions.addToEventSuccess, success])
    ),
    error: onError(List([DataStoreActions.addToEventError, error])),
  });
};

// New waitlist source END
