import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import { useIntl } from 'react-intl';
import {
  Typography,
  Button,
  Icon,
  Dropdown,
  DateTimePicker,
} from '@upperhand/playmaker';

import StaffAutocomplete from 'calendar/components/staff_unavailability/StaffAutocomplete.jsx';

import { t } from 'shared/utils/LocaleUtils';

import StaffUnavailabilityActions from 'calendar/actions/StaffUnavailabilityActions';

import './styles.scss';

const blockReasons = [
  'appointment',
  'bereavement',
  'busy',
  'coaching_commitment',
  'emergency',
  'sick',
  'vacation',
  'other',
];

const moment = extendMoment(Moment);

function StaffUnavailability({
  actions = StaffUnavailabilityActions,
  staffUnavailabilityStore = {},
  staffStore = {},
}) {
  const intl = useIntl();
  const {
    dateRange,
    staffIds,
    reason,
    selectedStaffId,
    isCreating,
    timeSlots,
  } = staffUnavailabilityStore;
  const { allStaffMap } = staffStore;
  const times = timeSlots.map(time => ({
    label: time,
    value: time,
  }));
  const timeRangeNotValid = dateRange?.start.isSameOrAfter(dateRange?.end);

  const handleFieldChange = (field, value) => {
    actions.handleChange({ field, value });
  };

  return (
    <div className="staff-unavailability">
      <div className="staff-unavailability__header">
        <Typography variant="h6">
          {t('.add_staff_unavailability', intl, __filenamespace)}
        </Typography>
        <Button
          rounded
          classes={{ root: 'staff-unavailability__close-btn' }}
          type="tertiary"
          onClick={actions.cancelBlockTime}
        >
          <Icon name="close" />
        </Button>
      </div>
      <div className="staff-unavailability__content">
        <div className="staff-unavailability__time-container">
          <div className="staff-unavailability__time">
            <Dropdown
              fullWidth
              label={t('.start_time', intl, __filenamespace)}
              items={times}
              value={dateRange?.start?.format('h:mma')}
              onChange={e => {
                actions.updateDateRange(
                  moment.range(
                    dateRange.start.clone().set({
                      hour: moment(e.target.value, 'h:mma').hour(),
                      minute: moment(e.target.value, 'h:mma').minute(),
                      second: 0,
                      millisecond: 0,
                    }),
                    dateRange.end
                  )
                );
              }}
            />
            <Dropdown
              fullWidth
              label={t('.end_time', intl, __filenamespace)}
              items={times}
              value={dateRange?.end?.format('h:mma')}
              onChange={e => {
                actions.updateDateRange(
                  moment.range(
                    dateRange.start,
                    dateRange.end.clone().set({
                      hour: moment(e.target.value, 'h:mma').hour(),
                      minute: moment(e.target.value, 'h:mma').minute(),
                      second: 0,
                      millisecond: 0,
                    })
                  )
                );
              }}
            />
          </div>
          <div className="staff-unavailability__date">
            <DateTimePicker
              fullWidth
              disablePast
              showClearIcon={false}
              label={t('.date', intl, __filenamespace)}
              type="date"
              value={dateRange?.start}
              onChange={date => {
                actions.updateDateRange(
                  moment.range(
                    dateRange.start.clone().set({
                      year: date.year(),
                      month: date.month(),
                      date: date.date(),
                    }),
                    dateRange.end.clone().set({
                      year: date.year(),
                      month: date.month(),
                      date: date.date(),
                    })
                  )
                );
              }}
            />
          </div>
        </div>
        {timeRangeNotValid && (
          <Typography variant="error" className="staff-unavailability__error">
            {t('.time_range_error', intl, __filenamespace)}
          </Typography>
        )}
        <div className="staff-unavailability__field">
          <Typography variant="fieldLabel">
            {t('.staff', intl, __filenamespace)}
          </Typography>
          <StaffAutocomplete
            selectedStaffId={selectedStaffId}
            staffItems={staffIds
              .map(id => ({
                label: allStaffMap.get(id)?.name(),
                value: id,
              }))
              .toArray()}
            onChange={value => handleFieldChange('selectedStaffId', value)}
          />
        </div>
        <div className="staff-unavailability__field">
          <Typography variant="fieldLabel">
            {t('.reason', intl, __filenamespace)}
          </Typography>
          <Dropdown
            fullWidth
            classes={{
              root: 'staff-unavailability__dropdown',
              selectedItem: 'staff-unavailability__dropdown-selected',
            }}
            name="reason"
            value={reason}
            items={blockReasons.map(r => ({
              label: r.replace('_', ' '),
              value: r,
            }))}
            onChange={e => handleFieldChange('reason', e.target.value)}
          />
        </div>
      </div>
      <div className="staff-unavailability__footer">
        <Button
          type="tertiary"
          onClick={actions.cancelBlockTime}
          classes={{ root: 'staff-unavailability__cancel-btn' }}
        >
          {t('.cancel', intl, __filenamespace)}
        </Button>
        <Button
          disabled={
            !selectedStaffId || !reason || isCreating || timeRangeNotValid
          }
          type="primary"
          onClick={actions.create}
          classes={{ root: 'staff-unavailability__block-btn' }}
        >
          {t('.add_to_schedule', intl, __filenamespace)}
        </Button>
      </div>
    </div>
  );
}

StaffUnavailability.propTypes = {
  actions: PropTypes.object,
  staffStore: PropTypes.object,
  staffUnavailabilityStore: PropTypes.object,
};

export default memo(StaffUnavailability);
