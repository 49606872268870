import { List, OrderedSet } from 'immutable';
import debounce from 'lodash.debounce';
import moment from 'moment-timezone';
import ScheduleDrawerActions from 'event_mgmt/display/actions/ScheduleDrawerActions.js';

import {
  StaffSource,
  SessionSource,
  ScheduleSource,
  ResourceSource,
} from 'sources';
import { UpperHandStore } from 'dataStores';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import AdminPageActions from 'containers/events/admin/admin_page/actions.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import EventSessionsActions from './Actions';
import { FILTER_SCHEDULE_NAME_ALL } from '../completeScheduleModal/Store';

export const FILTER_STAFF_ALL = 'all';
export const FILTER_RESOURCE_ALL = 'all';

class FutureEventSessionsStore extends UpperHandStore {
  constructor() {
    super();

    this.sessionIds = List();
    this.staffIds = OrderedSet();
    this.page = 1;
    this.perPage = 25;
    this.totalCount = 0;
    this.search = '';
    this.staffFilter = '';
    this.resourceFilter = '';
    this.filtersPresent = false;
    this.loading = false;
    this.isLoaded = false;
    this.sessionLoading = false;
    this.scheduleLoading = false;
    this.staffLoading = false;
    this.resourceLoading = false;
    this.sessionSummaryDrawer = {
      isOpen: false,
      sessionId: null,
      defaultSelectedTab: 'details',
    };
    this.scheduleNameFilter = '';
    this.scheduleNames = [];

    this.actions = EventSessionsActions;

    this.bindListeners({
      eventChanged: [AdminPageActions.mounted, AdminPageActions.fetchTeam],
      mounted: this.actions.mounted,
      sessionListSuccess: this.actions.sessionListSuccess,
      sessionListError: this.actions.sessionListError,
      pageSelected: this.actions.pageSelected,
      searchUpdated: this.actions.searchUpdated,
      staffFilterUpdated: this.actions.staffFilterUpdated,
      resourceFilterUpdated: this.actions.resourceFilterUpdated,
      staffListSuccess: this.actions.staffListSuccess,
      staffListError: this.actions.staffListError,
      scheduleListSuccess: this.actions.scheduleListSuccess,
      scheduleListError: this.actions.scheduleListError,
      resourceListSuccess: this.actions.resourceListSuccess,
      resourceListError: this.actions.resourceListError,
      openSessionSummaryDrawer: this.actions.openSessionSummaryDrawer,
      closeSessionSummaryDrawer: this.actions.closeSessionSummaryDrawer,
      listSessions: ScheduleDrawerActions.createScheduleSuccess,
      scheduleNamesSuccess: this.actions.scheduleNamesSuccess,
      scheduleNamesError: this.actions.scheduleNamesError,
      scheduleNameFilterUpdated: this.actions.scheduleNameFilterUpdated,
      viewNextSession: this.actions.viewNextSession,
      viewPreviousSession: this.actions.viewPreviousSession,
    });

    this.debouncedListSessions = debounce(() => {
      this.listSessions();
      this.emitChange();
    }, 600);
  }

  isLoading() {
    return (
      this.sessionLoading ||
      this.staffLoading ||
      this.scheduleLoading ||
      this.resourceLoading
    );
  }

  eventChanged(eventId) {
    if (this.eventId !== eventId) {
      this.isLoaded = false;
    }
    this.eventId = eventId;
  }

  mounted({ eventId, isTeamEvent }) {
    const isClient = currentUser().isClient();
    this.eventId = eventId;
    this.staffFilter = FILTER_STAFF_ALL;
    this.resourceFilter = FILTER_RESOURCE_ALL;
    this.scheduleNameFilter = FILTER_SCHEDULE_NAME_ALL;
    this.listSessions();
    this.listStaff();
    if (isTeamEvent && !isClient) {
      this.listScheduleNames();
    }
    if (currentCustomer().resources_enabled && !isClient) {
      this.listResources();
    }
  }

  listSessions() {
    this.sessionLoading = true;
    this.loading = true;

    const params = {
      page: this.page,
      per_page: this.perPage,
      event_ids: [this.eventId],
      start_time: moment().toISOString(),
      fields: ['note', 'client_note'],
    };

    if (this.search) {
      params.search = this.search;
    }

    if (this.staffFilter !== FILTER_STAFF_ALL) {
      params.staff_ids = [this.staffFilter];
    }

    if (this.resourceFilter !== FILTER_RESOURCE_ALL) {
      params.resource_ids = [this.resourceFilter];
    }

    if (this.scheduleNameFilter !== FILTER_SCHEDULE_NAME_ALL) {
      params.schedule_ids = [this.scheduleNameFilter];
    }

    SessionSource.list({
      params,
      success: this.actions.sessionListSuccess,
      error: this.actions.sessionListError,
    });
  }

  sessionListSuccess({ sessions, _page, _perPage, totalCount }) {
    this.sessionIds = sessions.map(a => a.id);
    this.totalCount = totalCount;
    this.filtersPresent = this.staffFilter !== FILTER_STAFF_ALL;

    const staffIds = sessions
      .map(s => s.staff_ids)
      .flatten()
      .toSet()
      .toJS();

    const scheduleIds = sessions
      .map(s => s.schedule_id)
      .toSet()
      .toJS();

    this.sessionLoading = false;
    this.isLoaded = true;
    this.loading = false;

    if (staffIds.length > 0) {
      this.staffLoading = true;
      this.loading = true;

      StaffSource.list({
        params: {
          ids: staffIds,
          per_page: 50,
        },
        success: {
          action: this.actions.staffListSuccess,
          args: [false],
        },
        error: this.actions.staffListError,
      });
    }

    if (scheduleIds.length > 0) {
      this.scheduleLoading = true;
      this.loading = true;

      ScheduleSource.list({
        params: {
          ids: scheduleIds,
          per_page: 50,
          fields: [
            'schedule_resources',
            'schedule_staff',
            'future_registration_count',
            'total_registration_count',
          ],
        },
        success: this.actions.scheduleListSuccess,
        error: this.actions.scheduleListError,
      });
    }
  }

  listResources() {
    this.resourceLoading = true;
    // We need to list all Resource for the event to support the filter dropdowns.
    ResourceSource.list({
      params: {
        per_page: 50,
      },
      success: this.actions.resourceListSuccess,
      error: this.actions.resourceListError,
    });
  }

  listStaff() {
    this.staffLoading = true;

    // We need to list all staff for the event to support the filter dropdowns.
    StaffSource.list({
      params: {
        event_ids: [this.eventId],
        per_page: 50,
      },
      success: {
        action: this.actions.staffListSuccess,
        args: [true],
      },
      error: this.actions.staffListError,
    });
  }

  staffListSuccess([{ staff }, all]) {
    if (all) {
      this.staffIds = staff
        .map(s => s.id)
        .flatten()
        .toOrderedSet();
    }

    this.staffLoading = false;
    this.loading = this.isLoading();
  }

  scheduleListSuccess() {
    this.scheduleLoading = false;
    this.loading = this.isLoading();
  }

  resourceListSuccess() {
    this.resourceLoading = false;
    this.loading = this.isLoading();
  }

  sessionListError() {
    this.sessionLoading = false;
    this.loading = this.isLoading();
  }

  staffListError() {
    this.staffLoading = false;
    this.loading = this.isLoading();
  }

  scheduleListError() {
    this.scheduleLoading = false;
    this.loading = this.isLoading();
  }

  resourceListError() {
    this.resourceLoading = false;
    this.loading = this.isLoading();
  }

  pageSelected([page, perPage]) {
    this.page = page;
    this.perPage = perPage;

    this.listSessions();
  }

  searchUpdated(newSearch) {
    this.search = newSearch;
    this.page = 1;

    this.debouncedListSessions();
  }

  staffFilterUpdated(newStaffFilter) {
    if (this.staffFilter !== newStaffFilter) {
      this.staffFilter = newStaffFilter;
      this.page = 1;
      this.listSessions();
    }
  }

  scheduleNameFilterUpdated(newNameFilter) {
    if (this.scheduleNameFilter !== newNameFilter) {
      this.scheduleNameFilter = newNameFilter;
      this.page = 1;
      this.listSessions();
    }
  }

  resourceFilterUpdated(newResourceFilter) {
    if (this.resourceLoading !== newResourceFilter) {
      this.resourceFilter = newResourceFilter;
      this.page = 1;
      this.listSessions();
    }
  }

  openSessionSummaryDrawer({
    sessionId,
    eventId,
    defaultSelectedTab = 'details',
  }) {
    this.sessionSummaryDrawer = {
      isOpen: true,
      sessionId,
      eventId,
      defaultSelectedTab,
    };
  }

  closeSessionSummaryDrawer() {
    this.sessionSummaryDrawer = {
      isOpen: false,
      sessionId: null,
      eventId: null,
      defaultSelectedTab: 'details',
    };
  }

  listScheduleNames() {
    ScheduleSource.listScheduleName({
      params: {
        fields: [
          'schedule_resources',
          'schedule_staff',
          'future_registration_count',
          'total_registration_count',
        ],
        end_date_min: moment().toISOString(),
        team_id: this.eventId,
      },
      success: this.actions.scheduleNamesSuccess,
      error: this.actions.scheduleNamesError,
    });
  }

  scheduleNamesSuccess({ schedule_labels: scheduleNames }) {
    this.scheduleNames = scheduleNames.map(({ label, id }) => ({
      label: `Schedule Name: ${label}`,
      value: id,
    }));
  }

  scheduleNamesError(...args) {
    this.notifyError('Error while fetching schedule names.', args);
  }

  viewNextSession() {
    const index = this.sessionIds.indexOf(this.sessionSummaryDrawer.sessionId);
    const nextSessionId = this.sessionIds.get(index + 1);

    if (nextSessionId) {
      this.openSessionSummaryDrawer({
        sessionId: nextSessionId,
        eventId: this.eventId,
      });
    }
  }

  viewPreviousSession() {
    const index = this.sessionIds.indexOf(this.sessionSummaryDrawer.sessionId);
    const previousSessionId = this.sessionIds.get(index - 1);

    if (previousSessionId) {
      this.openSessionSummaryDrawer({
        sessionId: previousSessionId,
        eventId: this.eventId,
      });
    }
  }
}

export default alt.createStore(
  FutureEventSessionsStore,
  'FutureEventSessionsStore'
);
