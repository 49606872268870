import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Set, OrderedSet } from 'immutable';
import {
  Typography,
  Grid,
  Checkbox,
  Button,
  Spinner,
  Expander,
} from '@upperhand/playmaker';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import CreditPassCredits from 'credit_passes/components/_CreditPassCredits.jsx';
import BookingSuccess from 'containers/sessionScheduling/components/BookingSuccess.jsx';
import NoPasses from 'containers/sessionScheduling/components/NoPasses.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import { CREDIT_PASS_EXPIRATION_STRATEGIES } from 'shared/records/CreditPass.js';

import {
  CreditPassDataStore,
  EventDataStore,
  EventTypeDataStore,
} from 'dataStores';

function CreditPasses({
  isLoading = false,
  eventId = null,
  eventTypeId = null,
  sessionIds = Set(),
  creditPassesIds = OrderedSet(),
  selectedCreditPassIds = OrderedSet(),
  clientId = null,
  hasMemberships = false,
  onMount = () => {},
  onPassSelect = () => {},
  onAddToCart = () => {},
  onViewMemberships = () => {},
  eventDataStore = {},
  eventTypeDataStore = {},
  creditPassDataStore = {},
}) {
  const intl = useIntl();

  useEffect(() => {
    onMount();
  }, [onMount]);

  const { creditPasses } = creditPassDataStore;
  const { events } = eventDataStore;
  const { eventTypes } = eventTypeDataStore;

  const checkAvailability = credit => {
    const allEvents = credit.get('all_events', false);
    const eventIds = credit.get('event_ids');
    const eventTypeIds = credit.get('event_type_ids');
    const isForEvent = eventIds.has(eventId) || eventTypeIds.has(eventTypeId);
    const enabledForEvent = allEvents || isForEvent;

    return enabledForEvent;
  };

  const passesList = creditPassesIds
    .map(c => creditPasses.get(c))
    .filter(cp => {
      const creditPassCredits = cp.get('credit_pass_credits');
      const creditPassCredit = creditPassCredits.find(c => {
        const enabledForEvent = checkAvailability(c);
        const unlimited = c.get('unlimited', false);
        const quantity = c.get('quantity');

        return enabledForEvent && (unlimited || quantity >= sessionIds.size);
      });

      return Boolean(creditPassCredit);
    });

  const totalQuantity = creditPassesIds.reduce((sum, id) => {
    const cp = creditPasses.get(id);

    if (!cp) return sum;
    const creditPassCredits = cp.get('credit_pass_credits');

    const quantitySum = creditPassCredits.reduce((acc, c) => {
      const enabledForEvent = checkAvailability(c);
      const quantity = c.get('quantity', 0);

      return enabledForEvent ? acc + quantity : acc;
    }, 0);

    return sum + quantitySum;
  }, 0);

  return (
    <>
      <div className="session-booking-passes">
        {isLoading && (
          <Grid
            container
            justify="center"
            alignItems="center"
            className="session-booking-passes__loading"
          >
            <Grid item>
              <Spinner />
            </Grid>
          </Grid>
        )}
        {!isLoading && (
          <Typography>
            <BookingSuccess
              clientId={clientId}
              eventId={eventId}
              sessionIds={sessionIds}
              action={undefined}
            />
            <Typography variant="body1">
              {t('.passes_available', intl, __filenamespace, {
                count: passesList.size,
              })}
            </Typography>
            <Typography
              variant="body2"
              className="session-booking-passes__description"
            >
              {t('.description', intl, __filenamespace)}
            </Typography>
          </Typography>
        )}
        {!isLoading && creditPassesIds.size === 0 && (
          <NoPasses
            hasMemberships={hasMemberships}
            onViewMemberships={onViewMemberships}
          />
        )}
        {!isLoading && passesList.size === 0 && creditPassesIds.size > 0 && (
          <NoPasses noAvailable totalCredits={totalQuantity} />
        )}
        {!isLoading && passesList.size > 0 && (
          <div className="session-booking-passes__passes">
            {passesList.map(creditPass => (
              <Grid
                container
                alignItems="center"
                justify="space-between"
                key={creditPass.id}
                className="session-booking-passes__pass"
              >
                <Grid item xs={10}>
                  <Typography
                    variant="fieldLabel"
                    className="session-booking-passes__pass-name"
                  >
                    {creditPass.name}
                  </Typography>
                </Grid>
                <Grid item>
                  <Checkbox
                    classes={{
                      root: 'session-booking-passes__pass-checkbox',
                    }}
                    checked={selectedCreditPassIds.has(creditPass.id)}
                    onChange={() => onPassSelect(creditPass.id)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    <FormattedCurrency value={creditPass.price} fromCents />
                    {t('.total_credits', intl, __filenamespace, {
                      total: creditPass.totalCredits(),
                    })}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {creditPass.get('expiration_strategy') ===
                    CREDIT_PASS_EXPIRATION_STRATEGIES.days_from_first_use && (
                    <Typography className="session-booking-passes__expiration">
                      {t('.expiration_use', intl, __filenamespace, {
                        days: creditPass.get('expiration_days'),
                      })}
                    </Typography>
                  )}
                  {creditPass.get('expiration_strategy') ===
                    CREDIT_PASS_EXPIRATION_STRATEGIES.days_from_purchase && (
                    <Typography className="session-booking-passes__expiration">
                      {t('.expiration_purchase', intl, __filenamespace, {
                        days: creditPass.get('expiration_days'),
                      })}
                    </Typography>
                  )}
                  {creditPass.get('expiration_strategy') ===
                    CREDIT_PASS_EXPIRATION_STRATEGIES.date && (
                    <Typography className="session-booking-passes__expiration">
                      {t('.expiration_date', intl, __filenamespace, {
                        date: creditPass.get('expires_at').format('MM/DD/YY'),
                      })}
                    </Typography>
                  )}
                  {creditPass.get('expiration_strategy') === 'never' && (
                    <Typography className="session-booking-passes__expiration">
                      {t('.expiration_never', intl, __filenamespace)}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Expander
                    arrowRight
                    contentOffset={false}
                    header={
                      <Typography>
                        {t('.view_more_details', intl, __filenamespace)}
                      </Typography>
                    }
                  >
                    <CreditPassCredits
                      creditPass={creditPass}
                      events={events.toArray()}
                      eventTypes={eventTypes.toArray()}
                    />
                  </Expander>
                </Grid>
              </Grid>
            ))}
          </div>
        )}
      </div>
      <Button
        fullWidth
        disabled={isLoading || selectedCreditPassIds.size === 0}
        classes={{
          root: 'session-booking-passes__button',
          label: 'session-booking-passes__button-label',
        }}
        onClick={onAddToCart}
      >
        {t('.add_to_cart', intl, __filenamespace)}
      </Button>
    </>
  );
}

CreditPasses.propTypes = {
  isLoading: PropTypes.bool,
  eventId: PropTypes.number,
  eventTypeId: PropTypes.number,
  sessionIds: PropTypes.instanceOf(Set),
  creditPassesIds: PropTypes.instanceOf(OrderedSet),
  selectedCreditPassIds: PropTypes.instanceOf(OrderedSet),
  clientId: PropTypes.number,
  onMount: PropTypes.func,
  onPassSelect: PropTypes.func,
  onAddToCart: PropTypes.func,
  onViewMemberships: PropTypes.func,
  eventDataStore: PropTypes.object,
  eventTypeDataStore: PropTypes.object,
  creditPassDataStore: PropTypes.object,
};

export default compose(
  memo,
  altContainer({
    stores: {
      creditPassDataStore: CreditPassDataStore,
      eventDataStore: EventDataStore,
      eventTypeDataStore: EventTypeDataStore,
    },
  })
)(CreditPasses);
