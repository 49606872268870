import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

import { currentCustomer } from 'shared/utils/CustomerUtils';
import { t } from 'shared/utils/LocaleUtils';

function ProminentDisclosure({ open, onClose, onPermissionGranted }) {
  const intl = useIntl();

  const handleAccept = async () => {
    onClose();
    onPermissionGranted();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('.title', intl, __filenamespace)}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {t('.description', intl, __filenamespace, {
            customerName: currentCustomer().name,
          })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} color="error">
          {t('.deny', intl, __filenamespace)}
        </Button>
        <Button variant="contained" onClick={handleAccept} color="secondary">
          {t('.allow', intl, __filenamespace)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(ProminentDisclosure);
