import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Chip } from '@upperhand/playmaker';

const getChip = (key, filter, onRemoveFilterChips) => {
  if (!key || !filter) return null;
  if (key === 'changed' || key === 'applied') return null;

  if (Array.isArray(filter)) {
    const label = `${key.replace('_', ' ')}: ${filter.join(', ')}`;
    return (
      <Grid item key={key}>
        <Chip
          label={label}
          classes={{ root: 'class-waitlist__active-filters-chip' }}
          onDelete={() => onRemoveFilterChips(key)}
        />
      </Grid>
    );
  }

  if (typeof filter === 'object') {
    const from = filter?.from?.format('MM/DD/YYYY');
    const to = filter?.to?.format('MM/DD/YYYY');
    const period = filter?.value?.replace('_', ' ') || key.replace('_', ' ');
    const label = `${period}: ${from} ${to ? `- ${to}` : ''}`;

    if (!from) return null;

    return (
      <Grid item key={key}>
        <Chip
          label={label}
          classes={{ root: 'class-waitlist__active-filters-chip' }}
          onDelete={() => onRemoveFilterChips(key)}
        />
      </Grid>
    );
  }

  return (
    <Grid item key={key}>
      <Chip
        label={`${key.replace('_', ' ')}: ${filter}`}
        classes={{ root: 'class-waitlist__active-filters-chip' }}
        onDelete={() => onRemoveFilterChips(key)}
      />
    </Grid>
  );
};

function ActiveFilters({
  activeFilters = {},
  onRemoveFilterChips = () => null,
}) {
  return (
    <Grid container spacing={1}>
      {Object.entries(activeFilters).map(([key, value]) =>
        getChip(key, value, onRemoveFilterChips)
      )}
    </Grid>
  );
}

ActiveFilters.propTypes = {
  activeFilters: PropTypes.object,
  onRemoveFilterChips: PropTypes.func,
};

export default ActiveFilters;
