import React from 'react';

function Apple() {
  return (
    <svg
      width="15"
      height="20"
      viewBox="0 0 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2015 3.07971C9.54712 3.88657 8.50017 4.52297 7.45321 4.43205C7.32234 3.34109 7.83491 2.18193 8.43473 1.46599C9.08908 0.636398 10.2342 0.045457 11.1612 0C11.2702 1.13642 10.8449 2.25012 10.2015 3.07971Z"
        fill="black"
      />
      <path
        d="M11.1502 4.64794C9.63434 4.55702 8.33655 5.54571 7.61677 5.54571C6.88608 5.54571 5.78459 4.69339 4.58495 4.71612C3.02542 4.73885 1.57495 5.65935 0.778822 7.12534C-0.85705 10.0573 0.353495 14.3984 1.93484 16.7849C2.70915 17.9668 3.63614 19.2623 4.85759 19.2169C6.01361 19.1714 6.47166 18.4328 7.8676 18.4328C9.27445 18.4328 9.67796 19.2169 10.8994 19.1942C12.1645 19.1714 12.9606 18.0123 13.7349 16.8304C14.6183 15.4894 14.9782 14.1825 15 14.1143C14.9782 14.0916 12.5571 13.1257 12.5353 10.2164C12.5135 7.78446 14.4438 6.62531 14.531 6.55713C13.4405 4.87522 11.7392 4.69339 11.1502 4.64794Z"
        fill="black"
      />
    </svg>
  );
}

export default Apple;
