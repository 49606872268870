class SessionSummaryDrawerActions {
  constructor() {
    this.generateActions(
      'fetchError',
      'fetchSuccess',
      'mounted',
      'selectTab',
      'setEditMode',
      'toggleEditMenu',
      'toggleMinimized'
    );
  }
}

export default alt.createActions(SessionSummaryDrawerActions);
