import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import AltContainer from 'alt-container';
import { Dropdown } from '@upperhand/playmaker';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';

import ButtonMenu from 'shared/components/ButtonMenu.jsx';
import EmptyState from 'shared/components/EmptyState.jsx';
import NoPrintersIcon from 'shared/components/icons/empty_states/Printers.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import ZebraStore from 'shared/stores/ZebraStore.js';
import ZebraActions from 'shared/actions/ZebraActions.jsx';
import ZebraWifiSetup from 'shared/components/zebra/ZebraWifiSetup.jsx';
import ZebraMediaSettings from 'shared/components/zebra/ZebraMediaSettings.jsx';

import { FlexBoxCenter, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { STANDARD_DRAWER_WIDTH, smallScreen } from 'shared/utils/DOMUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';

import './styles.scss';

const styles = {
  button: {
    height: 40,
  },
  buttonLabel: {
    fontSize: 16,
  },
};

const setDefaultPrinter = event => {
  ZebraActions.setDefaultPrinter(event.target.value);
};
const loadPrinters = () => ZebraActions.loadPrinters();
const closePrintSettings = () => ZebraActions.closePrintSettings();
const saveDefaultPrinter = () => {
  ZebraActions.updatePrinterSN();
  ZebraActions.closePrintSettings();
};
const printTestReceipt = () => ZebraActions.printTestReceipt();
const printTestLabel = () => ZebraActions.printTestLabel();

function PrintTestMenu({ printing, intl }) {
  return (
    <ButtonMenu
      color="default"
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      targetOrigin={{ horizontal: 'middle', vertical: 'top' }}
      label={t('.test', intl, __filenamespace)}
      buttonType="raised"
      labelPosition="before"
      buttonStyle={{ ...styles.button, width: 100 }}
      labelStyle={styles.buttonLabel}
    >
      {printing ? (
        <MenuItem disabled={printing}>
          {t('.printing', intl, __filenamespace)}
        </MenuItem>
      ) : (
        <span>
          <MenuItem onClick={printTestReceipt} disabled={printing}>
            {t('.test_receipt', intl, __filenamespace)}
          </MenuItem>
          <MenuItem onClick={printTestLabel} disabled={printing}>
            {t('.test_label', intl, __filenamespace)}
          </MenuItem>
        </span>
      )}
    </ButtonMenu>
  );
}

function PrinterDropDown({
  zebraStore: { isLoadingDevices, defaultPrinter, printers },
  intl,
}) {
  let printerOptions = printers.map(printer => ({
    value: printer,
    label: printer.name || ' ',
  }));

  printerOptions = printerOptions
    .insert(0, {
      value: '',
      label: t('.none', intl, __filenamespace),
    })
    .toJS();

  return (
    <SpinWhileLoading isLoading={isLoadingDevices}>
      <FlexBoxCenter className="printer-dropdown">
        <Dropdown
          value={defaultPrinter}
          onChange={setDefaultPrinter}
          fullWidth
          items={printerOptions}
        />

        <IconButton onClick={loadPrinters}>
          <RefreshIcon />
        </IconButton>
      </FlexBoxCenter>
    </SpinWhileLoading>
  );
}

function CloseAndSave({ intl, printing }) {
  return (
    <FlexBoxCenter>
      <Button
        disabled={printing}
        variant="contained"
        style={{ ...styles.button, marginRight: 15 }}
        onClick={saveDefaultPrinter}
      >
        {t('actions.save', intl, __filenamespace)}
      </Button>
      <Button
        variant="contained"
        color="default"
        style={styles.button}
        onClick={closePrintSettings}
      >
        {t('actions.close', intl, __filenamespace)}
      </Button>
    </FlexBoxCenter>
  );
}

function PrintStatus({ zebraStore }) {
  return (
    <SpinWhileLoading isLoading={zebraStore.isLoadingStatus}>
      <div className="print-status">
        <FormattedMessage id={messageId('.printer_status', __filenamespace)} />
        <div className="print-status__list">
          {zebraStore.statuses.map(p => (
            <div key={p} className="item">
              <FormattedMessage id={messageId(`.${p}`, __filenamespace)} />
            </div>
          ))}
        </div>
      </div>
    </SpinWhileLoading>
  );
}

class PrinterDrawer extends React.Component {
  componentDidUpdate(prevProps) {
    const { zebraStore } = this.props;
    const { drawerOpen } = zebraStore;
    const { zebraStore: prevZebraStore } = prevProps;
    const { drawerOpen: prevDrawerOpen } = prevZebraStore;

    // Call ZebraActions.fetch() only when the drawer opens
    if (!prevDrawerOpen && drawerOpen && !currentUser().isClient()) {
      ZebraActions.fetch();
    }
  }

  render() {
    const {
      zebraStore: {
        confirmationOpen,
        drawerOpen,
        isLoadingDevices,
        mediaOpen,
        networkOpen,
        printers,
        printing,
        wifiSettings,
        zebraSettings,
      },
      zebraStore,
      intl,
    } = this.props;

    return (
      <Drawer
        disableEnforceFocus
        open={drawerOpen}
        anchor="right"
        className="print-settings-drawer"
        PaperProps={{
          sx: {
            width: smallScreen(420)
              ? window.innerWidth * 0.9
              : STANDARD_DRAWER_WIDTH,
          },
        }}
        onClose={closePrintSettings}
      >
        <FlexBoxJustify className="drawer-header">
          <h2 className="drawer-header__text">
            <FormattedMessage
              id={messageId('.printer_settings', __filenamespace)}
            />
          </h2>
        </FlexBoxJustify>
        {printers.size > 0 && !isLoadingDevices ? (
          <div className="drawer-body">
            <div>
              <div className="drawer-body__heading">
                <FormattedMessage id={messageId('.printer', __filenamespace)} />
              </div>
              <div className="drawer-body__dropdown-label">
                <FormattedMessage
                  id={messageId('.choose_printer', __filenamespace)}
                />
              </div>
              <PrinterDropDown zebraStore={zebraStore} intl={intl} />
              <FlexBoxJustify className="printer-settings">
                <ZebraWifiSetup
                  zebraSettings={zebraSettings}
                  wifiSettings={wifiSettings}
                  networkOpen={networkOpen}
                  confirmationOpen={confirmationOpen}
                  intl={intl}
                />
                <ZebraMediaSettings
                  zebraSettings={zebraSettings}
                  mediaOpen={mediaOpen}
                  intl={intl}
                />
                <PrintTestMenu intl={intl} printing={printing} />
              </FlexBoxJustify>

              <PrintStatus zebraStore={zebraStore} intl={intl} />
              <CloseAndSave intl={intl} printing={printing} />
            </div>
          </div>
        ) : (
          <SpinWhileLoading isLoading={isLoadingDevices}>
            <EmptyState
              image={<NoPrintersIcon />}
              headerText={t('.empty_state_message', intl, __filenamespace)}
              primaryAction={{
                label: t('.search_for_printers', intl, __filenamespace),
                action: loadPrinters,
              }}
            />
          </SpinWhileLoading>
        )}
      </Drawer>
    );
  }
}

const PrintSettingsDrawer = injectIntl(({ intl }) => (
  <AltContainer stores={{ zebraStore: ZebraStore }}>
    <PrinterDrawer intl={intl} />
  </AltContainer>
));

export default PrintSettingsDrawer;
