import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { blue } from '@mui/material/colors';

import DaytimesExpander from 'event_mgmt/display/components/_DaytimesExpander.jsx';
import EventAthletes from 'event_mgmt/display/components/_EventAthletes.jsx';
import EventDescription from 'event_mgmt/display/components/_EventDescription.jsx';
import EventImage from 'event_mgmt/display/components/_EventImage.jsx';
import EventLocationAndStaff from 'event_mgmt/display/components/_EventLocationAndStaff.jsx';
import EventRestrictions from 'event_mgmt/display/components/_EventRestrictions.jsx';
import EventSchedule from 'event_mgmt/display/components/_EventSchedule.jsx';
import EventSeasons from 'event_mgmt/display/components/_EventSeasons.jsx';
import EventWaitingList from 'event_mgmt/display/components/_EventWaitingList.jsx';
import MembershipDiscountList from 'event_mgmt/display/components/_MembershipDiscountList.jsx';
import PurchaseButton from 'event_mgmt/display/components/_PurchaseButton.jsx';
import ScheduleButtons from 'shared/components/registration/ScheduleButtons.jsx';
import SchedulingDrawerActions from 'shared/actions/SchedulingDrawerActions';
import WaitlistItems from 'containers/clientProfile/components/Waitlist/WaitlistItems.jsx';
import uhTheme from '_uh_theme.jsx';
import {
  currentCustomer,
  enabledCustomerFeatures,
} from 'shared/utils/CustomerUtils.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { isLoggedIn, currentUser } from 'shared/utils/UserUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { Typography } from '@upperhand/playmaker';

const styles = {
  root: {
    fontFamily: uhTheme.fontFamily,
    maxWidth: '50rem',
    position: 'relative',
    margin: '0 30rem 0 3rem',
    paddingTop: '2rem',
  },

  actionColumn: {
    position: 'absolute',
    top: '-4.7rem', // Button height plus event header padding
    right: '-30rem',
    width: '28rem',
  },

  almostFull: {
    border: '1px solid var(--color-unpaid-red)',
    borderRadius: '2px',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2rem',
    padding: '1em',
    fontSize: '16px',
  },

  bottomDivider: {
    paddingBottom: 20,
    borderBottom: `2px solid ${uhColors.dividerGrey}`,
  },

  literal: `
    a { color: ${blue[300]}; }
    .event-description { font-family: ${uhTheme.fontFamily}; line-height: 1.7em; font-size: 15px; }
  `,
};

function AlmostFull({ spotsRemaining, style }) {
  return (
    <div style={merge(styles.almostFull, style)}>
      <FormattedMessage
        id={messageId('.spaces_left', __filenamespace)}
        values={{ spots: spotsRemaining }}
      />
    </div>
  );
}

function RightColumn({
  activeEventDiscounts,
  almostFull,
  athleteStore,
  automations,
  currentRegistrations,
  enrolledAthletes,
  event,
  clientCredits,
  isAvailableForPurchase,
  isAvailableForWaitlist,
  onPurchaseClick,
  onWaitlistClick,
  purchased,
  sessions,
  sessionsHasMore,
  sessionsLoading,
  spotsRemaining,
  waitlist,
}) {
  return (
    <div style={styles.actionColumn}>
      <PurchaseButton
        event={event}
        automations={automations}
        isAvailableForPurchase={isAvailableForPurchase}
        isPurchased={purchased}
        onPurchaseClick={onPurchaseClick}
        onWaitlistClick={onWaitlistClick}
        isAvailableForWaitlist={isAvailableForWaitlist}
        sessions={sessions}
        style={{ marginBottom: '2.4rem' }}
      />
      {event.isOpenBooking() &&
        !currentCustomer().disable_client_scheduling && (
          <ScheduleButtons
            event={event}
            clientCredits={clientCredits}
            profiles={athleteStore.allAthletes}
            registrations={currentRegistrations}
            onClientSelect={client =>
              SchedulingDrawerActions.clientSelected(client)
            }
            style={{ marginTop: '2.4rem' }}
          />
        )}
      {almostFull && <AlmostFull spotsRemaining={spotsRemaining} />}
      {!event.isOpenBooking() && (
        <DaytimesExpander
          style={{ marginTop: '2.4rem' }}
          sessionIds={event.session_ids}
          sessionsHasMore={sessionsHasMore}
          sessionsLoading={sessionsLoading}
        />
      )}
      <MembershipDiscountList
        eventDiscounts={activeEventDiscounts}
        style={{ margin: '2.4rem 0' }}
      />
      {!event.isOpenBooking() && (
        <EventAthletes
          athletes={enrolledAthletes}
          style={{ marginTop: '2.4rem' }}
        />
      )}
      {event.allow_waitlist && (
        <EventWaitingList
          waitlist={waitlist}
          athletes={athleteStore}
          style={{ marginTop: '2.4rem' }}
        />
      )}
      {enabledCustomerFeatures(['classes_waitlist']) && (
        <>
          <Typography variant="body1">Waitlist Details</Typography>
          <WaitlistItems
            hideInfo
            clientId={currentUser().customer_user_id}
            eventId={event?.id}
          />
        </>
      )}
    </div>
  );
}

function EventLarge({
  activeEventDiscounts,
  almostFull,
  athleteStore,
  automations,
  clientCredits,
  creditGrants,
  currentRegistrations,
  enrolledAthletes,
  event,
  eventStaffStore,
  hasGenderOrAgeRestriction,
  isAvailableForPurchase,
  isAvailableForWaitlist,
  onPurchaseClick,
  onWaitlistClick,
  purchased,
  registrations,
  sessions,
  clients,
  sessionsHasMore,
  sessionsLoading,
  spotsRemaining,
  waitlist,
}) {
  return (
    <div style={styles.root}>
      <style
        type="text/css"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: styles.literal }}
      />
      <EventLocationAndStaff
        event={event}
        eventStaffStore={eventStaffStore}
        style={merge(styles.bottomDivider, { marginBottom: 20 })}
      />
      {event.isFixedSchedule() && (
        <EventSeasons seasons={event.seasons.join(', ')} />
      )}
      {hasGenderOrAgeRestriction && (
        <EventRestrictions
          event={event}
          style={merge(styles.bottomDivider, { marginBottom: 20 })}
        />
      )}
      {isLoggedIn() && purchased && (
        <EventSchedule
          allowSingleSessionPurchase={event.allow_single_session_purchase}
          athletes={athleteStore}
          clients={clients}
          event={event}
          registrations={registrations}
          sessions={sessions}
          staff={eventStaffStore.staff}
          style={merge(styles.bottomDivider, { marginBottom: 20 })}
        />
      )}
      <EventDescription event={event} style={{ marginTop: '2rem' }} />
      <EventImage event={event} />
      <RightColumn
        activeEventDiscounts={activeEventDiscounts}
        almostFull={almostFull}
        athleteStore={athleteStore}
        automations={automations}
        clientCredits={clientCredits}
        creditGrants={creditGrants}
        currentRegistrations={currentRegistrations}
        enrolledAthletes={enrolledAthletes}
        event={event}
        isAvailableForPurchase={isAvailableForPurchase}
        isAvailableForWaitlist={isAvailableForWaitlist}
        onPurchaseClick={onPurchaseClick}
        onWaitlistClick={onWaitlistClick}
        purchased={purchased}
        sessions={sessions}
        sessionsHasMore={sessionsHasMore}
        sessionsLoading={sessionsLoading}
        spotsRemaining={spotsRemaining}
        waitlist={waitlist}
      />
    </div>
  );
}

export default EventLarge;
