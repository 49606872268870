import * as React from 'react';
import AltContainer from 'alt-container';
import { FormattedMessage, injectIntl } from 'react-intl';

import Drawer from '@mui/material/Drawer';
import { Tabs } from '@upperhand/playmaker';

import EventRateCreationActions from 'contacts/shared/actions/event_rates/EventRateCreationActions';
import EventRateEditingActions from 'contacts/shared/actions/event_rates/EventRateEditingActions';
import SecondaryDrawerActions from 'shared/actions/SecondaryDrawerActions.jsx';
import SecondaryDrawerStore from 'shared/stores/SecondaryDrawerStore.jsx';
import StaffAvailabilityStore from 'shared/stores/StaffAvailabilityStore.jsx';
import StaffDetailsDrawerActions from 'contacts/shared/actions/StaffDetailsDrawerActions.jsx';
import StaffDetailsDrawerEditing from 'contacts/index/components/StaffDetailsDrawerEditing.jsx';
import StaffDetailsDrawerPayroll from 'contacts/index/components/StaffDetailsDrawerPayroll.jsx';
import StaffDetailsDrawerStore, {
  INFO_TAB,
  PAYROLL_TAB,
} from 'contacts/shared/stores/StaffDetailsDrawerStore.jsx';
import StaffDetailsDrawerView from 'contacts/index/components/StaffDetailsDrawerView.jsx';
import UserDetailsDrawerAvatar from 'user_management/shared/components/_UserDetailsDrawerAvatar.jsx';
import { FlexBox } from 'shared/components/FlexBox.jsx';
import uhTheme from '_uh_theme.jsx';
import {
  AvailabilityScheduleDataStore,
  StaffUnavailabilityDataStore,
} from 'dataStores';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { smallScreen } from 'shared/utils/DOMUtils';

const styles = {
  editButton: {
    marginBottom: 10,
    marginTop: 10,
  },

  editButtonLabel: {
    color: uhTheme.palette.primary1Color,
  },

  header: {
    alignItems: 'center',
    borderBottom: '1px solid #e1e1e1',
    paddingLeft: 30,
    paddingRight: 10,
    minHeight: 56,
  },

  headerText: {
    fontWeight: 'bold',
  },

  tab: {
    backgroundColor: 'var(--color-white)',
    textTransform: 'capitalize',
  },
};

function StaffDetails({
  detailsDrawer,
  availabilitySchedule,
  staffAvailability,
  staffUnavailability,
}) {
  return (
    <div style={{ padding: '20px 20px 0 20px' }}>
      {detailsDrawer.infoEditMode ? (
        <StaffDetailsDrawerEditing staffAvailability={staffAvailability} />
      ) : (
        <StaffDetailsDrawerView
          unavailabilityIds={detailsDrawer.unavailabilityIds}
          staffUnavailability={staffUnavailability}
          staffUser={detailsDrawer.staff}
          availabilitySchedule={availabilitySchedule}
          canEditInfo={detailsDrawer.canEditInfo}
        />
      )}
    </div>
  );
}

function TabbedContent({
  detailsDrawer,
  availabilitySchedule,
  staffAvailability,
  staffUnavailability,
  intl,
}) {
  return (
    <Tabs
      variant="fullWidth"
      value={detailsDrawer.activeTab}
      onChange={(_e, tab) => StaffDetailsDrawerActions.tabSelected(tab)}
      tabItems={[
        {
          label: t('.info', intl, __filenamespace),
          value: INFO_TAB,
          content: (
            <div>
              <StaffDetails
                detailsDrawer={detailsDrawer}
                availabilitySchedule={availabilitySchedule}
                staffAvailability={staffAvailability}
                staffUnavailability={staffUnavailability}
              />
            </div>
          ),
        },
        {
          label: t('.payroll', intl, __filenamespace),
          value: PAYROLL_TAB,
          content: (
            <StaffDetailsDrawerPayroll
              staff={detailsDrawer.staff}
              hourlyRate={detailsDrawer.hourlyRate}
              isEditing={detailsDrawer.payrollEditMode}
              isSaving={detailsDrawer.isSavingHourlyRate}
            />
          ),
        },
      ]}
    />
  );
}

const Content = injectIntl(
  ({
    availabilityScheduleDataStore: { availabilitySchedules },
    detailsDrawer,
    staffAvailability,
    staffUnavailability,
    drawerActions,
    drawer,
    intl,
  }) => {
    if (detailsDrawer.staffId === null) return null;

    const staffUser = detailsDrawer.staff;
    const availabilitySchedule = availabilitySchedules.get(
      staffUser.availability_schedule_id
    );

    const handleRequestChange = () => {
      drawerActions.setActive(null);
      EventRateCreationActions.cancel();
      EventRateEditingActions.cancel();
      if (detailsDrawer.infoEditMode)
        StaffDetailsDrawerActions.toggleInfoEditMode();
    };

    return (
      <Drawer
        disableEnforceFocus
        anchor="right"
        open={drawer.activeDrawer === 'StaffDetailsDrawer'}
        onClose={handleRequestChange}
        PaperProps={{
          sx: { width: smallScreen(420) ? window.innerWidth * 0.9 : 375 },
        }}
      >
        <FlexBox style={styles.header}>
          <h2 style={styles.headerText}>
            <FormattedMessage
              id={messageId('.staff_profile', __filenamespace)}
            />
          </h2>
        </FlexBox>
        <UserDetailsDrawerAvatar user={detailsDrawer.staff} />
        {detailsDrawer.canViewPayroll ? (
          <TabbedContent
            detailsDrawer={detailsDrawer}
            availabilitySchedule={availabilitySchedule}
            staffAvailability={staffAvailability}
            staffUnavailability={staffUnavailability}
            intl={intl}
          />
        ) : (
          <StaffDetails
            detailsDrawer={detailsDrawer}
            availabilitySchedule={availabilitySchedule}
            staffAvailability={staffAvailability}
            staffUnavailability={staffUnavailability}
          />
        )}
      </Drawer>
    );
  }
);

function StaffDetailsDrawer(props) {
  return (
    <AltContainer
      actions={{
        drawerActions: SecondaryDrawerActions,
      }}
      stores={{
        availabilityScheduleDataStore: AvailabilityScheduleDataStore,
        drawer: SecondaryDrawerStore,
        detailsDrawer: StaffDetailsDrawerStore,
        staffAvailability: StaffAvailabilityStore,
        staffUnavailability: StaffUnavailabilityDataStore,
      }}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Content {...props} />
    </AltContainer>
  );
}

export default StaffDetailsDrawer;
