import * as React from 'react';
import { Expander, Typography } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import { ClientDataStore, RegistrationDataStore } from 'dataStores';
import { t } from 'shared/utils/LocaleUtils.js';

import AddAttendee from './AddAttendee.jsx';
import AttendeeListCard from './AttendeeListCard.jsx';
import DetailsActions from '../actions.js';

const styles = {
  container: {
    margin: 10,
  },
};

function Attendees({
  noExpander,
  registrationIds,
  count,
  isAddingAttendee,
  isAddEnabled,
  searchedAttendeeName,
  selectableAttendees,
  loadingAutocomplete,
  clientDataStore: { clients },
  registrationDataStore: { registrations },
  eventId,
  expanded,
  isTeamEvent,
  intl,
}) {
  const attendeesContent = (
    <>
      {isAddingAttendee && (
        <div style={noExpander ? {} : styles.container}>
          <AddAttendee
            noTitle={noExpander}
            loadingAutocomplete={loadingAutocomplete}
            eventId={eventId}
            intl={intl}
            selectableAttendees={selectableAttendees}
            searchedAttendeeName={searchedAttendeeName}
          />
        </div>
      )}
      {[...registrationIds]
        .map(id => {
          const registration = registrations.get(id);
          if (!registration) return null;

          const attendee = clients.get(registration.client_id);
          if (!attendee) return null;

          return { id, registration, attendee };
        })
        .filter(Boolean) // Remove null values
        .sort((a, b) => a.attendee.name().localeCompare(b.attendee.name())) // Sort by name
        .map(({ id, registration, attendee }) => {
          if (isTeamEvent) {
            return (
              <AttendeeListCard
                key={id}
                eventId={eventId}
                client={attendee}
                avatar={<UserAvatar user={attendee} size={24} />}
                unpaid={false}
              />
            );
          }
          return (
            <AttendeeListCard
              key={id}
              noSpaceOnLeft={noExpander}
              eventId={eventId}
              client={attendee}
              avatar={<UserAvatar user={attendee} size={24} />}
              onRemove={() =>
                DetailsActions.stageRegistrationToRemove(registration)
              }
              unpaid={false}
            />
          );
        })}
    </>
  );

  if (noExpander) {
    return attendeesContent;
  }

  return (
    <Expander
      header={
        <Typography variant="subtitle2">
          {t('.attendees', intl, __filenamespace, {
            count,
          })}
        </Typography>
      }
      secondaryAction={
        isAddEnabled ? DetailsActions.initAttendeeAdd : undefined
      }
      secondaryActionIcon="add"
      expanded={expanded}
      behavior="controlled"
      onChange={DetailsActions.attendeesChanged}
      contentOffset={false}
    >
      {attendeesContent}
    </Expander>
  );
}

export default altContainer({
  stores: {
    clientDataStore: ClientDataStore,
    registrationDataStore: RegistrationDataStore,
  },
})(Attendees);
