/* eslint-disable */
import * as React from 'react';
import { withStyles } from '@material-ui/styles';

import CopyHref from 'shared/components/CopyHref.jsx';
import CustomerActions from 'shared/actions/CustomerActions.jsx';
import EditProfile from 'user_management/shared/components/_EditProfile.jsx';
import ImageUpload from 'components/ImageUpload';
import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import { FlexBox } from 'shared/components/FlexBox.jsx';
import { LEFT_MARGIN_PIXELS, SINGLE_COLUMN_WIDTH } from 'shared/utils/DOMUtils';
import { handleChange } from 'shared/helpers/ChangeHandler.jsx';
import { shortHumanUrl } from 'event_mgmt/shared/utils/FormattingUtils.jsx';
import { isEmpty, merge } from 'shared/utils/ObjectUtils.jsx';
import { uhFormElement } from 'shared/styles/uhStyles.jsx';
import {
  FirstName,
  LastName,
  Email,
  DateOfBirth,
} from 'user_management/shared/components/UserProfileFields.jsx';
import {
  Address1,
  Address2,
  BusinessName,
  BusinessType,
  City,
  DefaultCurrency,
  State,
  TaxId,
  TimeZone,
  PostalCode,
  WebAddress,
  SportsOffered,
  BusinessTypeInfo,
} from 'user_management/shared/components/BusinessProfileFields.jsx';

const styles = {
  container: {
    '& > div': {
      marginBottom: '16px',
    },
  },
  stateContainer: {
    display: 'flex',
    '& > div:first-child': {
      marginRight: '8px',
      width: 120,
    },
  },

  column: {
    flex: `0 1 ${SINGLE_COLUMN_WIDTH}`,
    maxWidth: '100%',
  },

  formField: {
    width: SINGLE_COLUMN_WIDTH,
    marginTop: -15,
  },

  root: {
    flexWrap: 'wrap',
    fontSize: 15,
    marginLeft: LEFT_MARGIN_PIXELS,
    marginRight: LEFT_MARGIN_PIXELS,
    marginBottom: LEFT_MARGIN_PIXELS,
    maxWidth: '100%',
  },
};

const handleSave = id => {
  return _e => CustomerActions.update({ id });
};
const handleBusinessProfileChange = handleChange({ actions: CustomerActions });
const handleLegalEntityChange = handleChange({
  actions: CustomerActions,
  namedAction: 'updateLegalEntity',
});
const handleCustomerNameChange = e => {
  const { value } = e.target;
  handleLegalEntityChange(e, value);
  handleBusinessProfileChange(e, value, 'name');
};
const handleLegalEntityAddressChange = handleChange({
  actions: CustomerActions,
  prefix: 'address',
  namedAction: 'updateLegalEntity',
});
const handleSportsOfferedChange = (e, val) =>
  CustomerActions.handleUpdateSportsOffered(val);
const required = it => (isEmpty(it) ? 'required' : null);

const BusinessInfoOne = ({ customerStore, classes }) => {
  const { customer, sportsOffered } = customerStore;

  return (
    <div
      className={classes.container}
      style={merge(styles.column, { order: 1, fontSize: '16px' })}
    >
      <CopyHref
        style={{ paddingBottom: '0.5em' }}
        label="Client Sign Up (URL)"
        labelStyle={uhFormElement.mimicMuiFloatingLabel}
        text={shortHumanUrl(
          `${
            window.app_host || window.location.origin
          }/accounts/new_user?customer_id=${customer.id}`,
          50
        )}
        href={`${
          window.app_host || window.location.origin
        }/accounts/new_user?customer_id=${customer.id}`}
      />
      <BusinessName
        customer={customer.legal_entity}
        onChange={handleCustomerNameChange}
        errorText={customerStore.fieldErrors.getErrors('legal_entity.name')}
        name="business_name"
        label="Legal Entity Name (e.g. business name)"
      />
      {/* moved TimeZone from  BusinessInfoTwo section */}
      <TimeZone
        customer={customer}
        onChange={handleBusinessProfileChange}
        errorText={customer.timeZoneErrorText}
      />
      {/* <FirstName
        userProfile={customer.legal_entity}
        onChange={handleLegalEntityChange}
        errorText={customerStore.fieldErrors.getErrors(
          'legal_entity.first_name'
        )}
        defaultValue={customer.first_name}
      />
      <LastName
        userProfile={customer.legal_entity}
        onChange={handleLegalEntityChange}
        errorText={customerStore.fieldErrors.getErrors(
          'legal_entity.last_name'
        )}
        defaultValue={customer.last_name}
      />
      <DateOfBirth
        userProfile={customer.legal_entity}
        onChange={handleLegalEntityChange}
        errorText={customerStore.fieldErrors.getErrors('legal_entity.dob')}
      />
      <Email userProfile={customer} onChange={handleBusinessProfileChange} />
      */}
      <WebAddress
        customer={customer}
        onChange={handleBusinessProfileChange}
        protocolRequired
      />
      <BusinessTypeInfo
        customer={customer}
        onChange={handleBusinessProfileChange}
      />
      <SportsOffered
        sportsOffered={sportsOffered}
        onChange={handleSportsOfferedChange}
      />
      {/*
      <DefaultCurrency
        customer={customer}
        onChange={handleBusinessProfileChange}
      />{' '}
      */}
    </div>
  );
};

const BusinessInfoTwo = ({ customerStore, classes }) => {
  const { customer } = customerStore;
  const legalEntity = customer.legal_entity;
  const address1ErrorText = required(legalEntity.address.line1);
  const cityErrorText = required(legalEntity.address.city);
  const stateErrorText = required(legalEntity.address.state);
  const postalCodeErrorText = required(legalEntity.address.postal_code);
  const timeZoneErrorText = required(customer.time_zone);

  return (
    <div
      className={classes.container}
      style={merge(styles.column, { order: 3 })}
    >
      <Address1
        customer={legalEntity}
        onChange={handleLegalEntityAddressChange}
        errorText={address1ErrorText}
        fieldName="line1"
      />
      <Address2
        customer={legalEntity}
        onChange={handleLegalEntityAddressChange}
        fieldName="line2"
      />
      <City
        customer={legalEntity}
        onChange={handleLegalEntityAddressChange}
        errorText={cityErrorText}
      />
      <div className={classes.stateContainer}>
        <State
          customer={legalEntity}
          onChange={handleLegalEntityAddressChange}
          errorText={stateErrorText}
        />
        <PostalCode
          customer={legalEntity}
          onChange={handleLegalEntityAddressChange}
          errorText={postalCodeErrorText}
        />
      </div>
      <TimeZone
        customer={customer}
        onChange={handleBusinessProfileChange}
        errorText={timeZoneErrorText}
      />
      <BusinessType
        customer={customer.legal_entity}
        onChange={handleLegalEntityChange}
        errorText={customerStore.fieldErrors.getErrors('legal_entity.type')}
      />
      <TaxId
        customer={customer.legal_entity}
        onChange={handleLegalEntityChange}
        errorText={customerStore.fieldErrors.getErrors('legal_entity.tax_id')}
      />
    </div>
  );
};

const BusinessLogoUpload = ({ customer }) => (
  <ImageUpload
    cropAspect={1}
    uploadContainerWidth={styles.formField.width}
    previewContainerHeight={165}
    previewContainerWidth={180}
    onChange={value => {
      handleBusinessProfileChange(null, value, 'logo');
    }}
    image={customer.logo}
    style={{ marginTop: LEFT_MARGIN_PIXELS }}
    publicFile
  />
);

const BusinessInfoTab = ({ customerStore, classes }) => {
  const shouldSpin = customerStore.isLoadingCustomer;

  return (
    <SpinWhileLoading isLoading={shouldSpin} spinWhile="isLoading">
      <EditProfile>
        <div style={{ marginLeft: LEFT_MARGIN_PIXELS, marginBottom: 16 }}>
          <BusinessLogoUpload customer={customerStore.customer} />
        </div>

        <FlexBox style={styles.root}>
          <BusinessInfoOne customerStore={customerStore} classes={classes} />
          {/* <div style={{ width: 90, order: 2 }} />
          <BusinessInfoTwo customerStore={customerStore} classes={classes} /> */}
        </FlexBox>

        <div
          style={merge(styles.column, {
            marginTop: -22,
            marginLeft: LEFT_MARGIN_PIXELS,
          })}
        >
          <StateChangingButton
            onClick={handleSave(customerStore.customer.get('id'))}
            disabled={
              customerStore.isLoadingCustomer ||
              (customerStore.hasBeenValidated && !customerStore.isValid)
            }
            inProgress={customerStore.isSaving}
          />
        </div>
      </EditProfile>
    </SpinWhileLoading>
  );
};

export default withStyles(styles)(BusinessInfoTab);
