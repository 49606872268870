import React from 'react';
import AltContainer from 'alt-container';
import { FormattedMessage, injectIntl } from 'react-intl';
import { List } from 'immutable';

import DoneIcon from '@mui/icons-material/Done';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import AddCircle from 'shared/components/icons/AddCircle.jsx';
import AvailableStaffStore from 'event_mgmt/editing/stores/AvailableStaffStore.jsx';
import DrawerHeader from 'shared/components/DrawerHeader.jsx';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import EventResourceDrawerStore, {
  STEP_LISTING,
  STEP_CREATING,
} from 'event_mgmt/editing/stores/EventResourceDrawerStore.js';
import EventResourceDrawerActions from 'event_mgmt/editing/actions/EventResourceDrawerActions.js';
import Paginator from 'shared/components/Paginator.jsx';
import ResourceActions from 'resources/actions/ResourceActions.js';
import ResourceEditingStore from 'resources/stores/ResourceEditingStore.js';
import ResourceListingActions from 'resources/actions/ResourceListingActions.js';
import ResourceListingStore from 'resources/stores/ResourceListingStore.js';
import ResourceForm from 'resources/components/ResourceForm.jsx';
import SearchBar from 'shared/components/SearchBar.jsx';
import SecondaryDrawerActions from 'shared/actions/SecondaryDrawerActions.jsx';
import SecondaryDrawerStore from 'shared/stores/SecondaryDrawerStore.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import StaffIcon from 'shared/components/icons/Staff.jsx';
import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import {
  FlexBox,
  FlexBoxCenter,
  FlexBoxJustify,
} from 'shared/components/FlexBox.jsx';
import { STANDARD_DRAWER_WIDTH, smallScreen } from 'shared/utils/DOMUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  button: {
    height: 50,
  },

  buttonLabel: {
    fontSize: 16,
  },

  cardContainer: {
    padding: 10,
    flex: '1 1 auto',
    overflowY: 'auto',
    backgroundColor: uhColors.headerGrey,
  },

  formContainer: {
    padding: 10,
  },

  searchBarIcon: {
    height: 20,
    width: 20,
    marginRight: 16,
  },
};

const onDrawerClose = () => SecondaryDrawerActions.close();
const onCreate = () => ResourceActions.createResourceClicked();

function ResourceCard({ resource, selected, available, style }) {
  return (
    <Paper
      style={merge(
        {
          padding: 16,
          fontSize: 15,
          cursor: available && !selected ? 'pointer' : 'default',
        },
        style
      )}
      onClick={() =>
        available && !selected && EventActions.resourceAdded(resource)
      }
    >
      <FlexBoxCenter>
        {selected ? (
          <DoneIcon
            style={{
              height: 18,
              width: 18,
              marginRight: 12,
              color: uhColors.green,
            }}
          />
        ) : (
          <AddCircle
            style={{ height: 18, width: 18, marginRight: 12 }}
            color={available ? uhColors.activeBlue : uhColors.iconLightGrey}
          />
        )}
        <div style={{ fontWeight: 'bold' }}>{resource.name}</div>
      </FlexBoxCenter>

      {!available && (
        <div
          style={{
            paddingLeft: 30,
            marginTop: 10,
            fontWeight: 'bold',
            color: uhColors.iconLightGrey,
          }}
        >
          <FormattedMessage id={messageId('.unavailable', __filenamespace)} />
        </div>
      )}

      <div style={{ paddingLeft: 30 }}>
        {resource.preferring_staff.map(s => (
          <FlexBoxCenter key={s.id} style={{ marginTop: 10 }}>
            <StaffIcon style={{ height: 18, width: 18, marginRight: 10 }} />
            <div>{s.name()}</div>
          </FlexBoxCenter>
        ))}
      </div>
    </Paper>
  );
}

const onPageSelect = (page, perPage) => {
  ResourceListingActions.paginationOptionsUpdated(page, perPage);
};

function ResourceListing({
  resourceListingStore,
  assignedResourceIds,
  availableResourceIds,
  isCheckingConflicts,
  isOpenBooking,
  intl,
}) {
  return (
    <FlexBox style={{ flexDirection: 'column', height: '100%' }}>
      <DrawerHeader
        title={t('.add_resource', intl, __filenamespace)}
        onClose={onDrawerClose}
      />

      <FlexBoxJustify style={{ alignItems: 'center', flex: '0 0 auto' }}>
        <SearchBar
          onTypingEnd={ResourceListingActions.searchStringUpdated}
          iconStyle={styles.searchBarIcon}
          searchText={resourceListingStore.searchString}
          style={{ height: 56, padding: '0 20px' }}
        />
        <Button
          onClick={onCreate}
          style={{
            color: uhColors.activeBlue,
            fontWeight: 'bold',
            fontSize: 15,
            padding: 0,
            marginRight: 10,
          }}
        >
          {t('.create', intl, __filenamespace)}
        </Button>
      </FlexBoxJustify>

      <Divider style={{ height: 2, flex: '0 0 auto' }} />

      <div style={styles.cardContainer}>
        <SpinWhileLoading
          isLoading={resourceListingStore.isLoading || isCheckingConflicts}
        >
          {resourceListingStore.resources.map(r => (
            <ResourceCard
              key={r.id}
              resource={r}
              selected={assignedResourceIds.has(r.id)}
              available={isOpenBooking || availableResourceIds.has(r.id)}
              style={{ marginBottom: 10 }}
            />
          ))}
        </SpinWhileLoading>
      </div>

      <Paginator
        compact
        currentPage={resourceListingStore.page}
        perPage={resourceListingStore.perPage}
        totalCount={resourceListingStore.totalCount}
        onPageSelect={onPageSelect}
        style={{ flex: '0 0 auto', backgroundColor: uhColors.headerGrey }}
      />
    </FlexBox>
  );
}

const onSave = () => ResourceActions.resourceSaved();
const onCancel = () => EventResourceDrawerActions.createResourceCancelled();

function ResourceCreation({ resourceEditingStore, intl }) {
  return (
    <div>
      <DrawerHeader
        title={t('.add_resource', intl, __filenamespace)}
        onClose={onDrawerClose}
      />

      <div style={styles.formContainer}>
        <ResourceForm
          resourceEditingStore={resourceEditingStore}
          intl={intl}
          style={{ marginBottom: 10 }}
        />

        <StateChangingButton
          fullWidth
          label={t('actions.save', intl, __filenamespace)}
          labelInProgress={t('actions.saving', intl, __filenamespace)}
          labelStyle={styles.buttonLabel}
          inProgress={resourceEditingStore.isSaving}
          style={merge(styles.button, { marginBottom: 10 })}
          onClick={onSave}
        />
        <Button
          fullWidth
          variant="contained"
          color="default"
          style={merge(styles.button, { color: uhColors.activeBlue })}
          onClick={onCancel}
        >
          {t('actions.cancel', intl, __filenamespace)}
        </Button>
      </div>
    </div>
  );
}

function EventResourceDrawerContent({
  assignedResourceIds,
  availableResourceIds,
  drawerOpen,
  eventResourceDrawerStore,
  isCheckingConflicts,
  resourceEditingStore,
  resourceListingStore,
  intl,
}) {
  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      PaperProps={{
        sx: {
          width: smallScreen()
            ? window.innerWidth * 0.9
            : STANDARD_DRAWER_WIDTH,
        },
      }}
      onClose={onDrawerClose}
    >
      {eventResourceDrawerStore.step === STEP_LISTING && (
        <ResourceListing
          resourceListingStore={resourceListingStore}
          assignedResourceIds={assignedResourceIds}
          availableResourceIds={availableResourceIds}
          isCheckingConflicts={isCheckingConflicts}
          isOpenBooking={eventResourceDrawerStore.isOpenBooking}
          intl={intl}
        />
      )}

      {eventResourceDrawerStore.step === STEP_CREATING && (
        <ResourceCreation
          resourceEditingStore={resourceEditingStore}
          intl={intl}
        />
      )}
    </Drawer>
  );
}

function EventResourceDrawer(props) {
  return (
    <AltContainer
      stores={{
        eventResourceDrawerStore: EventResourceDrawerStore,
        resourceEditingStore: ResourceEditingStore,
        resourceListingStore: ResourceListingStore,
        assignedResourceIds: () => ({
          store: EventStore,
          value: EventStore.getState()
            .customerEvent.getIn(['schedules', 0, 'schedule_resources'], List())
            .map(sr => sr.resource_id)
            .toSet(),
        }),
        availableResourceIds: () => ({
          store: AvailableStaffStore,
          value: AvailableStaffStore.getState().resourceIds,
        }),
        isCheckingConflicts: () => ({
          store: EventStore,
          value: EventStore.getState().isCheckingConflicts,
        }),
        drawerOpen: () => ({
          store: SecondaryDrawerStore,
          value:
            SecondaryDrawerStore.getState().activeDrawer ===
            'EventResourceDrawer',
        }),
      }}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <EventResourceDrawerContent {...props} />
    </AltContainer>
  );
}

export default injectIntl(EventResourceDrawer);
