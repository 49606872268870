import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { OrderedSet, Map } from 'immutable';
import {
  Drawer,
  Dropdown,
  MultipleSelect,
  TextField,
  Grid,
  Button,
  Typography,
} from '@upperhand/playmaker';

import DateRangeDropdown from 'components/DateRangeDropdown';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import { SessionDataStore } from 'dataStores';

import './styles.scss';

const GENDERS = ['male', 'female'];
const STATUSES = ['pending', 'invited', 'expired', 'joined', 'removed'];

function WaitlistFiltersDrawer({
  filters = Map(),
  open = false,
  onFilterChange = () => null,
  onApplyFilters = () => null,
  onClearFilters = () => null,
  onClose = () => null,
}) {
  const intl = useIntl();

  const genderOptions = GENDERS.map(g => ({
    label: t(`.${g}`, intl, __filenamespace),
    value: g,
  }));

  genderOptions.splice(0, 0, {
    label: t('.all_genders', intl, __filenamespace),
    value: 'all',
  });

  const statusesOptions = STATUSES.map(s => ({
    label: t(`.${s}`, intl, __filenamespace),
    value: s,
  }));

  const filtersChanged = filters.get('changed', false);

  return (
    <Drawer
      open={open}
      title="Filters"
      classes={{
        root: 'waitlist-filters-drawer',
        content: 'waitlist-filters-drawer__content',
        footer: 'waitlist-filters-drawer__footer',
      }}
      onClose={onClose}
      content={
        <Grid container spacing={2}>
          {filtersChanged && (
            <Grid item container justify="flex-end" xs={12}>
              <Button
                type="tertiary"
                classes={{ root: 'clear-btn' }}
                onClick={onClearFilters}
              >
                {t('.clear_filters', intl, __filenamespace)}
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            <DateRangeDropdown
              outline
              rounded={false}
              label={false}
              value={filters.getIn(['period', 'value'], 'all')}
              classes={{
                root: 'gender-dropdown',
                select: 'gender-dropdown__select',
              }}
              items={[
                {
                  label: t('.all_time', intl, __filenamespace),
                  value: 'all',
                },
              ]}
              onChange={value =>
                onFilterChange({
                  field: 'period',
                  value: Map(value),
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              fullWidth
              label={t('.gender', intl, __filenamespace)}
              value={filters.get('gender') || 'all'}
              classes={{
                root: 'gender-dropdown',
                select: 'gender-dropdown__select',
              }}
              items={genderOptions}
              onChange={e => {
                const { value } = e.target;

                onFilterChange({
                  field: 'gender',
                  value: value !== 'all' ? value : null,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MultipleSelect
              fullWidth
              outline
              selectAll
              name="statuses"
              label={t('.invite_status', intl, __filenamespace)}
              rounded={false}
              selectedItems={filters.get('statuses')?.toArray() || STATUSES}
              items={statusesOptions}
              onChange={selectedItems => {
                onFilterChange({
                  field: 'statuses',
                  value: OrderedSet(selectedItems),
                });
              }}
            />
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={5}>
              <TextField
                fullWidth
                label={t('.age', intl, __filenamespace)}
                type="number"
                value={filters.get('min_age')}
                numberInputProps={{ min: 0 }}
                onChange={e => {
                  const { value } = e.target;

                  onFilterChange({
                    field: 'min_age',
                    value,
                  });
                }}
              />
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justify="center"
              xs={2}
              className="age-divider"
            >
              <Typography variant="body1">–</Typography>
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                label="&nbsp;"
                type="number"
                value={filters.get('max_age')}
                numberInputProps={{ min: 0 }}
                onChange={e => {
                  const { value } = e.target;

                  onFilterChange({
                    field: 'max_age',
                    value,
                  });
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      }
      footer={
        <Button fullWidth disabled={!filtersChanged} onClick={onApplyFilters}>
          {t('.apply_filters', intl, __filenamespace)}
        </Button>
      }
    />
  );
}

WaitlistFiltersDrawer.propTypes = {
  open: PropTypes.bool,
  filters: PropTypes.instanceOf(Map),
  onFilterChange: PropTypes.func,
  onClose: PropTypes.func,
  onApplyFilters: PropTypes.func,
  onClearFilters: PropTypes.func,
};

export default compose(
  memo,
  altContainer({
    stores: {
      sessionDataStore: SessionDataStore,
    },
  })
)(WaitlistFiltersDrawer);
