import React from 'react';
import { injectIntl } from 'react-intl';
import { Drawer } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { t } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils.js';

import WaitlistItems from 'containers/clientProfile/components/Waitlist/WaitlistItems.jsx';

import ClientInfo from './ClientInfo.jsx';

import ClientProfileDrawerActions from './ClientProfileDrawer/Actions';
import ClientProfileDrawerStore from './ClientProfileDrawer/Store';

function WaitlistDrawer({
  intl,
  clientProfileDrawerStore: { showWaitlistDrawer, clientId },
}) {
  return (
    <Drawer
      classes={{ root: 'waitlist-drawer' }}
      open={showWaitlistDrawer}
      title={t('.title', intl, __filenamespace)}
      onClose={() => ClientProfileDrawerActions.toggleWaitlistDrawer({})}
      content={
        <div className="waitlist-drawer__content">
          <ClientInfo clientId={clientId} />
          <WaitlistItems clientId={clientId} />
        </div>
      }
    />
  );
}

export default compose(
  React.memo,
  injectIntl,
  altContainer({
    stores: {
      clientProfileDrawerStore: ClientProfileDrawerStore,
    },
  })
)(WaitlistDrawer);
