import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { Set } from 'immutable';

import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import CustomerEvent from 'event_mgmt/shared/records/CustomerEvent.jsx';
import { EventSource, RegistrationSource } from 'sources';

import CreditPassPackage from 'shared/records/CreditPassPackage.js';
import OrderItem from 'shared/records/OrderItem';

import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { customerScopedRoute } from 'shared/utils/RouteUtils';
import { addFakeOrderItem } from 'shared/utils/FakeOrderItemsUtils';
import history from 'routes/History.js';

import { EventDataStore, SessionDataStore } from 'dataStores';
import CartStore from 'event_mgmt/shared/stores/CartStore.jsx';

import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';

import BrowseEventsBookDrawerActions from './Actions';

class BrowseEventsBookDrawerStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      openDrawer: BrowseEventsBookDrawerActions.openDrawer,
      closeDrawer: BrowseEventsBookDrawerActions.closeDrawer,
      scheduleSession: BrowseEventsBookDrawerActions.scheduleSession,
      sessionBooked: BrowseEventsBookDrawerActions.sessionBooked,
      fetchEventSuccess: BrowseEventsBookDrawerActions.fetchEventSuccess,
      fetchEventError: BrowseEventsBookDrawerActions.fetchEventError,
      goBack: BrowseEventsBookDrawerActions.goBack,
      addedToCart: BrowseEventsBookDrawerActions.addedToCart,
    });
  }

  reset() {
    this.clientId = null;
    this.eventId = null;
    this.sessionIds = Set();
    this.event = new CustomerEvent();
    this.isOpen = false;
    this.isLoading = false;
  }

  openDrawer({ eventId, sessionIds = Set() }) {
    this.eventId = eventId;
    this.sessionIds = sessionIds;
    this.isOpen = true;
    this.handleFetch();
  }

  closeDrawer() {
    this.reset();
  }

  goBack() {
    this.clientId = null;
    this.sessionIds = Set();
  }

  addFakeOrderItem({ selectedSessions, creditPassId }) {
    const { events } = EventDataStore.getState();
    const { sessions } = SessionDataStore.getState();
    const event = events.get(this.eventId);
    const schedules = selectedSessions
      .map(sId => {
        const request = new RegistrationSource.Schedule(this.clientId);
        request.bySession(sId);

        return {
          ...request.toServer(),
          starts_at: sessions.get(sId).starts_at,
          ends_at: sessions.get(sId).ends_at,
          schedule_id: sessions.get(sId).schedule_id,
        };
      })
      .toArray();

    addFakeOrderItem({
      schedules,
      event,
      creditPassId,
      clientId: this.clientId,
    });
  }

  addedToCart({ selectedCreditPasses, selectedSessions }) {
    const { cart } = CartStore.getState();
    const { order_items: orderItems } = cart;

    selectedCreditPasses.forEach(id => {
      const orderInCart = orderItems.find(
        oi => oi.getIn(['orderable', 'credit_pass_id']) === id
      );

      const cp = new CreditPassPackage({
        credit_pass_id: id,
        client_ids: orderInCart
          ? [...orderInCart.orderable.client_ids.toArray(), this.clientId]
          : [this.clientId],
      });

      const orderItem = new OrderItem({
        orderable: cp,
        orderable_type: 'credit_pass_package',
        quantity: cp.client_ids.size,
        order_id: cart.id,
      });

      const params = {
        url: orderInCart ? `order_items/${orderInCart.id}` : 'order_items',
        data: JSON.stringify({
          attributes: orderItem.toServer(),
        }),
        success: () => {
          this.addToCartProcessing += 1;

          if (this.addToCartProcessing < selectedSessions.size) {
            return;
          }

          if (this.addToCartProcessing === selectedSessions.size) {
            this.addToCartProcessing = 0;
          }

          this.addFakeOrderItem({ selectedSessions, creditPassId: id });
          this.closeDrawer();

          CartActions.fetch.defer({});

          history.push(customerScopedRoute('cart'));
        },
        error: (...args) => {
          this.notifyError('error adding credit pass to cart', args);
        },
      };

      if (orderInCart) {
        uhApiClient.put(params);
      } else {
        uhApiClient.post(params);
      }
    });
  }

  handleFetch() {
    this.isLoading = true;

    EventSource.fetch({
      id: this.eventId,
      success: BrowseEventsBookDrawerActions.fetchEventSuccess,
      error: BrowseEventsBookDrawerActions.fetchEventError,
    });
  }

  scheduleSession(clientId) {
    this.clientId = clientId;
  }

  // eslint-disable-next-line class-methods-use-this
  sessionBooked() {
    const message = new TranslatableMessage({
      id: 'containers.browseEventsBookDrawer.session_booked',
    });

    MessageWindowActions.addMessage.defer(message);
  }

  fetchEventSuccess(event) {
    this.event = event;
  }

  fetchEventError() {
    this.isLoading = false;
  }
}

export default alt.createStore(
  BrowseEventsBookDrawerStore,
  'BrowseEventsBookDrawerStore'
);
