import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Set, Map } from 'immutable';
import {
  Spinner,
  Grid,
  Typography,
  TextField,
  Button,
  Confirmation,
} from '@upperhand/playmaker';

import Paginator from 'shared/components/Paginator.jsx';
import WaitlistEntries from 'containers/classes/classesList/components/Waitlist/WaitlistEntries.jsx';
import WaitlistFiltersDrawer from 'containers/classes/classesList/components/Waitlist/Filters/WaitlistFiltersDrawer.jsx';
import ActiveFilters from 'containers/classes/classesList/components/Waitlist/Filters/ActiveFilters.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils';
import { t } from 'shared/utils/LocaleUtils';

import ClientProfileDrawerActions from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/Actions';
import ClassesListActions from 'containers/classes/classesList/Actions';
import ClassesListStore from 'containers/classes/classesList/Store';

function Waitlist({ classesListStore }) {
  const intl = useIntl();
  const [entryToRemove, setEntryToRemove] = useState(null);

  useEffect(() => {
    ClassesListActions.listWaitlist();
  }, []);

  const {
    waitlistEntries,
    searchWaitlistText,
    pagination,
    loadingWaitlist,
    waitlistFiltersDrawerOpen,
    filters,
    activeFilters,
  } = classesListStore;
  const waitlistPagination = pagination.get('waitlist');

  return (
    <div className="waitlist-tab">
      <Grid container spacing={1} className="class-waitlist">
        <Grid item xs={9} md={10}>
          <TextField
            rounded
            fullWidth
            value={searchWaitlistText}
            outline={false}
            icon="search"
            placeholder={t('.search', intl, __filenamespace)}
            onChange={(_event, value) =>
              ClassesListActions.searchWaitlist(value)
            }
          />
        </Grid>
        <Grid item xs={3} md={2}>
          <Button
            rounded
            fullWidth
            classes={{
              root: 'class-waitlist__filter-button',
              label: 'class-waitlist__button-label',
            }}
            icon="filter"
            iconPosition="right"
            type="tertiary"
            onClick={ClassesListActions.waitlistFiltersDrawerToggle}
          >
            {t('.filters', intl, __filenamespace)}
          </Button>
        </Grid>
        {loadingWaitlist && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div style={{ textAlign: 'center' }}>
                <Spinner type="indeterminate" />
              </div>
            </Grid>
          </Grid>
        )}
        {!loadingWaitlist && (
          <>
            <Grid item xs={12}>
              <ActiveFilters
                activeFilters={activeFilters.toJS()}
                onRemoveFilterChips={ClassesListActions.removeWaitlistFilter}
              />
            </Grid>
            <Grid item xs={12} className="class-waitlist__table-header">
              <div className="class-waitlist__table-header-content">
                <Grid container xs={12} alignItems="center" spacing={1}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="fieldLabel">
                      {t('.name', intl, __filenamespace)}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="fieldLabel">
                      {t('.gender', intl, __filenamespace)}
                    </Typography>
                  </Grid>
                  <Grid item xs={11} md={3}>
                    <Typography variant="fieldLabel">
                      {t('.age', intl, __filenamespace)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Typography variant="fieldLabel">
                      {t('.waitlist_date', intl, __filenamespace)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Typography variant="fieldLabel">
                      {t('.invite_status', intl, __filenamespace)}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            {waitlistEntries.size === 0 && (
              <Grid
                item
                container
                alignItems="center"
                justify="center"
                xs={12}
                className="empty-state"
              >
                <Typography>
                  {t('.empty_state', intl, __filenamespace)}
                </Typography>
              </Grid>
            )}
            {waitlistEntries.size > 0 && (
              <>
                <WaitlistEntries
                  waitlistEntriesIds={waitlistEntries}
                  onRemoveEntry={setEntryToRemove}
                  onShowClient={ClientProfileDrawerActions.mounted}
                  onAddToEvent={entry =>
                    ClassesListActions.waitlistAddToEvent({ entry })
                  }
                />
                <Paginator
                  currentPage={waitlistPagination.page}
                  perPage={waitlistPagination.perPage}
                  totalCount={waitlistPagination.totalCount}
                  onPageSelect={ClassesListActions.waitlistPageChange}
                />
              </>
            )}
          </>
        )}
      </Grid>
      <WaitlistFiltersDrawer
        open={waitlistFiltersDrawerOpen}
        filters={filters.get('waitlist')}
        onClose={ClassesListActions.waitlistFiltersDrawerToggle}
        onFilterChange={ClassesListActions.setWaitlistFilters}
        onApplyFilters={ClassesListActions.applyWaitlistFilters}
        onClearFilters={ClassesListActions.clearWaitlistFilters}
      />
      <Confirmation
        hasCheckbox={false}
        open={Boolean(entryToRemove)}
        title={t('.remove_entry', intl, __filenamespace)}
        onConfirm={() => {
          ClassesListActions.deleteWaitlistEntry(entryToRemove?.id);
          setEntryToRemove(null);
        }}
        onCancel={() => setEntryToRemove(null)}
      >
        <Typography variant="body1">
          {t('.remove_entry_confirmation', intl, __filenamespace, {
            clientName: entryToRemove?.client?.name(),
          })}
        </Typography>
      </Confirmation>
    </div>
  );
}

Waitlist.propTypes = {
  classesListStore: PropTypes.shape({
    waitlistEntries: PropTypes.instanceOf(Set),
    filters: PropTypes.instanceOf(Map),
    loadingWaitlist: PropTypes.bool,
    searchWaitlistText: PropTypes.string,
    pagination: PropTypes.object,
    waitlistFiltersDrawerOpen: PropTypes.bool,
  }),
};

export default compose(
  memo,
  altContainer({
    stores: {
      classesListStore: ClassesListStore,
    },
  })
)(Waitlist);
