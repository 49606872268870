import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Spinner, Typography } from '@upperhand/playmaker';
import { injectIntl } from 'react-intl';
import { Set } from 'immutable';
import Backdrop from '@mui/material/Backdrop';

import { t } from 'shared/utils/LocaleUtils.js';
import { compose } from 'shared/utils/SharedUtils.js';
import altContainer from 'shared/hocs/altContainer.jsx';

import { StaffDataStore, EventDataStore } from 'dataStores';

import SessionSchedulingActions from './Actions';
import SessionGrouping from './components/SessionGrouping.jsx';
import SessionSchedulingStore, { STEPS } from './Store';
import StaffFilter from './components/StaffFilter.jsx';
import DayOfWeekFilter from './components/DayOfWeekFilter.jsx';
import LocationFilter from './components/LocationFilter.jsx';
import BookingSuccess from './components/BookingSuccess.jsx';
import CreditPasses from './components/CreditPasses.jsx';

import './styles.scss';

const styles = {
  loadingContainer: {
    textAlign: 'center',
  },

  button: {
    height: 56,
  },

  footer: {
    width: '100%',
  },
};

function SessionScheduling(props) {
  const {
    clientId = 0,
    eventId = 0,
    allowPast = false,
    onNext = () => {},
    onBack = () => {},
    onNextAddToCart = () => {},
    onViewMemberships = () => {},
    selectedSessionIds: mountedSelectedSessionIds = Set(),
    sessionSchedulingStore = {},
    eventDataStore = {},
    intl,
  } = props;

  React.useEffect(() => {
    SessionSchedulingActions.mounted.defer({
      allowPast,
      clientId,
      eventId,
      onNext,
      onNextAddToCart,
      selectedSessionIds: mountedSelectedSessionIds,
    });

    return () => {
      SessionSchedulingActions.unmount.defer();
    };
  }, [clientId, eventId]); // eslint-disable-line react-hooks/exhaustive-deps

  const { events } = eventDataStore;
  const {
    bookingProcessing,
    creditCountsLoading,
    creditsAvailable,
    sessionIds,
    staffIds,
    selectedSessionIds,
    sessionsLoading,
    staffLoading,
    staffFilter,
    sessionsHasMore,
    selectedDayOfWeek,
    selectedLocations,
    locationIds,
    step,
    creditPassesIds,
    selectedCreditPassIds,
    creditPassesLoading,
    eventsLoading,
    eventTypesLoading,
    waitlistEntriesLoading,
    waitlistEntriesIds,
    hasMemberships,
  } = sessionSchedulingStore;
  const event = events.get(eventId);
  const eventTypeColor = event?.getIn(
    ['event_type', 'color'],
    'var(--color-primary)'
  );
  const loading =
    waitlistEntriesLoading ||
    staffLoading ||
    creditCountsLoading ||
    !!bookingProcessing;
  const creditsCount = creditsAvailable === Infinity ? '∞' : creditsAvailable;

  React.useEffect(() => {
    const scrollContainer = document.querySelector(
      '.scheduling-sessions__list'
    );

    if (scrollContainer) {
      // On screens with 2k+ resolution, the scroll event is not triggered so to fix loading more sessions we need to check if the container is bigger than the content
      const containerHeight = scrollContainer.clientHeight;
      const childrenHeight = [...scrollContainer.children].reduce(
        (acc, el) => acc + el.clientHeight,
        0
      );
      const shouldLoadMore =
        containerHeight > childrenHeight && sessionsHasMore && !sessionsLoading;

      if (shouldLoadMore) {
        SessionSchedulingActions.sessionsLoadMore();
      }
    }

    const handleLoadMore = () => {
      if (!scrollContainer) return;

      const atBottom =
        scrollContainer.scrollTop + scrollContainer.clientHeight >=
        scrollContainer.scrollHeight;

      if (atBottom && sessionsHasMore && !sessionsLoading) {
        SessionSchedulingActions.sessionsLoadMore();
      }
    };

    if (!loading) {
      scrollContainer?.addEventListener('scroll', handleLoadMore);
    }

    return () => scrollContainer?.removeEventListener('scroll', handleLoadMore);
  }, [loading, sessionsHasMore, sessionsLoading]);

  const handleBookBtnClick = () => {
    if (creditsAvailable > 0 || creditsAvailable === Infinity) {
      SessionSchedulingActions.bookSessions();
      return;
    }

    SessionSchedulingActions.setStep(STEPS.CREDIT_PASS_CHOOSE);
  };

  if (step === STEPS.SESSION_BOOK_SUCCESS) {
    return (
      <BookingSuccess
        clientId={clientId}
        sessionIds={selectedSessionIds}
        action={onBack}
      />
    );
  }

  if (step === STEPS.CREDIT_PASS_CHOOSE) {
    return (
      <CreditPasses
        isLoading={creditPassesLoading || eventsLoading || eventTypesLoading}
        sessionIds={selectedSessionIds}
        creditPassesIds={creditPassesIds}
        selectedCreditPassIds={selectedCreditPassIds}
        clientId={clientId}
        eventId={eventId}
        eventTypeId={event?.getIn(['event_type', 'id'])}
        hasMemberships={hasMemberships}
        onMount={SessionSchedulingActions.listCreditPasses}
        onPassSelect={SessionSchedulingActions.selectCreditPass}
        onAddToCart={SessionSchedulingActions.addCreditPassToCart}
        onViewMemberships={onViewMemberships}
      />
    );
  }

  return (
    <>
      <div className="scheduling-sessions">
        <Backdrop
          open={!!bookingProcessing}
          className="scheduling-sessions__backdrop"
        >
          <div className="scheduling-sessions__backdrop-content">
            <Spinner type="indeterminate" />
            <Typography variant="h6">
              {t('.processing', intl, __filenamespace)}
            </Typography>
          </div>
        </Backdrop>
        <div>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <LocationFilter
                value={selectedLocations}
                locationIds={locationIds}
                onChange={SessionSchedulingActions.updateSelectedLocations}
              />
            </Grid>
            <Grid item xs={6}>
              <DayOfWeekFilter
                value={selectedDayOfWeek}
                intl={intl}
                onChange={SessionSchedulingActions.updateSelectedDayOfWeek}
              />
            </Grid>
            <Grid item xs={12}>
              <StaffFilter
                staffIds={staffIds}
                value={staffFilter}
                onChange={SessionSchedulingActions.filterStaff}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body3">
                {t('.sessions_title', intl, __filenamespace)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body3"
                align="right"
                className="scheduling-sessions__credits"
              >
                <div
                  className="scheduling-sessions__credits-middot"
                  style={{ backgroundColor: eventTypeColor }}
                />
                &nbsp;
                {t('.credits_available', intl, __filenamespace, {
                  creditsCount,
                })}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className="scheduling-sessions__list">
          <SessionGrouping
            waitlistEntriesIds={waitlistEntriesIds}
            creditsCount={creditsCount}
            clientId={clientId}
            onSelect={SessionSchedulingActions.selectSession}
            onJoinWaitlist={SessionSchedulingActions.joinWaitlist}
            selectedSessions={selectedSessionIds}
            sessionIds={sessionIds}
            sessionsLoading={sessionsLoading}
          />
        </div>
        {sessionsLoading && (
          <div className="scheduling-sessions__spinner">
            <Spinner />
          </div>
        )}
      </div>
      <div style={styles.footer}>
        <Grid container spacing={0}>
          <Grid item xs={4}>
            <Button
              fullWidth
              type="tertiary"
              onClick={onBack}
              size="4x"
              variant="body2"
              disabled={!!bookingProcessing}
              classes={{
                root: 'scheduling-sessions__button',
                label: 'scheduling-sessions__button-label',
              }}
            >
              {t('.btn_cancel', intl, __filenamespace)}
            </Button>
          </Grid>
          <Grid item xs={8}>
            <Button
              fullWidth
              classes={{
                root: 'scheduling-sessions__button',
              }}
              onClick={handleBookBtnClick}
              disabled={selectedSessionIds.size <= 0 || !!bookingProcessing}
              size="4x"
            >
              {(creditsAvailable > 0 || creditsAvailable === Infinity) &&
                !creditCountsLoading && (
                  <Grid
                    container
                    alignItems="center"
                    justify="space-between"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography
                        variant="button2"
                        className="scheduling-sessions__button-label"
                      >
                        {t('.btn_ok', intl, __filenamespace)}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        className="scheduling-sessions__button-label"
                      >
                        {t('.btn_ok_info', intl, __filenamespace, {
                          selectedCount: selectedSessionIds.size,
                          allCount:
                            creditsAvailable === Infinity
                              ? '∞'
                              : creditsAvailable,
                        })}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              {creditsAvailable <= 0 &&
                creditsAvailable !== Infinity &&
                !creditCountsLoading && (
                  <Typography
                    variant="button2"
                    className="scheduling-sessions__button-label"
                  >
                    {t('.continue_to_summary', intl, __filenamespace)}
                  </Typography>
                )}
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

SessionScheduling.propTypes = {
  allowPast: PropTypes.bool,
  clientId: PropTypes.number,
  eventId: PropTypes.number,
  intl: PropTypes.object.isRequired,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  onNextAddToCart: PropTypes.func,
  onViewMemberships: PropTypes.func,
  selectedSessionIds: PropTypes.instanceOf(Set), // TODO: this should be a Set of strings
  sessionSchedulingStore: PropTypes.object,
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      eventDataStore: EventDataStore,
      staffDataStore: StaffDataStore,
      sessionSchedulingStore: SessionSchedulingStore,
    },
  })
)(SessionScheduling);
