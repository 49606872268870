import React, { memo } from 'react';
import { Checkbox, Dropdown, Grid, Typography } from '@upperhand/playmaker';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { t } from 'shared/utils/LocaleUtils';

import EditSessionActions from '../actions.js';

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    flexGrow: 1,
  },
  childrenContainer: {
    margin: '16px 8px 0',
  },
  title: {
    marginBottom: 16,
  },
  formElement: {
    margin: '16px 0 8px 0',
  },
  textField: {
    '& .UH-MuiInputBase-root': {
      height: '42px',
      borderRadius: '2px',
    },
  },
};

const schedulingDeadlineUnits = [
  { label: 'Min', value: 'min' },
  { label: 'Hour', value: 'hour' },
  { label: 'Day', value: 'day' },
];

const schedulingTimeFrameUnits = [
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' },
];

const cancellationUnits = [
  { label: 'Min', value: 'min' },
  { label: 'Hour', value: 'hour' },
];

function SessionPreferencesContent({
  intl,
  isTeamEvent,
  isLoading,
  editSessionStore,
  locationStore,
}) {
  const {
    data,
    scheduleDeadlineUnit,
    schedulingTimeFrameUnit,
    cancellationDeadlineUnit,
    locationId,
  } = editSessionStore;
  const scheduleDeadlineValue = data.get('scheduling_deadline');
  const cancellationDeadlineValue = data.get('cancellation_deadline');
  const schedulingTimeFrameValue = data.get('scheduling_timeframe');
  const isFixedSchedule = data.get('is_fixed_schedule');
  const isSspOn = data.get('is_ssp_on');
  const maxSize = data.get('max_size', 999);
  const minMaxSize = data.get('client_ids').size || 1;
  const showMaxSize = !isFixedSchedule || (isFixedSchedule && isSspOn);

  const { allLocations: locations } = locationStore;
  const locationsItems = locations
    .map(l => ({
      label: l.name || '',
      value: l.id,
    }))
    .toArray();

  const getValue = ({ e, defaultValue = 1, minValue = 1 }) => {
    const value = parseInt(e.target.value, 10);

    return value >= minValue ? value : defaultValue;
  };

  return (
    <Grid container spacing={1}>
      {isTeamEvent && (
        <Grid item xs={12}>
          <Typography
            variant="h5"
            className="events-session-creation__form-title"
          >
            {t('.location', intl, __filenamespace)}
          </Typography>
          <Box className="events-session-creation__form-item">
            <Dropdown
              fullWidth
              disabled={isLoading}
              value={locationId}
              onChange={e => {
                EditSessionActions.handleLocationChange(e.target.value);
              }}
              items={locationsItems}
            />
          </Box>
        </Grid>
      )}
      {showMaxSize && !isTeamEvent && (
        <Grid item xs={12}>
          <div className="edit-date-time-container__form-title">
            <Typography variant="h5">
              {t('.max_size', intl, __filenamespace)}
            </Typography>
          </div>
          <div className="events-session-creation__form-item">
            <TextField
              value={maxSize}
              fullWidth
              type="number"
              inputProps={{ min: minMaxSize }}
              onChange={e =>
                EditSessionActions.update(
                  'max_size',
                  getValue({
                    e,
                    minValue: minMaxSize,
                    defaultValue: minMaxSize,
                  })
                )
              }
            />
          </div>
        </Grid>
      )}
      {!isFixedSchedule && !isTeamEvent && (
        <>
          <Grid item xs={12}>
            <div className="edit-date-time-container__form-title">
              <Typography variant="h5">
                {t('.scheduling_deadline', intl, __filenamespace)}
              </Typography>
              <div className="checkbox">
                <Checkbox
                  checked={!!scheduleDeadlineValue}
                  onChange={() =>
                    EditSessionActions.update(
                      'scheduling_deadline',
                      scheduleDeadlineValue
                        ? null
                        : getValue({ e: { target: { value: 1 } } })
                    )
                  }
                />
              </div>
            </div>
            <div className="events-session-creation__form-item">
              {!!scheduleDeadlineValue && (
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={5}>
                    <Typography variant="fieldLabel">
                      {t('.before_session', intl, __filenamespace)}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      sx={styles.textField}
                      value={scheduleDeadlineValue}
                      fullWidth
                      type="number"
                      onChange={e =>
                        EditSessionActions.update(
                          'scheduling_deadline',
                          getValue({ e })
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Dropdown
                      fullWidth
                      value={scheduleDeadlineUnit}
                      items={schedulingDeadlineUnits}
                      onChange={e =>
                        EditSessionActions.setSchedulingUnit(e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
              )}
              {!scheduleDeadlineValue && (
                <Typography>
                  {t('.scheduling_info', intl, __filenamespace)}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="edit-date-time-container__form-title">
              <Typography variant="h5">
                {t('.cancellation_deadline', intl, __filenamespace)}
              </Typography>
              <div className="checkbox">
                <Checkbox
                  checked={!!cancellationDeadlineValue}
                  onChange={() =>
                    EditSessionActions.update(
                      'cancellation_deadline',
                      cancellationDeadlineValue
                        ? null
                        : getValue({ e: { target: { value: 24 } } })
                    )
                  }
                />
              </div>
            </div>
            <div className="events-session-creation__form-item">
              {!!cancellationDeadlineValue && (
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={5}>
                    <Typography variant="fieldLabel">
                      {t('.before_session', intl, __filenamespace)}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      sx={styles.textField}
                      value={cancellationDeadlineValue}
                      fullWidth
                      type="number"
                      onChange={e =>
                        EditSessionActions.update(
                          'cancellation_deadline',
                          getValue({ e })
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Dropdown
                      fullWidth
                      value={cancellationDeadlineUnit}
                      items={cancellationUnits}
                      onChange={e =>
                        EditSessionActions.setCancellationUnit(e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
              )}
              {!cancellationDeadlineValue && (
                <Typography>
                  {t('.cancellation_info', intl, __filenamespace)}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="edit-date-time-container__form-title">
              <Typography variant="h5">
                {t('.scheduling_timeframe', intl, __filenamespace)}
              </Typography>
              <div className="checkbox">
                <Checkbox
                  checked={!!schedulingTimeFrameValue}
                  onChange={() =>
                    EditSessionActions.update(
                      'scheduling_timeframe',
                      schedulingTimeFrameValue
                        ? null
                        : getValue({ e: { target: { value: 1 } } })
                    )
                  }
                />
              </div>
            </div>
            <div className="events-session-creation__form-item">
              {!!schedulingTimeFrameValue && (
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={5}>
                    <Typography variant="fieldLabel">
                      {t('.from_today', intl, __filenamespace)}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      sx={styles.textField}
                      value={schedulingTimeFrameValue}
                      fullWidth
                      type="number"
                      onChange={e =>
                        EditSessionActions.update(
                          'scheduling_timeframe',
                          getValue({ e })
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Dropdown
                      fullWidth
                      value={schedulingTimeFrameUnit}
                      items={schedulingTimeFrameUnits}
                      onChange={e =>
                        EditSessionActions.setSchedulingTimeFrameUnit(
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                </Grid>
              )}
              {!schedulingTimeFrameValue && (
                <Typography>
                  {t('.scheduling_timeframe_info', intl, __filenamespace)}
                </Typography>
              )}
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default memo(SessionPreferencesContent);
