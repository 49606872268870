import * as React from 'react';
import { Set } from 'immutable';
import { Expander, Typography } from '@upperhand/playmaker';
import { Box } from '@mui/material';

import altContainer from 'shared/hocs/altContainer.jsx';
import Staff from 'shared/records/Staff.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import { StaffDataStore } from 'dataStores';
import { t } from 'shared/utils/LocaleUtils.js';

import { withStyles } from '@material-ui/styles';
import AddStaff from './AddStaff.jsx';
import StaffListCard from './StaffListCard.jsx';
import DetailsActions from '../actions.js';

const styles = {
  container: {
    margin: '10px',
  },
  headerTypographyRoot: {
    maxWidth: '55px',
  },
  teamStaffContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
  },
};

function StaffList({
  expanded,
  intl,
  isAddingStaff,
  staffDataStore: { staff: allStaff },
  selectableStaff,
  staffIds,
  searchedStaffName,
  isTeamEvent,
  classes,
  simpleList = false,
  sessionStaffIds = Set(),
}) {
  const canRemove = staffIds.size > 1;

  const teamStaffContent = staffIds.map(s => {
    const staff = allStaff.get(s, new Staff());

    return (
      <Box sx={styles.teamStaffContainer} key={staff.id}>
        <Box>
          <UserAvatar user={staff} />
        </Box>
        <Box>
          <Box sx={{ fontWeight: 600 }}>{staff.name()}</Box>
          <Box>{staff.email}</Box>
        </Box>
      </Box>
    );
  });

  const eventStaffContent = (
    <Box>
      {isAddingStaff && (
        <Box sx={simpleList ? {} : styles.container}>
          <AddStaff
            intl={intl}
            noTitle={simpleList}
            selectableStaff={selectableStaff}
            searchedStaffName={searchedStaffName}
          />
        </Box>
      )}

      {staffIds.map(s => {
        const staff = allStaff.get(s, new Staff());
        const onRemove = canRemove
          ? () => DetailsActions.removeStaff(staff.id)
          : undefined;

        return (
          <StaffListCard
            key={staff.id}
            avatar={<UserAvatar user={staff} size={24} />}
            intl={intl}
            name={staff.name()}
            spaceOnLeft={!simpleList}
            isNew={simpleList && !sessionStaffIds?.includes(staff.id)}
            onRemove={onRemove}
          />
        );
      })}
    </Box>
  );

  if (isTeamEvent && !simpleList) {
    return (
      <Expander
        classes={{
          headerTypographyRoot: classes.headerTypographyRoot,
        }}
        header={
          <Typography variant="subtitle2">
            {t('.team_staff', intl, __filenamespace, {
              count: staffIds.size,
            })}
          </Typography>
        }
        expanded={expanded}
        behavior="controlled"
        onChange={DetailsActions.staffChangedTeam}
        contentOffset={false}
        arrowRight
      >
        {teamStaffContent}
      </Expander>
    );
  }

  if (isTeamEvent && Boolean(simpleList)) {
    return teamStaffContent;
  }

  if (simpleList) {
    return eventStaffContent;
  }

  return (
    <Expander
      header={
        <Typography variant="subtitle2">
          {t('.staff', intl, __filenamespace, {
            count: staffIds.size,
          })}
        </Typography>
      }
      secondaryAction={DetailsActions.initStaffAdd}
      secondaryActionIcon="add"
      expanded={expanded}
      behavior="controlled"
      onChange={DetailsActions.staffChanged}
      contentOffset={false}
    >
      {eventStaffContent}
    </Expander>
  );
}

export default altContainer({
  stores: {
    staffDataStore: StaffDataStore,
  },
})(withStyles(styles)(StaffList));
