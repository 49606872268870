import { Map } from 'immutable';

// eslint-disable-next-line import/no-cycle
import { UpperHandStore } from 'dataStores';
import { StaffUnavailabilityActions } from 'sources';

class StaffUnavailabilityDataStore extends UpperHandStore {
  constructor() {
    super();

    this.unavailabilities = Map();

    this.bindListeners({
      createSuccess: StaffUnavailabilityActions.createSuccess,
      createError: StaffUnavailabilityActions.createError,
      listSuccess: StaffUnavailabilityActions.listSuccess,
      listError: StaffUnavailabilityActions.listError,
      updateSuccess: StaffUnavailabilityActions.updateSuccess,
      updateError: StaffUnavailabilityActions.updateError,
      deleteSuccess: StaffUnavailabilityActions.deleteSuccess,
      deleteError: StaffUnavailabilityActions.deleteError,
    });
  }

  createSuccess(unavailability) {
    this.unavailabilities = this.unavailabilities.set(
      unavailability.id,
      unavailability
    );
  }

  createError(...args) {
    this.notifyError('Error creating staff unavailability', ...args);
  }

  listSuccess({ staff_unavailabilities: unavailabilities }) {
    const fetchedRecords = unavailabilities
      .groupBy(u => u.id)
      .map(u => u.first());

    this.unavailabilities = this.unavailabilities.merge(fetchedRecords);
  }

  listError(...args) {
    this.notifyError('Error listing staff unavailabilities', ...args);
  }

  updateSuccess(unavailability) {
    this.unavailabilities = this.unavailabilities.set(
      unavailability.id,
      unavailability
    );
  }

  updateError(...args) {
    this.notifyError('Error updating staff unavailability', ...args);
  }

  deleteSuccess(unavailability) {
    this.unavailabilities = this.unavailabilities.delete(unavailability.id);
  }

  deleteError(...args) {
    this.notifyError('Error deleting staff unavailability', ...args);
  }
}

export default alt.createStore(
  StaffUnavailabilityDataStore,
  'StaffUnavailabilityDataStore'
);
