import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as ReactRouterLink } from 'react-router-dom';

import SquareIcon from '@mui/icons-material/Square';

import { CREDIT_PASS_CREDIT_TYPES } from 'shared/records/CreditPassCredit.js';
import { FlexBox, FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';

const styles = {
  colorSquareContainer: {
    alignSelf: 'start',
    /*
     * This centers vertically with the top line - the text is 24px line height,
     * so this creates 2px margins on both top and bottom (bottom margin implicit).
     */
    marginTop: 2,
  },
  colorSquare: {
    width: 20,
    height: 20,
  },
  credits: {
    fontWeight: 'bold',
  },
  creditName: {
    marginLeft: 8,
    fontWeight: 'normal',
    display: 'block',
    /*
     * This was the inherited definition, but it is specified directly to
     * ensure that the manual vertically centering does not get messed up later.
     */
    lineHeight: '24px',
  },
  creditsForContainer: {
    margin: '5px 0',
  },
};

function Credit({ color, name, eventPath }) {
  const nameSpan = <span style={styles.creditName}>{name}</span>;

  return (
    <FlexBoxCenter>
      <FlexBox style={styles.colorSquareContainer}>
        <SquareIcon style={styles.colorSquare} sx={{ color }} />
      </FlexBox>
      {eventPath ? (
        <ReactRouterLink to={eventPath}>{nameSpan}</ReactRouterLink>
      ) : (
        nameSpan
      )}
    </FlexBoxCenter>
  );
}

function CreditPassWrapper({ creditPassCredit, children }) {
  return (
    <div
      className={`cp-view-credits-${creditPassCredit.id}`}
      style={styles.credits}
    >
      <div style={styles.creditsForContainer}>
        <FormattedMessage
          id={messageId('.credits_for', __filenamespace)}
          values={{ quantity: creditPassCredit.quantity }}
        />
      </div>
      {children}
    </div>
  );
}

function CreditPassCredits({ creditPass, eventTypes, events, style }) {
  return (
    <div className="cp-view-credits" style={style}>
      {creditPass.credit_pass_credits.map(creditPassCredit => {
        if (creditPassCredit.type === CREDIT_PASS_CREDIT_TYPES.all_events) {
          return (
            <CreditPassWrapper
              creditPassCredit={creditPassCredit}
              key={`credit-pass-credit-${creditPassCredit.id}`}
            >
              <FormattedMessage
                id={messageId('.all_events', __filenamespace)}
              />
            </CreditPassWrapper>
          );
        }

        const specificEventTypes = eventTypes.filter(eventType =>
          creditPassCredit.event_type_ids.includes(eventType.id)
        );

        const specificEvents = events.filter(e =>
          creditPassCredit.event_ids.includes(e.id)
        );

        return (
          <CreditPassWrapper
            creditPassCredit={creditPassCredit}
            key={`credit-pass-credit-${creditPassCredit.id}`}
          >
            {specificEventTypes.map(set => (
              <Credit
                key={`credit-event-type-${set.id}`}
                color={set.color}
                name={set.name}
                eventPath={
                  undefined
                  // This filter is getting removed - maybe remove?
                  // `${customerScopedRoute(`/events`)}?event_type[]=${set.id}`
                }
              />
            ))}
            {specificEvents.map(se => (
              <Credit
                key={`credit-event-${se.id}`}
                color={se.event_type.color}
                name={se.title}
                eventPath={se.admin_path}
              />
            ))}
          </CreditPassWrapper>
        );
      })}
    </div>
  );
}

export default CreditPassCredits;
