import { DefaultSourceActions } from './DefaultSourceActionList';

class WaitlistActions {
  constructor() {
    this.generateActions(
      'createWaitlistEntry',
      'createWaitlistEntrySuccess',
      'createWaitlistEntryError',
      'addToEventSuccess',
      'addToEventError',
      ...DefaultSourceActions
    );
  }
}

export default alt.createActions(WaitlistActions);
