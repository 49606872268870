import { OrderedSet, Map } from 'immutable';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import { WaitlistSource } from 'sources';

import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import ClientWaitlistActions from './Actions.js';

class ClientWaitlistStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      mounted: ClientWaitlistActions.mounted,
      list: ClientWaitlistActions.list,
      listSuccess: ClientWaitlistActions.listSuccess,
      listError: ClientWaitlistActions.listError,
      remove: ClientWaitlistActions.remove,
      removeSuccess: ClientWaitlistActions.removeSuccess,
      removeError: ClientWaitlistActions.removeError,
    });
  }

  reset = () => {
    this.clientId = null;
    this.eventId = null;
    this.isLoading = false;
    this.isRemoving = false;
    this.entryIds = OrderedSet();
    this.pagination = Map({
      page: 1,
      per_page: 50,
      total_count: 0,
      statuses: ['pending', 'invited'],
    });
  };

  mounted({ clientId, eventId }) {
    this.reset();

    if (!clientId && !eventId) return;

    this.clientId = clientId;
    this.eventId = eventId;
    this.list();
  }

  list() {
    this.isLoading = true;
    WaitlistSource.listEntries({
      params: {
        client_id: this.clientId,
        event_id: this.eventId,
        page: this.pagination.get('page'),
        per_page: this.pagination.get('per_page'),
        statuses: this.pagination.get('statuses'),
      },
      success: ClientWaitlistActions.listSuccess,
      error: ClientWaitlistActions.listError,
    });
  }

  listSuccess({ event_waitlist_entries: waitlist }) {
    this.isLoading = false;
    this.entryIds = OrderedSet(waitlist.map(entry => entry.id));
  }

  listError(...args) {
    this.isLoading = false;
    MessageWindowActions.addMessage.defer('Failed to load waitlist', args);
  }

  remove(entry) {
    this.isRemoving = true;
    WaitlistSource.removeEntry({
      id: entry.id,
      success: {
        action: ClientWaitlistActions.removeSuccess,
        args: [entry],
      },
      error: ClientWaitlistActions.removeError,
    });
  }

  removeSuccess([_, entry]) {
    this.isRemoving = false;
    this.pagination = this.pagination.set('page', 1);
    this.list();
    MessageWindowActions.addMessage.defer(
      `${entry.client.name()} removed from waitlist`
    );
  }

  removeError(...args) {
    this.isRemoving = false;
    MessageWindowActions.addMessage.defer(
      'Failed to remove from waitlist',
      args
    );
  }
}

export default alt.createStore(ClientWaitlistStore, 'ClientWaitlistStore');
