import * as React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@upperhand/playmaker';
import Session from 'shared/records/Session.js';
import { EventDataStore, SessionDataStore } from 'dataStores';

import CancelRegistrationModal from 'event_mgmt/shared/components/ParticipantClientEventList/CancelRegistrationModal.jsx';
import CustomerEvent from 'event_mgmt/shared/records/CustomerEvent.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';
import Attendees from './components/Attendees.jsx';
import DetailsActions from './actions';
import DetailsStore from './store';
import LocationDisplay from './components/LocationDisplay.jsx';
import Resources from './components/Resources.jsx';
import StaffList from './components/StaffList.jsx';
import PurchaseModal from './components/PurchaseModal.jsx';

import './styles.scss';

function Details({
  intl = {},
  detailsStore = {},
  sessionId = null,
  loadingSession = false,
  eventDataStore = {},
  sessionDataStore = {},
  eventId = null,
  isTeamEvent = false,
}) {
  const {
    totalRegistrations,
    clientsExpanded,
    clientId,
    isAddingStaff,
    isAddingResource,
    isAddingAttendee,
    loadingClients,
    loadingLocation,
    loadingResources,
    loadingStaff,
    loadingRegistrations,
    loadingAutocomplete,
    locationId,
    resourceIds,
    resourcesExpanded,
    selectableStaff,
    selectableResources,
    selectableAttendees,
    staffExpanded,
    staffIds,
    searchedStaffName,
    searchedResourceName,
    registrationToRemove,
    registrationIds,
    waiveBalance,
    creditOperation,
    searchedAttendeeName,
  } = detailsStore;

  const { events } = eventDataStore;
  const { sessions } = sessionDataStore;
  const session = sessions.get(sessionId, new Session());
  const event = events.get(eventId, new CustomerEvent());

  React.useEffect(() => {
    DetailsActions.mounted.defer(session, eventId);
  }, [session, eventId]);

  const isLoading =
    loadingSession ||
    loadingClients ||
    loadingResources ||
    loadingStaff ||
    loadingLocation ||
    loadingRegistrations;

  return (
    <div className="details-container">
      {isLoading ? (
        <div className="details-container__spinner">
          <Spinner type="indeterminate" />
        </div>
      ) : (
        <>
          <div className="details-container__list-container">
            <LocationDisplay locationId={locationId} />
          </div>
          <div className="details-container__list-container">
            <StaffList
              expanded={staffExpanded}
              intl={intl}
              isAddingStaff={isAddingStaff}
              selectableStaff={selectableStaff}
              staffIds={staffIds}
              searchedStaffName={searchedStaffName}
            />
          </div>
          <div className="details-container__list-container">
            <Attendees
              eventId={eventId}
              registrationIds={registrationIds}
              count={totalRegistrations}
              expanded={clientsExpanded}
              searchedAttendeeName={searchedAttendeeName}
              loadingAutocomplete={loadingAutocomplete}
              isAddingAttendee={isAddingAttendee}
              isAddEnabled={
                event.isClassSchedule() && !session.classGracePeriodReached()
              }
              selectableAttendees={selectableAttendees}
              isTeamEvent={isTeamEvent}
              intl={intl}
            />
          </div>
          <div className="details-container__list-container">
            <Resources
              resourceIds={resourceIds}
              isAddingResource={isAddingResource}
              expanded={resourcesExpanded}
              selectableResources={selectableResources}
              searchedResourceName={searchedResourceName}
              intl={intl}
            />
          </div>
          {!!clientId && <PurchaseModal clientId={clientId} intl={intl} />}
          <CancelRegistrationModal
            event={event}
            registration={registrationToRemove}
            intl={intl}
            waiveBalance={waiveBalance}
            creditOperation={creditOperation}
            onCancel={() => DetailsActions.stageRegistrationToRemove(null)}
            onConfirm={DetailsActions.cancelRegistration}
            onCreditOperationChange={DetailsActions.setCreditOperation}
            onWaiveChange={DetailsActions.setWaive}
          />
        </>
      )}
    </div>
  );
}

Details.propTypes = {
  intl: PropTypes.object,
  sessionId: PropTypes.string.isRequired,
  loadingSession: PropTypes.bool,
  eventDataStore: PropTypes.object,
  sessionDataStore: PropTypes.object,
  detailsStore: PropTypes.object,
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isTeamEvent: PropTypes.bool,
};

export default altContainer({
  stores: {
    detailsStore: DetailsStore,
    eventDataStore: EventDataStore,
    sessionDataStore: SessionDataStore,
  },
})(Details);
