import * as React from 'react';
import { List } from 'immutable';

import EventCard from 'calendar/components/views/shared/event_card/EventCard.jsx';

import CustomerEvent from 'event_mgmt/shared/records/CustomerEvent.jsx';

const styles = {
  EventCards: {
    position: 'absolute',
    top: '42px',
    right: '0',
    bottom: '0',
    left: '0',
    pointerEvents: 'none',
  },
};

const clientsForEventTime = (clientsMap, eventTime) =>
  eventTime.clientIds().map(id => clientsMap.get(id));

const staffForEventTime = (allStaffMap, eventTime) =>
  eventTime.staff_ids.map(id => allStaffMap.get(id));

const resourcesForEventTime = (resourcesMap, eventTime) =>
  eventTime.resource_ids.map(id => resourcesMap.get(id));

const eventTimeGroup = (eventTimes, eventTime) =>
  eventTimes.filter(e => e.dateRange().overlaps(eventTime.dateRange()));

function EventCards({
  allStaffMap,
  calendarEventTimes,
  clientsMap,
  eventsMap,
  membershipsMap,
  resourcesMap,
  sessions,
}) {
  let renderedEventTimes = List();

  return (
    <div style={styles.EventCards}>
      {calendarEventTimes.map(eventTime => {
        const group = eventTimeGroup(calendarEventTimes, eventTime);

        return group.map((gEventTime, index) => {
          if (renderedEventTimes.find(r => r === gEventTime)) return null;

          renderedEventTimes = renderedEventTimes.push(gEventTime);

          return (
            <EventCard
              key={`${index + gEventTime.dateRange().toString()}`}
              groupIndex={index}
              eventTime={gEventTime}
              event={
                gEventTime.isEvent()
                  ? eventsMap.get(gEventTime.event_id)
                  : new CustomerEvent({
                      event_type: { color: 'var(--color-old-gray)' },
                    })
              }
              clients={clientsForEventTime(clientsMap, gEventTime)}
              staff={staffForEventTime(allStaffMap, gEventTime)}
              resources={resourcesForEventTime(resourcesMap, gEventTime)}
              memberships={membershipsMap}
              sessions={sessions}
            />
          );
        });
      })}
    </div>
  );
}

export default EventCards;
