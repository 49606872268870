import * as React from 'react';
import PropTypes from 'prop-types';
import { Set } from 'immutable';
import Drawer from '@mui/material/Drawer';

import altContainer from 'shared/hocs/altContainer.jsx';
import SignInPrompt from 'shared/components/_SignInPrompt.jsx';
import CustomerEvent from 'event_mgmt/shared/records/CustomerEvent.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { isLoggedIn } from 'shared/utils/UserUtils.jsx';

import BrowseEventsBookDrawerActions from './Actions';
import BrowseEventsBookDrawerStore from './Store.js';

import Header from './components/Header.jsx';
import Content from './components/Content.jsx';

const styles = {
  drawerPaper: {
    width: '100%',
    maxWidth: '385px',
  },
  signInContainer: {
    padding: '15px 30px',
  },
};

function BrowseEventsBookDrawer({ browseEventsBookDrawerStore = {} }) {
  const { clientId, event, isOpen, sessionIds, eventId } =
    browseEventsBookDrawerStore;

  return (
    <Drawer
      disableEnforceFocus
      anchor="right"
      open={isOpen}
      PaperProps={{ sx: styles.drawerPaper }}
      onClose={BrowseEventsBookDrawerActions.closeDrawer}
    >
      <Header
        event={event}
        onClose={BrowseEventsBookDrawerActions.closeDrawer}
      />
      {isLoggedIn() ? (
        <Content
          eventId={eventId}
          clientId={clientId}
          sessionIds={sessionIds}
        />
      ) : (
        <SignInPrompt styles={styles.signInContainer} />
      )}
    </Drawer>
  );
}

BrowseEventsBookDrawer.propTypes = {
  browseEventsBookDrawerStore: PropTypes.shape({
    clientId: PropTypes.number,
    event: PropTypes.instanceOf(CustomerEvent),
    eventId: PropTypes.number,
    isOpen: PropTypes.bool,
    sessionIds: PropTypes.instanceOf(Set), // this should be a Set of strings
  }),
};

export default compose(
  altContainer({
    stores: {
      browseEventsBookDrawerStore: BrowseEventsBookDrawerStore,
    },
  })
)(BrowseEventsBookDrawer);
