import { Record } from 'immutable';
import moment from 'moment-timezone';
import Staff from 'shared/records/Staff.jsx';

class StaffUnavailability extends Record({
  id: null,
  staff: new Staff(),
  reason: '',
  starts_at: null,
  ends_at: null,
  created_at: null,
  updated_at: null,
}) {
  constructor(obj = {}) {
    super({
      ...obj,
      staff: new Staff(obj.staff),
      starts_at: obj.starts_at ? moment(obj.starts_at) : null,
      ends_at: obj.ends_at ? moment(obj.ends_at) : null,
      created_at: obj.created_at ? moment(obj.created_at) : null,
      updated_at: obj.updated_at ? moment(obj.updated_at) : null,
    });
  }
}

export default StaffUnavailability;
