import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { TextField, Button, Icon } from '@upperhand/playmaker';

import Form from 'shared/components/Form.jsx';
import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';

import { messageId, t } from 'shared/utils/LocaleUtils.js';

function OrderNoteForm({
  order,
  isLoading = false,
  onOrderNoteApply = () => {},
}) {
  const intl = useIntl();
  const [open, setOpen] = React.useState(false);
  const [orderNote, setOrderNote] = React.useState(order?.note || '');

  return (
    <div className="order-note-form">
      {!open && (
        <FlexBoxJustify
          className="order-note-form__header"
          onClick={() => setOpen(!open)}
        >
          <div className="order-note-form__title">
            <FormattedMessage id={messageId('.enter_note', __filenamespace)} />
          </div>
          <Icon name="add" />
        </FlexBoxJustify>
      )}
      {open && (
        <>
          <Form onSubmit={() => onOrderNoteApply({ orderNote })}>
            <TextField
              multiline
              rows={4}
              label={t('.note', intl, __filenamespace)}
              fullWidth
              onChange={e => setOrderNote(e.target.value)}
              onPaste={e => {
                e.preventDefault();
                setOrderNote(e.clipboardData.getData('Text').trim());
              }}
              value={orderNote}
            />
          </Form>
          <div className="order-note-form__actions">
            <Button
              classes={{
                root: 'order-note-form__cancel',
                label: 'order-note-form__cancel-label',
              }}
              type="tertiary"
              disabled={isLoading}
              onClick={() => setOpen(!open)}
            >
              {t('.cancel', intl, __filenamespace)}
            </Button>
            <StateChangingButton
              color="dark"
              label={t('.save', intl, __filenamespace)}
              labelInProgress={t('.saving', intl, __filenamespace)}
              inProgress={isLoading}
              onClick={() => onOrderNoteApply({ orderNote })}
            />
          </div>
        </>
      )}
    </div>
  );
}

OrderNoteForm.propTypes = {
  order: PropTypes.object.isRequired,
  onOrderNoteApply: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default injectIntl(OrderNoteForm);
