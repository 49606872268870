class DetailsActions {
  constructor() {
    this.generateActions(
      'addStaff',
      'addResource',
      'attendeesChanged',
      'addAttendee',
      'addAttendeeSuccess',
      'addAttendeeError',
      'cancelStaffAdd',
      'cancelResourceAdd',
      'cancelAttendeeAdd',
      'clientListError',
      'clientListSuccess',
      'creditCountListSuccess',
      'creditCountListError',
      'filterStaff',
      'filteredStaffListError',
      'filteredStaffListSuccess',
      'filteredResourceListSuccess',
      'filteredResourceListError',
      'filteredAttendeeListSuccess',
      'filteredAttendeeListError',
      'initAttendeeAdd',
      'initStaffAdd',
      'initResourceAdd',
      'locationListError',
      'locationListSuccess',
      'registrationListError',
      'registrationListSuccess',
      'mounted',
      'openPurchaseModal',
      'closePurchaseModal',
      'removeStaff',
      'removeResource',
      'resetChanges',
      'resourceListError',
      'resourceListSuccess',
      'resourcesChanged',
      'sessionStaffListError',
      'sessionStaffListSuccess',
      'sessionResourceListError',
      'sessionResourceListSuccess',
      'sessionUpdateError',
      'sessionUpdateSuccess',
      'stageRegistrationToRemove',
      'setCreditOperation',
      'setWaive',
      'cancelRegistration',
      'cancelRegistrationError',
      'cancelRegistrationSuccess',
      'staffChanged',
      'resourcesChanged',
      'updateResourceSearchText',
      'updateAttendeeSearchText',
      'updateStaffSearchText',
      'staffChangedTeam',
      'resourcesChangedTeam',
      'descriptionChanged',
      'setSessionTimes'
    );
  }
}

export default alt.createActions(DetailsActions);
