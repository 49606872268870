import React from 'react';
import { t } from 'shared/utils/LocaleUtils';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import CancelIcon from '@mui/icons-material/Cancel';

import altContainer from 'shared/hocs/altContainer.jsx';
import { CreditCountDataStore } from 'dataStores';
import { Grid, Typography, Chip } from '@upperhand/playmaker';

import DetailsActions from '../actions';

// TODO: Refactor Autocomplete to use this as CSS
const styles = {
  popUpIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

// TODO: Add autocomplete from Playmaker once it is done.
function AddAttendee({
  intl,
  noTitle,
  selectableAttendees,
  searchedAttendeeName,
  loadingAutocomplete,
  eventId,
  creditCountDataStore: { eventClients },
}) {
  const clientCredits = eventClients.get(eventId);

  const dataSource = loadingAutocomplete
    ? []
    : selectableAttendees.map(attendee => {
        const hasCredits =
          clientCredits?.get(attendee.value)?.availableCreditCount() > 0;

        return {
          label: attendee.text,
          value: attendee.value,
          hasCredits,
          credits: clientCredits,
        };
      });

  return (
    <Grid item xs={12}>
      {!noTitle && (
        <Typography variant="caption">
          {t('.add_attendee', intl, __filenamespace)}
        </Typography>
      )}
      <Autocomplete
        clearOnBlur
        noOptionsText=""
        inputValue={searchedAttendeeName || ''}
        sx={{ margin: '5px 0' }}
        options={dataSource}
        renderInput={params => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            placeholder={t('.placeholder', intl, __filenamespace)}
          />
        )}
        renderOption={(props, option) => (
          <MenuItem
            sx={{ justifyContent: 'space-between' }}
            key={option.value}
            onClick={() => {
              if (option.credits?.get(option.value)?.availableCreditCount() > 0)
                DetailsActions.addAttendee(option.value);
              else DetailsActions.openPurchaseModal(option.value);
            }}
          >
            {option.label}
            {option.hasCredits ? undefined : (
              <div style={{ display: 'inline', cursor: 'pointer' }}>
                <Chip
                  size="medium"
                  label={t('.no_credits', intl, __filenamespace)}
                  labelVariant="subtitle1"
                />
              </div>
            )}
          </MenuItem>
        )}
        popupIcon={
          <div
            role="presentation"
            style={styles.popUpIcon}
            onClick={DetailsActions.cancelAttendeeAdd}
          >
            <CancelIcon />
          </div>
        }
        onInputChange={(_e, value) =>
          DetailsActions.updateAttendeeSearchText([value])
        }
      />
      <Typography color="secondary" variant="body2">
        {t('.hint', intl, __filenamespace)}
      </Typography>
    </Grid>
  );
}

export default altContainer({
  stores: {
    creditCountDataStore: CreditCountDataStore,
  },
})(AddAttendee);
