import React from 'react';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import { Box } from '@mui/material';

import useUpdateTime from 'shared/utils/hooks/useUpdateTime';
import QuickScheduleActions from 'calendar/actions/QuickScheduleActions.jsx';
import { getIndicatorLineHeight } from 'calendar/utils/CalendarUtils.jsx';
import {
  HoursInDay,
  customerTZ,
} from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { smallScreen } from 'shared/utils/DOMUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';

const moment = extendMoment(Moment);

const styles = {
  TimeCells: {
    position: 'relative',
    borderRight: '1px solid #e3e3e3',
  },
  TimeCell: beforeNow => ({
    position: 'relative',
    height: '192px',
    borderBottom: '1px solid #e3e3e3',
    backgroundColor: beforeNow ? '#eaeaea' : '#f9f9f9',
  }),
  timeIndicatorLine: {
    position: 'absolute',
    left: '0',
    width: '100%',
  },
};

const scrollIntoViewAndShow = (el, dateRange, calendar) => {
  const container = document.getElementById('StandardCalendar');
  const { left } = el.getBoundingClientRect();
  const { scrollLeft } = container;

  const show = () => {
    QuickScheduleActions.open({
      cell: el,
      dateRange,
      calendar,
    });
  };

  if (smallScreen()) {
    container.scrollTo({ left: left + scrollLeft - 42, behavior: 'smooth' });
    show();
  } else {
    show();
  }
};

const eventZoneData = (e, el, divisions = 2) => {
  const { top, height } = el.getBoundingClientRect();
  const isTouch =
    (e.nativeEvent && e.nativeEvent.changedTouches) ||
    (e.changedTouches && e.changedTouches[0]);

  let clientY;

  if (isTouch) {
    clientY = e.nativeEvent
      ? e.nativeEvent.changedTouches[0].clientY
      : e.changedTouches[0].clientY;
  } else {
    clientY = e.nativeEvent ? e.nativeEvent.clientY : e.clientY;
  }

  const y = clientY - top;
  const zone = Math.max(Math.trunc((y / height) * divisions), 0);

  return {
    zone,
    divisions,
    height,
  };
};

const startMinuteFromEventZoneData = ({ zone, divisions, height }) =>
  ((zone * (height / divisions)) / height) * 60;

const endMinuteFromEventZoneData = ({ zone, divisions, height }) =>
  (((zone + 1) * (height / divisions)) / height) * 60;

function TimeIndicatorLine({ lineHeight, hasTimeIndicator }) {
  if (!hasTimeIndicator) return null;
  return (
    <>
      <Box
        sx={{
          ...styles.timeIndicatorLine,
          height: `${lineHeight}px`,
          backgroundColor: '#eaeaea',
          borderBottom: '1px solid var(--color-secondary)',
        }}
      />
      <Box
        sx={{
          ...styles.timeIndicatorLine,
          top: `${lineHeight}px`,
          height: `${192 - lineHeight}px`,
          backgroundColor: '#f9f9f9',
        }}
      />
    </>
  );
}

class TimeCell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstTouch: false,
      isDragging: false,
      quickScheduleDateRange: null,
    };

    this.el = null;

    this.handleClick = this.handleClick.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
  }

  handleClick(e) {
    e.preventDefault();

    if (currentUser().isInstructor()) {
      return;
    }

    const { isDragging } = this.state;
    const { date, hour } = this.props;

    if (!isDragging) {
      QuickScheduleActions.saveCellWidth(this.el.clientWidth);

      const zoneData = eventZoneData(e, this.el);
      const startMinute = startMinuteFromEventZoneData(zoneData);
      const startDate = date.clone().hour(hour).minute(startMinute).seconds(0);
      const endDate = startDate
        .clone()
        .add(60 / zoneData.divisions, 'minutes')
        .seconds(0);

      this.setState(
        () => ({
          quickScheduleDateRange: moment.range(startDate, endDate),
        }),
        () => {
          const { quickScheduleDateRange } = this.state;
          const { calendar } = this.props;

          scrollIntoViewAndShow(this.el, quickScheduleDateRange, calendar);
        }
      );
    }
  }

  handleMouseDown(e) {
    if (e.button !== 0) return;

    e.preventDefault();
    e.persist();

    if (currentUser().isInstructor()) {
      return;
    }

    window.addEventListener('mouseup', this.handleMouseUp);

    this.setState(
      () => ({
        firstTouch: true,
      }),
      () => {
        setTimeout(() => {
          const { firstTouch } = this.state;

          if (firstTouch) {
            this.setState({ isDragging: true });
            window.addEventListener('mousemove', this.handleMouseMove);

            const zoneData = eventZoneData(e, this.el);
            const startMinute = startMinuteFromEventZoneData(zoneData);
            const { date, hour } = this.props;
            const startDate = date
              .clone()
              .hour(hour)
              .minute(startMinute)
              .tz(customerTZ(), true);
            const endDate = startDate
              .clone()
              .add(60 / zoneData.divisions, 'minutes')
              .tz(customerTZ(), true);

            this.setState(
              () => ({
                quickScheduleDateRange: moment.range(startDate, endDate),
              }),
              () => {
                const { quickScheduleDateRange } = this.state;
                const { calendar } = this.props;

                scrollIntoViewAndShow(
                  this.el,
                  quickScheduleDateRange,
                  calendar
                );
              }
            );
          }
        }, 250);
      }
    );
  }

  handleMouseMove(e) {
    e.preventDefault();
    e.stopPropagation();

    if (currentUser().isInstructor()) {
      return;
    }

    const { isDragging, quickScheduleDateRange } = this.state;

    if (isDragging) {
      const zoneData = eventZoneData(e, this.el);
      const endMinute = endMinuteFromEventZoneData(zoneData);
      const endDate = quickScheduleDateRange.start
        .clone()
        .add(endMinute, 'minutes');

      this.setState(
        prevState => ({
          quickScheduleDateRange: moment.range(
            prevState.quickScheduleDateRange.start,
            endDate
          ),
        }),
        () => {
          QuickScheduleActions.updateDateRange(quickScheduleDateRange);
        }
      );
    }
  }

  handleMouseUp(e) {
    if (e.button !== 0) return;

    e.preventDefault();
    e.stopPropagation();

    if (currentUser().isInstructor()) {
      return;
    }

    this.setState(() => ({
      firstTouch: false,
      isDragging: false,
    }));

    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);
  }

  render() {
    const { beforeNow, hasTimeIndicator, lineHeight } = this.props;

    return (
      <Box
        role="presentation"
        data-qa="timeCell"
        ref={c => {
          this.el = c;
        }}
        sx={styles.TimeCell(beforeNow)}
        onClick={this.handleClick}
        onMouseDown={this.handleMouseDown}
      >
        <TimeIndicatorLine
          lineHeight={lineHeight}
          hasTimeIndicator={hasTimeIndicator}
        />
      </Box>
    );
  }
}

function TimeCells({ date = moment(), calendar }) {
  const currentTime = useUpdateTime();
  const lineHeight = getIndicatorLineHeight(currentTime);
  const currentHour = moment().hour();

  return (
    <div style={styles.TimeCells}>
      {HoursInDay.map(hour => (
        <TimeCell
          key={hour}
          date={date}
          hour={hour}
          beforeNow={hour < currentHour}
          calendar={calendar}
          hasTimeIndicator={hour === currentHour}
          lineHeight={lineHeight}
        />
      ))}
    </div>
  );
}

export default TimeCells;
