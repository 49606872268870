import React from 'react';

function Google() {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5442 8.75415C16.5442 8.17137 16.4973 7.58859 16.4035 7.0177H8.44348V10.3122H13.0038C12.8162 11.3707 12.2066 12.3222 11.3156 12.9169V15.0577H14.0354C15.6298 13.571 16.5442 11.3707 16.5442 8.75415Z"
        fill="#4285F4"
      />
      <path
        d="M8.44385 17.1272C10.7181 17.1272 12.6407 16.366 14.0358 15.0577L11.316 12.9169C10.554 13.4402 9.58099 13.7375 8.44385 13.7375C6.2399 13.7375 4.37593 12.2271 3.70771 10.2052H0.905884V12.4174C2.3361 15.3075 5.25516 17.1272 8.44385 17.1272Z"
        fill="#34A853"
      />
      <path
        d="M3.70803 10.2052C3.35628 9.14665 3.35628 7.99298 3.70803 6.92256V4.72226H0.905755C-0.301918 7.13664 -0.301918 9.99109 0.905755 12.4055L3.70803 10.2052Z"
        fill="#FBBC04"
      />
      <path
        d="M8.44385 3.39017C9.65133 3.36638 10.8119 3.83023 11.6794 4.67467L14.0944 2.22461C12.5587 0.773597 10.5423 -0.0232694 8.44385 0.000517618C5.25516 0.000517618 2.3361 1.83212 0.905884 4.72225L3.70771 6.93444C4.37593 4.90065 6.2399 3.39017 8.44385 3.39017Z"
        fill="#EA4335"
      />
    </svg>
  );
}

export default Google;
