/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

import Cart from 'cart/index/components/_Cart.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';

import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import altContainer from 'shared/hocs/altContainer.jsx';

import AutomationTemplateDescriptionStore from 'shared/stores/AutomationTemplateDescriptionStore.jsx';
import AthleteStore from 'event_mgmt/shared/stores/AthleteStore.jsx';
import CartStore from 'event_mgmt/shared/stores/CartStore.jsx';
import MembershipListingStore from 'memberships/stores/MembershipListingStore.jsx';
import RegistrationFormStore from 'shared/stores/RegistrationFormStore.jsx';
import MembershipViewingStore from 'memberships/stores/MembershipViewingStore.jsx';

import {
  CreditPassDataStore,
  StaffDataStore,
  SessionDataStore,
  EventDataStore,
} from 'dataStores';

import AthleteActions from 'event_mgmt/shared/actions/AthleteActions.jsx';
import AutomationTemplateDescriptionActions from 'shared/actions/AutomationTemplateDescriptionActions.jsx';
import CreditPassListingActions from 'credit_passes/actions/CreditPassListingActions.js';
import MembershipListingActions from 'memberships/actions/MembershipListingActions.jsx';
import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';

function CartIndex(props) {
  React.useEffect(() => {
    AthleteActions.list();

    MembershipListingActions.listRequested({});

    if (currentCustomer().credit_passes_enabled) {
      CreditPassListingActions.listRequested({});
    }

    return () => {
      CartActions.resetOnUnmount();
    };
  }, []);

  const { cart } = props;
  const { isLoadingCart } = cart;

  React.useEffect(() => {
    const eventIds = cart.cart.order_items?.map(
      item => item.orderable.event_id
    );

    if (eventIds && eventIds.size > 0) {
      AutomationTemplateDescriptionActions.list({ event_ids: eventIds.toJS() });
    }
  }, [cart.cart.order_items]);

  return (
    <main>
      <SpinWhileLoading isLoading={isLoadingCart}>
        <Cart
          className="iphone-x-content"
          payableTo={currentCustomer().name}
          {...props}
        />
      </SpinWhileLoading>
    </main>
  );
}

export default altContainer({
  stores: {
    athletes: AthleteStore,
    atdStore: AutomationTemplateDescriptionStore,
    cart: CartStore,
    membershipListingStore: MembershipListingStore,
    registrationForms: RegistrationFormStore,
    creditPassDataStore: CreditPassDataStore,
    staffDataStore: StaffDataStore,
    membershipViewingStore: MembershipViewingStore,
    sessionDataStore: SessionDataStore,
    eventDataStore: EventDataStore,
  },
})(CartIndex);
