import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Button } from '@upperhand/playmaker';

import UserAvatar from 'shared/components/_UserAvatar.jsx';

import { currentUser } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils';
import history from 'routes/History';

import WaitlistEntry from 'shared/records/WaitlistEntry';

function WaitlistItem({
  hideInfo = false,
  entry = new WaitlistEntry(),
  onRemove = () => {},
}) {
  const intl = useIntl();

  if (!entry) return null;

  const session = entry.get('session');
  const client = entry.get('client');
  const eventName = entry.getIn(['event', 'title']);
  const eventClientPath = entry.getIn(['event', 'path']);
  const eventAdminPath = entry.getIn(['event', 'admin_path']);
  const eventPath = currentUser().isClient() ? eventClientPath : eventAdminPath;

  return (
    <div className="client-waitlist__item">
      <div className="client-waitlist__item-session">
        <div>
          {!hideInfo && (
            <Typography
              variant="body1"
              className="client-waitlist__item-event-name"
            >
              {eventName}
            </Typography>
          )}
          <Typography variant="body1">{session?.formattedTime()}</Typography>
        </div>
        {!hideInfo && (
          <a
            href={eventPath}
            onClick={e => {
              e.preventDefault();
              history.push(eventPath);
            }}
          >
            {t('.view_event', intl, __filenamespace)}
          </a>
        )}
      </div>
      <div className="client-waitlist__item-client">
        <div className="client-waitlist__item-client-info">
          <UserAvatar user={client} size={25} />
          <Typography variant="body1">{client?.name()}</Typography>
        </div>
        <Button
          rounded
          type="tertiary"
          icon="close"
          onClick={() => onRemove(entry)}
        />
      </div>
    </div>
  );
}

WaitlistItem.propTypes = {
  entry: PropTypes.instanceOf(WaitlistEntry),
  hideInfo: PropTypes.bool,
  onRemove: PropTypes.func,
};

export default WaitlistItem;
