import * as React from 'react';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import { currentUser, isLoggedIn } from 'shared/utils/UserUtils.jsx';

function DynamicScriptLoader() {
  React.useEffect(() => {
    // Only load the scripts in production
    if (process.env.NODE_ENV !== 'production') {
      return;
    }

    const customer = currentCustomer();
    const user = currentUser();
    const loggedIn = isLoggedIn();
    const scripts = customer?.custom_tracking_scripts;

    // Only load the scripts if they exist or user is a client
    if (!scripts || (loggedIn && !user.isClient())) {
      return;
    }

    const wrapper = document.createElement('div');

    // Add an identifier to target it later
    wrapper.setAttribute('data-tracking-scripts', 'true');

    // Parse the scripts and append each to the DOM
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = scripts;

    const scriptElements = Array.from(tempDiv.querySelectorAll('script'));

    scriptElements.forEach(script => {
      const newScript = document.createElement('script');

      if (script.src) {
        // External script
        newScript.src = script.src;
        newScript.async = script.async;
      } else {
        // Inline script
        newScript.textContent = script.textContent;
      }

      wrapper.appendChild(newScript);
    });

    document.body.appendChild(wrapper);

    // Cleanup: Remove the scripts when the component unmounts
    // eslint-disable-next-line consistent-return
    return () => {
      // Ensure wrapper exists before removing to prevent errors
      if (wrapper?.parentNode) {
        wrapper.parentNode.removeChild(wrapper);
      }
    };
  }, []);

  return null; // No visible output
}

export default DynamicScriptLoader;
