import * as React from 'react';
import AltContainer from 'alt-container';
import { FormattedMessage, injectIntl } from 'react-intl';

import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';

import DrawerHeader from 'shared/components/DrawerHeader.jsx';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventStaffDrawerActions from 'event_mgmt/editing/actions/EventStaffDrawerActions.js';
import EventStaffDrawerStore from 'event_mgmt/editing/stores/EventStaffDrawerStore.js';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import ProfileCard from 'user_management/shared/components/ProfileCard.jsx';
import SearchBar from 'shared/components/SearchBar.jsx';
import SecondaryDrawerActions from 'shared/actions/SecondaryDrawerActions.jsx';
import SecondaryDrawerStore from 'shared/stores/SecondaryDrawerStore.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import { FlexBox } from 'shared/components/FlexBox.jsx';
import { STANDARD_DRAWER_WIDTH, smallScreen } from 'shared/utils/DOMUtils';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  cardContainer: {
    padding: 10,
    flex: '1 1 auto',
    overflowY: 'auto',
    backgroundColor: uhColors.headerGrey,
  },

  searchBarIcon: {
    height: 20,
    width: 20,
    marginRight: 16,
  },

  availableStaffCard: {
    marginBottom: 10,
  },

  unavailableStaffCard: {
    marginBottom: 10,
    color: 'grey',
  },
};

function Text({ children }) {
  return <div style={{ padding: 10, lineHeight: 1.4 }}>{children}</div>;
}

function StaffList({
  availableStaff,
  unavailableStaff,
  isCheckingConflicts,
  intl,
}) {
  return (
    <SpinWhileLoading isLoading={isCheckingConflicts}>
      {availableStaff.map(staff => (
        <ProfileCard
          key={staff.id}
          client={staff}
          subtext={t('.available', intl, __filenamespace)}
          style={styles.availableStaffCard}
          onClick={() => EventActions.staffAdded(staff.id)}
        />
      ))}

      {unavailableStaff.map(staff => (
        <ProfileCard
          key={staff.id}
          client={staff}
          subtext={t('.unavailable', intl, __filenamespace)}
          style={styles.unavailableStaffCard}
        />
      ))}

      {availableStaff.isEmpty() && unavailableStaff.isEmpty() ? (
        <Text>
          <FormattedMessage id={messageId('.no_results', __filenamespace)} />
        </Text>
      ) : (
        <div />
      )}
    </SpinWhileLoading>
  );
}

const drawerClosed = () => SecondaryDrawerActions.close();

const EventStaffDrawerContent = injectIntl(
  ({ eventStaffDrawerStore, isCheckingConflicts, drawerOpen, intl }) => {
    const { filterString, availableStaff, unavailableStaff, isScheduled } =
      eventStaffDrawerStore;

    return (
      <Drawer
        anchor="right"
        open={drawerOpen}
        PaperProps={{
          sx: {
            width: smallScreen()
              ? window.innerWidth * 0.9
              : STANDARD_DRAWER_WIDTH,
          },
        }}
        onClose={drawerClosed}
      >
        <FlexBox style={{ flexDirection: 'column', height: '100%' }}>
          <DrawerHeader
            title={t('.add_staff', intl, __filenamespace)}
            onClose={drawerClosed}
            style={{ flex: '0 0 auto' }}
          />

          <SearchBar
            onTypingEnd={EventStaffDrawerActions.filterStringUpdated}
            iconStyle={styles.searchBarIcon}
            searchText={filterString}
            style={{ height: 56, padding: '0 20px', flex: '0 0 auto' }}
          />

          <Divider style={{ height: 2, flex: '0 0 auto' }} />

          <div style={styles.cardContainer}>
            {isScheduled ? (
              <StaffList
                availableStaff={availableStaff}
                unavailableStaff={unavailableStaff}
                isCheckingConflicts={isCheckingConflicts}
                intl={intl}
              />
            ) : (
              <Text>
                <FormattedMessage
                  id={messageId('.not_scheduled', __filenamespace)}
                />
              </Text>
            )}
          </div>
        </FlexBox>
      </Drawer>
    );
  }
);

function EventStaffDrawer() {
  return (
    <AltContainer
      stores={{
        eventStaffDrawerStore: EventStaffDrawerStore,
        isCheckingConflicts: () => ({
          store: EventStore,
          value: EventStore.getState().isCheckingConflicts,
        }),
        drawerOpen: () => ({
          store: SecondaryDrawerStore,
          value:
            SecondaryDrawerStore.getState().activeDrawer === 'EventStaffDrawer',
        }),
      }}
    >
      <EventStaffDrawerContent />
    </AltContainer>
  );
}

export default EventStaffDrawer;
