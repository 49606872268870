import { List } from 'immutable';
import StaffUnavailability from 'shared/records/StaffUnavailability';
import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onSuccess, onError } from 'sources/handlers';
import DataStoreActions from './StaffUnavailabilityActions';

const parserTo = {
  type: StaffUnavailability,
  paginationKey: 'staff_unavailabilities',
};

const parser = new Parser(parserTo);

export const list = ({ params, success, error }) => {
  uhApiClient.get({
    url: parserTo.paginationKey,
    data: params,
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error: onError(DataStoreActions.listError, error),
  });
};

export const create = ({ recordAttributes, success, error }) => {
  uhApiClient.post({
    url: parserTo.paginationKey,
    data: JSON.stringify({
      attributes: recordAttributes,
    }),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.createSuccess, success])
    ),
    error: onError(List([DataStoreActions.createError, error])),
  });
};

export const update = ({ id, recordAttributes, success, error }) => {
  uhApiClient.put({
    url: `${parserTo.paginationKey}/${id}`,
    data: JSON.stringify({
      attributes: recordAttributes,
    }),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.updateSuccess, success])
    ),
    error: onError(List([DataStoreActions.updateError, error])),
  });
};

export const remove = ({ id, success, error }) => {
  uhApiClient.delete({
    url: `${parserTo.paginationKey}/${id}`,
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.deleteSuccess, success])
    ),
    error: onError(List([DataStoreActions.deleteError, error])),
  });
};
