import OrderItem from 'shared/records/OrderItem';
import { List } from 'immutable';

export const updateFakeOrderItem = orderItem => {
  const fakeOrderItems = window.localStorage.getItem('fake_order_items');
  const fakeOrderItemsParsed = JSON.parse(fakeOrderItems);

  if (fakeOrderItemsParsed) {
    const index = fakeOrderItemsParsed.findIndex(
      item => item.id === orderItem.id
    );

    if (index >= 0) {
      let item = fakeOrderItemsParsed[index];

      item = {
        ...item,
        orderable: {
          ...item.orderable,
          client_ids: [
            ...item.orderable.client_ids,
            ...orderItem.orderable.client_ids,
          ],
          tentative_details: [
            ...item.orderable.tentative_details,
            ...orderItem.orderable.tentative_details,
          ],
        },
      };

      fakeOrderItemsParsed[index] = item;
    } else {
      fakeOrderItemsParsed.push(orderItem);
    }

    window.localStorage.setItem(
      'fake_order_items',
      JSON.stringify(fakeOrderItemsParsed)
    );
  } else {
    window.localStorage.setItem(
      'fake_order_items',
      JSON.stringify([orderItem])
    );
  }
};

export const addFakeOrderItem = ({
  schedules,
  event,
  clientId,
  creditPassId,
}) => {
  const orderItem = {
    id: `book-before-purchase-${event.id}`,
    orderable: {
      id: 'book-before-purchase-package',
      client_ids: [clientId],
      event_id: event.id,
      schedule_type: 'class_book_sessions',
      credit_pass_id: creditPassId,
      tentative_details: schedules,
    },
    show_path: event.path,
    primary_display_text: event.title || 'Fake Order Item',
    secondary_display_text: event.event_type.name || 'Fake Order Item',
    orderable_type: 'class_registration_package',
  };

  updateFakeOrderItem(orderItem);
};

export const removeFakeOrderItem = id => {
  const fakeOrderItems = window.localStorage.getItem('fake_order_items');
  const fakeOrderItemsParsed = JSON.parse(fakeOrderItems);

  if (fakeOrderItemsParsed) {
    const index = fakeOrderItemsParsed.findIndex(item => item.id === id);

    if (index >= 0) {
      fakeOrderItemsParsed.splice(index, 1);
      window.localStorage.setItem(
        'fake_order_items',
        JSON.stringify(fakeOrderItemsParsed)
      );
    }
  }
};

export const getFakeOrderItems = () => {
  const fakeOrderItems = window.localStorage.getItem('fake_order_items');
  const fakeOrderItemsParsed = JSON.parse(fakeOrderItems);
  const fakeOrderItemsList = List(
    fakeOrderItemsParsed?.map(item => new OrderItem(item))
  );

  return fakeOrderItemsList;
};

export const getFakeOrderItemsSchedules = () => {
  const fakeOrderItems = window.localStorage.getItem('fake_order_items');
  const fakeOrderItemsParsed = JSON.parse(fakeOrderItems) || [];

  return fakeOrderItemsParsed.flatMap(item =>
    item.orderable.tentative_details.map(schedule => ({
      client_id: schedule.client_id,
      session_id: schedule.session_id,
    }))
  );
};

export const isFakeOrderItem = id => id.includes('book-before-purchase');

export const clearFakeOrderItems = () => {
  window.localStorage.removeItem('fake_order_items');
};

export const hasOnlyFakeOrderItems = orderItems =>
  orderItems.every(item => isFakeOrderItem(item.id));

export const removeFakeItemsWithoutPass = cpOrderItems => {
  if (!cpOrderItems) {
    clearFakeOrderItems();
    return;
  }

  const fakeOrderItems = getFakeOrderItems();
  const updatedOrderItems = fakeOrderItems.filter(item =>
    cpOrderItems.some(
      oi => oi.orderable.credit_pass_id === item.orderable.credit_pass_id
    )
  );

  if (updatedOrderItems.size > 0) {
    window.localStorage.setItem(
      'fake_order_items',
      JSON.stringify(updatedOrderItems)
    );
  } else {
    window.localStorage.removeItem('fake_order_items');
  }
};

export default {
  addFakeOrderItem,
  removeFakeOrderItem,
  updateFakeOrderItem,
  getFakeOrderItems,
  isFakeOrderItem,
};
